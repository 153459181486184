.landing-page-video {

  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;

  .landing-page-video__video {
    display: block;
    margin: auto;
    max-width: calc(160vh - 128px);
    border-radius: 6px;
    box-shadow: 0 15px 30px rgba(0,0,0,.15), 0 0 0 1px rgba(0,0,0,.05);
  }
}
