@import "../../../velotooler-app";

.vui-switch {
  cursor: pointer;
  width: 2.25rem;
  height: 1.25rem;
  background: $vui-dark-input-color;
  border: 1px solid $vui-color-primary;
  border-radius: 0.625rem;
  display: flex;
  align-items: center;
  @include transition-short(background);

  .vui-switch__thumb {
    @include size-rect(1rem);
    background: $color-white;
    border: 1px solid $vui-color-primary;
    border-radius: 0.5rem;
    box-shadow: 0 1px 3px 0 #1018281A;
    margin-left: 1px;
    @include transition-all-short();
  }

  &.checked {
    background: $vui-color-primary;

    .vui-switch__thumb {
      transform: translateX(16px);
    }
  }

  &.disabled {
    cursor: default;
  }

  .vui-switch__checkbox {
    left: -100px;
    top: 0;
    position: absolute;
  }
}
