@import "../variables";
@import "grid";

.stations-grid {
  @include gridContainer();

  .station {
    @include gridElement(station);

    .station__actions-tab {
      position: absolute;
      right: 0;
      top: 0;

      @include gridElementActionsTab();
    }

    .station__actions-tab-icon {
      font-size: 17px;
    }

    .station_serial {
      color: #6F6F6F;
      font-size: 0.8em;
    }
  }

  .fa-background {
    color: $vt-color-primary;
  }

  @include gridElementMedia(station);
}