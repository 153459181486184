@import "../../../../velotooler-app";

@mixin item-width($size) {
  .landing-page-scrolling-list__item--col-#{$size} {
    width: calc(100% / #{$size});

    a {
      max-width: #{6 - $size}30px;
    }
  }
}

.landing-page-scrolling-list {
  margin-top: 40px;
  min-height: 280px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 1rem;

  .landing-page-scrolling-list__item {
    margin: 0.5rem 0;

    a {
      color: $vui-light-text-color;
      line-height: 1.875rem;
      letter-spacing: 0.5px;
      transition: all 100ms ease-in-out;

      .scrolling-list-item__part-1 {
        transition: all 100ms ease-in-out;
      }

      .scrolling-list-item__part-2 {
        transition: all 100ms ease-in-out;
      }
    }
  }

  .landing-page-scrolling-list__item a:hover {
    color: $vui-color-primary;

    .scrolling-list-item__part-1 {
      font-weight: 900;
    }

    .scrolling-list-item__part-2 {
      font-weight: 600;
    }
  }

  .simplebar-scrollbar:before {
    -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.8);
    background-color: rgba(255, 255, 255, 0.8);
  }
}

@media only screen and (min-width: 1200px) {
  .landing-page-scrolling-list {
    width: 1170px !important;
  }
}

@media only screen and (min-width: 1441px) {
  .landing-page-scrolling-list {
    height: calc(100vh - 528px);
    margin-top: 20px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1440px) {
  .landing-page-scrolling-list {
    height: calc(100vh - 400px);
    margin-top: 0;
  }
}

@media only screen and (min-width: 992px) {
  .landing-page-scrolling-list {
    width: 970px;

    .landing-page-scrolling-list__container {
      margin: 0 -0.5rem;
      margin-top: -8px;

      .landing-page-scrolling-list__item {
        padding: 0 0.5rem;
        flex: 0 1 auto;
      }

      @for $i from 1 to 6 {
        @include item-width($i);
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .landing-page-scrolling-list {
    margin-top: 0;
    overflow-y: hidden;
    overflow-x: auto;
    height: calc(100vh - 428px);

    .landing-page-scrolling-list__container {
      height: calc(100vh - 428px);
      min-height: 290px;

      .landing-page-scrolling-list__item {
        padding: 0 0.5rem;
        max-width: 300px;
        flex: 0 1 auto;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .landing-page-scrolling-list {
    height: calc(100vh - 180px);

    .landing-page-scrolling-list__container {
      height: calc(100vh - 180px);
    }
  }
}
