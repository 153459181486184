.ng-ckeditor {
  border: 0 none;

  iframe {
    max-width: 100%;
  }
}

.cke_panel {
  iframe {
    max-width: 100%;
  }
}
