@import "../variables";

.gallery {
  overflow-y: hidden;
  overflow-x: auto;
  text-align: center;

  .img-container {
    position: relative;
    overflow-y: hidden;
    text-align: center;
    line-height: normal;
    margin: 3px;
    display: inline-block;

    .img-tmb {
      width: 140px;
      height: 140px;

      @media (max-width: 470px) {
        width: 100px;
        height: 100px;
      }
    }

    .remove {
      position: absolute;
      border-radius: 0;
      right: 0;
      top: 0;
      min-width: 36px;
      box-shadow: none !important;

      &.md-button {
        background-color: rgba(0, 0, 0, 0.6);
      }

      md-icon {
        text-align: center;
        color: $remove-icon-color;
        width: auto;
        height: auto;
      }

      &:active,
      &:hover,
      &:focus {
        md-icon {
          color: white;
        }
      }
    }

    .preview-button {
      margin: 0;
      padding: 0;
      border: none;
      border-radius: 0;
      box-shadow: none !important;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      opacity: .5;

      md-icon.fa {
        font-size: xx-large;
        color: $no-focusing-button;
        width: auto;
        height: auto;

        md-ripple-container {
          border-radius: 0;
        }
      }

      &:active,
      &:hover {
        md-icon.fa {
          color: $input-active-color
        }
      }

      &:active:after,
      &:hover:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $no-focusing-button;
      }
    }

    @media (min-width: 400px) {
      float: left;
    }
  }
}

.upload-images {
  .img-container {
    .retry {
      position: absolute;
      display: none;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 100;
    }

    .img-container__remove {
      padding: 3px 4px 3px 4px;

      md-icon {
        line-height: 21px;
      }
    }

    .preview-mask {
      display: none;
      border: 1px dashed #555;
    }

    .preview-mask__text {
      color: #999;
      height: 140px;
      width: 140px;
      display: table-cell;
      vertical-align: middle;
    }

    .remove {
      display: inline-block;
      margin: 0 !important;
      z-index: 100;
    }

    &.failed {
      .retry {
        display: block;
      }

      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: 0.8;
        z-index: 10;
      }
    }

    &.progress {
      .retry {
        display: none;
      }
      .remove {
        display: none;
      }
      .preview {
        display: none;
      }
      .preview-mask {
        display: block;
      }

      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.8;
        z-index: 10;
      }
    }
  }
}