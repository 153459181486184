/** =================== MONTSERRAT =================== **/

/** Montserrat Thin **/
@font-face {
  font-family: "Montserrat";
  font-weight: 100;
  font-style: normal;
  src: url("/fonts/Montserrat/Montserrat-Thin.ttf") format('truetype');
}

/** Montserrat Thin-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 100;
  font-style: italic;
  src: url("/fonts/Montserrat/Montserrat-ThinItalic.ttf") format('truetype');
}

/** Montserrat ExtraLight **/
@font-face {
  font-family: "Montserrat";
  font-weight: 200;
  font-style: normal;
  src: url("/fonts/Montserrat/Montserrat-ExtraLight.ttf") format('truetype');
}

/** Montserrat ExtraLight-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 200;
  font-style: italic;
  src: url("/fonts/Montserrat/Montserrat-ExtraLightItalic.ttf") format('truetype');
}

/** Montserrat Light **/
@font-face {
  font-family: "Montserrat";
  font-weight: 300;
  font-style: normal;
  src: url("/fonts/Montserrat/Montserrat-Light.ttf") format('truetype');
}

/** Montserrat Light-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 300;
  font-style: italic;
  src: url("/fonts/Montserrat/Montserrat-LightItalic.ttf") format('truetype');
}

/** Montserrat Regular **/
@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: normal;
  src: url("/fonts/Montserrat/Montserrat-Regular.ttf") format('truetype');
}

/** Montserrat Regular-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: italic;
  src: url("/fonts/Montserrat/Montserrat-Italic.ttf") format('truetype');
}

/** Montserrat Medium **/
@font-face {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  src: url("/fonts/Montserrat/Montserrat-Medium.ttf") format('truetype');
}

/** Montserrat Medium-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: italic;
  src: url("/fonts/Montserrat/Montserrat-MediumItalic.ttf") format('truetype');
}

/** Montserrat SemiBold **/
@font-face {
  font-family: "Montserrat";
  font-weight: 600;
  font-style: normal;
  src: url("/fonts/Montserrat/Montserrat-SemiBold.ttf") format('truetype');
}

/** Montserrat SemiBold-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 600;
  font-style: italic;
  src: url("/fonts/Montserrat/Montserrat-SemiBoldItalic.ttf") format('truetype');
}

/** Montserrat Bold **/
@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  font-style: normal;
  src: url("/fonts/Montserrat/Montserrat-Bold.ttf") format('truetype');
}

/** Montserrat Bold-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 700;
  font-style: italic;
  src: url("/fonts/Montserrat/Montserrat-BoldItalic.ttf") format('truetype');
}

/** Montserrat ExtraBold **/
@font-face {
  font-family: "Montserrat";
  font-weight: 800;
  font-style: normal;
  src: url("/fonts/Montserrat/Montserrat-ExtraBold.ttf") format('truetype');
}

/** Montserrat ExtraBold-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 800;
  font-style: italic;
  src: url("/fonts/Montserrat/Montserrat-ExtraBoldItalic.ttf") format('truetype');
}

/** Montserrat Black **/
@font-face {
  font-family: "Montserrat";
  font-weight: 900;
  font-style: normal;
  src: url("/fonts/Montserrat/Montserrat-Black.ttf") format('truetype');
}

/** Montserrat Black-Italic **/
@font-face {
  font-family: "Montserrat";
  font-weight: 900;
  font-style: italic;
  src: url("/fonts/Montserrat/Montserrat-BlackItalic.ttf") format('truetype');
}

/** Bebas Neue **/
@font-face {
  font-family: "Bebas Neue";
  font-weight: 400;
  font-style: normal;
  src: url("/fonts/Bebas_Neue/BebasNeue-Regular.ttf") format('truetype');
}
