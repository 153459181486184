.vtform__header {

  @media (max-width: $mobile-width) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $color-primary-l3;
    height: 64px;
    overflow: hidden;
  }

  @media (min-width: $not-mobile-width) {
    display: inline-block;
  }

  .vtform__header-back-btn {
    @media (max-width: $mobile-width) {
      position: absolute;
      float: left;
      height: 44px;
      left: 5px;
      top: 11px;

      .md-button {
        float: none !important;

        svg {
          width: 30px;
        }
      }
    }

    @media (min-width: $not-mobile-width) {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .vtform__header-title {
    display: inline-block;
    margin: 0;
    color: #aaa;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    @media (max-width: $mobile-width) {
      font-size: 1.2em;
      text-transform: uppercase;
      letter-spacing: 4px;
      float: left;
      height: 64px;
      position: absolute;
      left: 65px;
      top: 22px;
      width: 80%;
    }

    @media (min-width: $not-mobile-width) {
      height: 40px;
      vertical-align: middle;
      line-height: 40px;
      margin: 0 15px;
      font-size: 1.6em;
    }
  }
}