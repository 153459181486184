@import "../../../sass/variables";

.table-list {
  color: white;
  background-color: $form-background;
  font-size: 14px;

  .table-list__header {
    padding: 5px 15px;
    background-color: $color-primary-l2;
    display: none;

    @media (min-width: 768px) {
      display: block;
    }
  }

  .table-list__pl-loading-indicator {
    background-color: rgba(35, 35, 35, 0.9);
  }

  .table-list__header-row {
    color: #aaa;
    padding: 5px;
    text-transform: uppercase;
    margin: 0;
  }

  .table-list__body {
    padding: 15px 0;
    position: relative;
    min-height: 100px;
  }

  .table-list__data-row {
    padding: 10px 20px;
    border-bottom: 1px solid $color-primary-l2;
    margin: 0;

    &:hover {
      background-color: $color-primary-l2;
      cursor: pointer;
    }
  }

  .table-list__data-cell {
    font-weight: bold;
    margin-top: 15px;
    display: flex;
    position: relative;

    @media (min-width: 768px) {
      margin-top: 0;
    }

    &:before {
      content: attr(data-header)": ";
      font-weight: bold;
      display: inline-block;
      width: 8.5em;
      padding-right: 10px;
      vertical-align: top;
      color: #555;
      font-size: 15px;
      white-space: nowrap;

      @media (min-width: 768px) {
        display: none;
      }
    }
  }

  .tch-small {
    text-align: right;
  }

  .table-list__row {
    display: flex;
    display: -webkit-flex;
  }

  .table-list__col {
    flex: 1 1 100px;
    margin: 0 10px;
  }

  .table-list__paging {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }

  .table-list__col-10 {
    flex-grow: 1;
    -webkit-flex-grow: 1;
  }

  .table-list__col-20 {
    flex-grow: 2;
    -webkit-flex-grow: 2;
  }

  .table-list__col-30 {
    flex-grow: 3;
    -webkit-flex-grow: 3;
  }

  .table-list__col-40 {
    flex-grow: 4;
    -webkit-flex-grow: 4;
  }

  .table-list__col-50 {
    flex-grow: 5;
    -webkit-flex-grow: 5;
  }

  .table-list__col-60 {
    flex-grow: 6;
    -webkit-flex-grow: 6;
  }

  .table-list__col-70 {
    flex-grow: 7;
    -webkit-flex-grow: 7;
  }

  .table-list__col-80 {
    flex-grow: 8;
    -webkit-flex-grow: 8;
  }

  .table-list__col-90 {
    flex-grow: 9;
    -webkit-flex-grow: 9;
  }

  .table-list__col-100 {
    flex-grow: 10;
    -webkit-flex-grow: 10;
  }

  .table-list__data-cell-body {
    white-space: normal;
    display: inline-block;
  }

  .table-list__secondary-info {
    color: #777;
    font-size: 13px;
    font-weight: normal;
    white-space: nowrap;
  }

  .table-list__data-cell-icon {
    padding-right: 5px;
  }
}


