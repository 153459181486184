.request-flow__container {
  .request-flow__row {
    display: flex;
    align-items: center;

    .request-flow__row-number {
      flex: 1 0 50px;
      max-width: 50px;
      width: 50px;
      text-align: center;

      .row-number__label {
        width: 30px;
        height: 30px;
        border: 2px solid #4caa77;
        border-radius: 50%;
        line-height: 27px;
        margin: 0 10px;
        color: #4caa77;
      }
    }

    .request-flow__row-number:after, .request-flow__row-number:before {
      background-color: #4caa77;
      content: "";
      display: inline-block;
      height: 30px;
      width: 2px;
      position: relative;
      vertical-align: middle;
    }

    .request-flow__row-line {
      flex: 1 0 0;
      padding-left: 15px;
    }
  }
}

.task-flow-form {
  md-input-container:not(.md-input-invalid).md-input-has-value label {
    color: #F5F5F5 !important;
  }
}
