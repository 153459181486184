@import "../../../sass/variables";

.maintenance-page {
  color: white;
  font-size: 18px;

  .maintenance-page__header {
    height: 64px;
    background-color: $color-primary-l1;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }

  .maintenance-page__logo-container {
    height: 64px;
    width: 64px;
    display: inline-block;
  }

  .maintenance-page__logo {
    margin: auto;
    height: 64px;
    width: 42px;
    background: url(/img/velotooler-logo.png) center center no-repeat;
    background-size: contain;
    border-radius: 4px;
  }

  .maintenance-page__body {
    max-width: 800px;
    margin: 50px auto;
    padding: 30px;
    background-color: $color-primary;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }

  .maintenance-page__icon-container {
    text-align: center;
    margin-top: 60px;
  }
}