.landing-page-grid-three {
  .landing-page-grid-three__col {
    padding-right: 15px;
    padding-left: 15px;

    @media screen and (min-width: 901px) {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -ms-flex-positive: 1;
      flex-grow: 1;
      min-width: 0;
    }

    @media (max-width: 900px) {
      width: 100%;
    }
  }
}
