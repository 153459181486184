@import "../../../../velotooler-app";

@mixin carousel-animation($translateY, $opacity, $blur) {
  transform: translateY($translateY);
  opacity: $opacity;
  filter: blur($blur);
}

.landing-page-carousel {
  max-width: 555px;
  height: 450px;

  .card-stack {
    .landing-page-carousel__buttons {
      width: 3.5rem;
      height: 3.5rem;
      border-radius: 50%;
      border: 1px solid $color-dark;
      font-size: 1.375rem;
      color: $color-dark;
      line-height: 3.5rem;
      z-index: 100;
      @include transition-all-middle();

      path {
        stroke: $color-dark;
        @include transition-all-middle();
      }

      &:hover {
        background: $vui-color-primary;
        border: 1px solid $vui-color-primary;

        path {
          stroke: $color-white;
        }
      }
    }
  }
}

.transformThis {
  animation: scaleDown 600ms;
}

.transformPrev {
  animation: scaleUp 600ms;
}

@keyframes scaleUp {
  0% {
    @include carousel-animation(-100px, 0, 1px);
  }
  100% {
    @include carousel-animation(0, 1, 0);
  }
}

@keyframes scaleDown {
  0% {
    @include carousel-animation(0, 1, 0);
  }
  100% {
    @include carousel-animation(-100px, 0, 2px);
  }
}

@media only screen and (min-width: 992px) {
  .landing-page-carousel {
    .card-stack {
      margin-top: 94px;

      .landing-page-carousel__buttons {
        &.prev {
          top: 128.5px;
          left: -5.75rem;
          right: auto;
        }

        &.next {
          top: 128.5px;
          left: auto;
          right: -5.75rem;
        }
      }
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1440px) {
  .landing-page-carousel {
    .card-stack {
      margin-top: 62px;
    }
  }
}

@media only screen and (max-width: 991px) {
  .landing-page-carousel {
    .landing-page-carousel__controls {
      bottom: -40px;

      .landing-page-carousel__buttons {
        &.prev {
          margin-left: auto;
          margin-right: 10px;
        }

        &.next {
          margin-right: auto;
          margin-left: 10px;
        }
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .landing-page-carousel {
    .landing-page-carousel__controls {
      bottom: -20px;
    }

    height: 350px;
  }
}
