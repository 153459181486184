@import "../../../velotooler-app";

.vui-input-container {
  .vui-autocomplete {
    .vui-input::placeholder {
      color: $vui-dark-input-placeholder-color !important;
      opacity: 1;
    }

    &.vui-autocomplete--open {
      .vui-select__dropdown,
      .vui-autocomplete__dropdown--background {
        display: block !important;
      }
    }

    .vui-autocomplete__close {
      @include transition-all-short();
      display: none;
      position: absolute;
      top: 0;
      right: 0;
      margin: 9px 0.75rem;
      height: 1.5rem;

      svg {
        @include size-rect(1.5rem);
        fill: $vui-dark-text-color;
      }
    }

    &.vui-autocomplete--show-close {
      .vui-autocomplete__close {
        display: block !important;

        svg {
          fill: $vui-dark-text-color--hover
        }
      }

      .vui-input {
        padding-right: 3rem;
      }
    }
  }

  .vui-autocomplete__dropdown--background {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    display: none;
  }
}


.vui-input-container.vui-form-control-lg {
  .vui-autocomplete {
    .vui-autocomplete__close {
      margin: 23px 14px;
    }

    .vui-input {
      padding-right: 3rem;
    }
  }
}

@media only screen and (max-width: 767px) {
  .vui-input-container {
    .vui-autocomplete {
      .vui-autocomplete__close {
        margin: 10px;
      }
    }
  }
}
