@import "../../../velotooler-app";

@mixin link-padding() {
  padding: 0 0.875rem;
}

@mixin link-size($size) {
  height: $size;
  line-height: $size;
}

.vui-header-link {
  display: flex;

  a {
    color: $vui-dark-text-color;
    @include link-size(5.25rem);
    @include transition-color-short();
  }
}

.vui-header-link, .vui-header-link.default {
  a {
    @include link-padding();
  }
}

.vui-header-link:hover a,
.vui-header-link:active a,
.vui-header-link.default:hover a,
.vui-header-link.default.active a {
  color: $vui-dark-text-color--hover;
}

@media only screen and (min-width: 992px) {
  .vui-header-link.underline {
    a {
      @include link-padding();
    }
  }

  .vui-header-link.underline:hover a,
  .vui-header-link.underline.active a {
    text-decoration: underline;
  }

  .vui-header-link.rounded {
    a {
      height: 3rem;
      line-height: 3rem;
      border-radius: 1.5rem;
      border-width: 1px;
      border-color: $vui-dark-text-color;
      margin: 18px 0;
      transition: background-color 250ms;
      display: block;
      padding: 0 1.75rem;
    }
  }

  .vui-header-link.rounded:hover,
  .vui-header-link.rounded.active {
    a {
      color: $vui-light-text-color;
      background-color: $vui-dark-text-color;
    }
  }
}

@media only screen and (max-width: 991px) {
  .vui-header-link {
    margin: 10px 0;

    & a,
    &.default a {
      @include link-size(2.25rem);
    }
  }

  .vui-header-link.rounded {
    border-radius: 0;
    border-width: 0;
    line-height: 2.25rem;
  }
}
