@import "../variables";

/*cookie bar styles*/
.cc_banner-wrapper {
  z-index: 100;
  position: relative
}

.cc_container {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  padding: 10px;
  background: #222;
  color: #fff;
  font-size: 17px;
  font-family: Montserrat, serif;
  box-sizing: border-box;
  animation-duration: 0.8s;
  -webkit-animation-duration: 0.8s;
  -moz-animation-duration: 0.8s;
  -o-animation-duration: 0.8s;
  -webkit-animation-name: slideUp;
  animation-name: slideUp;
  z-index: 101;

  a {
    text-decoration: none;
    color: $vt-color-primary;
    transition: 200ms color;
  }

  a:hover, a:active {
    color: $vt-color-primary-hue-1;
  }

  .cc_btn {
    padding: 8px 10px;
    background-color: $vt-color-primary;
    cursor: pointer;
    text-align: center;
    font-size: 0.6em;
    display: block;
    width: 33%;
    margin-left: 10px;
    margin-right: 64px;
    float: right;
    max-width: 120px;
    line-height: 1em;
    color: #000;
    transition: background 200ms ease-in-out, color 200ms ease-in-out, box-shadow 200ms ease-in-out, font-size 200ms;
    -webkit-transition: background 200ms ease-in-out, color 200ms ease-in-out, box-shadow 200ms ease-in-out;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    animation-duration: 0.8s;
    -webkit-animation-duration: 0.8s;
    -moz-animation-duration: 0.8s;
    -o-animation-duration: 0.8s;
    -webkit-animation-name: slideUp;
    animation-name: slideUp;
  }

  .cc_btn:hover, .cc_btn:active {
    background-color: $vt-color-primary-hue-1;
    color: #000;
  }

  .cc_btn {
    color: #000;
    background-color: $vt-color-primary;
    transition: background 200ms ease-in-out, color 200ms ease-in-out, box-shadow 200ms ease-in-out;
    -webkit-transition: background 200ms ease-in-out, color 200ms ease-in-out, box-shadow 200ms ease-in-out;
    border-radius: 5px;
    -webkit-border-radius: 5px;
  }

  .cc_message {
    margin: 0;
    padding: 0;
    line-height: 1.5em;
    transition: font-size 200ms;
    font-size: 0.6em;
    display: block;
    animation-duration: 0.8s;
    -webkit-animation-duration: 0.8s;
    -moz-animation-duration: 0.8s;
    -o-animation-duration: 0.8s;
    -webkit-animation-name: slideUp;
    animation-name: slideUp;
  }

  ::-moz-selection {
    background: #ff5e99;
    color: #fff;
    text-shadow: none;
  }
}

@media screen and (min-width: 500px) {
  .cc_container {
    .cc_btn {
      font-size: 0.8em;
    }
    .cc_message {
      font-size: 0.8em;
    }
  }
}

@media screen and (min-width: 768px) {
  .cc_container {
    .cc_btn {
      font-size: 1em;
    }
    .cc_message {
      font-size: 1em;
      line-height: 1em;
    }
  }
}

@media screen and (min-width: 992px) {
  .cc_container {
    .cc_message {
      font-size: 1em;
    }
  }
}

@media print {
  .cc_banner-wrapper, .cc_container {
    display: none;
  }
}

@media screen and (min-width: 500px) {
  .cc_container {
    .cc_btn {
      font-size: 0.8em;
    }
  }
  .cc_container {
    .cc_message {
      margin-top: 0.5em;
      font-size: 0.8em;
    }
  }
}

@media screen and (min-width: 768px) {
  .cc_container {
    padding: 15px 30px 15px;
  }
  .cc_container {
    .cc_btn {
      font-size: 1em;
      padding: 8px 15px;
    }
  }
  .cc_container {
    .cc_message {
      font-size: 1em;
    }
  }
}

@media screen and (min-width: 992px) {
  .cc_container {
    .cc_message {
      font-size: 1em;
    }
  }
}

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(66px);
    transform: translateY(66px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slideUp {
  0% {
    -webkit-transform: translateY(66px);
    -ms-transform: translateY(66px);
    transform: translateY(66px);
  }
  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}
