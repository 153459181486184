.vtform__footer {
  @media (max-width: $mobile-width) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50px;
  }

  @media (min-width: $not-mobile-width) {
    text-align: right;
    padding-bottom: 15px;
  }

  .vtform__footer-submit-btn {
    @media (max-width: $mobile-width) {
      color: #222 !important;
      width: 100%;
      text-transform: uppercase !important;
      margin: 0 !important;
      border-radius: 0;
      height: 50px;
    }
  }
}