.profile-work-area__work-area-locations-container {
  display: flex;
}

.profile-work-area__work-area-location {
  margin-bottom: 4px;
  margin-left: 4px;
  padding: 5px;
  border: solid 1px #3c3c3c;
  border-radius: 5px;
  font-size: 13px;
}

.profile-work-area__work-area-map-container {
  margin: 10px -10px -40px -10px;

  .google-map {
    height: 450px;
  }
}