@import "../../../../velotooler-app";

.landing-page-main-block {
  .vui-content {
    padding-top: 120px;
    background-color: #00000000;
  }

  .landing-page-main-block__title {
    text-transform: uppercase;
    font-size: 4.5rem;
    line-height: 5.625rem;
    max-width: 850px;
    font-weight: 700;
  }

  .landing-page-main-block__desc {
    font-size: 1.25rem;
    line-height: 2.5rem;
    margin-top: 2rem;
    max-width: 850px;
    margin-bottom: 2rem;
  }

  .landing-page-main-block__action {
    .vui-btn {
      max-width: 32rem;
      width: 100%;
      margin-top: 2rem;
    }
  }

  .landing-page-main-block__vt-icon {
    margin-top: 5rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .landing-page-main-block {
    .vui-content {
      padding-top: 60px;
      min-height: auto;
    }

    .landing-page-main-block__title {
      font-size: 2.25rem;
      line-height: 3.375rem;
      max-width: 650px;
    }

    .landing-page-main-block__desc {
      font-size: 1rem;
      line-height: 1.25rem;
    }

    .landing-page-main-block__vt-icon {
      margin-top: 4rem;
    }
  }
}

@media only screen and (max-width: 767px) {
  .landing-page-main-block {
    .vui-content {
      padding-top: 60px;
      min-height: auto;
    }

    .landing-page-main-block__title {
      font-size: 1.625rem;
      line-height: 2.75rem;
      max-width: 450px;
    }

    .landing-page-main-block__desc {
      font-size: 1rem;
      line-height: 1.25rem;
    }

    .landing-page-main-block__action {
      padding-bottom: 2rem;
    }

    .landing-page-main-block__vt-icon {
      display: none;
    }
  }
}
