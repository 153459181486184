@import "../../variables";

.profile-location {
  .profile-location__add-btn {
    @media (max-width: $mobile-width) {
      width: 100%;
    }
    margin: 0 !important;

  }

  .profile-location__add-btn-icon {
    font-size: 17px;
    vertical-align: middle;
    &.fa-home {
      font-size: 22px;
    }
  }
}