.notification-message {
  .notification-message__text-group-body {
    text-overflow: ellipsis;
    word-break: break-word;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    font-size: 14px;
    padding-top: 6px;
    max-width: 292px;
    max-height: 120px;
  }

  .notification-message__unread-sign {
    width: 6px;
    height: 6px;
    border-radius: 5px;
    background-color: yellow;
    display: inline-block;
  }

  .notification-message__action {
    text-align: center;
  }

  .notification-message__action-button {
    width: 100%;
    margin: 6px 0;
  }

  .notification-message__text-group {
    padding: 10px;
    width: 71%;
  }

  .notification-message__message {
    display: inline-block;
    vertical-align: sub;
    font-weight: normal;
    color: #a0a0a0;
  }

  .notification-message__date-container {
    font-weight: normal;
    text-align: right;
  }

  .notification-message__date {
    margin-left: 5px;
  }

  .notification-message__date_active {
    color: #ccc;
  }

  .notification-message__body {
    display: flex;
    padding-top: 7px;
    padding-bottom: 7px;
  }

  .notification-message__title-image {
    margin: auto 5px;
    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
  }

  .notification-message__footer {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #333333;
    font-size: 13px;
    padding: 5px 10px;
    color: #888;
  }

  .notification-message__additional-info {
    font-weight: bold;
  }

  .notification-message__actions {
    line-height: 41px;
    max-width: 50px;
  }

  .notification-message__actions-menu-icon {
    color: inherit;
  }
}

.notification-message__actions-menu {
  border-radius: 2px;
  a:focus {
    background-color: rgba(107, 107, 107, 0.2) !important;
  }
}

.notification-message__actions-menu:after, .notification-message__actions-menu:before {
  bottom: 100%;
  left: 98%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.notification-message__actions-menu:after {
  border-bottom-color: #222;
  border-width: 13px;
  margin-left: -28px;
}

.notification-message__actions-menu:before {
  border-bottom-color: #222;
  border-width: 13px;
  margin-left: -28px;
}

.notification-message__actions-menu-button {
  line-height: 18px;
}

.notification-message__actions-menu-button-title {
  font-size: 15px;
}

.notification-message__actions-menu-button-description {
  font-size: 12px;
  color: #777;
}