@import "../../../../velotooler-app";

.landing-page-info-message {
  padding: 1.25rem 2.25rem;
  background: $color-white;
  box-shadow: 1rem 1rem 4.5rem rgba(0, 0, 0, 0.15);
  border-radius: 0.625rem;

  .landing-page-info-message__body {
    margin-top: 0.625rem;
    line-height: 1.5rem;
    font-size: 0.875rem;
  }
}
