@import "../variables";

.feedback {
  .feedback__button {
    position: relative;
    display: inline-block;
    color: $label-color;
    padding-left: 44px;
    line-height: 1.3em;
  }

  .feedback__rate {
    position: absolute;
    left: 0;
    width: 44px;
    overflow: hidden;
  }

  .feedback__stars {
    width: 140px;

    li .active {
      color: $color-main;
    }
  }

  .feedback__button:hover {
    .feedback__rate {
      width: auto;
    }

    .feedback__stars {
      margin-left: 0;
      background-color: $form-background;
    }
  }

  @media (min-width: 1100px) {
    .feedback__button {
      padding-left: 0;
    }
    .feedback__rate {
      position: relative;
      top: 5px;
      display: inline-block;
      width: auto;
    }
    .feedback__stars {
      margin-left: 0;
    }
    .stars__number {
      display: none;
    }
  }
}

.feedback-stars_small {
  font-size: 3px;
  min-width: 60px;

  & > li {
    font-size: 13px;
    display: inline-block;
    padding-right: 0px;
    padding-left: 0px;
    & > i {
      font-size: 12px;
    }
  }
}

.feedback-stars_default-cursor {
  & > li {
    cursor: default;
  }
}

.feedback-stars_theme-light-yellow {
  & > li i {
    color: #ffec92 !important;
  }
}