.bike-grid {

  .bike-grid__owner {
    padding-top: 20px;
    font-size: 15px;
  }

  .bike-grid__bike-title {
    font-size: 14px;
    line-height: 25px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
  }

  .bike-grid__bike-sn {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    color: #ccc;
  }

  .bike-grid__price {
    position: absolute;
    bottom: 20px;
    right: 0;
    background-color: rgba(30, 30, 30, .7);
    color: white;
    font-weight: bold;
    padding: 5px;
  }

  .bike-grid__bike-status {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 11px;
    padding-top: 10px;

    .broken {
      color: #ffd351;
      font-weight: bold;
      border-radius: 3px;
      vertical-align: middle;
    }

    .used {
      color: #4caa77;
      font-weight: bold;
      border-radius: 3px;
      white-space: nowrap;
      vertical-align: middle;
    }
    .in_transfer {
      color: #FCEBB6;
      font-weight: bold;
      border-radius: 3px;
      white-space: nowrap;
      vertical-align: middle;
    }
  }

}