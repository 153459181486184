.vui-phone-number {
  width: 100%;

  .iti__flag-container {
    border-right-width: 1px;
    border-style: solid;
    border-color: rgb(45, 45, 45);
  }

  .iti__country-list {
    background-color: rgb(40, 40, 40);
    border-width: 1px;
    border-style: solid;
    border-color: rgb(45, 45, 45);
  }

  .iti__country {
    padding-top: .5rem;
    padding-bottom: .5rem;
    border-bottom-width: 1px;
    border-style: solid;
    border-color: rgb(45, 45, 45);
  }

  .iti__divider {
    border-bottom-width: 1px;
    border-style: solid;
    border-color: rgb(75, 75, 75);
  }

  input {
    background-color: transparent;

    ::-webkit-input-placeholder {
      color: #e3342f;
    }

    ::-ms-input-placeholder {
      color: #e3342f;
    }

    ::placeholder {
      color: #e3342f;
    }

    ::-moz-placeholder {
      color: #e3342f;
    }
  }
}

.vui-phone-number__error {
  color: #ef5753;
  border-color: #ef5753;
}
