.vui-info-model {
  .vui-modal {
    row-gap: 0;

    .vui-modal__actions {
      padding-top: 2rem;
    }
  }

  .vui-info-model__body {
    max-width: 440px;
    text-align: center;
    font-weight: 600;
  }

  .vui-info-model__actions {
    .vui-btn {
      width: 100%;
    }
  }
}