@import "../../../sass/variables";

div[dashboard-requests="'history'"] {
  .invisible {
    display: none;
  }
}

.requests {

  .linked-row {
    line-height: normal;
    margin: 0 0 10px 0;
    padding: 10px 0;
    text-align: left;
    border-bottom: 1px solid $color-primary-l2;
    white-space: normal;

    &:hover {
      background-color: $color-primary-l2 !important;
    }
  }

  .rg__request-notification-budget {
    position: absolute;
    top: -1px;
    right: 0;
    padding: 0px 6px;
    border: 1px solid #5d5d5d;
  }

  .rg__request-status {
    background: none;
    width: 100%;
    text-align: center;
    position: relative;

    .open, .draft, .awaiting-customer-confirmation {
      background-color: #667b9c;
      color: black;
      padding: 3px 10px;
      border-radius: 5px;
      font-size: 0.9em;
    }

    .awaiting-customer-confirmation {
      font-size: .8em;
    }

    .resolved {
      background-color: #e27f37;
      color: black;
      padding: 3px 10px;
      border-radius: 5px;
      font-size: 0.9em;
    }

    .done,
    .refunded,
    .reassigned,
    .awaiting-confirmation {
      background-color: #d1a713;
      color: black;
      padding: 3px 10px;
      border-radius: 5px;
      font-size: 0.9em;
    }

    .in-progress {
      background-color: #2c9a57;
      color: black;
      padding: 3px 10px;
      border-radius: 5px;
      font-size: 0.9em;
    }

    .pending,
    .ready-for-reassign {
      background-color: #d1a713;
      color: black;
      padding: 3px 10px;
      border-radius: 5px;
      font-size: 0.9em;
    }

    .expired {
      background-color: #3a3a3a;
      color: white;
      padding: 3px 10px;
      border-radius: 5px;
      font-size: 0.9em;
    }

    .canceled,
    .could-not-be-fixed,
    .charge-failed,
    .rejected {
      background: rgba(156, 69, 67, 0.87);
      color: white;
      padding: 3px 10px;
      border-radius: 5px;
      font-size: 0.9em;
    }

    .in-dispute,
    .transfer-failed,
    .refund-failed {
      background: rgba(154, 34, 32, 0.78);
      color: white;
      padding: 3px 10px;
      border-radius: 5px;
      font-size: 0.9em;
    }
  }

  .cancel-button {
    box-sizing: border-box;
    border: 1px solid #444;
    color: #999;
    border-radius: 6px;
    font-size: 0.8em;
    min-height: auto;
    height: auto;
    line-height: 2em;
    margin: 0;
  }

}

.request-grid {
  display: flex;
  flex-flow: row wrap;
  padding: 0 15px;

  .price-per-bike__label {
    font-size: 11px;
    font-weight: 100;
    padding-top: 5px;
    color: #ffec92;
    text-align: center;
  }

  .rg__row {
    display: flex;
    flex-flow: row nowrap;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
  }

  .rg__col {
    display: flex;
    flex: auto;
    align-self: center;
    color: white;
    overflow: hidden;
    padding: 0 5px;
    margin: 5px auto;
  }

  .rg__title-col {
    color: #aaa;
  }

  .rg__title {
    display: none;
    padding: 20px 0;
    justify-content: center;
    font-size: 13px;
    border-bottom: 1px solid #444;

    .rg__col--dates, .rg__col--bicycle {
      flex-flow: row wrap;
    }
  }

  $rg_date_top_margin: 5px;
  .rg__date, .rg__bike {
    margin: $rg_date_top_margin auto;
  }

  .rg__dates-dropdown, .rg__bikes-dropdown {
    position: relative;
    overflow: hidden;
    padding-bottom: 1rem;

    .rg__dates-dropdown--list, .rg__bikes-dropdown--list {
      max-height: calc(1rem + #{$rg_date_top_margin} * 2);
    }

    .rg__dropdown-button {
      position: absolute;
      display: block;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  .rg__dates-dropdown--opened, .rg__bikes-dropdown--opened {

    .rg__dates-dropdown--list, .rg__bikes-dropdown--list {
      max-height: 50rem;

      .rg__date, .rg__bike {
        white-space: normal;
      }
    }

    .rg__dropdown-button--arrow-down {
      span {
        transform: rotate(0deg);
      }
    }
  }

  .rg__dates-dropdown--list, .rg__bikes-dropdown--list {
    overflow: hidden;

    .rg__date, .rg__bike {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .rg__dropdown-button {
    display: none;
    font-size: 0.7rem;
    background: none;
    text-decoration: none;
  }

  .rg__dropdown-button--arrow-down {
    span {
      display: inline-block;
      transform: rotate(180deg);
      transition: transform .2s ease-out;
    }
  }

  .rg__col--status {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
    flex: 0 0 auto;
  }

  .rg__col--dates,
  .rg__col--bicycle {
    flex-flow: column nowrap;
  }
  .rg__col--button {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
    flex: 0 0 auto;
  }

  .rg__item {
    flex: 0 0 50%;
    min-width: 260px;
    margin: 0 auto;

    .request__label {
      color: #666;
      text-transform: none;
    }

    .request-content__label {
      text-transform: none;
    }
  }

  .rg__row--display-block {
    display: block;
    margin: 0;
  }

  .rg__col--vertical-flow {
    flex-flow: column nowrap;
    align-items: left;
  }

  .rg--bottom-margin {
    margin-bottom: 5px;
  }

  .rg__col--deadline {
    flex: 0 0 auto;
  }

  .rg__task {
    text-align: left;
  }

  .rg__mechanic {
    text-align: left;
  }

  .rg__address {
    text-align: left;
  }

  .rg__col--first {
    flex: 1 1 60%;
  }

  .rg__col--second {
    flex: 0 0 auto;
  }

  .rg--desktop {
    display: none;
    min-height: 65px;
  }

  .rg__request-status,
  .rg__request-status-title {
    min-width: 140px;
    width: 140px;
  }

  @media (min-width: 644px) {
    div.rg__item:last-child:not(:nth-child(odd)) {
      margin: 0;
    }
  }

  @media (max-width: 768px) {
    .rg__col--bicycle {
      display: none;
    }
  }

  @media (min-width: 768px) {
    flex-flow: column nowrap;

    .rg__item {
      flex: auto;
      margin: 0;
    }

    .rg__title {
      display: flex;
    }
    .rg__col {
      align-self: flex-start;
      margin-top: auto;
      margin-bottom: auto;
    }

    .rg__col--task {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 33%;
      flex: 1 1 33%;
      padding-left: 10px;
    }
    .rg__col--mechanic {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 33%;
      flex: 1 1 33%;
    }
    .rg__col--address {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 33%;
      flex: 1 1 33%;
    }
    .rg__col--dates {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 33%;
      flex: 1 1 33%;
    }
    .rg__col--bicycle {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 33%;
      flex: 1 1 33%;
    }
    .rg__col--price {
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 7em;
      flex: 1 1 100px;
    }

    .rg--desktop {
      display: flex;
    }
    .rg--mobile {
      display: none;
    }
  }
}
