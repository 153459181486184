@import "../../../velotooler-app";

.vui-cancelation-info-model {
  .vui-cancelation-info-model__body {
    max-width: 400px;
    font-size: 0.875rem;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;

    .vui-cancelation-info-model__body--message {
      padding-top: 0.5rem;
    }

    .vui-cancelation-info-model__body--attention {
      color: $vui-input-error-color;
      font-weight: 600;
    }

    .vui-manage-service-model__description textarea {
      height: 7rem;
    }
  }

  .vui-cancelation-info-model__actions {
    column-gap: 1.5rem;
  }
}