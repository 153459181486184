@import "../../../velotooler-app";

.vui-href {
  color: $vui-dark-text-color;

  &:hover,
  &:focus,
  &:active {
    color: $vui-dark-text-color--hover;
  }
}
