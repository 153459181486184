.vt-alert {
  &.vt-alert_info {
    background-color: #e8fffe;
    border-color: #4dc0b5;
    color: #0d3331;
  }

  &.vt-alert_warning {
    background-color: #fff5eb;
    border-color: #f6993f;
    color: #462a16;
  }

  &.vt-alert_error {
    background-color: #fcebea;
    border-color: #e3342f;
    color: #3b0d0c;
  }

  &.vt-alert_success {
    background-color: #e3fcec;
    border-color: #38c172;
    color: #0f2f21;
  }
}