.vtform__body {
  @media (max-width: $mobile-width) {
    position: fixed;
    top: 90px;
    overflow-y: auto;
    height: 425px;
    padding: 10px;

    &::-webkit-scrollbar {
      width: 4px;
    }
  }
}