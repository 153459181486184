@import "../../../velotooler-app";

.vui-input-container {
  .vui-multiselect-filter {
    .vui-input::placeholder {
      color: $vui-dark-input-placeholder-color !important;
      opacity: 1;
    }

    .vui-multiselect-filter__dropdown-icon {
      position: absolute;
      right: 0;
      top: 0;
      margin: 17px 0.75rem;
      transition: all 250ms ease-in-out;
    }

    .vui-input {
      cursor: pointer;
      padding: 0.5rem 2rem 0.5rem 1rem !important;
      clear: both;
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &.vui-input-container--opened {
      .vui-multiselect-filter__dropdown-icon {
        transform: rotate(180deg);
      }

      .vui-multiselect-filter__dropdown--background,
      .vui-select__dropdown {
        display: block !important;
      }
    }
  }

  .vui-multiselect-filter__dropdown--background {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    display: none;
    z-index: 5;
  }

  &.vui-input-container__filter {
    margin: 0;

    .vui-input {
      font-size: 0.75rem;
      line-height: 0.75rem;
      padding: 0.5rem 1rem;
      height: 2rem;
      border: 1px solid $vui-main-menu-color;
      font-weight: 500;
    }

    .vui-multiselect-filter__dropdown-icon {
      width: 0.625rem;
      height: 0.625rem;
      margin: 0.6875rem 1rem;
    }

    .vui-select__option {
      font-size: .75rem;
      line-height: 2.25rem;
    }
  }

  .vui-select__option {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;

    span {
      clear: both;
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .vui-checkbox__icon {
    cursor: pointer;
    height: 1.5rem;
    width: 1.25rem;
    display: block;

    &::after {
      content: url("data:image/svg+xml,%3Csvg%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M15.8333%202.5H4.16667C3.24619%202.5%202.5%203.24619%202.5%204.16667V15.8333C2.5%2016.7538%203.24619%2017.5%204.16667%2017.5H15.8333C16.7538%2017.5%2017.5%2016.7538%2017.5%2015.8333V4.16667C17.5%203.24619%2016.7538%202.5%2015.8333%202.5Z%22%20stroke%3D%22%23808080%22%20stroke-width%3D%221.66667%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%3C%2Fsvg%3E");
      opacity: 1;
      margin-left: -1.25rem;
      transition: opacity 0.3s ease-in-out;
    }

    &::before {
      transition: opacity 0.3s ease-in-out;
      opacity: 0;
      content: url("data:image/svg+xml,%3Csvg%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M7.5%209.16667L10%2011.6667L18.3333%203.33333M17.5%2010V15.8333C17.5%2016.2754%2017.3244%2016.6993%2017.0118%2017.0118C16.6993%2017.3244%2016.2754%2017.5%2015.8333%2017.5H4.16667C3.72464%2017.5%203.30072%2017.3244%202.98816%2017.0118C2.67559%2016.6993%202.5%2016.2754%202.5%2015.8333V4.16667C2.5%203.72464%202.67559%203.30072%202.98816%202.98816C3.30072%202.67559%203.72464%202.5%204.16667%202.5H13.3333%22%20stroke%3D%22%23808080%22%20stroke-width%3D%221.66667%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%3C%2Fsvg%3E%0A");
    }
  }

  .active {
    .vui-checkbox__icon {
      &::after {
        opacity: 0;
      }

      &::before {
        opacity: 1;
      }
    }
  }
}

.vui-input-container.vui-form-control-lg {
  .vui-multiselect-filter {
    .vui-multiselect-filter__dropdown-icon {
      margin: 1.875rem 1rem;
    }

    .vui-input {
      padding-right: 3rem;
    }
  }
}

@media only screen and (max-width: 767px) {
  .vui-input-container,
  .vui-input-container.vui-form-control-lg {
    .vui-multiselect-filter {
      .vui-multiselect-filter__dropdown-icon {
        margin: 1rem 0.75rem;
      }

      .vui-input {
        padding-right: 2rem;
      }
    }

    &.vui-input-container__filter {
      .vui-multiselect-filter__dropdown-icon {
        width: 0.625rem;
        height: 0.625rem;
        margin: 0.6875rem 1rem;
      }
    }
  }
}
