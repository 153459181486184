@import "../../../sass/variables";

.custom-service-item {
  line-height: 28px;
  color: white;
  justify-content: space-around;
  align-items: center;

  .custom-service-item__name {
    flex-basis: 45%;
  }

  .custom-service-item__price {
    flex-basis: 15%;
    padding-left: 15px;
  }

  .custom-service-item__actions {

  }

  .custom-service-item__actions-remove {
    line-height: 23px;

    &:hover {
      background: transparent;
    }
  }

  .custom-service-item__switch {
    margin: 0;
    height: 28px;
    font-weight: bold;
  }

  .custom-service-item__switch_enabled {
    color: white;
  }

  .custom-service-item__switch_disabled {
    color: #777777;
  }

  .custom-service-item__switch_in-review {
    color: #EABE6A;
  }

  .custom-service-item__remove-btn {
    display: flex;
    border-radius: 50%;
    min-width: 27px;
    min-height: 27px;
    width: 27px;
    margin: auto;
    img {
      display: flex;
      margin: auto;
      width: 15px;
    }

    &:hover {
      background-color: transparent;
      cursor: pointer;
    }
  }

  .custom-service-item__delete-btn {
    padding-top: 15px;
    .md-button {
      width: 204px;
    }
  }

  .custom-service-item__underlying-text {
    color: #aaa;
    font-size: 12px;
    font-weight: normal;

    &.custom-service-item__underlying-text_custom {
      color: $color-green;
    }
  }
}