@import "steps/bicycle-step",
"steps/choose-mechanic-step",
"modal/create-request-warning-dialog";

.create-request {
  display: flex;

  &.create-request_checkout-stage {

    height: max-content;
    min-height: 300px;

    .create-request__main-section {
      max-width: 0;
      opacity: 0;
      -webkit-transition: max-width 250ms ease-in-out;
      transition: max-width 250ms ease-in-out;
      height: 0;
    }

    .create-request__checkout-btn {
      visibility: visible;
    }

    .create-request__summary-back-button {
      visibility: visible;
    }

    .create-request__summary-footer {
      border-top: 1px solid $color-primary-l2;
    }

    .create-request__summary-section {
      max-width: 700px;
      display: block;
      max-height: none;
    }
  }

  &.create-request_payment-stage {
    .create-request__payment-details {
      transform: translate3d(0, -410px, 0);
      z-index: 4;
    }

    .create-request__summary-body {
      height: 300px;
      opacity: 0;
    }

  }

  .create-request_desktop-only {
    visibility: visible !important;
  }

  .create-request_mobile-only {
    visibility: hidden !important;
  }

  .create-request__input-with-icon {
    position: relative;
    padding-left: 30px;
  }

  .create-request__input-icon {
    position: absolute;
    left: 8px;
    top: 25%;
    transform: translateY(-20%);
    font-size: 26px;
    color: #999;
  }

  .create-request__summary-footer {
    text-align: center;
    padding: 10px 0;
  }

  .create-request__checkout-btn {
    width: 150px !important;
    visibility: hidden;

    &.create-request__checkout-btn_active {
      visibility: visible;
    }
  }

  .create-request__task-context-item-container {
    margin: 10px;
  }

  .create-request__task-context-close-icon {
    background-color: rgba(30, 30, 30, 0.3);
    border-radius: 50%;
    font-size: 11px;
    padding: 3px 4px;
    color: #222;
    margin-left: 5px;
  }

  .create-request__task-item {
    margin-bottom: 5px;
    margin-top: 5px;

  }

  .create-request__task-context-item {
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 3px;
    padding: 10px 15px;
    font-weight: bold;
    font-size: 15px;
    background-color: rgba(81, 197, 126, 0.4);
    cursor: pointer;

    &:hover {
      background-color: rgba(81, 197, 126, 0.5);
    }
  }

  .request-card {
    background-color: $color-primary;
  }

  .select-available-time-card-content {
    background: none !important;
  }

  .create-request__body {
    position: relative;
    margin: 0;
  }

  .create-request__main-section {
    flex-basis: 800px;
    max-width: 800px;
    visibility: visible;
    -webkit-transition: opacity 0s linear 250ms, max-width 250ms ease-in-out;
    transition: opacity 0s linear 250ms, max-width 250ms ease-in-out;
    /*-webkit-transform: translateZ(0);
    transform: translateZ(0);*/
  }

  .create-request__summary-section {
    max-width: 400px;
    min-width: 400px;
    margin: 0 auto;
    width: 100%;
    -webkit-transition: max-width 250ms ease-in-out;
    transition: max-width 250ms ease-in-out;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);

    @media (max-width: 1200px) {
      max-width: 300px;
      min-width: 300px;
    }
  }

  .create-request__mobile-navigation {
    border: none;
    display: none;
    width: 100%;
    height: 55px;
    position: fixed;
    left: 0;
    bottom: 0;
    padding: 0;
    margin: 0;
    z-index: 78;
    -webkit-overflow-scrolling: touch;

    .md-button {
      line-height: 43px !important;
    }
  }

  .create-request__summary {
    width: 100%;
    background-color: $color-primary;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    border-radius: 4px;
    position: relative;
    overflow: hidden;
  }

  .create-request__summary-header {
    background-color: rgba(255, 255, 0, 0.1);
    padding: 0 20px 20px 20px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    position: relative;
    max-height: 400px;
    -webkit-transition: max-height 250ms ease-in-out;
    transition: max-height 250ms ease-in-out;
  }

  .create-request__price-view {
    justify-content: space-around;
  }

  .create-request__label_gray {
    color: #ccc;
    font-size: 14px;
  }

  .create-request__summary-body {
    padding: 20px;
    font-size: 15px;
  }

  .create-request__payment-details {
    overflow: hidden;
    position: absolute;
    left: 0;
    width: 100%;
    bottom: -620px;
    padding: 20px;
    height: 620px;
    background-color: $color-primary;
    transition: transform cubic-bezier(0.25, 0.8, 0.25, 1) 0.5s;
  }

  .create-request__summary-h2 {
    font-size: 17px;
    font-weight: bold;
    color: #999;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .create-request__summary-mechanic-avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }

  .create-request__summary-edit-button {
    min-width: 28px !important;
    margin: 0 !important;
    color: #999;
    background: none;
    border: none;

    i {
      font-size: 23px;
    }
  }

  .create-request__summary-mechanic {
    border-bottom: 1px solid $color-primary-l3;
    padding-bottom: 10px;
  }

  .create-request__address-details-field {
    &.collapsible-field_active {
      overflow: visible;
    }
  }

  .create-request__section {
    margin-bottom: 10px;
  }

  .create-request__section-title {
    position: relative;

    &:after {
      content: "";
      display: block;
      position: absolute;
      height: 1px;
      background-color: $vt-color-primary;
      width: 150px;
      top: 55%;
    }
  }

  .create-request__section-body-icon {
    margin: auto;
    padding: 0 !important;
  }

  .create-request__section-body {
    padding-bottom: 10px;
    padding-top: 10px;
    font-size: 16px;
    font-weight: bold;
    line-height: 28px;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover {
      background-color: $color-primary-l2;
    }
  }

  .create-request__section-title-text {
    background-color: $color-primary;
    z-index: 3;
    padding-right: 15px;
    display: inline-block;
    position: relative;
    color: #aaa;
  }

  .create-request__summary-icon {
    color: #777;
    margin-right: 5px;
    font-size: 17px;
  }

  .create-request__head-text {
    text-align: left;
  }

  .create-request__estimated-time {
    color: #ccc;
    margin-top: 5px;
    font-size: 15px;
  }

  .create-request__summary-back-button {
    visibility: hidden;
    margin: 0;
    padding: 0;
    width: 25px;
    height: 25px;
    svg {
      fill: #aaa;
    }
  }

  .create-request__summary-back-btn-container {
    background-color: rgba(255, 255, 0, 0.1);
    padding: 10px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .create-request__back-button {
    left: -10px;
    position: absolute;
    top: 19px;
    svg {
      fill: #aaa;
    }
    background: none !important;
  }

  .choose-task-button {
    white-space: normal;
    font-size: 14px !important;
    text-align: left;
    padding: 10px !important;
    border-radius: 4px;
    color: white !important;
    width: 100%;
    text-transform: none !important;
    margin: 0 !important;

    &.active {
      color: $color-green !important;
    }
    &:hover {
      background-color: rgba(70, 70, 70, 0.3);
    }
  }

  .create-request__desc {
    color: #ccc;
    font-size: 1.2em;
  }

  .md-padding {
    padding-bottom: 10px;
  }

  &.vt-form {
    background: none;
    box-shadow: none;
    max-width: 1200px !important;
  }

  md-input-container.title {
    h2 {
      text-align: center;
    }
  }

  .show-animation.ng-hide-add {
    -webkit-animation: 0.3s hide;
    animation: 0.3s hide;
  }

  @keyframes hide {
    0% {
      opacity: 1;
      transform: scale(1);
    }
    30% {
      transform: scale(1.02);
    }
    100% {
      opacity: 0;
      transform: scale(0.5);
    }
  }

  @-webkit-keyframes hide {
    0% {
      opacity: 1;
      transform: scale(1);
    }
    30% {
      transform: scale(1.02);
    }
    100% {
      opacity: 0;
      transform: scale(0.5);
    }
  }

  .show-animation.ng-hide-remove {
    -webkit-animation: 0.3s show;
    animation: 0.3s show;
  }

  @keyframes show {
    0% {
      opacity: 0;
      transform: scale(0.5);
    }
    70% {
      transform: scale(1.02);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  @-webkit-keyframes show {
    0% {
      opacity: 0;
      transform: scale(0.5);
    }
    70% {
      transform: scale(1.02);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  .choose-bike {
    md-autocomplete {
      min-width: auto;
    }
    .autocomplete {
      width: 100%;
    }
    .bikes-grid {
      margin: 0;

      .bike {
        max-width: 100%;
        flex-basis: 100%;
        padding: 10px;
        border-bottom: 1px solid #333;
        cursor: pointer;
        margin-top: 0;
        margin-bottom: 4px;

        .bike_status {
          background-color: transparent;
        }

        .serial-number {
          color: #6F6F6F
        }
      }
    }

    .bike:hover, .bike.active {
      background: $navbar-button-background-hover;
    }
  }

  .pagination-buttons {
    width: 100%;
  }

  form {
    .card-item {
      padding: 5px;
      margin: 5px;
      white-space: nowrap;

      .card-owner {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
      }

      .md-button {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  @media (max-width: 1000px) {
    .create-request_desktop-only {
      visibility: hidden !important;
    }

    .create-request__mobile-floating-btn {
      width: 100%;
      margin: 0 !important;
      height: 100%;
      border-radius: 0;
    }

    .create-request__summary-back-btn-container {
      border-radius: 0;
    }

    .create-request__summary {
      background-color: $color-primary-d1;
      border-radius: 0;
      box-shadow: none;
    }

    .create-request__payment-details {
      background-color: $color-primary-d1;
    }

    .create-request__section-title-text {
      background-color: $color-primary-d1;
    }

    .create-request_mobile-only {
      visibility: visible !important;
    }

    .create-request__summary-section {
      max-width: 0;
      min-width: 0;
      max-height: 0;
    }

    .create-request__summary-footer {
      height: 50px;
    }

    .create-request__checkout-btn {
      display: none;
    }

    .create-request__back-button {
      left: -18px;
      top: -22px;
    }

    .create-request__body {
      padding: 0;
    }

    .create-request__head-text {
      font-size: 25px;
      margin-top: 10px;
    }

    .create-request__desc {
      margin: 0 0 20px 0;
    }

    .create-request__mobile-navigation {
      display: block;
    }

    &.create-request_payment-stage {
      .create-request__payment-details {
        transform: translate3d(0, -540px, 0);
        z-index: 4;
      }

      .create-request__summary-body {
        height: 450px;
        opacity: 0;
      }
    }

  }
}
