@import "../../../velotooler-app";

.vui-more-action,
.vui-more-action a {
  font-size: 0.875rem;
  line-height: 2.5rem;
  padding: 0 1.25rem;
  font-weight: 600;
  cursor: pointer;
  color: $vui-dark-input-placeholder-color;
  width: 100%;
  transition: all 200ms ease-in-out;
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;
  height: 2rem;
  align-items: center;

  span {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 0.75rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-transform: none;
  }
}

.vui-more-action a {
  padding: 0;
}

.vui-more-action:hover,
.vui-more-action:focus {
  color: $vui-dark-text-color;
  background: #F6F6F6;
}
