.user-avatar-with-name {
  cursor: pointer;
  display: block;

  .user-avatar-with-name__avatar {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    vertical-align: middle;
    margin-right: 5px;
  }

  .user-avatar-with-name__avatar_no-user {
    cursor: auto;
  }

  .user-avatar-with-name__name {
    vertical-align: middle;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
    display: inline-block;
  }

  .user-avatar-with-name__name_no-user {
    color: #777;
    cursor: auto;
  }

  &.user-avatar-with-name_full-width {
    .user-avatar-with-name__name {
      max-width: 155px;
    }
  }

  &.user-avatar-with-name_width-100 {
    .user-avatar-with-name__name {
      max-width: 100%;
    }
  }

}

