$breakpoint: 640px;

$border: solid 1px rgba(black, 0.4);
$width: 100%;
$gutter_unit_size: 5px;

.vt-table {
  display: flex;

  .vt-table__item {
    order: 0;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: 100%;
    width: 100%;
    box-sizing: border-box
  }

  @for $j from 1 to 11 {
    &[data-gutter="#{$j}"] {
      .vt-table__item {
        padding: ($gutter_unit_size * $j);
        font-size: 15px;
      }
    }
  }

  &.vt-table-bordered {
    &.vt-table__columns .vt-table__item:not(.vt-table__item__foot),
    &.vt-table__rows .vt-table__item {
      border: 1px solid #222222;
      border-radius: 5px;
    }
  }

  &.vt-table-colored {
    .vt-table__item {
      background: rgba(26, 26, 26, 0.9);
      color: white;
      &.vt-table__item__head {
        background: rgba(26, 26, 26, 0.9);
        color: #777;
      }
      &.vt-table__item__foot {
        background: #ccc;
      }
      &.vt-table__item__link {
        color: #38adb8;
      }
    }
  }
}

@media only screen and (max-width: ($breakpoint + 1)) {
  .vt-table {
    flex-direction: column;
    flex-wrap: no-wrap;
    .vt-table__item {
      &.vt-table__item__foot {
        margin-bottom: 1rem;
      }
    }

    &.vt-table--bordered {
      &.vt-table__rows .vt-table__item.vt-table__item__foot {
        border-bottom: none;
      }
    }
  }
}

@media only screen and (min-width: $breakpoint) {
  .vt-table {
    .vt-table__item {
      &.vt-table__item__head {
        text-align: right;
        background: rgba(26, 26, 26, 0.9);
        color: #777;
      }
    }
  }
}

@media only screen and (min-width: $breakpoint) {
  .vt-table {
    flex-direction: row;
    flex-wrap: wrap;

    &.vt-table__columns {
      .vt-table__item {
        &.vt-table__item__head {
          order: -1;
          background: rgba(26, 26, 26, 0.9);
          color: #777;
        }
        &.vt-table__item__foot {
          order: 1;
        }
      }
    }

    &.vt-table-bordered {
      &.vt-table__rows {
        border: 1px solid #222222;
        border-radius: 5px;
      }
    }

    @for $i from 1 to 11 {
      &[data-columns="#{$i}"] {
        .vt-table__item {
          flex-basis: $width/$i;
          width: $width/$i;
        }
      }
    }
  }
}

@media only screen and (min-width: 640px) {
  .vt-table.vt-table-bordered.vt-table__rows .vt-table__item {
    border: none;
  }
}

@media only screen and (max-width: 641px) {
  .vt-table.vt-table-bordered.vt-table__rows .vt-table__item:not(.vt-table__item__head) {
    border-bottom: 1px solid #222;
  }

  .vt-table.vt-table-bordered.vt-table__rows .vt-table__item.vt-table__item__head {
    border: none;
  }
}