@import "../../../velotooler-app";

.vui-side-form-container {
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100% - 84px);
  padding: 4px 0;
  width: 100%;
  top: 84px;
  left: 0;
  position: absolute;
  z-index: 10;
  background-color: rgb(40, 40, 40, .8);
  opacity: 1;
  @include transition-short(all);

  &.vui-side-form-container__hide {
    display: none;
  }

  &.vui-side-form-container__hide--animated {
    opacity: 0;

    .vui-side-form {
      transform: translateX(500px);
    }
  }

  .vui-side-form {
    max-height: inherit;
    transform: translateX(0);
    @include transition-short(all);

    .vui-form {
      box-shadow: 0 0 0 transparent;
      max-width: 400px;
      margin: 0 0 0 auto;
      padding: 1rem 2rem;
      border-radius: 2rem 0 0 2rem;
      display: flex;
      flex-direction: column;
      row-gap: 2rem;
      background: $color-white;

      .vui-form__title {
        text-align: left;
        margin-bottom: 0;
      }
    }

    .vui-side-form__title {
      font-size: 1.375rem;
      font-weight: 700;
      line-height: 27px;
      text-align: left;
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .vui-side-form-container {
    height: calc(100% - 60px);
    top: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .vui-side-form-container {
    height: calc(100% - 60px);
    padding: 0;
    width: 100%;
    top: 60px;

    .vui-side-form {
      .vui-form {
        max-width: 100%;
        border-radius: 0;
      }
    }
  }
}