.vui-transfer-bicycle-model {
  .vui-modal-container {
    .vui-modal {
      max-width: 448px;
    }
  }

  .vui-transfer-bicycle-model__body {
    max-width: 320px;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
  }

  .vui-transfer-bicycle-model__actions {
    column-gap: 1.5rem;
  }
}