@import "variables";

* {
  outline: none;
}

img {
  //IE compatible
  border: 0;
}
.link {
  color: $vt-color-primary;
  text-decoration: none;

  &:hover,
  &:active {
    color: $vt-color-primary-hue-1;
  }
}

.base-template {
  font-family: Montserrat, serif;
  color: white;

  .bt-lighter-background {
    background-color: $color-primary-l1;
  }

  h1 {
    text-align: center;
    font-size: 40px;
  }

  h1, h2 {
    color: white;
  }

  p {
    color: white;
    text-align: justify;
    font-size: 17px;
    line-height: 26px;
  }

  .bt-section-title {
    margin-bottom: 50px;

    h1 {
      font-size: 2em;
      color: white;
      margin: 0 10px;
      text-align: center;
    }
    h2 {
      font-size: 2em;
      color: white;
      margin: 0 10px;
      text-align: center;
      font-weight: normal;
    }

    h3 {
      font-size: 20px;
      text-align: center;
      margin-left: 10px;
      margin-right: 10px;
      color: #ccc;
      font-weight: normal;
    }
    .align-left {
      text-align: left;
    }

    span {
      color: white;
      font-size: 1.1em;
    }
  }

  .bt-content-wrapper {
    max-width: 1200px;
    margin: 0 auto;
    padding: 60px 15px;
    overflow: hidden;
  }
  .bt-content-background {
    overflow: hidden;
  }

}
