.share-buttons {

  .share-buttons__icon {
    font-size: 30px;
  }

  .share-buttons__button {
    color: white;
  }

  .share-buttons__button_twitter {
    color: #0095dc;
  }

  .share-buttons__button_facebook {
    color: #3b5998;
  }

  .share-buttons__button_google {
    color: #dc4a38;
  }

  .share-buttons__button_linkedin {
    color: #0077b5;
  }

  &.share-buttons_vertical {
    .share-buttons__button {
      display: block;
    }
  }

  &.share-buttons_small-resolution {
    .share-buttons__icon {
      @media (max-width: 360px) {
        font-size: 22px;
      }
    }
  }

}