@import "../../../velotooler-app";

.vui-edit-btn {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 0.75rem;
  text-align: left;
  color: $vui-label-color;
  display: flex;
  flex-direction: row;
  column-gap: 0.25rem;
  align-items: flex-end;
  @include transition-short(color);

  &:focus {
    outline: 0;
  }

  path {
    @include transition-short(stroke);
    stroke: $vui-label-color;
  }
}

.vui-edit-btn:active,
.vui-edit-btn:focus,
.vui-edit-btn:hover,
.vui-edit-btn[disabled] {
  color: $vui-text-placeholder-color;

  path {
    stroke: $vui-text-placeholder-color;
  }
}
