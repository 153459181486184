.detailed-location-autocomplete {

  .md-input-has-placeholder {
    label {
      transform: translate3d(0, 6px, 0) scale(0.9) !important;
      transition: transform cubic-bezier(0.25, 0.8, 0.25, 1) 0.5s !important;
    }
  }

  .detailed-location-autocomplete__input-container {
    margin-top: 25px;
    max-height: 33px;
  }

  @media (max-width: 410px) {
    .detailed-location-autocomplete__input-container {
      margin-top: 47px;
    }
  }
}
