@import "variables";

.credit-card-form {
  min-height: 300px;
  padding: 10px 0;

  .col-sm-4 {
    padding: 0 10px;
  }

  .card {

    .card-actions {
      padding-bottom: 1px;
    }

    .card_actions_swith-button {
      position: absolute;
      right: 15px;
      top: 33px;
    }

    &:before {
      min-height: 0;
    }

    border-radius: 4px;
    display: block;
    margin: 15px auto;
    padding: 0;
    background-color: $color-primary-l2;
    border-top: 0;
    white-space: nowrap;
    position: relative;

    &.card-default {
      background-color: $color-primary-l4;
      transform: scale(1.05);
      box-shadow: 0 3px 6px rgba(0, 0, 0, .16), 0 3px 6px rgba(0, 0, 0, .23);
    }

    &.card-default-button, .md-button[disabled] span {
      color: $done-button-text-color !important;
    }

    .remove-button {
      position: absolute;
      top: 0;
      right: 0;
      padding: 10px;
      color: $remove-icon-color;

      &:hover {
        background-color: rgba(97, 91, 91, 0.36);
      }
    }

    .card-info {
      padding: 10px 30px;

      .card-brand-img {
        img {
          width: 70px;
          height: 44px;
        }
      }

      .card-brand-img,
      .card-number {
        display: table-cell;
        vertical-align: middle;

        img {
          border: none;
        }
      }
    }

    .card-owner {
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0 7px;
      width: 100%
    }

    .remove-card {
      color: $vt-color-primary;
      margin: 5px auto;
      display: block;
    }

    .remove-card:hover {
      color: $vt-color-primary-hue-1;
    }

    @media (min-width: 796px) and (max-width: 846px) {
      max-width: 250px;
      font-size: 14px;
    }

    @media (min-width: 768px) and (max-width: 795px) {
      font-size: 12px;
      max-width: 250px;
    }

    @media (min-width: 471px) and (max-width: 767px) {
      font-size: 14px;
      max-width: 350px;
    }

    @media (max-width: 470px) {
      font-size: 12px;
      max-width: 250px;
    }

  }

  show-cards {
    width: 100%;
  }

  md-icon {
    height: 26px;
    width: 26px;
    font-size: x-large !important;
  }

  md-fab-trigger {
    text-align: center;
  }
}
