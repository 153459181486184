@import "../../sass/variables";

.vt-wizard {

  color: white;

  md-tabs-wrapper {
    display: none !important;
  }

  .vt-wizard__body {
    position: relative;
    padding: 0 50px;
    margin: 0;
  }

  .vt-wizard__mobile-navigation {
    border: none;
    display: none;
    background-color: $color-primary-l3;
    width: 100%;
    height: 55px;
    position: fixed;
    left: 0;
    bottom: 0;
    padding: 0;
    margin: 0;
    z-index: 100;
    -webkit-overflow-scrolling: touch;

    .md-button {
      line-height: 43px !important;
    }
  }

  .vt-wizard_desktop-only {
    visibility: visible !important;
  }

  .vt-wizard_mobile-only {
    visibility: hidden !important;
  }

  .vt-wizard__back-button {
    left: -10px;
    position: absolute;
    top: 19px;
    svg {
      fill: #aaa;
    }
    background: none !important;
  }

  .vt-wizard__head-text {
    text-align: left;
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 20px;
    padding-top: 20px;
  }

  .vt-wizard__step-dashes {
    height: 4px;
    margin: 24px 55px 0;
    position: relative;
    display: flex;
    justify-content: space-between;
    background-color: #777;
  }

  .vt-wizard__step-dash {
    background: $color-primary-d1;
    height: 4px;
    margin: 0 -2px;
    position: relative;
    width: 4px;
  }

  .vt-wizard__step-dash_active {
    background-color: $vt-color-primary;
  }

  @media (max-width: $mobile-width) {
    .vt-wizard__mobile-navigation {
      display: block;
    }

    .vt-wizard_desktop-only {
      visibility: hidden !important;
    }

    .vt-wizard_mobile-only {
      visibility: visible !important;
    }

    .vt-wizard__back-button {
      left: -5px;
      top: 13px;
    }

    .vt-wizard__head-text {
      font-size: 25px;
      margin-top: 10px;
      margin-left: 40px;
      margin-right: 20px;
    }

    .vt-wizard__body {
      padding: 0 5px;
    }
  }

}