@import "../../../../velotooler-app";

.landing-page-accordion-item {
  margin-bottom: 1.25rem;

  .landing-page-accordion-item__title {
    cursor: pointer;
    font-size: 1.375rem;
    line-height: 1.75rem;
    text-transform: uppercase;
    background: $input-default-color;

    .landing-page-accordion-item__title--clickable {
      padding: 1.25rem 1.25rem 1.25rem 3rem;
      display: flex;
      min-height: 92px;
      align-items: center;
      justify-content: center;
    }

    .accordion-title__content {
      width: 100%;
      padding-right: 1rem;
    }

    .accordion-title__icon {
      display: flex;
      align-items: center;
      transition: 0.4s;
    }
  }

  .landing-page-accordion-item__content {
    max-height: 0;
    transition: 0.4s;
    margin: 0;
    font-size: 1.25rem;
    line-height: 2.5rem;
    overflow: hidden;

    landing-page-accordion-item-content {
      padding: 1.25rem 3rem 0 3rem;
      display: block;
    }
  }

  &.accordion-item--open {
    .landing-page-accordion-item__title {;
      .accordion-title__icon {
        transform: rotate(180deg);
      }
    }

    .landing-page-accordion-item__content {
      max-height: 1000px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .landing-page-accordion-item {
    .landing-page-accordion-item__title {
      font-size: 1rem;
      line-height: 1.25rem;

      .landing-page-accordion-item__title--clickable {
        padding: 0.5rem 0.75rem 0.5rem 0.5rem;
      }
    }

    .landing-page-accordion-item__content {
      font-size: 1rem;
      line-height: 1.25rem;

      landing-page-accordion-item-content {
        padding: 1rem 0.5rem 0 0.5rem;
      }
    }
  }
}
