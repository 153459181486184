.profile-verifications {

  .profile-verifications__verifications-section {
    padding: 0 20px;
  }

  .profile-verifications__verification-item {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }

  .profile-verifications__verification-item-key {
    flex: 1 1 70%;
    color: #eee;
  }

  .profile-verifications__verification-item-value {
    flex: 1 1 30%;
    color: #ffec92;
    font-size: 12px;
    text-transform: uppercase;
    &.done {
      color: #51c57e;
    }
  }
}