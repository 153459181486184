@import "../../../velotooler-app";

vui-tab {
  .vui-tab {
    font-size: 0.875rem;
    line-height: 17px;
    color: $vui-label-color;
    cursor: pointer;
    @include transition-all-short();

    .vui-tab__link {
      padding: 0.5rem  1rem  0.5rem 1rem;
      color: $vui-label-color;
      display: block;
      text-wrap: nowrap;
    }

    &[disabled] {
      pointer-events: none;
      cursor: default;
    }

    &.active:not([disabled]) {
      display: block;
    }

    &.active:not([disabled]),
    &:active:not([disabled]),
    &:focus:not([disabled]),
    &:hover:not([disabled]) {
      color: $vui-dark-text-color;
      border-bottom: 3px solid $vui-color-primary;

      .vui-tab__link:not([disabled]) {
        color: $vui-dark-text-color;
      }
    }

    &:not(.active) {
      border-bottom: 3px solid $vui-main-menu-color;
    }
  }
}

@media only screen and (max-width: 991px) {
  vui-tab {
    .vui-tab {
      font-size: 0.75rem;
    }
  }
}