@import "../variables";

$toggle-component-color: #565555;
$component-color: #c5c1c1;
$color-state-new: #51c57e;
$color-state-ok: #d1a713;
$color-state-bad: #EF5350;
$main-info-padding: 3px;

.vt-list-element {
  box-sizing: border-box;
  padding: 15px 5px 12px 0;
  font-size: 1em;
  color: $component-color;
  border-top: solid 1px #615e5e;

  .vt-list-element__brand {
    height: 25px;

    md-input-container {
      height: 38px;
    }
  }

  .vt-list-element__model {
    height: 38px;

    md-input-container {
      height: 50px;
    }
  }

  .vt-list-element__name {
    height: 38px;

    md-input-container {
      height: 50px;
    }
  }

  .vt-list-label-text {
    display: inline-block;
    margin-left: 1px;
    width: calc(100% - 50px);
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    & > span:not(:first-of-type) {
      color: $text-main-color;
    }
  }

  .vt-list-comp-icon {
    width: 1em;
    height: 1em;
  }

  .vt-list-comp-toggle, .vt-list-remove, .vt-list-comp-certified {
    float: right;
    color: $toggle-component-color;
    cursor: pointer;
  }

  .vt-list-comp-certified, .vt-list-comp-toggle, .vt-list-remove {
    font-size: 1.3em;
  }

  .vt-list-comp-certified {
    margin-right: 5px;

    &.vt-certified-new {
      color: $color-state-new;
    }

    &.vt-certified-ok {
      color: $color-state-ok;
    }

    &.vt-certified-bad {
      color: $color-state-bad;
    }
  }

  .vt-list-remove {
    padding-right: $main-info-padding;
    margin-left: 10px;
  }

  .vt-list-confirmation {
    color: $vt-color-primary;
    float: right;
    padding: 0 5px 0 5px;
  }

  .vt-list-main-info {
    cursor: pointer;
    height: 20px;
  }

  .vt-list-details-container {
    padding-left: 20px;
    list-style-type: square;
    line-height: 2.5em;
    font-size: .8em;
    max-height: 0;
    margin: 0;
    overflow: hidden;
    transition: max-height 500ms ease-in-out;
    -webkit-transition: max-height 500ms ease-in-out; /* Safari 3.1 to 6.0 */

    &.open-details {
      max-height: 417px;
    }

    md-input-container {
      margin-top: 0 !important;
    }

    input {
      color: $text-main-color;
      text-transform: uppercase;
    }

    & li:first-child .ui-input-container {
      margin-left: 6px;
    }
  }

  .certified-component {
    color: $color-state-new;
  }

  .non-certified-component {
    color: $color-state-bad;
  }

  .ui-radio {
    label {
      padding: 0 5px 0 15px;
    }

    .check {
      height: 14px;
      width: 14px;
      top: 8px;
    }

    .check:before {
      height: 4px;
      width: 4px;
      top: 2px;
    }
  }

  .ui-input-container {
    padding: 0;

    .ui-label {
      bottom: -.3em;

      &:after {
        background-color: $text-main-color;
      }
    }

    md-autocomplete[disabled] {
      background: none;
    }

    md-autocomplete {
      input {
        height: 23px;
      }

      input[disabled] {
        border-color: rgba(255, 242, 183, 0.25) !important;
        border-bottom: 1px solid rgba(255, 242, 183, 0.25);
        color: #888888 !important;
      }
    }
  }
}

vt-component-element:last-child .vt-list-element {
  border-bottom: solid 1px #615e5e;
}

@media (max-width: 785px) {
  .wide-screen-components {
    display: none;
  }
}

@media (min-width: 786px) {
  .mobile-screen-components {
    display: none;
  }
}

.vt-components-container {
  margin-bottom: 20px;
}

.ui-radio input[type=radio][checked] ~ .check {
  border: 3px solid $vt-color-primary;

  &::before {
    background: $vt-color-primary;
  }
}

.ui-radio input[type=radio][checked] {
  & ~ span {
    color: $vt-color-primary;
  }
}

.components-grid {

  .component-label {
    line-height: 27px;
  }

  .component-save-icon {
    padding-left: 0;
  }
}

.additional-components-form {
  .row.vt-section.section-without-border {
    border: none;
  }
}


