@import "../../../velotooler-app";

.vui-upload-images-model {
  .vui-upload-images-model__body {
    width: 670px;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    align-items: center;

    .body__photos-container {
      width: 100%;
    }

    .body__main-photo {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      align-items: center;
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 17px;
      text-transform: uppercase;

      .main-photo {
        @include size-rect(180px);
        border-radius: 0.25rem;
        cursor: pointer;
        border: 1px solid transparent;
        @include transition-short(border-color);

        &:hover {
          border: 1px solid $vui-color-primary--hover;
        }
      }

      .remove-image {
        position: absolute;
        margin: 40px -140px 0 0;
      }
    }
  }

  .vui-upload-images-model__actions {
    column-gap: 1.5rem;
  }
}

@media only screen and (max-width: 767px) {
  .vui-upload-images-model {
    .vui-upload-images-model__body {
      max-width: 400px;
      width: 100%;
    }

    .vui-modal-container {
      .vui-modal {
        padding: 1rem;
        row-gap: 1rem;

        .vui-upload-images-model__body {
          row-gap: 1rem;
        }
      }
    }
  }
}