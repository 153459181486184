@import "../../../velotooler-app";

@mixin content-size($height) {
  .coming-soon {
    .coming-soon__content,
    .coming-soon__background,
    .coming-soon__label {
      height: $height;
    }
  }
}

.coming-soon {
  .coming-soon__background {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.69) 0%,
      rgba(0, 0, 0, 0.5625) 71.87%,
      rgba(0, 0, 0, 0.3) 100%), url("/img/home/service-bg.png") no-repeat center center;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    @include background-cover();
    filter: grayscale(100%);
  }

  .coming-soon__label svg {
    @include size-100();
  }
}

@include content-size(calc(100vh - #{$vui-header-height}));

@media only screen and (max-width: 767px) {
  @include content-size(calc(100vh - #{$vui-header-height-sm}));
}
