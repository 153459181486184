@import "../../../sass/variables";

.vt-widget {

  background-color: $color-primary;

  .vt-widget__pl-loading-indicator {
    background-color: rgba(35, 35, 35, 0.9) !important;
  }

  .vt-widget__header {
    color: white;
    text-align: left;
    font-weight: 500;
    padding: 10px;
    background-color: $color-primary-l2;
  }

  .vt-widget__body {
    color: white;
    font-size: 15px;
    padding: 15px;
    min-height: 50px;
  }

  .vt-widget__footer {
    margin-top: 20px;
    border-top: 1px solid $color-primary-l3;
    text-align: center;
    padding: 10px;
  }

  .vt-widget__footer_without-border {
    border-top: none;
  }

  .vt-widget__header_without-border {
    border-bottom: none;
  }

  .vt-widget__empty-data-label {
    color: #555;
    text-align: center;
  }
}