@import "create/create-core-service";
@import "view/core-service-view";
@import "list/core-service-category-list";
@import "list/personal-service-list";
@import "list/corporate-service-list";
@import "view/core-service-category-view";
@import "item/custom-service-item";
@import "item/custom-service-category-item";
@import "item/custom-service-node";
@import "create/create-custom-service-category";
@import "view/core-service-buckets-view";
@import "create/create-core-service-bucket";

.custom-services-page {
  max-width: 900px;
  md-tab-item {
    max-width: 220px !important;
  }
}
