.profile-photos {

  .profile-photos__photos-section {

    .img-container {
      margin: 1px;
      max-height: 120px;
    }
    .img-tmb {
      width: 120px;
      height: 120px;
    }
  }
}