@import "../../../velotooler-app";

.vui-side-menu-item {
  &.vui-btn.btn-outline-dark {
    border: 0 solid $color-white;
    border-radius: 0 0.625rem 0.625rem 0;
    @include transition-all-short();
    padding: 0 2rem 0 2rem;
    min-width: 1.25rem;

    span {
      font-size: 0.875rem;
      text-align: left;
      transition: all 250ms ease-in-out;
      clear: both;
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
    }

    path {
      @include transition-all-short();
    }

    &[disabled] {
      pointer-events: none;
    }

    &.active:not([disabled]),
    &:active:not([disabled]),
    &:focus:not([disabled]),
    &:hover:not([disabled]) {
      .vui-inverted-icon {
        path {
          -webkit-filter: invert(100%);
          filter: invert(100%);
        }
      }
    }
  }
}

.vui-side-menu--expanded {
  .vui-side-menu-item.vui-btn.btn-outline-dark {
    span {
      width: 166px;
      padding-left: 1rem;
    }
  }
}

.vui-side-menu--collapsed {
  .vui-side-menu-item.vui-btn.btn-outline-dark {
    span {
      width: 0;
      padding-left: 0;
    }
  }
}

@media only screen and (max-width: 991px) {
  .vui-side-menu-item {
    &.vui-btn.btn-outline-dark {
      border-radius: 0;

      span {
        font-size: 1rem;
        font-weight: 700;
        width: 100% !important;
        line-height: 3rem;
      }
    }
  }
}