.vui-change-bicycle-group {
  .vui-modal-container {
    .vui-modal {
      max-width: 448px;
    }
  }

  .vui-change-bicycle-group__body {
    max-width: 320px;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
  }

  .vui-change-bicycle-group__actions {
    column-gap: 1.5rem;
  }
}