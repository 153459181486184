@mixin gridContainer() {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
}

@mixin gridElement($type) {
  margin-top: 10px;
  position: relative;
  overflow: hidden;
  min-width: 190px;
  width: 100%;
  flex: 1 1 100%;

  .#{$type}__header {
    @include gridElementHeader();
  }

  .#{$type}_text {
    @include gridElementText();
  }

  .#{$type}__button {
    position: relative;
  }

  .#{$type}_title {
    color: $color-white;
  }
  .#{$type}_avatar {
    @include gridElementAvatar();
  }
  .#{$type}_header {
    font-size: 1.7em;
    text-align: center;
  }
  @include gridElementButtonCoverBlock($type);

  .#{$type}_bright-background {
    background-color: rgba(20, 20, 20, 0.7);
  }
}

@mixin gridElementHeader() {
  background-color: $form-background;
  position: relative;
  padding: 10px;
  margin: 0;
}

@mixin gridElementActionsTab() {
  background-color: $form-background;
  height: 50px;

  .md-menu {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translate(0, -50%);

    .md-button {
      min-width: 5px;
      color: white;
      margin: 5px 1px;

      md-icon {
        color: #aaa;
        width: 20px;
        height: 20px;
      }
    }
  }
}

@mixin gridElementText() {
  color: #555555;
  font-size: 0.875em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  letter-spacing: 0.010em;
  line-height: 1.4em;
}

@mixin gridElementAvatar() {
  position: relative;
  background-color: $container-background;

  img {
    display: block;
    width: 100%;
  }
}

@mixin gridElementButtonCoverBlock($class) {
  .#{$class}__button_cover-block {
    border-radius: 0px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    margin: 0;
    padding: 0;
    min-width: 0;
    min-height: 0;
  }
  .#{$class}__button_cover-block:active,
  .#{$class}__button_cover-block:focus {
    background-color: rgba(50, 50, 50, 0.4);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
  }

  .#{$class}__button_cover-block.#{$class}__button {
    width: calc(100% - 10px);
    margin-left: 5px;
    border: thin dashed #444444;
  }
}

@mixin gridElementMedia($class) {
  @media (min-width: 460px) {
    margin: 0 -5px;

    .#{$class} {
      float: left;
      max-width: 50%;
      flex-basis: 50%;
      padding: 0 5px;
    }
  }

  @media (min-width: 900px) {
    .#{$class} {
      max-width: 33.333333%;
      flex-basis: 33.333333%;
    }
  }

  @media (min-width: 1200px) {
    .#{$class} {
      max-width: 20%;
      flex-basis: 20%;
    }
  }
}