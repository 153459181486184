.vt-tabset {
  width: 100%;
  overflow: hidden;

  .vt-tabset__button.vt-tabset__button_active {
    background-color: #2b2b2b;
  }

  .vt-tabset__button.vt-tabset__button_active:not([disabled]):hover {
    background-color: #2b2b2b;
  }

  .vt-tabset__button:not([disabled]):hover {
    background-color: rgba(72, 72, 72, 0.2);
  }

  .vt-tabset__button {
    padding: 8px 16px;
    float: left;
    width: auto;
    border: none;
    outline: none;
    display: block;
    margin: 0;
    border-radius: 0;
    color: white;
  }
}