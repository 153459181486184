@import "../../../velotooler-app";

.vui-menu {
  .vui-main-menu {
    .vui-main-menu__logo {
      z-index: 120;
      @include transition-short(max-width);

      .vui-logo {
        @include margin-x(auto);
        display: block;
        width: fit-content;
      }
    }

    .vui-main-menu__logo-small {
      display: none;
    }

    .vui-main-menu__menu-toggler {
      padding: 0 1.25rem;
      outline: none;
    }

    .vui-main-menu__breadcrumb {
      flex-grow: 2;
      text-align: left;
    }

    .vui-main-menu__navigations {
      vui-main-menu-items {
        padding: 1.5rem;
        display: flex;
        flex-direction: row;
        column-gap: 1.5rem;
        align-items: center;
      }
    }
  }
}

.vui-menu.vui-side-menu--expanded {
  .vui-main-menu {
    .vui-main-menu__logo {
      max-width: 250px;
    }
  }
}

.vui-menu.vui-side-menu--collapsed {
  .vui-main-menu {
    .vui-main-menu__logo {
      max-width: 88px;
    }
  }
}

.vui-main-menu__breadcrumb-mobile {
  display: none;
}

@media (min-width: 768px) and (max-width: 991px) {
  .vui-menu {
    .vui-main-menu {
      .vui-main-menu__logo {
        display: none;
      }

      .vui-main-menu__logo-small {
        display: block;
        z-index: 1000;
        outline: none;

        .vui-logo {
          @include size-rect($vui-portal-header-height-sm);
          margin-right: 2rem;

          svg {
            @include size-rect($vui-portal-header-height-sm);
          }
        }
      }

      .vui-main-menu__menu-toggler {
        padding: 0 2rem;
        outline: none;
      }

      .vui-main-menu__navigations {
        vui-main-menu-items {
          padding: 0.75rem 2rem;
          column-gap: 2rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .vui-menu {
    .vui-main-menu {
      .vui-main-menu__breadcrumb {
        display: none;
      }

      .vui-main-menu__logo {
        @include size-rect($vui-portal-header-height-sm);
        max-width: 100% !important;
        flex-grow: 2;

        svg {
          @include size-rect($vui-portal-header-height-sm);
        }
      }

      .vui-main-menu__logo-small {
        display: none;
      }

      .vui-main-menu__navigations {
        vui-main-menu-items {
          padding: 0.75rem 1.25rem;
          column-gap: 1.25rem;
        }
      }
    }
  }

  .vui-main-menu__container {
    row-gap: 1rem;
    padding-bottom: 0.5rem;

    .vui-main-menu__breadcrumb-mobile {
      display: block;
    }
  }
}