@import "../variables";

.short-address-view {
  color: #aaa;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.short-address-view__location-marker {
  color: $vt-color-primary;
  vertical-align: baseline;
}

.short-address-view__location-text {
  vertical-align: middle;
  font-size: 14px;
}