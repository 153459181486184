.vt-link {
  color: #4988ad;
  cursor: pointer;
  background: none !important;
  text-decoration: none;

  &:disabled {
    color: #333;
  }
}

.vt-link_theme-yellow {
  color: #ffec92;
}

.vt-link_theme-wheat {
  color: wheat;
}

.vt-link_theme-white {
  color: white;
}

.vt-link_bold {
  font-weight: 500;
}

.vt-link_block {
  display: block;
}