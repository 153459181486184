@import "../../../velotooler-app";

.vui-form {
  background: $color-white;
  box-shadow: 30px 30px 65px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  padding: 90px 120px;
  @include margin-x(auto);

  .vui-form__title {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 2rem;
  }

  &.vui-form__with-back {
    padding: 0 120px 90px 120px;
  }

  .vui-form__header-actions {
    padding: 55px 0 22px 0;
    margin: 0 -65px;
  }
}

.vui-form-lg {
  max-width: 100%;
}

.vui-form-md {
  max-width: 780px;
}


@media only screen and (max-width: 767px) {
  .vui-form {
    padding: 60px;

    &.vui-form__with-back {
      padding: 0 60px 60px 60px;
    }

    .vui-form__header-actions {
      padding: 28px 0 16px 0;
      margin: 0px -48px;
    }
  }
}
