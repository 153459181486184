@import "../../../variables";

.profile-about {
  .profile-about__about-section {
    display: flex;
    position: relative;
  }

  .profile-about__about-section_overflow {

    &:before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: linear-gradient(transparent 30px, $color-primary);
    }

  }

  .profile-about__about-section-text {
    text-align: justify;
    flex: 1 1 300px;
    line-height: 22px;
    text-overflow: ellipsis;
  }
}