@import "../../../velotooler-app";

@mixin coloredButton($bgColor, $textColor) {
  background: $bgColor;
  span {
    color: $textColor;
  }

  path {
    stroke: $textColor;
  }
}

@mixin borderedButton($borderColor, $borderSize) {
  border: $borderSize solid $borderColor;
}

@mixin buttonStyle($borderRadius) {
  border-radius: $borderRadius;
  span {
    font-size: 1.375rem;
    line-height: 4.375rem;
  }
}

@mixin minButtonStyle($borderRadius) {
  border-radius: $borderRadius;
  min-width: 120px;
  span {
    font-size: 1rem;
    line-height: 2.5rem;
  }
}

@mixin hoverButtonStyle($bgColor, $borderColor, $textColor) {
  &:hover:not([disabled]),
  &:active:not([disabled]),
  &:focus:not([disabled]),
  &.active:not([disabled]) {
    border-color: $borderColor;
    background: $bgColor;

    span {
      color: $textColor;
    }

    path {
      stroke: $textColor;
    }
  }
}

@mixin disabledButtonStyle($opacity, $textColor) {
  &[disabled] {
    cursor: default;
    opacity: $opacity;

    span {
      color: $textColor;
    }

    path {
      stroke: $textColor;
    }
  }
}

.vui-btn, button.vui-btn {
  display: inline-block;
  text-align: center;
  padding: 0 1rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

  span {
    font-weight: 600;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    text-wrap: nowrap;
  }

  &.btn-lg {
    min-width: 260px;
  }

  &.btn-md {
    min-width: 160px;
  }

  &.btn-sm {
    font-size: 0.875rem;
    border-radius: 0.25rem;
    min-width: 84px;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 0.5rem;
    justify-content: space-between;

    path {
      transition: stroke .15s ease-in-out;
    }

    &.btn-sm-primary {
      padding: 9px 1rem;
      column-gap: 1rem;
      @include coloredButton($vui-color-primary, $vui-dark-text-color);
      @include hoverButtonStyle($vui-color-primary--hover, $transparent, $vui-dark-text-color);
      @include disabledButtonStyle(0.3, $vui-dark-text-color);
    }

    &.btn-outline-sm-dark {
      @include coloredButton($transparent, $color-dark);
      @include borderedButton($color-dark, 1px);
      @include hoverButtonStyle($color-dark, $vui-color-dark--hover, $vui-light-text-color);
      @include disabledButtonStyle(0.15, $color-dark);
    }

    &.btn-sm-dark {
      @include coloredButton($color-dark, $vui-light-text-color);
      @include hoverButtonStyle($vui-color-dark--hover, $transparent, $vui-light-text-color);
      @include disabledButtonStyle(0.1, $vui-light-text-color);
      padding: 0.375rem 0.5rem;
    }

    &.btn-sm-tr {
      @include coloredButton($transparent, $vui-dark-text-color);
      @include hoverButtonStyle($transparent, $transparent, $vui-dark-text-color--hover);
      @include disabledButtonStyle(0.1, $vui-light-text-color);
      padding: 0.375rem 0.5rem;
    }

    svg {
      max-height: 0.875rem;

      path {
        stroke: $color-white;
      }
    }
  }

  &.btn-primary {
    @include coloredButton($vui-color-primary, $vui-dark-text-color);
    @include buttonStyle(0.24rem);
    @include hoverButtonStyle($vui-color-primary--hover, $transparent, $vui-dark-text-color);
    @include disabledButtonStyle(0.3, $vui-dark-text-color);
  }

  &.btn-dark {
    @include coloredButton($color-dark, $vui-light-text-color);
    @include buttonStyle(0.24rem);
    @include hoverButtonStyle($vui-color-dark--hover, $transparent, $vui-light-text-color);
    @include disabledButtonStyle(0.1, $vui-light-text-color);
  }

  &.btn-min-primary {
    @include coloredButton($vui-color-primary, $vui-dark-text-color);
    @include minButtonStyle(0.24rem);
    @include hoverButtonStyle($vui-color-primary--hover, $transparent, $vui-dark-text-color);
    @include disabledButtonStyle(0.3, $vui-dark-text-color);
  }

  &.btn-min-dark {
    @include coloredButton($color-dark, $vui-light-text-color);
    @include minButtonStyle(0.25rem);
    @include hoverButtonStyle($vui-color-dark--hover, $transparent, $vui-light-text-color);
    @include disabledButtonStyle(0.1, $vui-light-text-color);
  }

  &.btn-outline-primary {
    @include coloredButton($transparent, $vui-color-primary);
    @include borderedButton($vui-color-primary, 1px);
    @include buttonStyle(0.625rem);
    @include hoverButtonStyle($transparent, $vui-color-primary--hover, $vui-color-primary--hover);
    @include disabledButtonStyle(0.6, $vui-color-primary);
  }

  &.btn-outline-dark {
    @include coloredButton($transparent, $color-dark);
    @include borderedButton($color-dark, 1px);
    @include buttonStyle(0.25rem);
    @include hoverButtonStyle($color-dark, $vui-color-dark--hover, $vui-light-text-color);
    @include disabledButtonStyle(0.15, $color-dark);
  }

  &.btn-outline-min-primary {
    @include coloredButton($transparent, $vui-color-primary);
    @include borderedButton($vui-color-primary, 1px);
    @include minButtonStyle(0.625rem);
    @include hoverButtonStyle($transparent, $vui-color-primary--hover, $vui-color-primary--hover);
    @include disabledButtonStyle(0.6, $vui-color-primary);
  }

  &.btn-outline-min-dark {
    @include coloredButton($transparent, $color-dark);
    @include borderedButton($color-dark, 1px);
    @include minButtonStyle(0.25rem);
    @include hoverButtonStyle($transparent, $vui-color-dark--hover, $vui-color-dark--hover);
    @include disabledButtonStyle(0.15, $color-dark);

    span {
      line-height: 38px;
    }
  }

  &.btn-outline-min-primary-rounded {
    @include coloredButton($transparent, $vui-color-primary);
    @include borderedButton($vui-color-primary, 1px);
    @include minButtonStyle(1.5rem);
    @include hoverButtonStyle($transparent, $vui-color-primary--hover, $vui-color-primary--hover);
    @include disabledButtonStyle(0.6, $vui-color-primary);
  }

  &.btn-outline-min-dark-rounded {
    @include coloredButton($transparent, $color-dark);
    @include borderedButton($color-dark, 1px);
    @include minButtonStyle(1.5rem);
    @include hoverButtonStyle($transparent, $vui-color-dark--hover, $vui-color-dark--hover);
    @include disabledButtonStyle(0.15, $color-dark);
  }

  &:focus {
    outline: 0;
  }

  &.btn-outline-dark {
    .vui-input-group path {
      fill: white;
      stroke: black;
      transition: all 250ms ease-in-out;
    }

    &.active,
    &:active,
    &:focus,
    &:hover {
      .vui-input-group path {
        fill: black;
        stroke: white;
      }
    }
  }
}

.vui-btn.vui-fab {
  width: 52px;
  height: 52px;
  padding: 0;
  border-radius: 50%;
  background: $transparent;
  transition: all 250ms ease-in-out;

  &.vui-fab-min {
    width: 38px;
    height: 38px;

    svg {
      width: 38px;
      height: 38px;
    }
  }

  g > *, circle, path {
    stroke: $color-dark;
    transition: all 250ms ease-in-out;
  }

  &:active,
  &:focus,
  &:hover {
    background: $vui-color-primary;

    g > *, circle {
      stroke: $transparent;
    }

    path {
      stroke: $transparent;
      fill: white;
    }
  }
}

@media only screen and (max-width: 767px) {
  .vui-btn, button.vui-btn {
    &.btn-primary,
    &.btn-dark,
    &.btn-min-dark,
    &.btn-outline-primary,
    &.btn-outline-dark,
    &.btn-outline-min-primary,
    &.btn-outline-min-dark,
    &.btn-outline-min-primary-rounded,
    &.btn-outline-min-dark-rounded {
      span {
        font-size: 1rem;
        line-height: 2.5rem;
      }
    }
  }

  .vui-btn.vui-fab {
    width: 38px;
    height: 38px;

    svg {
      width: 38px;
      height: 38px;
    }
  }
}
