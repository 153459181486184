@import "../../../velotooler-app";

.infinite-scroll {
  display: flex;
  flex-direction: row;
  justify-content: center;
  column-gap: 1rem;
  font-size: 0.875rem;
  font-weight: 600;

  img {
    max-width: 60px;
  }
}
