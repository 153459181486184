@import "../../../sass/variables";

.general-service-bucket-view {
  max-width: 800px !important;

  .general-service-bucket-view__remove-button {
    float: left;
    margin: 6px 8px;
  }

  .general-service-view-form__switch {
    $remove-tool-btn-color: rgb(255, 236, 146);
    $task-switch-thumb-background-color: #51C57E;
    $task-switch-bar-background-color: rgba(81, 197, 126, 0.5);
    $task-switch-bar-disabled-background-color: #777777;
    $task-switch-icon-color: #00aae5;

    margin: 20px 0;

    .md-checked .md-thumb {
      background-color: $task-switch-thumb-background-color;
    }

    .md-checked .md-bar {
      background-color: $task-switch-bar-background-color;
    }

    md-switch[disabled] .md-bar {
      background-color: $task-switch-bar-disabled-background-color;
    }

    md-switch[disabled] .md-thumb {
      background-color: $input-disabled-color;
    }

    md-switch .md-thumb {
      background-color: $input-disabled-color;
    }

    .general-service-view-form__switch__label {
      vertical-align: middle;
      display: inline-block;
      margin-right: 10px;
      padding-left: 3px;
    }

    .general-service-view-form__switch__body {
      display: inline-block;
      margin: 0;
      vertical-align: middle;
      height: 25px;
    }

    .general-service-view-form__switch__fab {

    }

    .general-service-view-form__switch__icon {
      color: $task-switch-icon-color;
      height: 15px;
      width: 15px;
      font-size: small !important;
      margin-top: -8px;
      cursor: pointer;
    }

  }
}
