@import "../../../velotooler-app";

.bike-autocomplete {
  .bike-autocomplete__close-icon {
    position: absolute;
    right: 13px;
    top: 9px;
    width: 13px;
    height: 13px;
  }

  .bike-autocomplete__item-sign {
    width: 6px;
    height: 6px;
    border-radius: 0.25rem;
    vertical-align: top;
  }

  .bike-autocomplete__item-new-sign {
    background-color: $color-green;
  }

  .bike-autocomplete__menu {
    display: inline-block;
  }

  .bike-autocomplete__menu-item:hover {
    background-color: #2d2d2d;
    box-shadow: 14px 4px 10px rgba(31, 31, 31, 0.5);
  }

  .bike-autocomplete__menu-item {
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 45px;
    line-height: 45px;
    padding: 0 20px;
    outline: none;
    font-size: 16px;
    color: #666;
    text-decoration: none;
    border-bottom: 2px solid #666;
    cursor: pointer;

    @media (max-width: 350px) {
      padding: 0 10px;
    }
  }

  .bike-autocomplete__menu-item_selected {
    background-color: #222;
    border-bottom: 2px solid white !important;
    font-weight: 500;
    span {
      color: white;
    }
  }

  .bike-autocomplete__list {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 345px;

    &::-webkit-scrollbar {
      width: 5px;
      background-color: #666;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #444;
    }
  }

  .bike-autocomplete__item-container {
    padding: 2px 0;
  }

  .bike-autocomplete__select-icon {
    width: 25px;
    height: 25px;
    border: none;
    margin: auto;
  }

  .bike-autocomplete__item-sn-label {
    font-size: 14px;
  }

  .bike-autocomplete__item-description-label {
    color: #F5F5F5;
  }

  .bike-autocomplete__item-sn-name {
    background-color: $color-green;
    font-weight: 900;
    color: black !important;
    border-radius: 3px;
    padding: 0 3px;
    margin-left: 5px;
  }

  .bike-autocomplete__item-icon {
    $icon-width: 52px;
    width: $icon-width;
    height: $icon-width;
    border: 1px solid #333;
    border-radius: 50%;
    margin: auto;
  }

  .bike-autocomplete__item-description {
    flex: 26 0 0;
    margin: auto auto auto 15px;
  }

  .bike-autocomplete__item {
    background-color: transparent;
    border-top: 1px solid transparent;
    display: flex;
    width: 100%;
    box-sizing: border-box;
    align-self: stretch;
    margin: auto;
    padding: 5px 10px;
    flex: 1 0 0;
    cursor: pointer;

    span {
      color: #777;
      font-size: 13px;
    }

    &:hover {
      background-color: $color-primary-l2;
    }
  }

  .bike-autocomplete__empty-bike-list {
    text-align: center;
    padding: 60px 0;
  }

  .bike-autocomplete__items-not-found-icon {
    width: 160px;
  }

  .bike-autocomplete__items-not-found-title {
    padding-top: 30px;
    font-size: 24px;
    color: #999;
  }

  .bike-autocomplete__items-not-found-text {
    font-size: 16px;
    color: #777;
    padding-top: 20px;
  }

  .bike-autocomplete__close-icon {
    position: absolute;
    right: 13px;
    top: 9px;
    width: 13px;
    height: 13px;
  }
}
