@import "../../sass/variables";

.edit-mechanic-work-area {
  .edit-mechanic-work-area__body {
    display: flex;
  }

  .edit-mechanic-work-area__update-btn-section {
    background-color: #444;
  }

  .edit-mechanic-work-area__main-section {
    flex-basis: 52%;

    @media (max-width: $md-width) {
      flex-basis: 100%;
    }
  }

  .edit-mechanic-work-area__map-section {
    flex-basis: 48%;

    @media (max-width: $md-width) {
      display: none;
    }

    padding: 5px;

    .google-map {
      margin: 0;
      height: 600px;
    }
  }

  .edit-mechanic-work-area__item {
    cursor: pointer;
    border-bottom: 1px solid #222;

    &:hover {
      background-color: #222;
    }
  }

  .edit-mechanic-work-area__item-header {
    padding: 15px;
    margin: 0;
  }

  .edit-mechanic-work-area__item_expanded {
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    background-color: #292929;

    &:hover {
      background-color: #292929;
    }
  }

  .edit-mechanic-work-area__item-city {
    font-weight: bold;
    margin-bottom: 5px;
  }

  .edit-mechanic-work-area__item-edited-sign {
    width: 6px;
    height: 6px;
    border-radius: 5px;
    background-color: yellow;
    display: inline-block;
    vertical-align: top;
  }

  .edit-mechanic-work-area__item-zip-codes-label {
    font-size: 14px;
    color: #777;
  }

  .edit-mechanic-work-area__item-zip-code {
    font-size: 14px;
  }

  .edit-mechanic-work-area__item-angle {
    line-height: 64px;
  }

  .edit-mechanic-work-area__item-angle_up {
    transform: rotate(180deg);
  }

  .edit-mechanic-work-area__item-expanded-content {
    padding: 0 15px 15px 15px;
    border-top: 1px dashed #393939;
  }

  .edit-mechanic-work-area__activity-switch {
    line-height: 24px;
    height: 26px;
    margin: 0 20px 0 0;
    font-weight: bold;
  }

  .edit-mechanic-work-area__activity-switch-text_inactive {
    color: #888;
    font-weight: normal;
  }

}