.vui-add-bicycle-model {
  .vui-modal {
    max-width: 440px;
    width: 100%;
  }

  .vui-add-bicycle-model__body {
    display: flex;
    flex-direction: column;

    .vui-input-container__serial-number {
      .vui-input {
        padding-right: 3.375rem;
      }

      .vui-popover {
        position: absolute;
        right: 0;
        top: 0;
        margin: 0.5rem 1rem;

        .vui-popover__element {
          height: 1.5rem;
        }

        .vui-popover__dropdown {
          right: 0;
          width: 780px;
          padding-bottom: 4rem;
          border-radius: 2rem;
        }
      }
    }
  }

  .vui-add-bicycle-model__actions {
    column-gap: 1.5rem;
  }
}

@media only screen and (max-width: 991px) {
  .vui-add-bicycle-model {
    .vui-add-bicycle-model__body {
      .vui-input-container__serial-number {
        .vui-popover {
          .vui-popover__dropdown {
            width: 325px;
            padding-bottom: 2rem;

            .vui-popover__dropdown-container {
              display: block;
            }
          }
        }
      }
    }
  }
}