@import "../../../../velotooler-app";

@mixin contentHeight($paddingTop, $paddingBottom, $minHeight) {
  .landing-page-section-content {
    .container {
      padding-top: $paddingTop;
    }

    .container.container--full-screen {
      min-height: $minHeight;
    }

    .landing-page-section-content__background,
    .landing-page-section-content__content {
      min-height: $minHeight;
    }

    .landing-page-section-content__content {
      padding-bottom: $paddingBottom;
    }
  }
}

.landing-page-section-content {
  .landing-page-section-content__background {
    filter: grayscale(100%);
  }
}

@media only screen and (min-width: 1441px) {
  @include contentHeight(130px, 5rem, 880px);
}

@media only screen and (min-width: 992px) and (max-width: 1440px) {
  @include contentHeight(130px, 5rem, 880px);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  @include contentHeight(100px, 4rem, 768px);
}

@media only screen and (max-width: 767px) {
  @include contentHeight(0, 2rem, 500px);
}
