@import "../../../velotooler-app";

.vui-menu {
  .vui-content {
    padding: 1rem 1.5rem;
    background: $vui-main-menu-color;
    transition: margin-left 250ms ease-in-out;
    width: auto;
    min-height: calc(100vh - $vui-header-height);
    height: calc(100vh - $vui-header-height);
    overflow-y: auto;
  }
}

.vui-menu.vui-side-menu--expanded {
  .vui-content {
    margin-left: 250px;
  }
}

.vui-menu.vui-side-menu--collapsed {
  .vui-content {
    margin-left: 88px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .vui-menu {
    .vui-content {
      padding: 0;
      min-height: calc(100vh - $vui-portal-header-height-sm);
      height: calc(100vh - $vui-portal-header-height-sm);
    }
  }

  .vui-menu.vui-side-menu--expanded,
  .vui-menu.vui-side-menu--collapsed {
    .vui-content {
      margin-left: 0;
    }
  }
}

@media only screen and (max-width: 767px) {
  .vui-menu {
    .vui-content {
      padding: 0;
      min-height: calc(100vh - $vui-portal-header-with-bsk-height-sm);
      height: calc(100vh - $vui-portal-header-with-bsk-height-sm);
    }
  }

  .vui-menu.vui-side-menu--expanded,
  .vui-menu.vui-side-menu--collapsed {
    .vui-content {
      margin-left: 0;
    }
  }
}