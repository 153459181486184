@import "../variables";

.vt-modal {

  form {
    overflow: initial;
  }

  background-color: $color-primary-l1;
  color: white;

  .vt-modal__header {
    background-color: $color-primary-l3;
    color: #aaa;
    padding: 17px 25px;
    font-size: 18px;
  }

  .vt-modal__header-close-btn {
    position: fixed;
    -webkit-overflow-scrolling: touch;
    right: 16px;
    top: 16px;
  }

  .vt-modal__body {
    min-height: 120px;
    padding: 30px;

    @media (max-width: 470px) {
      padding: 30px 22px;
    }
  }

  .vt-modal__body_no-padding {
    padding: 0;
  }

  .vt-modal__footer {
    padding: 10px 30px;
    border-top: 1px solid #333;

    &.vt-modal__footer-mobile {
      @media (max-width: 470px) {
        display: none;
      }

      @media (min-width: 471px) {
        display: block;
      }
    }
  }

  @media (max-width: 599px) {
    min-width: 300px;
  }

  @media (min-width: 600px) {
    min-width: 580px;
    max-width: 754px;
  }
}

.vt-modal-mobile {
  @media (max-width: 470px) {
    margin: 0;
    width: 100%;
    min-width: 100%;
    height: 100%;
  }

  .vt-modal-mobile__header-close-btn {
    @media (max-width: 470px) {
      position: fixed;
      -webkit-overflow-scrolling: touch;
      right: 16px;
      top: 16px;
    }

    @media (min-width: 471px) {
      display: none;
    }
  }

  .vt-modal-mobile__header {
    @media (max-width: 470px) {
      height: 50px;
      line-height: 30px;
      padding: 10px 13px;
    }
  }

  .vt-modal-mobile__footer {
    @media (min-width: 471px) {
      display: none;
    }

    @media (max-width: 470px) {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 0;
      background: $vt-color-primary;
      display: block;
      height: 50px;
      -webkit-overflow-scrolling: touch;

      .md-button {
        color: #222;
        width: 100%;
        margin: 0;
        height: 100%;
      }
    }
  }

  .vt-modal-mobile__body {
    @media (max-width: 347px) {
      padding-left: 5px;
      padding-right: 5px;
    }

    @media (max-width: 470px) {
      position: fixed;
      -webkit-overflow-scrolling: touch;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 4px;
      }
    }
  }
}