@import "../variables";

.md-virtual-repeat-container.md-autocomplete-suggestions-container ul.md-autocomplete-suggestions li {
  &:hover, &.selected {
    background-color: #444 !important;

  }

  md-autocomplete-parent-scope span span.highlight {
    color: $vt-color-primary !important;
    font-weight: bold;
  }

  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    vertical-align: middle;
  }
}

md-autocomplete {

  @media (max-width: 450px) {
    min-width: 300px;
  }

  min-width: 430px;
}

