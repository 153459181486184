@import "../../../velotooler-app";

$countries: ad, ae, af, ag, ai, al, am, ao, aq, ar, as, at, au, aw, ax, az, ba, bb, bd, be, bf, bg, bh, bi, bj, bl, bm, bn, bo, bq, br, bs, bt, bv, bw, by, bz, ca, cc, cd, cf, cg, ch, ci, ck, cl, cm, cn, co, cr, cu, cv, cw, cx, cy, cz, de, dj, dk, dm, do, dz, ec, ee, eg, eh, er, es, et, fi, fj, fk, fm, fo, fr, ga, gb, gd, ge, gf, gg, gh, gi, gl, gm, gn, gp, gq, gr, gs, gt, gu, gw, gy, hk, hm, hn, hr, ht, hu, id, ie, il, im, in, io, iq, ir, is, it, je, jm, jo, jp, ke, kg, kh, ki, km, kn, kp, kr, kw, ky, kz, la, lb, lc, li, lk, lr, ls, lt, lu, lv, ly, ma, mc, md, me, mf, mg, mh, mk, ml, mm, mn, mo, mp, mq, mr, ms, mt, mu, mv, mw, mx, my, mz, na, nc, ne, nf, ng, ni, nl, no, np, nr, nu, nz, om, pa, pe, pf, pg, ph, pk, pl, pm, pn, pr, ps, pt, pw, py, qa, re, ro, rs, ru, rw, sa, sb, sc, sd, se, sg, sh, si, sj, sk, sl, sm, sn, so, sr, ss, st, sv, sx, sy, sz, tc, td, tf, tg, th, tj, tk, tl, tm, tn, to, tr, tt, tv, tw, tz, ua, ug, um, us, uy, uz, va, vc, ve, vg, vi, vn, vu, wf, ws, ye, yt, za, zm, zw, es-ct, es-ga, eu, gb-eng, gb-nir, gb-sct, gb-wls, un, xk;

@mixin flag-icon($country) {
  &.iti__#{$country} {
    background: url("/img/flags/#{$country}.svg");
  }
}

.iti__flag {
  width: 35px;
  height: 26px;
  border-radius: 3px;

  @each $country in $countries {
    @include flag-icon($country);
  }
}

.vui-input-container.vui-input-container__input-group-left {
  &.vui-form-control-lg {
    .vui-phone-number {
      .vui-input {
        padding-left: 156px !important;
      }

      .vui-phone-number__container {
        .iti__flag-container {
          margin-left: 64px;

          .iti__selected-dial-code {
            font-size: 1rem;
          }

          .iti__selected-flag {
            .iti__flag {
              width: 35px;
              height: 26px;
              border-radius: 3px;
            }
          }
        }
      }
    }
  }

  .vui-phone-number {
    .vui-input {
      padding-left: 118px !important;
    }

    .vui-phone-number__container {
      .iti__flag-container {
        margin-left: 44px;

        .iti__selected-dial-code {
          font-size: 14px;
        }

        .iti__selected-flag {
          padding: 0px 6px 0 6px;
        }
      }
    }
  }
}

.vui-input-container {
  .vui-phone-number {
    .vui-input {
      padding-left: 96px !important;
    }

    .vui-phone-number__container {
      .iti__flag-container {
        margin-left: 0;

        .iti__selected-flag {
          padding: 0 6px 0 16px;

          .iti__flag {
            width: 24px;
            height: 18px;
          }
        }
      }
    }
  }
}

.vui-form-control-lg {
  .vui-phone-number {
    .iti--separate-dial-code {
      .iti__selected-dial-code {
        margin-top: 0;
      }
    }

    .vui-phone-number__container {
      .iti__flag-container {
        margin-left: 62px;
      }
    }
  }
}

.vui-phone-number {
  .iti--separate-dial-code {
    .iti__selected-dial-code {
      font-size: 1rem;
      font-weight: 600;
      font-family: Montserrat, serif;
    }

    .iti__selected-flag {
      background-color: transparent;
    }
  }

  .vui-phone-number__container {
    width: 100%;

    .vui-phone-number__error {
      border: 1px solid $vui-input-error-color;
      color: $vui-input-error-color;
    }

    .iti__flag-container {
      margin-left: 48px;
    }

    .iti__flag-container {
      border-right-width: 0;
    }

    .iti__arrow {
      display: none;
    }

    .iti__flag {
      width: 35px;
      height: 26px;
      border-radius: 3px;

      @each $country in $countries {
        @include flag-icon($country);
      }
    }

    .iti__country {
      .iti__flag {
        width: 20px;
        height: 15px;
        border-radius: 2px;
      }
    }

    .iti__country-list {
      background: $color-white;
      border-radius: 4px;
      border-width: 0;
      box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
      margin-top: 20px;
      margin-bottom: 20px;
      max-height: calc(70px * 5);
      overflow-y: auto;
      z-index: 9999;
      scrollbar-color: rgba(255, 255, 255, 0.8) rgba(0, 0, 0, 0) !important;
      scrollbar-width: thin !important;

      .iti__divider {
        border-color: $vui-text-placeholder-color;
      }

      .iti__country {
        border-bottom-width: 0;
        font-size: 1rem;
        line-height: 2.65rem;
        padding: 0 20px;
        font-weight: 600;
        cursor: pointer;
        color: $vui-dark-input-placeholder-color;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
        transition: all 200ms ease-in-out;

        .iti__flag-box,
        .iti__country-name {
          margin-right: 16px;
        }
      }

      .iti__country:hover,
      .iti__country:focus,
      .iti__country.active {
        color: $vui-dark-text-color;
        background: #F6F6F6;
      }
    }

    .iti__country-list::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
      border-radius: 3px;
      background-color: transparent;
    }

    .iti__country-list::-webkit-scrollbar {
      width: 6px;
      background-color: transparent;
    }

    .iti__country-list::-webkit-scrollbar-thumb {
      border-radius: 3px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .2);
      background-color: rgba(0, 0, 0, .2);
    }
  }
}

@media only screen and (max-width: 767px) {
  .vui-input-container.vui-input-container__input-group-left.vui-form-control-lg {
    .vui-phone-number {
      .vui-input {
        padding-left: 118px !important;
      }

      .vui-phone-number__container {
        .iti__flag-container {
          margin-left: 44px;

          .iti__selected-dial-code {
            font-size: 14px;
          }

          .iti__selected-flag {
            padding: 0px 6px 0 6px;

            .iti__flag {
              width: 24px;
              height: 18px;
            }
          }
        }
      }
    }
  }
}
