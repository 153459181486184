@import "../../../velotooler-app";

.vui-radio-button {
  display: flex;
  flex-direction: initial;
  align-items: center;
  margin: 0.75rem 0;

  .vui-radio-button__label {
    cursor: pointer;
    display: inline-block;
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 400;
    color: $vui-dark-text-color;
    margin-left: 1.25rem;
  }

  .vui-radio-button__input {
    display: none;
  }

  .vui-radio-button__icon {
    cursor: pointer;
    height: 20px;
    width: 20px;
    display: block;

    &::after {
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3E%3Cpath d='M10.0003 18.3327C14.6027 18.3327 18.3337 14.6017 18.3337 9.99935C18.3337 5.39698 14.6027 1.66602 10.0003 1.66602C5.39795 1.66602 1.66699 5.39698 1.66699 9.99935C1.66699 14.6017 5.39795 18.3327 10.0003 18.3327Z' stroke='black' stroke-width='1.66667' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      opacity: 1;
      margin-left: -20px;
      transition: opacity 0.3s ease-in-out;
    }

    &::before {
      transition: opacity 0.3s ease-in-out;
      opacity: 0;
      content: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.0003 18.3327C14.6027 18.3327 18.3337 14.6017 18.3337 9.99935C18.3337 5.39698 14.6027 1.66602 10.0003 1.66602C5.39795 1.66602 1.66699 5.39698 1.66699 9.99935C1.66699 14.6017 5.39795 18.3327 10.0003 18.3327Z' stroke='black' stroke-width='1.66667' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M10.0003 12.4993C11.381 12.4993 12.5003 11.3801 12.5003 9.99935C12.5003 8.61864 11.381 7.49935 10.0003 7.49935C8.61961 7.49935 7.50033 8.61864 7.50033 9.99935C7.50033 11.3801 8.61961 12.4993 10.0003 12.4993Z' stroke='black' stroke-width='1.66667' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    }
  }

  &.vui-radio-button--checked, &:checked {
    .vui-radio-button__icon {
      &::after {
        opacity: 0;
      }

      &::before {
        opacity: 1;
      }
    }
  }
}
