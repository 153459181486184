@import "../../../velotooler-app";

.vui-page-size {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .vui-page-size__label {
    background: $color-white;
    font-size: 0.75rem;
    line-height: 0.75rem;
    padding: 0.5rem 0.875rem;
    font-weight: 500;
    height: 2rem;
    min-width: 2.25rem;
    display: flex;
    align-items: center;
    color: $vui-input-label-color;
    border: 1px solid $vui-main-menu-color;
    border-radius: 0.25rem 0 0 0.25rem;
  }

  .vui-page-size__select-page {
    .vui-input-container {
      .vui-input {
        border-radius: 0 0.25rem 0.25rem 0;
        width: 68px;
      }
    }
  }
}