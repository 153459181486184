.collapsible-list {

  .collapsible-list__show-more {
    max-height: 0;
    transition: max-height .5s;
    overflow: hidden;

    &.collapsible-list__show-more_visible {
      max-height: 4em;
    }
  }
}