@import "../../../velotooler-app";

.reassign-request {
  display: flex;

  .reassign-request_checkout-stage_background {
    background-color: rgba(255, 255, 0, 0.1);
  }

  &.reassign-request_checkout-stage {

    height: max-content;
    min-height: 300px;

    .reassign-request__main-section {
      max-width: 0;
      opacity: 0;
      -webkit-transition: max-width 250ms ease-in-out;
      transition: max-width 250ms ease-in-out;
      height: 0;
    }

    .reassign-request__checkout-btn {
      visibility: visible;
    }

    .reassign-request__summary-back-button {
      visibility: visible;
    }

    .reassign-request__summary-footer {
      border-top: 1px solid $color-primary-l2;
    }

    .reassign-request__summary-section {
      max-width: 700px;
      display: block;
      max-height: none;
    }
  }

  .reassign-request__input-with-icon {
    position: relative;
    padding-left: 30px;
  }

  .reassign-request__input-icon {
    position: absolute;
    left: 8px;
    top: 25%;
    transform: translateY(-20%);
    font-size: 26px;
    color: #999;
  }

  .reassign-request__summary-footer {
    text-align: center;
    padding: 10px 0;
  }

  .reassign-request__checkout-btn {
    width: 150px !important;
    visibility: hidden;

    &.reassign-request__checkout-btn_active {
      visibility: visible;
    }
  }

  .reassign-request__task-context-item-container {
    margin: 10px;
  }

  .reassign-request__task-context-close-icon {
    background-color: rgba(30, 30, 30, 0.3);
    border-radius: 50%;
    font-size: 11px;
    padding: 3px 4px;
    color: #222;
    margin-left: 5px;
  }

  .reassign-request__task-item {
    margin-bottom: 5px;
    margin-top: 5px;

  }

  .reassign-request__task-context-item {
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 3px;
    padding: 10px 15px;
    font-weight: bold;
    font-size: 15px;
    background-color: rgba(81, 197, 126, 0.4);
    cursor: pointer;

    &:hover {
      background-color: rgba(81, 197, 126, 0.5);
    }
  }

  .request-card {
    background-color: $color-primary;
  }

  .select-available-time-card-content {
    background: none !important;
  }

  .reassign-request__body {
    position: relative;
    margin: 0;
  }

  .reassign-request__main-section {
    flex-basis: 800px;
    max-width: 800px;
    visibility: visible;
    -webkit-transition: opacity 0s linear 250ms, max-width 250ms ease-in-out;
    transition: opacity 0s linear 250ms, max-width 250ms ease-in-out;
  }

  .reassign-request__summary-section {
    padding-top: 20px;
    max-width: 400px;
    min-width: 400px;
    margin: 0 auto;
    width: 100%;
    -webkit-transition: max-width 250ms ease-in-out;
    transition: max-width 250ms ease-in-out;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);

    @media (max-width: 1200px) {
      max-width: 300px;
      min-width: 300px;
    }
  }

  .reassign-request__mobile-navigation {
    border: none;
    display: none;
    width: 100%;
    height: 55px;
    position: fixed;
    left: 0;
    bottom: 0;
    padding: 0;
    margin: 0;
    z-index: 78;
    -webkit-overflow-scrolling: touch;

    .md-button {
      line-height: 43px !important;
    }
  }

  .reassign-request__summary {
    width: 100%;
    background-color: $color-primary;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    border-radius: 4px;
    position: relative;
    overflow: hidden;
  }

  .reassign-request__summary-header {
    background-color: rgba(255, 255, 0, 0.1);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    position: relative;
    max-height: 400px;
    -webkit-transition: max-height 250ms ease-in-out;
    transition: max-height 250ms ease-in-out;
  }

  .reassign-request__summary-body {
    padding: 20px;
    font-size: 15px;
  }

  .reassign-request__summary-h2 {
    font-size: 17px;
    font-weight: bold;
    color: #999;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .reassign-request__summary-mechanic-avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }

  .reassign-request__summary-edit-button {
    min-width: 28px !important;
    margin: 0 !important;
    color: #999;
    background: none;
    border: none;

    i {
      font-size: 23px;
    }
  }

  .reassign-request__summary-mechanic {
    border-bottom: 1px solid $color-primary-l3;
    padding-bottom: 10px;
  }

  .reassign-request__section {
    margin-bottom: 10px;
  }

  .reassign-request__section-body-icon {
    margin: auto;
    padding: 0 !important;
  }

  .reassign-request__section-body {
    padding-bottom: 10px;
    padding-top: 10px;
    font-size: 16px;
    font-weight: bold;
    line-height: 28px;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover {
      background-color: $color-primary-l2;
    }
  }

  .reassign-request__summary-icon {
    color: #777;
    margin-right: 5px;
    font-size: 17px;
  }

  .reassign-request__head-text {
    text-align: left;
  }

  .reassign-request__estimated-time {
    color: #ccc;
    margin-top: 5px;
    font-size: 15px;
  }

  .reassign-request__summary-back-button {
    visibility: hidden;
  }

  &.vt-form {
    background: none;
    box-shadow: none;
    max-width: 1200px !important;
  }

  .show-animation.ng-hide-add {
    -webkit-animation: 0.3s hide;
    animation: 0.3s hide;
  }

  @keyframes hide {
    0% {
      opacity: 1;
      transform: scale(1);
    }
    30% {
      transform: scale(1.02);
    }
    100% {
      opacity: 0;
      transform: scale(0.5);
    }
  }

  @-webkit-keyframes hide {
    0% {
      opacity: 1;
      transform: scale(1);
    }
    30% {
      transform: scale(1.02);
    }
    100% {
      opacity: 0;
      transform: scale(0.5);
    }
  }

  .show-animation.ng-hide-remove {
    -webkit-animation: 0.3s show;
    animation: 0.3s show;
  }

  @keyframes show {
    0% {
      opacity: 0;
      transform: scale(0.5);
    }
    70% {
      transform: scale(1.02);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  @-webkit-keyframes show {
    0% {
      opacity: 0;
      transform: scale(0.5);
    }
    70% {
      transform: scale(1.02);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  @media (max-width: 1000px) {

    .reassign-request__mobile-floating-btn {
      width: 100%;
      margin: 0 !important;
      height: 100%;
      border-radius: 0;
    }

    .reassign-request__summary {
      background-color: $color-primary-d1;
      border-radius: 0;
      box-shadow: none;
    }

    .reassign-request__summary-section {
      max-width: 0;
      min-width: 0;
      max-height: 0;
    }

    .reassign-request__summary-footer {
      height: 50px;
    }

    .reassign-request__checkout-btn {
      display: none;
    }

    .reassign-request__mobile-navigation {
      display: block;
    }
  }
}