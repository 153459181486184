@import "../../../sass/variables";

.custom-service-node {

  .custom-service-node__category {
    display: inline-block;
    vertical-align: middle;
    font-size: 18px;
    padding: 10px 25px;
    cursor: pointer;
    width: 100%;
    background-color: $color-brown;
  }

  .custom-service-node__service-container {
    margin: 30px 0;
  }

  .custom-service-node__service-separator {
    height: 1px;
    background-color: #333;
  }

  .custom-service-node__service {
    padding: 10px 25px;
    margin: 0;
  }

  .custom-service-node__highlightable-item {
    cursor: pointer;

    &:hover {
      background-color: $color-primary-l5-o3;
    }
  }

  .custom-service-node__no-services-label {
    text-align: center;
    color: #aaa;
  }

  &.custom-service-node_main {
    .custom-service-node__category {
      display: none;
    }

    .custom-service-node__service {
      color: #ffec92;
      font-weight: bold;
    }
  }

  .custom-service-node__actions {
    text-align: center;

  }
}