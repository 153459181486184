@import "../../../velotooler-app";

.content-view {
  min-height: calc(100vh - 122px);
  padding: 0;
  display: flex;
  flex-direction: column;
  row-gap: 2rem;

  &.content-view__after-tabs {
    min-height: calc(100vh - 182px);

    .content-view__content {
      min-height: calc(100vh - 246px);
    }
  }

  .content-view__header {

  }

  .content-view__content {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    justify-content: space-between;
    min-height: calc(100vh - 188px);
  }

  .content-view__no-content {
    font-size: 1.625rem;
    font-weight: 700;
    line-height: 2.75rem;
    text-align: left;
    color: $vui-label-color;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .content-view {
    min-height: calc(100vh - $vui-portal-header-height-sm);
    padding: 1.5rem 2rem;

    &.content-view__after-tabs {
      padding-top: 2rem;
      min-height: calc(100vh - 160px);

      .content-view__content {
        min-height: calc(100vh - 218px);
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .content-view {
    min-height: calc(100vh - $vui-portal-header-with-bsk-height-sm);
    padding: 1.5rem 1.25rem;

    &.content-view__after-tabs {
      padding-top: 2rem;
      min-height: calc(100vh - 198px);

      .content-view__content {
        min-height: calc(100vh - 254px);
      }
    }

    .content-view__no-content {
      font-size: 1.25rem;
      font-weight: 600;
      line-height: 1.5rem;
    }
  }
}