@import "../../../velotooler-app";

.vui-unsatisfied-with-service-model {
  .vui-modal {
    max-width: 440px;
    width: 100%;
  }

  .vui-unsatisfied-with-service-model__body {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    max-width: 440px;

    .contact-us__link {
      text-decoration: underline;
      cursor: pointer;
      @include transition-short(color);

      &:hover {
        color: $vui-dark-text-color--hover;
      }
    }
  }

  .vui-unsatisfied-with-service-model__actions {
    column-gap: 1.5rem;

    .vui-btn {
      width: 50%;
    }
  }
}