.corporate-service-list {

  .corporate-service-list__group-label-separator {
    border-top: 1px dashed #555;
    margin-bottom: 5px;
  }

  .corporate-service-list__group-label {
    color: #ccc;
    margin: 40px 0 15px 0;
  }

  .corporate-service-list__group-label-body {
    padding-left: 25px;
  }

  .corporate-service-list__actions {
    padding: 0 15px;
    text-align: center;
  }

  .corporate-service-list__switch {
    font-weight: bold;
    margin: 25px 10px;

    @media (max-width: 425px) {
      position: inherit;
      padding: 0 10px;
      border-bottom: 1px solid #2d2d2d;
    }
  }

  .corporate-service-list__switch_enabled {
    color: white;
  }

  .corporate-service-list__switch_disabled {
    color: #777777;
  }

  .corporate-service-list__node {
    margin: 30px 0;
  }

  &:first-child .corporate-service-list__node {
    margin-top: 0;
  }

}
