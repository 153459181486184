@import "../sass/variables";

.chat {
  width: 100%;
  color: #434651;

  .inet-connection-status {
    padding: 6px 0 0 0;
    color: #999;
    font-size: 14px;
    text-align: center;
  }

  .inet-status-connection-circular {
    display: inline-block;
    vertical-align: middle;
  }

  .chat__header-about {
    float: left;
    padding-left: 10px;
    line-height: 25px;
  }

  .chat__header-with {
    font-weight: bold;
    font-size: 16px;
    color: #F5F5F5;
  }

  .chat__header-about-connection-status {
    padding: 0 10px;
    color: #999;
  }

  .chat__header {
    background-color: $form-background;

    img {
      float: left;
      max-width: 50px;
      border-radius: 50%;
      width: 50px;
      height: 50px;
    }

    @media (max-width: 470px) {
      font-size: 12px;
      padding: 0 3px;
      flex: 8 0 0;
      margin: auto;

      img {
        max-width: 40px;
        width: 40px;
        height: 40px;
      }
    }
  }

  .chat__history-image {
    display: inline-block;
    padding: 3px;

    img {
      cursor: pointer;
      width: 120px;
      height: 120px;
    }
  }

  .chat__history-message {
    display: inline-block;
    color: white;
    padding: 8px 10px 1px 10px;
    line-height: 16px;
    font-size: 14px;
    border-radius: 7px;
    margin-bottom: 5px;
    max-width: 400px;

    &:after {
      bottom: 100%;
      left: 7%;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      margin-left: -10px;
    }

    @media (max-width: 470px) {
      max-width: 182px;
    }

    @media (min-width: 471px) and (max-width: 672px) {
      max-width: 275px;
    }
  }

  .chat__history-message-datetime {
    color: #777777;
    text-align: right;
    padding: 5px;
    font-size: 0.8em;
  }

  .chat__history-message-user {
    font-weight: bold;
  }

  .chat__history-message-user-name {
    max-height: 34px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    display: initial;
  }

  .chat__history-message-user-type {
    color: #777;
    border-radius: 3px;
    padding: 1px 5px;
    font-size: 10px;
    vertical-align: middle;
    display: inline-block;
  }

  .chat__history-message-my {
    background: #effdde;
    color: black;
    margin-left: auto;
  }

  .chat__history-message-other {
    background: white;
    margin-right: auto;
    color: black;
  }

  .user-name {
    color: seagreen;
  }

  .user-name_admin {
    color: rgba(0, 0, 0, 0.77);
  }

  .user-name_bike_organization_owner {
    color: cadetblue;
  }
  .user-name_bike_business_supervisor {
    color: #a695e7;
  }

  .user-name_mechanic {
    color: lightcoral;
  }
  .user-name_team_manager {
    color: cadetblue;
  }

  .chat__history {
    background-image: url("/img/background-for-chat.jpg");
    background-size: cover;
    padding: 30px 30px 20px;
    overflow-y: scroll;
    height: 450px;

    ul {
      padding-left: 0;
    }

    li {
      list-style-type: none;
      word-wrap: break-word;
    }

    @media (max-width: 470px) {
      padding: 10px 30px 10px;
      overflow-y: scroll;
      max-height: 100%;
      position: fixed;
      top: 58px;
      height: 553px;
      right: 0;
      left: 0;
      bottom: 117px;
    }
  }

  .chat__back-btn {
    flex: 1 0 0;
    margin: auto;
    text-align: left;
  }

  .chat__input-panel-text {

    &::-webkit-input-placeholder {
      color: #212121;
      padding-left: .7em;
    }
    &:-ms-input-placeholder {
      color: #212121;
      padding-left: .7em;
    }
    &:-moz-placeholder {
      color: #212121;
      opacity: 1;
      padding-left: .7em;
    }
    &::-moz-placeholder {
      color: #212121;
      opacity: 1;
      padding-left: .7em;
    }

    &:focus {
      &::-webkit-input-placeholder {
        color: transparent;
      }
      &::-moz-placeholder {
        color: transparent;
      }
      &:-moz-placeholder {
        color: transparent;
      }
      &:-ms-input-placeholder {
        color: transparent;
      }
    }
  }

  .chat__input-panel-text::-webkit-scrollbar {
    width: 0;
  }

  .chat__message-box {
    background: $form-background;
    position: relative;

    & .message-input {
      box-sizing: border-box;
      font-size: 15px;
      line-height: 24px;
      overflow-X: hidden;
      word-wrap: break-word;
      border: none;
      outline: none !important;
      min-height: 34px;
      padding-top: 5px;
      resize: none;
      max-height: 100px;
      width: 100%;
      color: $input-default-color;
      background: rgba(0, 0, 0, 0);
    }

    & .message-submit {
      position: absolute;
      top: 0;
      right: 2.5em;
      height: 100%;
      color: #fff;
      background: transparent;
      font-size: 14px;
      text-transform: uppercase;
      outline: none !important;
      transition: background .2s ease;
      padding: 0 5px;
      margin: 0px;
      border-radius: 0;

      &:hover {
        background: #444;
      }
    }

    & .smile-btn {
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 2.5em;
      min-width: 0 !important;
      text-transform: uppercase;
      outline: none !important;
      background-image: $smile-img;
      background-position: center;
      background-size: 1.5em;
      background-repeat: no-repeat;
      transition: background .2s ease;
      padding: 0 5px;
      margin: 0;
      border-radius: 0;

      &:hover {
        background: #444;
        background-image: $smile-img;
        background-position: center;
        background-size: 1.5em;
        background-repeat: no-repeat;
      }
    }

    @media (max-width: 470px) {
      position: fixed;
      right: 0;
      left: 0;
      bottom: 0;

      & .message-input {
        min-height: 42px;
        line-height: 17px;
        padding: 13px 0 0;
      }

    }
  }

  .message-input.chat__message-box-input {
    -ms-overflow-y: hidden !important;
  }

  .chat__input-container {
    width: 100%;
    display: block;
  }

  .md-icon-button {
    color: #ccc;
    margin: 0;
    padding: 0;
    width: 30px;
    height: 32px;
    min-height: 24px;
    font-size: 20px;
  }

  .md-icon-button[disabled] {
    color: #ccc !important;
  }

  .position_relative {
    position: relative;
  }

  .chat__message-box-send-message-btn {
    position: absolute;
    bottom: 6px;
    right: 7px;

    &:not([disabled]).md-focused {
      background-color: transparent;
    }

    &:hover {
      color: #999;
    }

    @media (max-width: 470px) {
      bottom: 11px;
    }
  }

  .chat__message-box-smile-btn {
    position: absolute;
    bottom: 6px;
    right: 42px;
    font-size: 25px;

    &:not([disabled]).md-focused {
      background-color: transparent;
    }

    &:hover {
      color: #999;
    }

    @media (max-width: 470px) {
      bottom: 11px;
    }
  }

  .chat__message-box-attach-file-btn {
    position: absolute;
    bottom: 6px;
    left: 10px;

    &:not([disabled]).md-focused {
      background-color: transparent;
    }

    &:hover {
      color: #999;
    }

    @media (max-width: 470px) {
      bottom: 11px;
    }
  }

  .chat__input-panel {
    width: 100%;
    padding: 4px 83px 4px 48px;
    background-color: $color-primary-l3;
  }

  .padding-2-0 {
    padding: 2px 10px;
  }
}