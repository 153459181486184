@import "../../../velotooler-app";

.vui-main-menu-item {
  svg path {
    @include transition-short(stroke);
  }

  &[disabled] {
    pointer-events: none;
    cursor: default;
    svg path {
      stroke: $vui-label-color;
    }
  }

  &:hover:not([disabled]) {
    svg path {
      stroke: $vui-label-color;
    }
  }
}