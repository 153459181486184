@import "../../../velotooler-app";

  .vui-color-picker {
    .vui-color-picker__input {
      display: flex;
      flex-direction: row;
      column-gap: 1rem;

      .color {
        max-width: 1.25rem;
        width: 1.25rem;
        height: 1.25rem;
        border-radius: 0.625rem;
        border: 1px solid #EDEDED;
      }
    }

    &.vui-input-container--opened {
      .vui-color-picker__dropdown--background,
      .vui-color-picker__dropdown {
        display: flex !important;
        flex-direction: column;
      }
    }

    .color {
      height: 2.5rem;
      width: 2.5rem;
      flex: 1 0 25%;
      max-width: 2.5rem;
      border-radius: 0.25rem;
      border: 1px solid #EDEDED;
      cursor: pointer;
      outline: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        stroke: #FFE600;
        display: none;
      }
    }

    .color.dark-red {
      background-color: #9C0000;
    }

    .color.red {
      background-color: #DF1D1D;
    }

    .color.white {
      background-color: #ffffff;

      svg {
        stroke: #000;
      }
    }

    .color.green {
      background-color: #019902;
    }

    .color.light-green {
      background-color: #2DF32E;

      svg {
        stroke: #000;
      }
    }

    .color.dark-blue {
      background-color: #000094;
    }

    .color.blue {
      background-color: #1819E4;
    }

    .color.light-blue {
      background-color: #44EBFF;

      svg {
        stroke: #000;
      }
    }

    .color.black {
      background-color: $vui-dark-text-color;
    }

    .color.grey {
      background-color: #B8B8B8;
    }

    .color.pink {
      background-color: #F92FF8;
    }

    .color.purple {
      background-color: #900C8E;
    }

    .color.brown {
      background-color: #944900;
    }

    .color.yellow {
      background-color: $vui-color-primary;

      svg {
        stroke: #000;
      }
    }

    .color.orange {
      background-color: #FF7439;
    }

    .color.carbon {
      background: linear-gradient(27deg, #151515 5px, transparent 5px) 0 5px,
      linear-gradient(207deg, #151515 5px, transparent 5px) 10px 0px,
      linear-gradient(27deg, #222 5px, transparent 5px) 0px 10px,
      linear-gradient(207deg, #222 5px, transparent 5px) 10px 5px,
      linear-gradient(90deg, #1b1b1b 10px, transparent 10px),
      linear-gradient(#1d1d1d 25%, #1a1a1a 25%, #1a1a1a 50%, transparent 50%, transparent 75%, #242424 75%, #242424);
      background-color: #131313;
      background-size: 20px 20px;
      color: #fff;
    }
  }

.vui-input-container {
  .vui-color-picker__dropdown--background {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    display: none;
  }
}

.disabled {
  .vui-color-picker {
    .vui-color-picker__input {
      .color {
        cursor: default;
      }
    }
  }
}

.vui-color-picker__dropdown {
  position: absolute;
  display: none;
  background: $color-white;
  width: 312px;
  height: 320px;
  padding: 1.25rem;
  border-radius: 0.25rem;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  z-index: 9999;
  flex-direction: row;
  column-gap: 0.5rem;
  justify-content: space-around;

  .vui-color-picker__dropdown-container {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    align-items: center;

    .vui-color-picker__dropdown-title {
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 17px;
      text-align: center;
      max-width: 210px;
    }

    .colors-container {
      display: flex;
      flex-flow: row wrap;
      justify-content: start;
      gap: 0.25rem;
      max-width: 172px;

      .color.active,
      .color:hover {
        max-width: 2.5rem;
        max-height: 2.5rem;
        border: 1px solid $vui-color-primary;

        svg {
          display: block;
        }
      }
    }
  }
}
