@import "../../variables";

.vt-total-count {

  color: white;

  .vt-total-count__internal {
    margin: 5px;
    background-color: #111;
  }

  .vt-total-count__header {
    padding: 15px;
    color: #777;
    text-align: center;
    font-size: 15px;

    @media (min-width: 1600px) {
      font-size: 20px;
    }
  }

  .vt-total-count__body {
    font-size: 50px;
    text-align: center;


    @media (min-width: 1600px) {
      font-size: 70px;
    }
  }

  .vt-total-count_highlight {
    -webkit-animation: bck_change 1s ease-in-out;
    animation: bck_change 1s ease-in-out;
  }

  .vt-total-count__footer {
    font-size: 15px;
    bottom: 20px;
    margin: auto;
    text-align: center;
    min-height: 40px;


    @media (min-width: 1600px) {
      font-size: 20px;
    }
  }

  .vt-total-count__plus {
    color: #51c57e;
    margin-right: 10px;
  }

  .vt-total-count__minus {
    color: #EF5350;
    margin-left: 10px;
  }

  .vt-total-count__chart {
    padding-right: 5px;
    padding-left: 5px;
  }

  @keyframes bck_change {
    0% {background-color: #111;}
    50% {background-color: $vt-color-primary;}
    1000% {background-color: #111;}
  }

}