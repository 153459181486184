@import "../../../velotooler-app";

vui-g-map {
  display: block;
  width: 100%;
  height: 100%;
}

.vui-g-map {
  width: 100%;
  height: 100%;

  .gm-style {
    .gm-ui-hover-effect,
    .gm-style-iw-tc {
      display: none !important;
    }

    .gm-style-iw-ch {
      padding: 0;
    }

    .gm-style-iw-c {
      border-radius: 1rem;
      padding: 0 !important;
      min-width: 260px !important;

      .gm-style-iw-d {
        display: contents;
      }
    }

    .vui-g-map__location-info {
      display: flex;
      flex-direction: row;
      column-gap: 1rem;
      max-width: 220px;
      padding: 1rem;

      .location-info__content {
        font-size: 0.875rem;
        font-weight: 700;
        line-height: 17px;
      }
    }

    .vui-g-map__service-provider-info {
      display: flex;
      flex-direction: row;
      border-radius: 1rem;
      max-width: 420px;

      .service-provider__avatar {
        max-height: 100px;

        img {
          width: 100px;
          height: 100px;
        }
      }

      .service-provider__content {
        display: flex;
        flex-direction: column;
        row-gap: 0.5rem;
        padding: 0.5rem 0.5rem 0.5rem 1rem;
        min-width: 320px;

        .title {
          display: flex;
          flex-direction: row;
          column-gap: 0.5rem;
          justify-content: space-between;
          font-size: 0.875rem;
          font-weight: 600;

          .title__name {
            width: fit-content;
          }

          .title__rating {
            display: flex;
            flex-direction: row;
            column-gap: 0.25rem;
            color: $vui-label-color;
          }
        }

        .category {
          font-size: 0.625rem;
          font-weight: 600;
          color: #B3B3B3;
          text-transform: uppercase;
        }

        .vui-btn.btn-sm-primary {
          justify-content: center;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .vui-g-map {
    .gm-style {
      .vui-g-map__service-provider-info {
        .service-provider__content {
          min-width: 150px;
          max-width: 340px;
          width: 100%;
        }
      }
    }
  }
}