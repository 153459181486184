@import "../../../../velotooler-app";

.landing-page-arrowed-list-item {
  margin-top: 30px;

  .landing-page-arrowed-list-item__arrow {
    width: 0;
    margin-left: -40px;
    @include transition-all-middle()
  }

  .landing-page-arrowed-list-item__content {
    font-size: 1.25rem;
    max-width: 420px;
    margin-left: 40px;
  }
}

.landing-page-arrowed-list-item:hover {
  .landing-page-arrowed-list-item__arrow {
    width: 51px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1440px) {
  .landing-page-arrowed-list-item {
    .landing-page-arrowed-list-item__content {
      font-size: 1rem;
      max-width: 360px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .landing-page-arrowed-list-item {
    .landing-page-arrowed-list-item__content {
      font-size: 1rem;
      max-width: 244px;
      margin-left: 24px;
    }

    .landing-page-arrowed-list-item__arrow {
      margin-left: -32px;
    }
  }
}
