@import "../../../velotooler-app";

.vui-more-btn {
  .vui-more-btn__content {
    max-height: 1.5rem;
  }

  .vui-edit-btn path {
    stroke: $vui-dark-text-color;
  }

  .vui-more-btn__dropdown-icon {
    position: absolute;
    right: 0;
    top: 0;
    margin: 17px 0.75rem;
    transition: all 250ms ease-in-out;
  }

  &.vui-input-container--opened {
    .vui-more-btn__dropdown-icon {
      transform: rotate(180deg);
    }

    .vui-more-btn__dropdown--background,
    .vui-select__dropdown {
      display: block !important;
    }

    .vui-select__dropdown {
      position: fixed;
    }
  }
}

.vui-more-btn__dropdown--background {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  display: none;
}
