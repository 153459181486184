@import "../../../velotooler-app";

.vui-search-dealer-model {
  .vui-modal-container {
    .vui-modal {
      max-width: 448px;
      width: 100%;
    }
  }

  .vui-search-dealer-model__body {
    max-width: 320px;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;

    .vui-input-container {
      margin: 0;
    }
  }

  .service-provider-autocomplete-item {
    display: flex;
    flex-direction: row;
    column-gap: 0.5rem;
    align-items: center;
    justify-content: flex-start;
    margin: 0.5rem 0;
    overflow: hidden;

    .item__avatar {
      display: flex;

      img {
        @include size-rect(1.75rem);
        min-width: 1.75rem;
        border-radius: 0.875rem;
      }
    }

    .item__info {
      display: flex;
      flex-direction: column;
      row-gap: 0.25rem;
      justify-content: center;
      font-size: 0.75rem;
      line-height: 0.75rem;
      overflow: hidden;

      div {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  .vui-search-dealer-model__actions {
    column-gap: 1.5rem;
  }
}