.landing-page-vt-logo {
  display: block;

  img {
    width: 100%;
    margin-bottom: -5px;
  }
}

@media only screen and (max-width: 767px) {
  .landing-page-vt-logo {
    display: none;
  }
}
