.vt-message-dialog {
  .vt-message-dialog__body {
    font-size: 16px;
  }

  .vt-message-dialog__title {
    font-size: 23px;
    text-align: center;
  }

  .vt-message-dialog__footer {
    font-size: 16px;
  }
}