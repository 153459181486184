@import "../../../velotooler-app";

.vui-import-bicycles-model {
  .vui-import-bicycles-model__body {
    width: 400px;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    align-items: center;

    .body__selected-file {
      font-size: 0.875rem;
      font-weight: 600;
    }
  }

  .vui-import-bicycles-model__actions {
    column-gap: 1.5rem;
  }
}

@media only screen and (max-width: 767px) {
  .vui-import-bicycles-model {
    .vui-modal-container {
      .vui-modal {
        padding: 1rem;
        row-gap: 1rem;

        .vui-import-bicycles-model__body {
          row-gap: 1rem;
          width: 100%;
        }
      }
    }
  }
}