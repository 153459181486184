@import "../../../velotooler-app.scss";

.image-viewer {
  .image-viewer__actions {
    min-height: 18px;
    font-size: 13px;
  }

  .image-viewer__button-actions {
    -o-transition: opacity 200ms linear;
    transition: opacity 200ms linear;
  }

  .image-viewer__actions-divider {
    margin-left: 15px;
    pointer-events: none;

    &:before {
      content: "\00b7";
      color: #939393;
      padding: 0 1px;
      text-decoration: none;
      vertical-align: middle;
      display: inline-block;
      pointer-events: none;
      position: relative;
      left: -8px;
    }
  }

  .image-viewer__dialog {
    margin: auto;
    top: 50%;

    &.md-transition-in {
      transform: translate(0, -50%) scale(1);
    }
  }

  .image-viewer__dialog-form {
    max-height: calc(100vh - 150px);
    max-width: calc(100vw - 20px);
  }

  .image-viewer__dialog-form-content {
    position: relative;
    margin-left: 5px;
    margin-right: 5px;

    .loading-gif {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .img-tmb {
      max-height: calc(100vh - 150px);
    }
  }

  .image-viewer__dialog-prev-btn, .image-viewer__dialog-next-btn {
    position: absolute;
    top: 0;
    width: 20%;
    height: calc(100% - 3px);
    outline: none;
    border: none;
    opacity: 0;
    cursor: pointer;

    &:hover {
      background-color: rgba(0, 0, 0, 0.4);
      opacity: 1;
    }
  }

  .image-viewer__dialog-prev-btn {
    left: 0;
    background: url("/img/gallery-arrow-left.png") no-repeat center center;
  }

  .image-viewer__dialog-next-btn {
    right: 0;
    background: url("/img/gallery-arrow-right.png") no-repeat center center;
  }
}