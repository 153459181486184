@import "../../../velotooler-app";

.vui-input-container {
  .vui-location-filter {
    .vui-location-filter__dropdown-icon {
      position: absolute;
      right: 0;
      top: 0;
      margin: 17px 0.75rem;
      transition: all 250ms ease-in-out;
    }

    .vui-input {
      padding-right: 2.5rem;
      cursor: pointer;

      &::placeholder {
        color: $vui-dark-input-placeholder-color !important;
        opacity: 1;
      }
    }

    .vui-select__dropdown {
      padding: 0.5rem;

      .vui-autocomplete {
        .vui-select__dropdown {
          display: none !important;
        }
      }

      .vui-autocomplete.vui-autocomplete--open {
        .vui-autocomplete__dropdown--background {
          display: none !important;
        }

        .vui-select__dropdown {
          position: relative;
          padding: 0;
          margin: 0;
          width: 100% !important;
          box-shadow: 0 0 0 rgba(0, 0, 0, 0);
          display: block !important;
        }
      }

      svg {
        position: absolute;
        left: 0.625rem;
        top: 1rem;
        width: 1rem;
        height: 1rem;
      }

      .vui-input {
        padding: .5rem 1rem 0.5rem 2rem;
      }
    }

    &.vui-input-container--opened {
      .vui-location-filter__dropdown-icon {
        transform: rotate(180deg);
      }

      .vui-location-filter__dropdown--background,
      .vui-select__dropdown {
        display: block !important;
      }
    }
  }

  .vui-location-filter__dropdown--background {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    display: none;
  }

  &.vui-input-container__filter {
    margin: 0;

    .vui-input {
      font-size: 0.75rem;
      line-height: 0.75rem;
      padding: 0.5rem 1rem;
      height: 2rem;
      border: 1px solid $vui-main-menu-color;
      font-weight: 500;
    }

    .vui-location-filter__dropdown-icon {
      width: 0.625rem;
      height: 0.625rem;
      margin: 0.6875rem 1rem;
    }

    .vui-location-filter__option {
      font-size: .75rem;
      line-height: 2.25rem;
    }
  }
}

.vui-input-container.vui-form-control-lg {
  .vui-location-filter {
    .vui-location-filter__dropdown-icon {
      margin: 1.875rem 1rem;
    }

    .vui-input {
      padding-right: 3rem;
    }
  }
}

@media only screen and (max-width: 767px) {
  .vui-input-container,
  .vui-input-container.vui-form-control-lg {
    .vui-location-filter {
      .vui-location-filter__dropdown-icon {
        margin: 16px 12px;
      }

      .vui-input {
        padding-right: 2rem;
      }
    }

    &.vui-input-container__filter {
      .vui-location-filter__dropdown-icon {
        width: 0.625rem;
        height: 0.625rem;
        margin: 0.6875rem 1rem;
      }
    }
  }
}
