@import "../../../velotooler-app";

.vui-content {
  background: $vui-content-background;
  min-height: calc(100vh - #{$vui-header-height});
}

@media only screen and (max-width: 767px) {
  .vui-content {
    min-height: calc(100vh - #{$vui-header-height-sm});
  }
}
