@import "fonts.scss",
"variables.scss",
"base-template-styles.scss",
"./components/vt-components.scss",
"./public/mechanic/mechanic.scss",
"./credit-cards.scss",
"./bike/bike-transfer.scss",
"./bike/add-bike",
"./bike/additional-components.scss",
"ckeditor.scss",
"phone-number.scss",
"smile.scss",
"profile/mechanic/mechanic-profile-view.scss",
"./profile/tabs/change-profile.scss",
"./profile/profile",
"footer.scss",
"md-overrides",
"../components/components",
"./chat";

/*should be generated*/
@import "tailwind";

.color-disabled {
  color: $text-main-color;
}

.color-error {
  color: $input-remove-color;
}

.color-success {
  color: $done-button-text-color;
}

.color-primary {
  color: $vt-color-primary !important;
}

.color-green {
  color: $done-button-text-color;
}

.color-white {
  color: white;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
  z-index: 100000;
}

::-webkit-scrollbar-thumb {
  background-color: #80868b;
  border-radius: 4px;
  -webkit-transition: .2s ease-in-out;
  transition: .2s ease-in-out
}

::-webkit-scrollbar-thumb:hover {
  background-color: #80868b;
}

::-webkit-scrollbar-track {
  border-width: 0;
}

html {
  height: auto;
  overflow-y: overlay;
}

html, body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  background: $color-primary-d1;
  font-family: Montserrat, serif;
  vertical-align: baseline;
  position: relative;
  -webkit-font-smoothing: antialiased;
  -ms-overflow-style: scrollbar;
}

body.md-dialog-is-showing {
  overflow: hidden !important;
  top: 0px !important;
}

button, select, html, textarea, input {
  font-family: Montserrat, serif;
}

@media(max-width: $mobile-width) {
  .menu-offset {
    padding-bottom: $sidebar-size;
  }
}

@media(min-width: $not-mobile-width) {
  .menu-offset {
    padding-left: $sidebar-size;
  }
}

.main {
  overflow: hidden;
  min-height: calc(100vh - 64px);
  transition: background-image .5s;
  transition-timing-function: ease;

  .ng-enter.animate {
    transition: 1s;
    opacity: 0;
  }

  .ng-enter-active.animate {
    opacity: 1;
  }

  .ng-leave.animate {
    opacity: 0;
  }

  .ng-leave-active.animate {
    transition: 1s;
    opacity: 0;
  }
}

img.logo {
  width: 220px;
  float: left;
}

h3.logo {
  float: left;
  color: $vt-color-primary;
  font-weight: bold;
}

h3.logo:hover {
  color: $vt-color-primary-hue-1;
}

.nav-buttons {
  .md-raised.nav-link.md-button {
    font-size: 18px;
    color: $color-main;
    border: 0px;
    line-height: 1;
    background: transparent;
    float: right;
    margin-left: 0px;
    margin-right: 0px;
    padding: 0px 10px;

    span {
      color: $vt-color-primary;
      vertical-align: sub;
    }
  }

  .md-raised.nav-link.md-button md-icon {
    color: $color-main;
    background: transparent;
    font-size: 26px;
    vertical-align: inherit;
    margin-left: 0px;
  }

  .md-raised.nav-link.md-button .fa.fa-sign-out {
    padding-top: 4px !important;
  }

  .md-raised.nav-link.md-button .fa.fa-home {
    padding-top: 2px !important;
  }

  .md-raised.nav-link.md-button .fa.fa-bell-o {
    padding-top: 4px !important;
  }

  .md-raised.nav-link.md-button:hover {
    color: $vt-color-primary-hue-1;
    border: 0px;
    background: rgba(200, 200, 200, 0.12);
  }
}

.vt-form.registration {
  .left.link.login-link {
    padding-top: 8px;
  }

  h1 {
    font-size: 1.5em;
    color: white;
  }

  @media (min-width: 768px) {
    max-width: 580px;
    padding-left: 90px;
    padding-right: 90px;
  }

  .profile-tab-form {
    padding: 0 0 40px 0;
  }
}

.vt-form.admin-page {
  max-width: 1200px;
  width: 100%;
}

.vt-form {
  color: $input-default-color;
  margin: 1em 0;
  background-color: $form-background;
  border-radius: 2px;
  padding-left: 10px;
  padding-right: 10px;
  max-width: 600px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

  &.vt-form_align-center {
    margin-left: auto;
    margin-right: auto;
  }

  .vt-form__header {
    text-align: center;
    font-size: 23px;
    padding: 20px;
    margin-left: -10px;
    margin-right: -10px;
    border-bottom: 1px solid #444;

    &.vt-form__header_no-border {
      border-bottom: none;
    }
  }

  .admin-page {
    width: 800px;
  }

  md-content {
    background-color: transparent;
  }

  md-tabs-wrapper {
    padding-left: 12px;
    padding-right: 12px;
  }

  md-input-container {
    margin-bottom: 0px;

    .error {
      height: 0;
    }
  }

  .right.link.login-link {
    padding-top: 8px;
  }

  md-tab-content:first-child {
    padding: 0px;
  }
}

md-dialog {
  .vt-form {
    box-shadow: none;
  }
}

hgroup {
  text-align: center;
  color: $vt-color-primary;
}

h1, h3 {
  font-weight: 200;
}

.width-100, md-input-container {
  width: 100% !important;
}

.width50 {
  width: 50%;
}

.width49 {
  width: 49%;
}

.width80 {
  width: 80%;
}

.min-w-125 {
  min-width: 125px !important;
}

.margin-left-3 {
  margin-left: 3px;
}

.margin-top-minus5 {
  margin-top: -5px;
}

.margin-top-5 {
  margin-top: 5px;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.margin-top-15 {
  margin-top: 15px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-25 {
  margin-top: 25px;
}

.margin-top-40 {
  margin-top: 40px !important;
}

.margin-top-50 {
  margin-top: 50px !important;
}

.margin-top-70 {
  margin-top: 70px !important;
}

.margin-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

@media (max-width: $mobile-width) {
  .mobile-margin-left-minus-25 {
    margin-left: -25px;
  }

  .mobile-margin-right-minus-25 {
    margin-right: -25px;
  }
}

form {
  .vt-button.md-button {
    font-size: 16px;
    color: $vt-color-primary;
    border: 0px;
    padding: 0 10px;
    line-height: 2;
    background: transparent;
    margin: 0px;
    text-transform: initial;

    span {
      color: $vt-color-primary;
    }
  }

  .md-raised.vt-button.md-button:hover,
  .md-raised.vt-button.md-button:focus,
  .md-raised.vt-button.md-button.md-focused {
    color: $vt-color-primary-hue-1;
    border: 0px;
    background: $navbar-button-background-hover;
  }

  .vt-button.md-button.clear-session,
  .vt-button.md-button.clear-session.md-raised:hover,
  .vt-button.md-button.clear-session.md-raised:focus,
  .vt-button.md-button.clear-session.md-raised.md-focused {
    border: 0px;
  }

  .vt-button.md-button.clear-session[disabled] {
    background: transparent !important;

    span {
      color: $input-disabled-color !important;
    }
  }
}

.center {
  text-align: center !important;
}

md-input-container.md-default-theme .md-input,
md-input-container .md-input,
.md-select-value, .md-select-value.md-select-placeholder {
  color: $input-default-color;
  border-color: $input-default-color;
}

md-select.md-default-theme:not([disabled]):focus .md-select-value,
md-select:not([disabled]):focus .md-select-value {
  color: $input-default-color;
  border-color: $vt-color-primary;
}

md-input-container {
  label {
    color: $label-color;
  }

  input.md-input, input.md-input:focus {
    border-color: #555;
  }
}

md-input-container:not(.md-input-has-value) input:not(:focus) {
  color: $input-default-color;
}

md-input-container.md-default-theme:not(.md-input-invalid).md-input-has-value label,
md-input-container:not(.md-input-invalid).md-input-has-value label {
  color: $label-color;
}

md-input-container:not(md-input-invalid).md-input-focused label {
  color: $vt-color-primary !important;
}

md-input-container:not(.md-input-invalid).md-input-has-value,
md-input-container.md-input-has-value {
  label.md-label {
    color: $label-color;
  }

  input.md-input, input.md-input:focus {
    border-color: $vt-color-primary;
  }
}

md-input-container.md-input-focused.md-input-invalid,
md-input-container.md-input-focused {
  label.md-label {
    color: $vt-color-primary;
  }

  input.md-input, input.md-input:focus {
    border-color: $vt-color-primary;
  }
}

md-input-container.md-input-invalid label.md-label,
md-input-container.md-input-invalid .md-placeholder,
md-input-container.md-input-invalid .md-placeholder {
  color: $input-error-color;
}

md-input-container.md-input-invalid .md-input {
  border-color: $input-error-color;
}

md-input-container.md-input-invalid {
  ::-webkit-input-placeholder {
    color: $input-error-color;
  }

  ::-moz-placeholder {
    color: $input-error-color;
  }

  :-moz-placeholder {
    color: $input-error-color;
  }

  :-ms-input-placeholder {
    color: $input-error-color;
  }
}

md-input-container.md-input-invalid.md-input-has-value {
  input.ng-invalid {
    border-color: $input-error-color;
  }
}

.left {
  float: left;
  text-align: left;
}

.right {
  float: right !important;
  text-align: right !important;
}

.menu-tabs {
  color: $vt-color-primary;
  padding-top: 10px;
  font-size: 30px;
}

md-sidenav.md-sidenav-left.md-whiteframe-z2 {
  z-index: 2000;
  background: rgba(0, 0, 0, 0.9);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);

  md-toolbar {
    a.logo {
      padding-left: 50px;
    }
  }

  md-content {
    .mobile-menu {
      padding: 0px;
      margin: 0px;

      .mobile-tab {
        padding: 25px;
        cursor: pointer;
        color: $input-default-color;
      }

      .mobile-tab:hover {
        color: $input-active-color;
        background: $navbar-button-background-hover;
      }

      .mobile-tab.active {
        background-color: $color-main;
        color: #424242;
      }
    }
  }
}

md-input-container {
  .error {
    span {
      color: $input-error-color;
      font-size: 14px;
      line-height: 24px;
    }
  }

  .error.time-error {
    span {
      font-size: 12px;
      line-height: 12px;
    }
  }
}

.input-container {
  position: relative;

  .error-label {
    position: absolute;
    bottom: 18px;
    left: 2px;

    span {
      color: $input-error-color;
      font-size: 14px;
      line-height: 24px;
    }
  }
}

md-tab-item.md-tab {
  span {
    color: #999;
  }
}

@media (max-width: 470px) {
  md-tab-item {
    font-size: 12px !important;
    max-width: 150px !important;
  }
}

@media (min-width: 471px) and (max-width: 870px) {
  md-tab-item {
    max-width: 200px !important;
  }
}

@media (min-width: 871px) {
  md-tab-item {
    max-width: 250px !important;
  }
}

.md-tab.md-active {
  span {
    color: white;
  }
}

md-tab-content:first-child {
  padding: 5px;
}

md-pagination-wrapper {
  font-size: 20px;
}

input {
  outline: none;
}

input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 50px rgba(0, 0, 0, 0.5) !important; /* Цвет фона */
  -webkit-text-fill-color: $input-default-color !important; /* цвет текста */
  color: $input-default-color !important; /* цвет текста */
}

.vt-form.profile, .verification-tab {
  .avatar {
    padding-top: 10px;

    .current {
      max-width: 100%;
      max-height: 420px;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.39);
    }
  }

  .center {
    text-align: center;
  }
}

.upload-avatar {
  .upload-avatar__avatar {
    height: 300px;
    background-position: center center;

    @media (max-width: $mobile-width) {
      height: 200px;
    }
  }

  .upload-avatar__avatar_default {
    width: 250px !important;
    height: 250px !important;
    margin: auto;
  }
}

.mechanic-amount.earned {
  font-size: 24px !important;
  color: $vt-color-primary;
  font-weight: bold;

  .amount {
    vertical-align: initial !important;
  }
}

.mechanic-amount.pending {
  font-size: 24px !important;
  font-weight: bold;
  color: gray !important;

  .amount {
    vertical-align: initial !important;
    color: gray !important;
  }
}

.show-mechanic-account {
  color: white;
  margin-left: 26px;
  border-radius: 2px;
  padding: 30px 10px;
  line-height: 30px;

  .currency-account {
    border-radius: 20px;
    border: 1px solid hsla(199, 8%, 47%, .4);
    padding: 1px 15px;;
  }

  .pending {
    color: #929292;
    border: 1px solid #525252;
    border-radius: 6px;
    padding: 0px 6px 0 6px;
  }

  .verified {
    color: #24b47e;
    border: rgba(36, 180, 126, .4);
    border-radius: 6px;
    padding: 0px 6px 0 6px;
  }

  .unverified {
    color: #e39f48;
    border: 1px solid rgba(227, 159, 72, .4);
    border-radius: 6px;
    padding: 0px 6px 0 6px;
  }

  .md-button.md-fab.md-mini {
    line-height: 39px;
  }

  .md-button {
    margin: 0 !important;
    background-color: $vt-color-primary;
  }

  .md-button.md-fab:not([disabled]):hover {
    background-color: $vt-color-primary;
  }

}

.profile-tab-form {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;

  a.target-blank {
    color: $vt-color-primary;
  }

  a.target-blank:active,
  a.target-blank:hover,
  a.target-blank:focus {
    color: $input-active-color;
  }

  .error {
    color: $input-error-color;
  }

  .terms-accepted {
    padding-bottom: 15px;
  }
}

.vt-form.address-verification-form {
  max-width: 1200px;
  width: 100%;

  .layout-row > .flex-50, .layout-row > .flex-33, .layout-row > .flex-66 {
    padding-left: 10px !important;
  }

  .zip-code-info {
    padding: 0px 17px 15px 17px;
  }
}

.vt-form.profile-images-verification-form {
  width: 880px;
}

.add-buttons-fab.add-bike-fab {
  float: left;
  margin-top: -13px;

  .md-button.md-fab {
    background-color: rgba(0, 0, 0, 0);
    color: rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0 !important;

    md-icon {
      height: 26px;
      width: 26px;
      font-size: x-large !important;
      color: $vt-color-primary;
    }
  }

  .md-button.md-fab:hover,
  .md-button.md-fab:active,
  .md-button.md-fab:focus {
    background-color: $navbar-button-background-hover;

    md-icon {
      color: $input-active-color;
    }
  }
}

.add-buttons-fab {
  md-icon {
    height: 26px;
    width: 26px;
    font-size: x-large !important;
  }

  float: right;
}

.add-buttons-fab.serial-number-button {
  .md-fab.addon.md-button {
    bottom: 5px;
    box-shadow: none;
  }
}

.enter-rating-form {
  .add-buttons-fab.serial-number-button {
    .md-fab.addon.md-button {
      margin: 0px;
    }
  }
}

@media (min-width: 493px) {
  .enter-rating-form {
    .add-buttons-fab.serial-number-button {
      .md-fab.addon.md-button {
        bottom: -5px;
      }
    }
  }
}

@media (max-width: 494px) {
  .enter-rating-form {
    .add-buttons-fab.serial-number-button {
      .md-fab.addon.md-button {
        bottom: 10px;
      }
    }
  }
}

.add-buttons-fab.type-of-bike {
  .md-fab.md-button {
    width: 84px;
    height: 84px;

    md-icon {
      background-size: 100% !important;
      width: 73px;
      height: 73px;
    }
  }
}

@media (min-width: 799px) {
  md-dialog {
    md-dialog-content {
      overflow: overlay;

      .avatar-form {
        width: 600px;
      }

      .show-picture-form {
        width: 700px;

        .show-img-buttons {
          height: 100%;
        }
      }

      .show-new-deadline-form {
        width: 450px;
      }
    }
  }
}

@media (max-width: 800px) {
  md-dialog {
    md-dialog-content {
      .avatar-form, .show-picture-form, .show-serial-number-form, .show-new-deadline-form {
        width: 100%;
      }
    }
  }
}

.show-serial-number-form {
  .serial-numbers {
    p {
      color: $input-default-color;
      min-width: 135px;
      padding-left: 15px;
    }
  }
}

md-dialog {
  background-color: $form-background;

  md-dialog-content {
    .avatar-form {
      text-align: center;

      .dialog-content {
        margin-left: 10px;
        margin-right: 10px;
      }
    }

    color: $vt-color-primary;

    h1 {
      text-align: center;
      color: white;
    }
  }

  .md-button.md-primary:active, .md-button.md-primary:hover {
    color: $input-active-color !important;
  }
}

md-progress-linear.md-default-theme .md-bar, md-progress-linear .md-bar {
  background-color: $input-default-color;
}

.progress-line {
  .md-container.md-mode-determinate {
    background-color: rgba(255, 230, 63, 0.36);
  }
}

md-dialog-actions {
  div.left {
    width: 50%;

    button {
      float: left;
    }
  }

  div.right {
    width: 50%;

    button {
      float: right;
    }
  }
}

.table {
  .title {
    font-weight: bold;
  }

  .table-row {
    color: $input-default-color;
    padding: 10px 0px;
  }

  .table-row:hover {
    background: rgba(200, 200, 200, 0.1);
  }

  table {
    width: 100%;

    th {
      text-align: left
    }

    th, td {
      color: $input-default-color;
      padding: 10px 5px;
    }
  }
}

.admin-table {

  th:not(:first-child), td:not(:first-child) {
    max-width: 173px;
    min-width: 173px;
  }

  div {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.md-button.dropdown {
  overflow: visible;
}

.dropdown .dropdown-menu {
  max-width: 0;
  position: absolute;
  left: 40px;
  top: 100%;
  margin: 0;
  padding: 0;
  opacity: 0;
  overflow: hidden;
  list-style: none;
}

.dropdown .dropdown-menu .md-button {
  min-width: 100%;
  border-radius: 0;
  margin: 0;
  padding: 6px 16px;
}

.dropdown.active .dropdown-menu {
  max-width: 300px;
  left: 0;
  opacity: 1;
  background: rgba(0, 0, 0, 0.6);
}

md-checkbox {
  margin-bottom: 0px;
}

md-checkbox.md-default-theme:not(.md-checked) .md-icon,
md-checkbox:not(.md-checked) .md-icon {
  border-color: $vt-color-primary;
}

md-checkbox.md-checked .md-icon {
  background-color: $vt-color-primary;
}

md-checkbox.md-default-theme[disabled] .md-icon, md-checkbox[disabled] .md-icon {
  border-color: rgba(255, 235, 0, 0.26);
}

.md-button.remove-button, .md-button.add-button {
  padding-left: 0px;
  padding-right: 0px;
  min-width: 32px;
  line-height: 24px;
  min-height: 24px;
  padding-top: 8px;

  md-icon.fa {
    color: #F44336;
  }
}

form .vt-button.md-button.remove-button span {
  color: $input-remove-color;
}

form .vt-button.md-button.remove-button:active span,
form .vt-button.md-button.remove-button:hover span,
form .vt-button.md-button.remove-button:focus span {
  color: $input-active-remove-color;
}

.md-button.remove-button {
  color: #F44336;

  @media (max-width: 375px) {
    margin-left: -10px;
  }
}

.md-button.add-button {
  color: $input-default-color;

  md-icon.fa {
    color: $input-default-color;
  }
}

.md-button.remove-button[disabled] {
  md-icon.fa {
    color: #E57373;
  }
}

.vt-form.add-type-of-bike {
  .name {
    width: 100%;
  }

  .wheel {
    width: 33%;
  }

  .default {
    padding-left: 15px;
    padding-right: 0px;
    padding-bottom: 15px;
  }

  .remove {
    padding-bottom: 15px;
  }

  md-content.md-padding {
    padding-bottom: 30px;
  }
}

.wide {
  width: 100%;
}

.vt-form.admin-page {
  md-content.md-padding {
    padding-bottom: 80px;
  }
}

md-content.md-padding {
  padding-bottom: 30px;

  .md-fling.md-fab.admin-fab {
    position: absolute;

    md-fab-trigger {
      right: 0px;
      bottom: auto;
      left: auto;
      position: absolute;
    }

    md-fab-actions {
      right: 0px;
      bottom: 0px;
      left: auto;
      position: absolute;
    }
  }
}

.md-primary.md-button.back-button {
  color: $vt-color-primary;
}

.md-select {
  .md-select-value {
    color: $input-default-color !important;
    border-color: $vt-color-primary;
    text-align: left;
  }
}

md-select .md-select-value.md-select-placeholder, md-select .md-select-value {
  border-bottom: 1px solid #555555;
  padding-bottom: 0;
  color: $label-color;
}

md-input-container.md-input-has-value {
  md-select .md-select-value.md-select-placeholder, md-select .md-select-value {
    border-bottom: 1px solid $vt-color-primary;
  }
}

md-input-container {
  label:before(md-select.ng-invalid.ng-invalid-required) {
    .md-select-value {
      border-color: $input-error-color;
    }
  }
}

md-icon.fa.fa-bicycle {
  padding-right: 5px;
  padding-bottom: 2px;
}

.bicycle-filters {
  .md-button {
    width: 153px;
  }
}

.vt-form.add-bike, .vt-form.add-type-of-bike, .vt-form.create-request {
  max-width: 800px;
  width: 100%;

  @media (max-width: 599px) {
    .brand-name:nth-child(1) {
      padding-right: 10px;
      padding-left: 0;
    }
  }

  md-tabs-wrapper {
    display: none;
  }

  .task-label {
    margin: 0px 0px 20px 20px;
  }
}

.add-buttons-fab.show-comment {
  button.md-button.md-fab.addon {
    bottom: 1px;
  }
}

.add-buttons-fab {
  button.md-button.md-fab.addon {
    z-index: 20;
    line-height: 36px;
    min-width: 0;
    width: 36px;
    height: 36px;
    vertical-align: middle;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    border-radius: 50%;
    background-clip: padding-box;
    overflow: hidden;
    transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
    transition-property: background-color, box-shadow, transform;
    bottom: 16px;
    background: rgba(0, 0, 0, 0);

    md-icon {
      color: $vt-color-primary;
    }
  }

  button.md-button.md-fab.addon:hover,
  button.md-button.md-fab.addon:focus,
  button.md-button.md-fab.addon.md-focused {
    md-icon {
      color: $input-active-color;
    }
  }
}

button.md-button.md-raised.vt-button.non-border {
  border: 0px !important;
  text-align: center;
  padding: 0px;
  margin: 0px;
  background-color: transparent;
}

button.md-button.md-raised.vt-button.non-border:focus {
  background-color: $navbar-button-background-hover;
}

i:focus {
  outline-color: transparent;
}

.autocomplete {
  ::-webkit-input-placeholder,
  :-moz-placeholder,
  ::-moz-placeholder,
  :-ms-input-placeholder {
    color: $input-default-color;
  }
}

/* customize google autocomplete */
.pac-container.pac-logo {
  background-color: $form-background;
  border-top: 0px;

  .pac-item {
    font-size: medium;
    padding: 5px 10px;
    border-top: 0px;
    color: $input-default-color;

    .pac-item-query {
      color: $input-default-color;
      font-size: medium;
    }

    .pac-matched {
      color: $vt-color-primary !important;
    }

    .pac-icon.pac-icon-marker {
      display: none;
    }
  }

  .pac-item:active,
  .pac-item:hover,
  .pac-item:focus {
    background-color: rgba(100, 100, 100, 0.5);
    cursor: pointer;
  }
}

.pac-item-selected, .pac-item-selected:hover {
  background-color: rgba(100, 100, 0, 0.5);
  cursor: pointer;
}

.pac-logo:after {
  display: none !important;
}

md-content {
  background-color: rgba(0, 0, 0, 0);
}

md-option {
  color: $input-default-color;
  background-color: #333;
}

md-select-value.md-select-value.md-select-placeholder {
  span, span.md-select-icon {
    color: $label-color;
  }
}

.md-select-menu-container.md-active.md-clickable {
  md-option[selected],
  md-option:hover,
  md-option:active,
  md-option:focus {
    background-color: #444;
    font-sive: 18px;
  }

  md-select-menu.md-default-theme:not([disabled]) md-option[selected], md-select-menu md-option[selected] {
    color: $vt-color-primary !important;
  }
}

md-select.md-default-theme.ng-invalid.ng-dirty .md-select-value, md-select.ng-invalid.ng-dirty .md-select-value {
  color: $input-error-color !important;
  border-bottom-color: $input-error-color !important;
}

md-select-menu.md-default-theme:not([disabled]) {
  md-option[selected], md-select-menu md-option[selected] {
    color: #F44336 !important;
  }
}

.choose-color {
  height: 30px;
  width: 100%;
}

md-input-container {
  md-autocomplete {
    background: rgba(0, 0, 0, 0);
    min-width: auto;

    md-autocomplete-wrap {
      box-shadow: initial !important;

      .md-input {
        order: initial;
      }

      button {
        display: none;

        md-icon > svg > g > path {
          fill: $input-default-color;
        }
      }

      button:active,
      button:focus,
      button:hover {
        md-icon > svg > g > path {
          fill: $input-active-color;
        }
      }
    }
  }
}

.md-autocomplete-suggestions li {
  line-height: 38px;
  height: 40px;
}

.color-row {
  width: 160px;
}

.color {
  height: 40px;
  width: 40px;
  float: left;
  cursor: pointer;
  font-size: x-large;
  text-align: center;
  outline: 0;
}

.color.dark-red {
  background-color: #800000;
  color: #ffffff;
}

.color.red {
  background-color: #ff0000;
  color: #ffffff;
}

.color.white {
  background-color: #ffffff;
  color: #000;
}

.color.green {
  background-color: #008000;
  color: #fff;
}

.color.light-green {
  background-color: #00ff00;
  color: #000;
}

.color.dark-blue {
  background-color: #000090;
  color: #fff;
}

.color.blue {
  background-color: #0000ff;
  color: #fff;
}

.color.light-blue {
  background-color: #00ffff;
  color: #800000;
}

.color.black {
  background-color: #000000;
  color: #fff;
}

.color.grey {
  background-color: #7f7f7f;
  color: #800000;
}

.color.pink {
  background-color: #ff00ff;
  color: #fff;
}

.color.purple {
  background-color: #800080;
  color: #fff;
}

.color.brown {
  background-color: #996633;
  color: #fff;
}

.color.yellow {
  background-color: $vt-color-primary;
  color: #000;
}

.color.orange {
  background-color: #ff8000;
  color: #000;
}

.color-border {
  border: 1px solid #555;
}

.color.carbon {
  background: linear-gradient(27deg, #151515 5px, transparent 5px) 0 5px,
  linear-gradient(207deg, #151515 5px, transparent 5px) 10px 0px,
  linear-gradient(27deg, #222 5px, transparent 5px) 0px 10px,
  linear-gradient(207deg, #222 5px, transparent 5px) 10px 5px,
  linear-gradient(90deg, #1b1b1b 10px, transparent 10px),
  linear-gradient(#1d1d1d 25%, #1a1a1a 25%, #1a1a1a 50%, transparent 50%, transparent 75%, #242424 75%, #242424);
  background-color: #131313;
  background-size: 20px 20px;
  color: #fff;
}

.color.active {
  max-width: 40px;
  max-height: 40px;
  border: 3px solid $input-active-color;
}

.color-option {
  float: left;
  padding-top: 11px;
  position: absolute;
}

md-radio-button.md-default-theme .md-off, md-radio-button .md-off {
  border-color: $vt-color-primary;
}

md-content.md-default-theme, md-content {
  color: $input-default-color;

  .link a {
    color: $vt-color-primary;
  }
}

md-content.md-default-theme, md-content md-input-container.md-input-invalid md-select.ng-invalid-required md-select-value {
  border-color: $input-error-color !important;

  .md-select-icon, span {
    color: $input-error-color !important;
  }
}

.color-panel, .verification-tab {
  padding-top: 15px;
}

color-panel > div {
  max-width: 160px;
}

vt-tooltip {
  position: absolute;
  z-index: $z-index-tooltip;
  overflow: hidden;
  pointer-events: none;
  border-radius: 4px;

  font-weight: 500;
  font-size: $tooltip-fontsize-sm;
  @media screen and (min-width: $layout-breakpoint-sm) {
    font-size: $tooltip-fontsize-lg;
  }

  .md-content {
    height: auto;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transform-origin: center top;
    transform: scale(0);
    opacity: 0;
    line-height: $tooltip-height-sm;
    padding-left: $tooltip-lr-padding-sm;
    padding-right: $tooltip-lr-padding-sm;
    @media screen and (min-width: $layout-breakpoint-sm) {
      height: $tooltip-height-lg;
      line-height: $tooltip-height-lg;
      padding-left: $tooltip-lr-padding-lg;
      padding-right: $tooltip-lr-padding-lg;
    }

    &.md-show-add {
      transition: $swift-ease-out;
      transition-duration: .2s;
      transform: scale(0);
      opacity: 0;
    }

    &.md-show, &.md-show-add-active {
      transform: scale(1);
      opacity: 1;
      transform-origin: center top;
    }

    &.md-show-remove {
      transition: $swift-ease-out;
      transition-duration: .2s;

      &.md-show-remove-active {
        transform: scale(0);
        opacity: 0;
      }
    }
  }

  &.md-hide {
    transition: $swift-ease-in;
  }

  &.md-show {
    transition: $swift-ease-out;
    pointer-events: auto;
    will-change: opacity, height, width;
  }
}

.show-chosen-color {
  height: 40px;
  width: 100%;
  padding-left: 0px;
  padding-right: 0px;
  margin-left: 0px;
  margin-right: 0px;
}

.show-chosen-color.active,
.show-chosen-color:active,
.show-chosen-color:focus,
.show-chosen-color:hover {
  border: 1px solid $input-active-color;
}

.show-color.color {
  height: 40px;
  float: left;
}

.user-info {
  td {
    vertical-align: top;
    width: 150px;
    padding: 5px 5px 6%;
  }
}

.choose-file-container {
  text-align: center;
  padding: 30px;
  margin: 0;

  button.vt-button.choose-images {
    height: 40px;
    padding-left: 0;
    padding-right: 0;
    box-shadow: none !important;
    float: inherit;
    min-width: 120px;
  }

  button.vt-button.choose-images:active,
  button.vt-button.choose-images:focus,
  button.vt-button.choose-images:hover {
    background-color: $navbar-button-background-hover;
  }

  @media (max-width: 470px) {
    padding: 2px;
  }
}

form .vt-button.md-button span:active,
form .vt-button.md-button span:focus,
form .vt-button.md-button span:hover {
  color: $input-active-color;
}

.cursor-pointer, th.search, .data-table .search {
  cursor: pointer;
}

.data-table {
  .radio-button-filters {
    margin-bottom: 15px;

    .md-button {
      width: 100%;
    }

    .md-button.active {
      background: $vt-color-primary;
      color: $input-disabled-color;
    }

    .md-button.active:hover {
      background: $vt-color-primary-hue-1;
      color: $input-disabled-color;
    }
  }

  .data-table-actions {
    .data-table-action {
      min-width: 36px;
      width: 36px;
    }
  }

  table.table {
    border-spacing: 0px;
    float: left;

    .selecting-row {
      cursor: pointer;
    }

    .selecting-row:focus,
    .selecting-row:active,
    .selecting-row:hover {
      background: rgba(200, 200, 200, 0.1);
    }

    tr.title {
      th {
        padding: 0px 5px;
      }
    }

    th.no-vertical-padding {
      padding: 0px 5px;
    }

    .search {
      .title {
        float: left;
      }
    }

    .search:not(.ASC), .search:not(.DESC) {
      .sort-marker.fa-sort-down:before, .sort-marker.fa-sort-desc:before {
        content: '';
      }
    }

    .search.ASC, .search.DESC {
      .sort-marker {
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        float: right;
      }
    }

    .search.ASC {
      .sort-marker {
        /* padding-top: 6px;*/
      }

      .sort-marker.fa-sort-down:before, .sort-marker.fa-sort-desc:before {
        content: "\f0de";
        color: $input-default-color;
      }
    }

    .search.DESC {
      .sort-marker.fa-sort-down:before, .sort-marker.fa-sort-desc:before {
        content: "\f0dd";
        color: $input-default-color;
      }
    }

    md-input-container {
      margin: 0;

      .md-errors-spacer {
        min-height: 0px !important;
      }
    }
  }
}

.pagination {
  margin: 0;
  display: inline-block;
  border-radius: 0;
  padding: 15px 0;

  li {
    display: inline;

    a {
      color: $vt-color-primary;
      text-decoration: initial;

      span {
        text-transform: initial;
      }
    }

    a:hover, a:active, a:focus {
      color: $input-active-color;
    }
  }

  a[disabled] {
    span {
      color: #555;
    }
  }

  .page {
    a {
      min-width: 30px;
      padding: 0 0px;
      margin: 0px 0px;
      line-height: 25px;
      min-height: 20px;
    }
  }

  .prev, .next {
    a {
      line-height: 25px;
      min-width: 50px;
      padding: 0 0px;
      margin: 0px 0px;
      min-height: 20px;
    }
  }
}

@media (min-width: 768px) {
  .pagination {
    float: right;
  }
}

md-toast.md-bottom {
  min-width: 320px;
  float: none;
  width: auto;
  right: 0;
  left: auto;
}

md-toast {
  position: fixed;
  min-width: inherit;
  z-index: 1501;

  .warning {
    background-color: #f28550;
    color: white;
  }

  .error {
    color: white;
    background-color: #d05f5c;
  }

  .success {
    background-color: #619b59;
    color: white;
  }

  .info {
    background-color: #276f8c;
    color: white;
  }

  &.md-top {
    width: 100%;
    float: none;
    right: 0;
    left: auto;
    padding: 0;
    top: 0;
    position: fixed;

    .md-toast-content {
      display: block;
      min-height: 64px;
      height: auto;
      max-width: none;
    }

    .md-toolbar-tools {
      max-width: none;

      > span {
        margin: 22px 60px;
      }

      > .md-button:last-child {
        top: 50%;
        transform: translateY(-50%);
        right: 30px;
        position: absolute;
      }
    }

    md-icon {
      min-height: 16px;
      min-width: 16px;
      height: 16px;
      width: 16px;
    }

    .md-toast-text {
      text-align: center;
      float: none;
    }
  }

  .md-toolbar-tools {
    padding: 0;
    font-size: 15px;
    display: block;
    max-height: 100%;
    max-width: 500px;

    > .md-button {
      float: right;
    }

    > span {
      margin-top: 15px;
      margin-bottom: 15px;
      margin-right: 10px;
      display: block;
      float: left;
    }

    md-icon {
      min-height: 16px;
      min-width: 16px;
      height: 16px;
      width: 16px;
    }

    > .md-button:last-child {
      top: 5px;
      right: 5px;
      position: absolute;
    }
  }

  .md-toast-content::before {
    display: none;
  }

  .md-toast-content {
    display: block;
    min-height: 35px;
    height: auto;
    max-width: 376px;
    border-radius: 0;

    .md-toolbar-tools {
      height: auto;
    }
  }
}

.md-toast-animating {
  overflow: auto !important;
}

@media (max-width: $mobile-width) {
  md-toast {

    &.md-bottom {
      width: 100%;
      float: none;
      right: 0;
      left: auto;
      padding: 0;

      .md-toast-content {
        max-width: none;
        border-radius: 0;
      }
    }
  }
}

md-list-item {
  img {
    width: 50px;
    height: 30px;
    margin: 5px;
    border: 1px solid black;
  }

  img[alt] {
    font-size: 60%;
  }

  border-top: 1px solid $input-default-color;
}

.list-style_none {
  list-style: none;
}

.request-new-card {
  transition: .5s cubic-bezier(0.46, 0.03, 0.52, 0.96) all;
  overflow: hidden;

  .action {
    padding-top: 25px;

    .md-button.md-primary {
      color: black;
      background-color: rgb(232, 255, 0);
      float: right;
    }

    .md-button.md-primary.md-fab:not([disabled]):hover {
      background-color: greenyellow;
    }
  }
}

.question {
  font-size: x-large;
}

a.md-button {
  span {
    vertical-align: middle;
  }

  &.no-request-btn {
    margin: 13px 8px 35px 8px;
  }
}

form {
  md-chip {
    font-size: 12px;
  }

  md-chips {
    .md-chips, .md-chips.md-focused {
      box-shadow: 0 1px $input-default-color;
    }

    .md-chip {
      background: rgba(0, 0, 0, 0.26);
      color: $input-default-color;
    }
  }

  md-icon {
    color: $vt-color-primary
  }
}

md-input-container:not(.md-input-invalid).md-input-focused {
  md-icon {
    color: $input-default-color;
  }

  .md-input {
    border-color: $input-default-color;
  }
}

.label {
  padding-left: 15px;
  padding-right: 15px;
}

p.label {
  padding-left: 25px;
}

.margin-top-0 {
  margin-top: 0px;
}

.md-button {
  text-transform: none;
}

md-radio-group.verified {
  md-radio-button {
    margin-left: 30px;
  }
}

img.img-tmb {
  max-width: 100%;
}

img.img-tmb.no-diploma {
  max-width: 400px;
}

select-date {
  width: 100%;
}

.select-date {
  margin-left: -3px;
  margin-right: -3px;
}

.select-date {
  .year {
    width: 20%;
  }

  .month {
    width: 60%;
  }

  .day {
    width: 20%;
  }
}

md-input-container.md-default-theme .md-input[disabled],
md-input-container .md-input[disabled],
md-input-container.md-default-theme .md-input [disabled],
md-input-container .md-input [disabled] {
  color: $input-disabled-color;
}

md-checkbox.md-default-theme, md-checkbox,
md-checkbox.md-default-theme[disabled] .md-label, md-checkbox[disabled] .md-label,
md-checkbox.md-default-theme[disabled] .md-icon, md-checkbox[disabled] .md-icon,
md-checkbox.md-default-theme[disabled].md-checked .md-icon, md-checkbox[disabled].md-checked .md-icon,
md-checkbox.md-default-theme[disabled].md-checked .md-icon:after, md-checkbox[disabled].md-checked .md-icon:after {
  color: white;
  border-color: $input-active-color;
}

md-radio-group.md-default-theme[disabled],
md-radio-group[disabled],
md-radio-button.md-default-theme[disabled],
md-radio-button[disabled],
md-radio-group.md-default-theme[disabled] .md-container .md-off, md-radio-group[disabled] .md-container .md-off, md-radio-button.md-default-theme[disabled] .md-container .md-off, md-radio-button[disabled] .md-container .md-off {
  color: $input-active-color;
  border-color: $input-active-color;
}

hr {
  color: $input-default-color;
  background-color: $input-default-color;
  border: none;
  height: 1px;
}

a.md-button.md-default-theme.md-primary, a.md-button.md-primary, .md-button.md-default-theme.md-primary, .md-button.md-primary {
  color: $vt-color-primary;
}

.md-raised.vt-button.non-border.show-img-buttons {
  md-icon.fa {
    color: $vt-color-primary;
  }
}

.trim-header {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}

.status-bike {
  position: relative;
  margin-top: -28px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.7);
  padding-top: 5px;
  padding-bottom: 5px;
}

.card-link {
  text-decoration: initial;
  color: inherit;
}

.show-chosen-color-bike {
  height: $bike-colors-panel-height;
  width: 100%;
  padding-left: 0px;
  padding-right: 0px;
  outline: none;

  .show-color.color {
    height: 20px;
    float: left;
    outline: none;
  }
}

.vertical-label {
  margin: 6px 5px;
  padding-top: 20px;
}

.vertical-label.disable-container, .vertical-select-label.disable-container, .vertical-color-label.disable-container {
  padding-top: 0px;
}

.vertical-color-label {
  padding-top: 30px;
}

@media (max-width: 799px) {
  .vertical-color-label {
    margin-left: 5px;
  }
}

@media (min-width: 800px) {
  .vertical-color-label {
    margin-left: 16px;
  }
}

.vertical-select-label {
  margin: 8px 5px;
  padding-top: 20px;
}

md-input-container.bike-input-container {
  padding-left: 0px;
  padding-right: 0;
  height: 20px;
}

md-input-container.bike-input-container.color-container {
  padding-top: 6px;
}

md-input-container.bike-input-container.disable-container {
  margin: inherit;

  .md-errors-spacer {
    display: none;
  }
}

.edit-bike-button {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0) !important;
  margin: 0;
}

.upload-photos-button {
  margin: 7px 0;
}

.bike-info-tab {
  .component-label {
    color: $input-default-color;
  }
}

.bike-info-tab {
  padding-top: 15px;
}

.bike-info-tab, .request-info-tab {
  input[disabled].md-input, md-select[disabled].md-select {
    border-width: 0;
    background-image: none;
    color: #888;
    border-bottom: 1px solid #625f50 !important;

    md-select-value.md-select-value {
      background-image: none;
      border-width: 0;
    }

    .md-select-icon {
      display: none;
    }
  }
}

.cursor-auto {
  cursor: auto;
}

md-autocomplete.md-default-theme[disabled], md-autocomplete[disabled] {
  background: none;
}

.margin-left-0 {
  margin-left: 0px;
}

.margin-left-6 {
  margin-left: 6px;
}

.bike-gallery {
  width: 100%;

  .show-img-buttons.md-button {
    height: 100px;
    width: 40px;
    min-width: 40px;
    padding: 0;
    box-shadow: none;
    font-size: xx-large;

    md-icon {
      vertical-align: baseline;
    }
  }

  .show-img-buttons.md-button[disabled] {
    md-icon {
      color: #111 !important;
    }
  }

  .gallery-right, .gallery-left {
    display: inherit;
    float: left;
  }

  .images {
    display: inherit;
    float: left;
    width: calc(100% - 80px);
    height: 100px;
    overflow: hidden;

    table {
      border-collapse: collapse;

      td {
        border-spacing: 0px;
        padding: 0px 2px;
      }
    }

    .images-content {
      width: 100%;
      display: inline-block;
      left: 0px;
      position: relative;
      transition: transform 0.5s cubic-bezier(0.35, 0, 0.25, 1);

      .image {
        height: 100px;
        width: 100px;
        display: block;
        position: relative;

        img {
          width: 100px;
          height: 100px;
        }
      }

      .vt-button.preview {
        position: absolute;
        border-radius: 0;
        margin-left: 0;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        display: block;

        md-icon.fa {
          display: none;
        }
      }

      a.vt-button.preview:hover {
        display: flex;

        md-icon.fa {
          margin: auto;
        }
      }

      .vt-button.preview:hover {
        background-color: rgba(0, 0, 0, 0.6);

        md-icon.fa {
          display: block;
          color: $input-active-color;
        }
      }
    }
  }

  @include floatClearAfter();
}

.brand-name {
  padding-left: 10px;
}

.show-components.fa-sort-desc:before {
  vertical-align: baseline;
}

.show-components.fa-sort-asc:before {
  vertical-align: middle;
}

.component-toggle {
  padding-bottom: 15px;

  .vt-button {
    width: 100%;
    box-shadow: 0 0 0 0 !important;

    md-icon.fa {
      color: $vt-color-primary;
    }
  }
}

.box-show-setup.animate, .box-hide-setup.animate {
  -webkit-transition: all linear 0.3s;
  -moz-transition: all linear 0.3s;
  -ms-transition: all linear 0.3s;
  -o-transition: all linear 0.3s;
  transition: all linear 0.3s;
}

.box-show-setup.animate {
  opacity: 0;
  height: 0;
}

.box-show-setup.box-show-start.animate {
  opacity: 1;
  height: 100px;
}

.box-hide-setup.animate {
  opacity: 1;
  height: 0;
}

.box-hide-setup.box-hide-start.animate {
  opacity: 0;
  height: 100px;
}

.components-show.ng-hide-add.animate, .show-zip-info.ng-hide-add.animate {
  -webkit-animation: 0.5s hide;
  animation: 0.5s hide;
}

@keyframes hide {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  30% {
    transform: scale(1.02);
  }
  100% {
    opacity: 0;
    transform: scale(0.5);
  }
}

@-webkit-keyframes hide {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  30% {
    transform: scale(1.02);
  }
  100% {
    opacity: 0;
    transform: scale(0.5);
  }
}

.components-show.ng-hide-remove.animate, .show-zip-info.ng-hide-remove.animate {
  -webkit-animation: 0.3s show;
  animation: 0.3s show;
}

@keyframes show {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  70% {
    transform: scale(1.02);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@-webkit-keyframes show {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  70% {
    transform: scale(1.02);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.yellow-label {
  font-size: 11px;
  color: $vt-color-primary;
  text-transform: uppercase;
  white-space: nowrap;
}

.review-bike-tab, .pass-quiz-form {
  .review-row {
    padding: 10px 0px 10px 0px;

    .col-xs-4, .col-xs-6 {
      padding-right: 0px;
    }
  }

  hr {
    background-color: #333;
  }
}

.pass-quiz-form, .verification-form {
  .no-margin {
    margin: 0px;
  }

  .no-padding {
    padding: 0px;
  }

  .back.md-button {
    margin-top: 6px;
  }
}

.remove-button-container {
  padding-left: 0px;
  padding-right: 8px;
  text-align: right;
  padding-top: 10px;
}

.biography-form {
  padding-left: 20px;
  padding-right: 20px;

  .margin-top-18 {
    margin-top: 18px;
  }

  .no-padding {
    padding: 0;
  }

  .layout-gt-xs-row {
    height: 60px;
  }

  .spacing-between-units {
    padding-bottom: 80px;
    padding-top: 40px;
  }

  .add-button {
    width: 100%;
  }

  .remove-button {
    margin-top: 0;
    margin-bottom: 0;
  }

  md-input-container {
    margin-bottom: 9px;

    .error {
      height: auto;

      span.absolute {
        position: absolute;
      }
    }

    .md-errors-spacer {
      display: none;
    }
  }

  label.paragraph,
  div.paragraph {
    padding-left: 2px;
  }

  .bio-desc {
    padding-left: 2px;
    padding-right: 2px;

    @media (max-width: 380px) {
      .biography-team-name {
        display: none;
      }
    }
  }

  .paragraph {
    margin-bottom: 20px;
  }

  .section-header {
    font-size: 1.5em;
  }

  .remove-button {
    margin-top: 28px;
    margin-left: 0px;
    margin-right: 0px;
  }

  .add-button-center {
    text-align: center;
    margin-bottom: 15px;
  }

  .margin-top-40 {
    margin-top: 40px;
  }

  @media (min-width: 768px) {
    .title {
      padding-top: 28px;
    }
  }
  @media (max-width: 767px) {
    .title {
      padding-top: 20px;
      padding-bottom: 0px;
    }
  }
  @media (max-width: 599px) {
    .add-button {
      margin-left: 0;
      margin-right: 0;
    }
    .spacing-between-units {
      padding-bottom: 0;
    }
    .section-header {
      margin-top: 20px;
    }

    .xs-margin-top-20 {
      margin-top: 20px;
    }
  }
}

.no-vertical-padding {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}

.no-vertical-margin {
  margin-bottom: 0px !important;
  margin-top: 0px !important;
}

md-toolbar.md-default-theme:not(.md-menu-toolbar), md-toolbar:not(.md-menu-toolbar) {
  background-color: $form-background;

  .nav-button-logo {
    margin-right: 0px;
  }
}

.bike-service-around {
  padding-bottom: 5px;
  margin-top: 6px;
}

.google-map {
  width: 100%;
  height: 1000px;
  position: relative;
  margin-bottom: 30px;
}

.google-map.profile-company-map {
  height: 300px;
}

@media (max-height: 500px) {
  .google-map {
    height: 400px;
  }
}

.search-request-google-map {
  width: 100%;
  height: 600px;
  position: relative;
}

.marker-info {
  color: $footer-color-link;
}

.create-request-map {
  width: 100%;
  height: 400px;
  position: relative;
}

.bike-status {
  @include getBikeStatuses(11px);
}

.change-status-dialog-content {
  min-width: 300px;
  max-width: 550px;
  width: 100%;

  .message {
    word-wrap: break-word;
    white-space: normal;
    padding-left: 30px;
    padding-right: 30px;
    font-size: larger;
    color: $input-default-color;
  }
}

md-dialog .md-actions, md-dialog md-dialog-actions {
  display: initial;
}

.vt-form.biography,
.vt-form.profile {
  max-width: 1000px;
  width: 100%;
}

.admin-form {
  max-width: 1200px !important;
}

.table.professional-summary {
  width: 100%;

  th {
    border-bottom: 1px solid $input-default-color;
    text-align: left;
  }
}

.text-align-right {
  text-align: right;
}

.vt-form.show-bike {
  max-width: 1000px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;

  .history-row {
    padding-left: 0;
    padding-right: 0;
  }

  .create_request_action_button {
    display: block;
    min-width: 120px;
    margin: 23px 10px;
    background-color: $vt-color-primary;
    color: black;
    padding: 10px 10px;
  }

  .create_request_action_button:hover {
    background-color: $vt-color-primary-hue-1;
  }

  .hgroup-as-action-bar.row {
    margin-left: -10px;
    margin-right: -10px;

    .col-xs-2, .col-xs-8 {
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  .img-tmb {
    width: 100%;
    border-radius: 5px;
  }
}

.img-certification {
  position: absolute;
  width: 75px !important;
  margin-top: 15px;
  margin-left: 15px;
}

.img-bike-owner {
  position: absolute;
  width: 50px !important;
  bottom: 5px;
  left: 5px;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
}

md-input-container.autocomplete {
  .md-label {
    color: $label-color;
  }

  label {
    color: $label-color;
  }

  margin-bottom: 7px;

  md-autocomplete {
    height: 30px;
  }
}

@media (min-width: 500px) {
  md-input-container[no-float] {
    &.md-input-focused label {
      display: none;
    }

    &.md-input-has-value label {
      display: none;
    }

    & label {
      color: rgb(162, 162, 162);
    }
  }
}

md-input-container.component-input input {
  border-color: rgb(162, 162, 162) !important;
}

md-input-container.md-input-focused label:not(.md-no-float),
md-input-container.md-input-has-placeholder label:not(.md-no-float),
md-input-container.md-input-has-value label:not(.md-no-float) {
  transform: translate3d(0, 3px, 0) scale(0.9);
}

md-input-container.md-input-invalid label,
md-input-container.md-input-invalid.autocomplete label,
md-select.md-select.ng-invalid + label {
  color: $input-error-color;
}

md-input-container {
  .md-select.ng-invalid.ng-touched {
    md-select-value.md-select-value {
      color: $input-error-color !important;
      border-color: $input-error-color !important;

      span {
        color: $input-error-color !important;
      }
    }
  }

  .md-select.ng-invalid.ng-touched + label.md-label {
    color: $input-error-color;
  }
}

form.ng-submitted {
  md-input-container {
    .md-select.ng-invalid {
      md-select-value.md-select-value {
        color: $input-error-color !important;
        border-color: $input-error-color !important;

        span {
          color: $input-error-color !important;
        }
      }
    }
  }

  .md-select.ng-invalid + label.md-label {
    color: $input-error-color;
  }
}

md-input-container {
  md-autocomplete {
    height: 30px;

    md-autocomplete-wrap {
      input.md-input {
        color: $input-default-color;

        ::-webkit-input-placeholder {
          color: rgba(0, 0, 0, 0) !important;
        }

        ::-moz-placeholder {
          color: rgba(0, 0, 0, 0) !important;
        }

        :-moz-placeholder {
          color: rgba(0, 0, 0, 0) !important;
        }

        :-ms-input-placeholder {
          color: rgba(0, 0, 0, 0) !important;
        }
      }

      ::-webkit-input-placeholder {
        color: rgba(0, 0, 0, 0) !important;
      }

      ::-moz-placeholder {
        color: rgba(0, 0, 0, 0) !important;
      }

      :-moz-placeholder {
        color: rgba(0, 0, 0, 0) !important;
      }

      :-ms-input-placeholder {
        color: rgba(0, 0, 0, 0) !important;
      }
    }
  }
}

.vt-button.md-button {
  color: $vt-color-primary;
  text-align: center !important;
}

.vt-button.md-button:focus,
.vt-button.md-button:active,
.vt-button.md-button:hover {
  color: $vt-color-primary;
}

.loading-indicator {
  z-index: 1501;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);

  loading-indicator {
    position: absolute;
    top: calc(50% - 50px);
    left: calc(50% - 50px);
  }

  @media (max-width: 470px) {
    loading-indicator {
      left: calc(50% - 31px);
    }
  }
}

loading-indicator {
  md-progress-circular {
    svg path {
      stroke: $vt-color-primary !important;
    }

    .md-gap {
      border-top-color: $vt-color-primary !important;
      border-bottom-color: $vt-color-primary !important;
    }

    .md-left {
      .md-half-circle {
        border-top-color: $vt-color-primary !important;
        border-left-color: $vt-color-primary !important;
      }
    }

    .md-right {
      .md-half-circle {
        border-top-color: $vt-color-primary !important;
        border-right-color: $vt-color-primary !important;
      }
    }
  }
}

.md-primary.back-button.width-100.admin-link {
  margin-left: 0px;
  margin-right: 0px;
  font-size: large;
}

.no-left-padding {
  padding-left: 0px !important;
}

.verified-checkbox {
  md-checkbox {
    margin-top: 10px !important;
  }
}

.fa.fa-exclamation-circle.error {
  color: $input-error-color !important;
  font-size: large;
  padding-left: 5px;
  vertical-align: middle;
  padding-bottom: 2px;
}

.email {
  color: $vt-color-primary;
}

.layout-wrap.layout-row.disable-container-select {
  height: 32px;
}

.layout-wrap.layout-row.enable-container-select {
  height: 52px;
}

.change-create-request {
  .action-button {
    min-width: 36px;
  }

  .md-button.remove i {
    color: $input-remove-color;
  }

  .md-button.remove:active i,
  .md-button.remove:hover i,
  .md-button.remove:focus i {
    color: $input-active-remove-color;
  }
}

.change-task-container {
  md-tabs-canvas {
    display: none;
  }

  .task-code {
    font-size: 15px;
    display: block;
    text-align: right;
    color: #4caa77;
  }
}

.show-task-dialog-container {
  max-width: 800px;
  width: 100%;
  padding: 20px 15px;

  h2 {
    text-align: center;
    color: $input-default-color;
  }

  .md-label {
    margin-top: 10px;
    margin-bottom: 10px;
    color: $input-default-color;
  }
}

.show-task-form {
  width: 100%;
}

.change-task-form {
  padding-left: 20px;
  padding-right: 20px;
  max-width: 500px;
  width: 500px;

  md-input-container {
    margin-bottom: 0px;

    md-checkbox {
      margin-bottom: 20px;

      .md-label {
        span {
          color: $input-default-color;
        }
      }
    }
  }

  .action-button {
    min-width: 36px;
    margin-top: 16px;
  }

  .md-button.remove i {
    color: $input-remove-color;
  }

  .md-button.remove:active i,
  .md-button.remove:hover i,
  .md-button.remove:focus i {
    color: $input-active-remove-color;
  }
}

mdp-date-picker {
  md-input-container {
    width: 100%;
  }
}

mdp-calendar {
  .md-icon-button.md-button {
    md-icon {
      color: $vt-color-primary;
    }

    md-icon:active,
    md-icon:hover,
    md-icon:focus {
      color: $vt-color-primary-hue-1;
    }
  }

  .md-icon-button.md-raised.md-button[disabled] {
    span {
      color: #666;
    }
  }

  .md-icon-button.md-raised.md-button:not([disabled]) {
    span {
      color: white;
      font-weight: bold;
    }
  }

  .md-button.md-raised:not([disabled]):hover {
    background-color: rgba(100, 100, 100, .4);

    span {
      color: white;
      font-weight: bold;
    }
  }

  .md-icon-button.md-raised:not([disabled]).md-button.md-accent {
    background-color: $vt-color-primary;

    span {
      color: #222;
    }
  }

  .md-icon-button.md-button.md-accent.md-raised:not([disabled]):hover {
    background-color: $vt-color-primary;
  }

  .md-icon-button.md-raised:not([disabled]).md-button.md-accent.md-focused {
    background-color: $vt-color-primary;
  }

  .md-icon-button.md-raised.md-button.md-accent
  .md-icon-button.md-raised.md-button {
    span {
      color: white;
      font-weight: bold;
    }
  }
}

.mdp-clock-switch-container {
  .mdp-clock-center.md-primary, .mdp-pointer.md-primary {
    background-color: $vt-color-primary;
    color: $vt-color-primary;
  }

  .mdp-animation-zoom.mdp-clock {
    border: 1px solid $vt-color-primary;
    background: black;
  }

  .md-icon-button.md-raised.md-button.md-primary {
    background-color: $vt-color-primary;

    span {
      color: black;
    }
  }

  .md-icon-button.md-raised.md-button.md-primary:hover {
    background-color: $vt-color-primary-hue-1;

    span {
      color: black;
    }
  }

  .md-icon-button.md-raised.md-button {
    span {
      color: $vt-color-primary;
    }
  }

  .md-icon-button.md-raised.md-button:hover {
    background-color: $vt-color-primary;

    span {
      color: black;
    }
  }
}

.stripe-info {
  padding-top: 10px;
  padding-left: 0px;

  a .fa.fa-question {
    color: $vt-color-primary;
  }

  a:hover .fa.fa-question {
    color: $vt-color-primary-hue-1;
  }
}

.request-details {
  .back.md-button {
    margin-top: 25px;
  }

  h1 {
    font-weight: 300;
  }
}

@media only screen and (max-width: 799px) {
  .create-request, .request-details {
    .back.md-button {
      margin-top: 20px;
    }
  }
}

.verification-form, .vt-form.show-bike, .vt-form.request-details, .vt-form.admin-form {
  hgroup.hgroup-as-action-bar {
    h1 {
      white-space: inherit;
    }
  }

  .back.md-button {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 799px) {
  .verification-form, .vt-form.show-bike, .vt-form.request-details, .vt-form.admin-form {
    .back.md-button {
      margin-top: 15px;
    }
  }
}

.vt-form.create-request md-input-container .error {
  height: auto;

  span {
    color: $input-error-color !important;
  }
}

.vt-form.create-request md-input-container .error.md-auto-hide {
  height: 0;
}

.add-available-time {
  width: 100%;
  height: 126px;
  border-radius: 0px;
  margi-top: 10px !important;
  background: rgba(50, 50, 50, 0.5) !important;

  i {
    font-size: 50px;
  }
}

.add-available-time:hover {
  background: rgba(100, 100, 100, 0.5) !important;
}

.time-picker-container {
  padding: 5px;

  table {
    width: 100%;
    border-spacing: 0px;

    .picker {
      width: 95%;

      .md-label {
        padding-left: 30px;
      }
    }

    .fa.fa-clock-o, .fa.fa-calendar {
      font-size: x-large;
      margin-bottom: 20px;
      color: $vt-color-primary;
      cursor: pointer;
    }

    .fa.fa-clock-o:hover, .fa.fa-calendar:hover,
    .fa.fa-clock-o:active, .fa.fa-calendar:active,
    .fa.fa-clock-o:focus, .fa.fa-calendar:focus {
      color: $input-active-color;
    }
  }
}

.select-available-time-card {
  padding: 5px;

  .deadline md-datepicker {
    padding-right: 0px;
    margin-right: -50px;

    .md-datepicker-input-container {
      width: auto;
    }
  }

  .select-available-time-card-content {
    background: rgba(70, 70, 70, 0.5);
    padding: 5px 15px;
    border-radius: 4px;

    md-input-container {
      margin-top: 0px;
    }

    .remove-availability-date, .remove-availability-date[disabled], .remove-availability-date[disabled]:hover {
      position: relative;
      float: right;
      min-width: 36px;
      background-color: rgba(0, 0, 0, 0) !important;
      z-index: 100;
      box-shadow: none;

      i {
        color: $vt-color-primary;
      }
    }

    .remove-availability-date:hover {
      i {
        color: $input-remove-color;
      }
    }
  }
}

.card-number-button {
  width: 100%;
}

.md-fab.addon.bike-action {
  width: 36px;
  height: 36px;
  line-height: 44px;
}

md-menu.bike-menu {
  position: absolute;
  top: 0;
  right: 0;

  .md-button {
    line-height: 32px;
    height: 36px;
    width: 36px;
    margin: 0;
    padding: 6px;

    md-icon {
      color: $vt-color-primary;
    }

    md-icon:active,
    md-icon:hover,
    md-icon:focus {
      color: $vt-color-primary;
    }
  }
}

.md-open-menu-container {
  md-menu-content {
    background-color: #222;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

    .md-button {
      color: #ccc;
    }

    .md-button:active,
    .md-button:focus,
    .md-button:hover {
      color: white;
    }
  }
}

.status-request {
  position: relative;
  margin-top: -10px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.7);
  padding-top: 5px;
  padding-bottom: 5px;
  text-transform: lowercase;
}

md-slider.md-min .md-thumb:after, md-slider.md-default-theme .md-track.md-track-fill, md-slider .md-track.md-track-fill {
  background-color: $vt-color-primary;
}

md-slider.md-default-theme .md-track, md-slider .md-track, md-slider.md-default-theme .md-track-ticks, md-slider .md-track-ticks {
  background-color: $footer-color-link-hover;
}

md-slider.md-default-theme.revert .md-track, md-slider.revert .md-track, md-slider.md-default-theme.revert .md-track-ticks, md-slider.revert .md-track-ticks {
  background-color: $vt-color-primary;
}

md-slider.md-default-theme.revert .md-track.md-track-fill, md-slider.revert .md-track.md-track-fill {
  background-color: $footer-color-link-hover;
}

.search-price-label {
  min-width: 40px;
  max-width: 54px;
  text-align: right;
  color: $input-default-color;
  padding-left: 10px;
}

.md-label.search-label {
  color: $label-color;
}

.custom-radio-button {
  margin-top: 15px;
  margin-bottom: 10px;

  ul {
    padding-left: 0px;
    width: 100%;

    li {
      @media (max-width: 470px) {
        width: auto;
      }
      width: $width-weekday-button;
      display: inline-block;
      list-style-type: none;

      .active {
        background-color: $vt-color-primary;
        color: $footer-color-link;
      }

      .active:hover,
      .active:active {
        background-color: $vt-color-primary-hue-1;
      }

      .md-button {
        font-size: 11px;
        min-width: 40px;
        width: 100%;
        margin-left: 0px;
        margin-right: 0px;
        padding-left: 0px;
        padding-right: 0px;
        border-radius: 0px;
        border-bottom: 2px solid $vt-color-primary;
        border-top: 2px solid $vt-color-primary;
      }

      .md-button.active[disabled] {
        background-color: $vt-color-primary-hue-1;
      }

      .md-button[disabled] {
        border-bottom: 2px solid $vt-color-primary-hue-1;
        border-top: 2px solid $vt-color-primary-hue-1;
      }

      .md-button.active[disabled] span {
        color: $footer-color-link;
      }

      .md-button[disabled] span {
        color: $vt-color-primary-hue-1;
      }
    }

    li:first-child {
      .md-button {
        border-left: 2px solid $vt-color-primary;
        border-bottom-left-radius: 3px;
        border-top-left-radius: 3px;
      }

      .md-button[disabled] {
        border-left: 2px solid $vt-color-primary-hue-1
      }
    }

    li:last-child {
      .md-button {
        border-right: 2px solid $vt-color-primary;
        border-bottom-right-radius: 3px;
        border-top-right-radius: 3px;
      }

      .md-button[disabled] {
        border-right: 2px solid $vt-color-primary-hue-1
      }
    }
  }
}

.radius-search-request.custom-radio-button {
  ul {
    li {
      width: 20%;
    }
  }
}

.experience-search-request.custom-radio-button {
  ul {
    li {
      width: $width-experience-search-button;
    }
  }
}

.bike-dashboard {
  md-list {
    padding: 0px;

    md-list-item {
      border-top: 0px;

      .md-button {
        margin: 0px;
      }

      .md-list-item-text {
        color: $input-default-color;
      }

      .service-icon {
        float: left;
        margin: 10px 10px 0px 0px;
        border: 0px;
        width: 36px;
        height: 36px;
      }
    }

    md-list-item.focus-marker,
    md-list-item:active,
    md-list-item:hover,
    md-list-item:focus {
      background: $navbar-button-background-hover;
    }
  }
}

md-list-item.md-2-line {
  min-height: 60px;
}

@media only screen and (max-width: 799px) {
  .bike-dashboard {
    .request-list {
      max-width: 100%;
      max-height: 100%;
      box-sizing: border-box;
      padding-left: 9px;
      padding-right: 9px;

      md-list-item {
        padding-top: 8px;
        padding-bottom: 8px;
      }

      .loading-request {
        position: fixed;
        width: 100%;

        md-progress-circular {
          margin: auto;
        }
      }
    }

    .request-map, .request-img {
      display: none;
    }
  }
}

@media (min-width: 800px) {
  .bike-dashboard {
    .request-list {
      max-width: 50%;
      max-height: 100%;
      box-sizing: border-box;

      .loading-request {
        md-progress-circular {
          margin: 40% auto;
        }
      }
    }

    .request-map, .request-img {
      max-width: 50%;
      max-height: 100%;
      box-sizing: border-box;
    }
  }
}

.loading-request {
  md-progress-circular {
    .md-gap {
      border-top-color: $vt-color-primary !important;
      border-bottom-color: $vt-color-primary !important;
    }

    .md-left {
      .md-half-circle {
        border-top-color: $vt-color-primary !important;
        border-left-color: $vt-color-primary !important;
      }
    }

    .md-right {
      .md-half-circle {
        border-top-color: $vt-color-primary !important;
        border-right-color: $vt-color-primary !important;
      }
    }
  }
}

.vt-form.show-request {
  max-width: 1000px;
  width: 100%;
}

md-list-item.md-2-line, md-list-item.md-2-line > .md-no-style {
  min-height: 60px;
  line-height: 26px;
}

.width-100.bike-input-container.request-upload-image {
  padding-bottom: 10px;

  .add-buttons-fab button.md-button.md-fab.addon {
    margin-right: 0px;
  }
}

.padding-top15 {
  padding-top: 15px;
}

.padding-top30 {
  padding-top: 30px;
}

.padding-left5 {
  padding-left: 5px !important;
}

.padding-0-15 {
  padding: 0 15px;
}

.padding-left2 {
  padding-left: 2px !important;
}

.margin-top15 {
  margin-top: 15px;
}

.margin-right10 {
  margin-right: 10px;
}

.padding-bottom10 {
  padding-bottom: 10px;
}

.padding-bottom-40 {
  padding-bottom: 40px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.border-top {
  border-top: 1px solid #333;
}

.add-buttons-fab.request-change-buttons {
  height: 20px;
}

.availability-title {
  padding-left: 5px;
  margin-top: -4px;
}

.add-buttons-fab.request-change-buttons {
  button.md-button.md-fab.addon {
    margin-right: 0px;
    padding-right: 0px;
  }
}

.task-desc {
  margin-top: 15px;

  .additional-desc {
    .task-label {
      margin-bottom: 10px;
    }
  }
}

.request-card {
  flex-direction: row-reverse;
  background: rgba(50, 50, 50, 0.5);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  border-radius: 4px;
}

.negotiation-tab {
  padding-top: 10px;
}

.negotiation-date {
  font-size: 14px;
}

md-input-container .time-disabled.md-input[disabled] {
  color: $input-default-color;
  border-bottom: 0px;
}

.mechanic-link {
  color: $input-default-color;
  text-decoration: initial;
}

.show-bike-link {
  color: white;
  text-decoration: initial;
  font-weight: 500;
}

.mechanic-link:active,
.mechanic-link:hover,
.mechanic-link:focus {
  color: $input-default-color;
  text-decoration: initial;
}

.md-label.left.time {
  color: $input-default-color;
}

.white-space-pre {
  white-space: pre-wrap;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.clearfix {
  width: 100%;
  display: block;
}

.clearfix:after {
  visibility: hidden;
  display: table;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.request-status {
  font-size: larger;
  padding-bottom: 3px;
  padding-top: 3px;
  border-radius: 15px;
  margin-bottom: 15px;

  .additional-status-info {
    font-size: 0.8em;
    color: #3a3a3a;
  }

  @media (max-width: 470px) {
    font-size: 16px;
  }
}

.request-status.open,
.request-status.awaiting-customer-confirmation,
.request-status.draft {
  background-color: #667b9c;
  color: black;
}

.request-status.in-progress {
  background-color: #2c9a57;
  color: black;
}

.request-status.pending,
.request-status.ready-for-reassign {
  background-color: #d1a713;
  color: black;
}

.request-status.resolved {
  background-color: #e27f37;
  color: black;
}

.request-status.done,
.request-status.reassigned,
.request-status.refunded,
.awaiting-confirmation {
  background-color: #d1a713;
  color: black;
}

.request-status.expired {
  background-color: #3a3a3a;
  color: white;
}

.request-status.canceled,
.request-status.charge-failed,
.request-status.could-not-be-fixed,
.request-status.rejected {
  background: rgba(156, 69, 67, 0.87);
  color: white;
}

.request-status.in-dispute,
.request-status.transfer-failed,
.request-status.refund-failed {
  background: rgba(154, 34, 32, 0.78);
  color: white;
}

md-dialog.bike-construction-dialog {
  width: 80%;
  max-width: 900px;
  padding-left: 5px;
}

.bike-construction-img, .bike-construction-desc {
  display: table-cell;
}

.bike-construction-img {
  background: url("/img/find_sn.svg") no-repeat center;
  background-size: contain;
  width: 60%;
}

.bike-construction-desc {
  padding: 60px;
}

@media only screen and (max-width: 799px) {
  .bike-construction-desc {
    padding: 0 10px;
  }
}

@media (max-width: 699px) {
  .vt-form.show-request {
    max-width: 100%;
    width: 100%;
  }
}

@media only screen and (max-width: 500px) {
  md-dialog.bike-construction-dialog {
    padding-left: 2px;
  }
  .bike-construction-desc {
    padding: 0 0 0 10px;
  }
}

.error span:not(.ng-hide) + span, .error span:not(.ng-hide) + span + span {
  display: none !important;
}

.no-tool {
  color: $input-error-color;
}

.add-buttons-fab.serial-number-button.show-error {
  float: inherit !important;
  padding: 0px;
  margin: 0px;

  md-icon {
    color: $color-blue;
    height: 15px;
    width: 15px;
    font-size: small !important;
    margin-top: -8px;
    cursor: pointer;
  }
}

md-tooltip {
  .md-content {
    height: 26px !important;
    line-height: 26px !important;
    font-size: 13px;
    background-color: rgba(30, 30, 30, .8);
    color: #eee;
  }
}

.rating-label {
  color: $input-default-color;
  display: inline-block;
  max-width: 140px;
  width: 100%;
}

.consultation-message {
  color: $input-default-color;
  padding-left: 10px;
  padding-right: 10px;
  font-size: medium;
}

.request-comment {
  width: 100%;
  margin-top: 20px;
  padding: 15px;
  border: solid 1px #222;
  border-radius: 10px;

  .md-label.review-label {
    font-size: 0.8em;
    color: #667b9c;
  }

  .message {
    margin-top: 15px;
    word-wrap: break-word;
  }
}

.change-color-dialog-content {
  min-width: 180px;
  width: 180px;

  h2 {
    text-align: center;
    margin-top: 10px !important;
    margin-bottom: 10px;
  }

  .colors {
    padding-left: 10px;
    padding-right: 10px;
  }

  .color-dialog-actions {
    padding-left: 10px;
    padding-right: 10px;

    .md-button {
      min-width: 70px;
      margin-left: 0px;
      margin-right: 0px;
    }

    .left, .right {
      text-align: center;
    }
  }
}

.notification-menu {
  height: auto;
  display: block;

  .md-button {
    width: 100%;
    display: block;
    padding-bottom: 15px;
    padding-top: 15px;
    border-bottom: solid 1px #111;
    min-width: 300px;
  }
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.display-inline-block {
  display: inline-block;
}

md-icon.sort-marker {
  height: auto;
}

.width-100 > button.md-button.md-raised.vt-button {
  margin-bottom: 1px;
}

.reviews-md-content {
  .reviews-block {
    border-bottom: 1px solid $color-primary-l2;
    margin: 22px 0;
    color: #777;
    padding-bottom: 10px;

    .angular-input-stars > li {
      padding: 0;
    }

    > div {
      padding: 10px;
    }

    @media screen and (max-width: 599px) {
      > div {
        padding: 10px 5px;
      }
    }
  }

  .reviews-block__service {
    font-weight: 500;
  }

  ul[ng-class="listClass"] {
    margin: 0;
  }

  a {
    color: $vt-color-primary;
    padding-bottom: 10px;
    text-decoration: none;
    display: block;
  }

  a:hover, a:active {
    color: $vt-color-primary-hue-1;
  }

  .pagination {
    float: inherit !important;

    .md-button[disabled] {
      span {
        color: #555 !important;
      }
    }

    .md-button {
      span {
        color: $vt-color-primary !important;
      }
    }

    .md-button:hover {
      span {
        color: $vt-color-primary-hue-1 !important;
      }
    }
  }
}

.bike-history-tab {
  padding: 15px;
  min-height: 130px;

  .bike-history-tab__gallery {
    img {
      width: 90px !important;
      height: 90px !important;
    }
  }

  .bike-history-tab__repair-comment {
    margin: 10px 0;
  }

  .bike-history-tab__repair-comment-label {
    font-weight: bold;
    color: white;
  }

  .history-row {
    padding: 5px;

    .history-date {
      color: $vt-color-primary;
    }
  }

  .bike-history-row {
    padding: 10px 0;
    border-bottom: 1px solid $color-primary-l2;

    .link {
      white-space: normal;
    }
  }

  .pagination {
    .md-button[disabled] {
      span {
        color: #555 !important;
      }
    }

    .md-button {
      span {
        color: $vt-color-primary !important;
      }
    }

    .md-button:hover {
      span {
        color: $vt-color-primary-hue-1 !important;
      }
    }
  }

  .loading-indicator {
    position: absolute;
    width: 100%;
    height: 100%;

    loading-indicator {
      top: calc(50% - 50px);
      left: calc(50% - 50px);
    }
  }
}

.md-subhead.trim-header.add-bike {
  font-size: x-large;
  text-align: center;
  vertical-align: middle;
}

.add-bike-card-header {
  height: 44px;
}

.add-card {
  .stripe-logo {
    width: 130px;
    padding-top: 2px;
  }
}

.payment-form {

  .payment-form__submit-button {
    color: white !important;
  }

  .md-button {
    float: right;
  }

  a.md-button {
    float: left;
  }

  .stripe-logo {
    width: 130px;
    padding-top: 2px;
  }

  .card-description {
    padding-left: 2px;
    padding-right: 2px;
  }
}

.stripe-account {
  .powered-by-stripe {
    margin-top: 20px;
  }

  .stripe-logo {
    width: 130px;
  }
}

.charge-form {
  .stripe-account {
    margin-top: 6px;
  }
}

.profile-mechanic-payment {
  .link {
    white-space: nowrap;
  }

  .card-description {
    padding-left: 2px;
    padding-right: 2px;
  }

  .notes {
    margin-left: 0px;
  }
}

.show-account-number {
  margin-top: 29px;
}

.rzslider .rz-pointer {
  top: -8px;
  z-index: 3;
  width: 16px;
  height: 16px;
  cursor: pointer;
  background-color: $vt-color-primary;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;

}

.rzslider .rz-pointer:after {
  position: absolute;
  top: 6px;
  left: 6px;
  width: 4px;
  height: 4px;
  background: $vt-color-primary;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.rzslider .rz-pointer.rz-active:after {
  background: $vt-color-primary;
}

.rzslider .rz-pointer.rz-active {
  background: $vt-color-primary;
}

.rzslider .rz-bar.rz-selection {
  background: $vt-color-primary;
}

.rzslider .rz-bubble, .rzslider .rz-bubble.rz-limit {
  color: #888;
}

.rzslider .rz-bar {
  background: #555;
  height: 2px;
}

.autocomplete.search-request {
  margin-top: 22px;
}

.bike-components {
  padding-left: .8em !important;
  padding-right: 1em !important;

  .brand-model-container {
    word-wrap: break-word;
    padding-left: 1em;
  }

  .vertical-select-label {
    margin-left: 0px !important;
    word-wrap: break-word;
  }

  .remove-component-container {
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media (min-width: 500px) {
  .component-row {
    margin-top: -30px !important;
  }
}

@media (max-width: 500px) {
  .component-row {
    margin-top: 30px !important;
  }
}

.next-task-type {
  color: $vt-color-primary;
}

.price-label {
  padding-bottom: 10px;
}

md-datepicker {
  background: rgba(0, 0, 0, 0);
  color: $vt-color-primary;
  width: 100%;

  .md-datepicker-button {
    z-index: 79;
  }

  .md-button.md-icon-button {
    margin-left: 0;
    padding-left: 0;
    text-align: right;
    margin-right: 13px;
  }

  .md-datepicker-open .md-datepicker-calendar-icon {
    fill: $vt-color-primary-hue-1 !important;
  }

  .md-datepicker-input-container {
    width: 100%;
    margin-left: -52px;
    border-bottom: 0;

    .md-datepicker-input {
      color: $input-default-color;
      margin-left: 50px;
    }
  }
}

.md-datepicker-calendar-pane {
  z-index: 1501;
}

.md-datepicker-calendar-pane.md-whiteframe-z1.md-pane-open {
  border-color: $footer-color-link-hover;

  .md-datepicker-input-mask, .md-datepicker-input-mask-opaque {
    background: rgba(0, 0, 0, 0);
  }

  .md-datepicker-calendar {
    background: rgba(10, 10, 10, 1);

    .md-calendar-day-header {
      background: rgba(10, 10, 10, 1);
      color: $vt-color-primary;
      border-top: 1px solid $footer-color-link-hover;
      border-bottom: 1px solid $footer-color-link-hover;
    }

    .md-calendar-scroll-mask {
      .md-calendar-date-disabled, .md-calendar-date.md-calendar-date-disabled {
        color: $footer-color-link-hover !important;
      }

      .md-virtual-repeat-offsetter {
        .md-calendar {
          color: $vt-color-primary;

          tr:last-child td {
            color: $vt-color-primary;
          }

          .md-calendar-date.d-calendar-date-disabled {
            color: $footer-color-link-hover !important;
          }

          tbody::before {
            content: '';
            display: block;
            height: 15px;

          }
        }
      }
    }
  }
}

.md-calendar {
  background: rgba(0, 0, 0, 0.87);
}

.md-calendar-date.md-calendar-selected-date .md-calendar-date-selection-indicator,
.md-calendar-date.md-focus.md-calendar-selected-date .md-calendar-date-selection-indicator {
  background: $vt-color-primary;
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}

.md-calendar-date-selection-indicator:hover {
  background: $vt-color-primary-hue-1;
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}

.md-calendar-date.md-calendar-date-today .md-calendar-date-selection-indicator {
  border: 0;
}

.md-calendar.md-default-theme tr:last-child td, .md-calendar tr:last-child td {
  border-bottom-color: $footer-color-link-hover;
}

.choose-all-tools {
  margin-left: 8px;
}

.choose-all-tools.expert-tools {
  padding-top: 30px;
}

.add-available-date {
  max-width: 66px;
  margin-top: 10px;
}

.home-page {
  background-image: none;
}

.login-page {
}

.default {
}

.dashboard-page {
}

.registration-page {
}

.sign-up-page {
}

.add-bike-page {
}

.show-bike-page {
}

.md-button.show-card-info {
  border-radius: 50%;
  min-width: 36px;
  color: $vt-color-primary;
}

.md-button.show-card-info:hover {
  color: $vt-color-primary-hue-1;
}

.no-request {
  background: rgba(0, 0, 0, 0);
  min-height: 200px !important;
  padding: 0 10px;

  .request-bicycle-service__btn {

  }

  h2 {
    text-align: center;
    padding-top: 70px;
    padding-bottom: 25px;
    color: #fff;
  }
}

.no-request:hover {
  background: rgba(0, 0, 0, 0) !important;
}

.new-deadline-date-header {
  text-align: center;
  padding-top: 20px;
}

.bold {
  font-weight: bold;
}

.md-label.review-label {
  color: $label-color;
}

.md-label.review-label_theme-red {
  color: $input-remove-color;
  font-weight: 500;
}

.notification-budget {
  font-size: 12px;
  text-align: center;
  border-radius: 50%;
  background: $input-remove-color;
  color: $input-default-color !important;
  padding: 2px 5px;
  font-weight: 700;
}

.request-tools {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.vt-form.completed-registration {
  max-width: 600px;
  width: 100%;
}

.vt-form.about {
  max-width: 1000px;
  width: 100%;
}

.vt-form.about, .vt-form.completed-registration {
  margin-top: 80px;

  h3 {
    color: $vt-color-primary;
  }

  p {
    line-height: 1.5;
    text-align: justify;
  }

  ul {
    line-height: 1.5;
  }

  a {
    color: $vt-color-primary;
    text-decoration: initial;
  }

  a:hover {
    color: $vt-color-primary-hue-1;
  }

  .fee {
    color: $vt-color-primary;
  }
}

.md-button.back {
  min-width: 36px;
  min-height: 36px;
  width: 36px;
  border-radius: 50%;
  color: $vt-color-primary;
  font-size: large;
}

.feedback-comment {
  word-wrap: break-word;
  max-height: 500px;
  overflow: auto;
  color: #ccc;
}

.change-location-button {
  float: right;
}

@media (min-width: 768px) {
  .bike-location-form {
    .change-location-button {
      float: right;
      margin-top: 16px;
    }
  }
}

@media (max-width: 767px) {
  .bike-location-form {
    .change-location-button {
      float: right;
      margin-bottom: 16px;
    }
  }
}

.auth-container {
  padding: 7% 10px 20px;
}

.sign-up-user {
  text-align: center;
  max-width: 700px;
  margin: 0 auto;
  font-size: 2em;
  padding-bottom: 1em;
  margin-bottom: 30px;

  h2 {
    font-size: 1em;
    margin: 0;
    padding: 1.5em 0;
  }

  ul {
    list-style-type: none;
    padding-left: 0px;
    margin: 0;

    li {
      font-size: 1em;
      padding: .8em 0;

      .md-button.registration-link {
        font-size: 1em;
        height: 80px;
        min-width: 450px;
        padding: 0.5em;
        border: 2px solid $color-main;
        background-color: $color-main;
        color: black;
        text-transform: uppercase;
        font-weight: 600;
        transition: box-shadow 0.9s cubic-bezier(0.25, 0.8, 0.25, 1), background-color 0.9s cubic-bezier(0.25, 0.8, 0.25, 1);
        line-height: 38px;
      }

      a.registration-link.md-button:hover {
        background-color: $vt-color-primary-hue-1;
      }
    }
  }

  @media (min-width: 800px) {
    ul li {
      .md-button.registration-link {
        min-width: 450px;
      }
    }
  }

  @media (max-width: 799px) {
    font-size: 1.5em;

    ul li {
      .md-button.registration-link {
        height: 65px;
        min-width: 280px;
      }
    }
  }
}

.no-negotiation {
  text-align: center;
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
}

.about, .completed-registration {
  .contact-info {
    display: flex;
    justify-content: center;

    div {
      display: inline-block;
    }
  }

  span {
    color: $vt-color-primary;
  }

  textarea {
    white-space: pre-wrap;
  }
}

@media (min-width: 768px) {
  .vt-form.about {
    .contact-info {
      padding-top: 60px;
    }
  }
}

hgroup.hgroup-as-action-bar {

  .md-button.md-fab {
    background-color: rgba(0, 0, 0, 0);
    color: rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0 !important;

    md-icon {
      height: 26px;
      width: 26px;
      font-size: x-large !important;
      color: $vt-color-primary;
    }
  }

  .md-button.md-fab:hover,
  .md-button.md-fab:active,
  .md-button.md-fab:focus {
    background-color: $navbar-button-background-hover;

    md-icon {
      color: $input-active-color;
    }
  }

  h1 {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    @media (max-width: 799px) {
      font-size: 1.5em;
    }
    @media (max-width: 425px) {
      font-size: 1.3em;
    }
    @media (max-width: 320px) {
      font-size: 1em;
    }
  }
}

md-select[name=howDidYouHear] {
  md-select-value {
    span:nth-child(1) {
      max-width: 94%;
    }
  }
}

.short-story {
  word-wrap: break-word;
}

.other {
  word-wrap: break-word;
}

.credo {
  word-wrap: break-word;
}

show-session, insurance, [insurance] {
  text-align: center;

  .as-table {
    display: table;
    width: 100%;
    text-align: center;
    vertical-align: middle;
    color: $input-default-color;

    .text-left {
      text-align: left;
    }

    .as-table-row {
      display: table-row;

      > div {
        display: table-cell;
        padding: 10px;
        border-bottom: 1px solid $color-primary-l2;
        word-break: break-all;
      }

      .remove {
        .md-button.back {
          color: $remove-icon-color;
          margin: 0px;

          .md-ripple-container {
            border-radius: 50%;
          }
        }
      }
    }

    .as-table-row.table-header {
      color: #777;
    }

    @media (max-width: 480px) {
      display: block;

      .checkbox-button {
        text-align: right;
      }
      .as-table-row.table-header {
        display: none;
      }
      .text-left {
        text-align: inherit;
      }
      .as-table-row {
        display: block;
        position: relative;
        border: 1px solid $vt-color-primary;
        margin: 10px 0;

        > div {
          display: block;
          border: none;
        }

        .remove {
          position: absolute;
          margin: 0px;
          right: 0px;
          top: 0px;
          padding: 0px;

          .md-button.back {
            margin: 0px;
          }
        }
      }
    }
  }
}

.message-dialog {
  padding: 20px;
  position: relative;
  z-index: 999999;

  h1 {
    color: $input-default-color;
  }

  .show-message-dialog-content.serial-number {
    margin: 3px 0px !important;
  }

  md-input-container {
    display: block;

  }

  label.paragraph {
    margin-bottom: 20px;
    padding-left: 2px;
    font-size: 1.5em;
  }

  md-select {
    .md-select-value {
      border-bottom-color: $input-default-color;
    }
  }

  @media (min-width: 800px) {
    max-width: 700px;
  }
}

.select-organisation__message-dialog {
  .select-organisation__message-dialog_title {
    text-align: center;
    color: white;
    font-weight: 400;
    @media (min-width: 700px) {
      font-size: 28px;
    }

    @media (min-width: 400px) and (max-width: 699px) {
      font-size: 20px;
    }

    @media (max-width: 399px) {
      font-size: 18px;
    }
  }

  @media (min-width: 700px) {
    min-width: 600px;
    min-height: 200px;
  }

  @media (min-width: 400px) and (max-width: 699px) {
    min-width: 400px;
    min-height: 200px;
  }

  @media (max-width: 399px) {
    min-width: 300px;
    min-height: 200px;
  }
}

form[name=showRequestForm] {
  .available-time {
    width: 100%;
    margin: 10px 0;

    > div {
      margin: 5px;
    }

    .date-time {
      max-width: 100%;
    }
  }
}

[contenteditable=true]:empty:not(:focus):before {
  content: attr(placeholder);
  color: #aaa;
  display: block; /* For Firefox */
}

.smile-item {
  &:hover {
    background-color: #555555;
  }
}

.smile-container {
  padding: 12px;
  position: absolute;
  top: -17.5em;
  right: 0.7em;
  width: 16em;
  height: 17em;
  background: rgba(68, 68, 68, 0.97);
  border-radius: 5px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 4px;
    border-radius: 3px;
  }
}

.main-desc-form {

  .main-desc-form__input-container-with-select {
    padding-bottom: 27px;
  }

  .not-owner {
    margin: 12px 0 12px 0;
  }

  .main-desc-form__input-container {
    margin-top: 8px;
  }

  .main-desc-form__color-label {
    height: 46px;
    padding: 0;
    line-height: 27px;
  }

  .main-desc-form__color {
    line-height: 29px;
    margin: 0;
  }

  .edit-label {
    padding-top: 10px;
  }

  .action-buttons {

    .vt-action-button {
      min-width: 160px;
    }

    @media (max-width: 767px) {
      text-align: center;
    }
  }

  .img-tmb {
    max-width: 320px;

    @media (min-width: 960px) {
      width: 100%;
    }
  }

  .fab-icon-button-no-circle {

    button.md-button.md-fab.addon {
      z-index: 20;
      line-height: 36px;
      min-width: 0;
      width: 36px;
      height: 36px;
      vertical-align: middle;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
      border-radius: 50%;
      background-clip: padding-box;
      overflow: hidden;
      transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
      transition-property: background-color, box-shadow, transform;
      background: rgba(0, 0, 0, 0);

      md-icon {
        color: #ddd;
        height: 26px;
        width: 26px;
        font-size: x-large !important;
      }
    }

    button.md-button.md-fab.addon:hover,
    button.md-button.md-fab.addon:focus,
    button.md-button.md-fab.addon.md-focused {
      md-icon {
        color: white;
      }
    }
  }
}

.agreement {
  font-size: 16px;
  text-align: justify;

  .serial-number {
    color: $vt-color-primary-hue-1;
  }
}

.show-bike-status-dialog-content {
  min-width: 300px;
  width: 600px;

  .message {
    color: $input-default-color;
    padding-left: 10px;
    padding-right: 10px;

    h3 {
      margin-bottom: 5px;
      color: $vt-color-primary;
    }
  }
}

a.show-status-link {
  color: $vt-color-primary;
  text-decoration: none;
}

a.show-status-link:hover, a.show-status-link:active {
  color: $vt-color-primary-hue-1;
}

.ellipsis-label {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.md-toolbar-tools {
  h1 {
    color: $vt-color-primary;
  }
}

.notes {
  margin-left: 8px;
  font-size: 20px;
  margin-bottom: 15px;
  color: $vt-color-primary;
}

.dialog-form {
  .width-100 {
    width: 600px;
    margin-left: 50px;
  }

  .Ok {
    button {
      float: right;
      margin-right: 50px;
    }
  }
}

.enter-sn {
  .fa.fa-exclamation.no-sn {
    color: $input-error-color;
  }
}

.enter-sn:hover {
  color: $vt-color-primary-hue-1;

  .fa.fa-exclamation.no-sn {
    color: $input-remove-color;
  }
}

.next-section-button {
  position: relative;

  a {
    position: absolute;
    left: 50%;
    top: -32px;
    margin-left: -32px;
  }
}

.upload {
  color: white;
}

.upload-button {
  color: red;
  background-color: #0db9f0;
}

h2.show-user-contact-info-link {
  margin-top: 20px;
  margin-bottom: 0;
  text-align: center;

  a {
    color: $vt-color-primary;
    text-decoration: initial;
  }

  a:hover {
    color: $vt-color-primary-hue-1;
  }
}

.answer {
  padding-left: 20px;
}

.error-answer {
  color: rgba(207, 27, 0, 0.62);
}

.invisible {
  visibility: hidden;
}

.upload-image-dialog {
  max-width: 605px;
  margin: 0 auto;
  overflow: hidden;
  display: block;
  top: 50%;
  transform: translate(0, -50%) scale(1);

  &.md-transition-in {
    transform: translate(0, -52%) scale(1);
  }

  @media (max-width: 613px) {
    max-width: 458px;
    min-width: 320px;
  }

  @media (max-width: 467px) {
    max-width: 312px;
    min-width: 312px;
  }

  .upload-form {
    position: relative;
    width: 100%;
    height: 60vh;
  }

  .img-crop-block {
    position: absolute;
    width: 250px;
    height: 250px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background: url('../img/user-no-avatar.png') no-repeat center center;
    background-size: contain;
  }

  .mechanic-bg-img {
    background-image: url('../img/mechanic-no-avatar.png');
  }

  .organisation-bg-img {
    background-image: url('../img/no-organization-avatar.png');
  }

  .invisible {
    visibility: hidden;
  }

  .select-file {
    margin: 6px 8px;
    display: block;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    background: #333;
    border-radius: 3px;

    .vt-button.choose-images.md-button {
      width: 100%;
      display: block;
      cursor: pointer;
      color: #aaa;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
    }

    .vt-button.choose-images.md-button:hover {
      background: $navbar-button-background-hover;
    }

    .img-storage {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 20;

      -moz-opacity: 0;
      filter: alpha(opacity=0);
      opacity: 0;
    }
  }

  h1 {
    color: $vt-color-primary;
    text-align: center;
  }
}

.show-picture {

  margin: auto;
  top: 50%;

  &.md-transition-in {
    transform: translate(0, -50%) scale(1);
  }

  .show-picture__form {
    max-height: calc(100vh - 150px);
    max-width: calc(100vw - 20px);
  }

  .img-tmb {
    max-height: calc(100vh - 150px);
  }
}

.bike-avatar {
  position: relative;

  .link-container {
    position: absolute;
    width: 100%;
    height: 100%;

    .md-button.show-bike-button {
      width: 100%;
      height: 100%;
      z-index: 10;

      .md-ripple-container {
        border-radius: 0;
      }
    }
  }
}

.request-content {
  overflow: hidden;

  .request-desc {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 45px;
  }
}

.admin-title {
  padding-top: 1em;
}

.notification-budget.total-notifications {
  margin-left: -10px;
}

.show-bikes-button-img {
  margin-top: 18px;
  width: 44px !important;
}

.dashboard .dashboard__header .md-button img.show-bikes-button-img {
  display: initial;
}

.no-tabs-title {
  md-tabs-wrapper {
    display: none;
  }
}

.ei-message {
  width: 100%;
  position: fixed;
  top: 62px;
  left: 0px;
  text-align: center;
  min-height: 40px;
  background: $input-error-color;
  color: $input-default-color;
  padding-top: 5px;
  padding-bottom: 5px;
  z-index: 999;
}

@media (max-width: 799px) {
  .ei-message {
    position: relative;
  }
}

.capitalize {
  text-transform: uppercase
}

md-checkbox[disabled].user-session .md-icon {
  color: $input-disabled-color;
  border-color: $input-disabled-color;
}

.billing-address-zip-dialog {
  max-width: 500px;
  width: 100%;

  md-input-container {
    width: 100%;
  }
}

.verification-tab {
  padding-bottom: 50px;

  .biography-verification {
    word-wrap: break-word;
  }

  .head {
    font-size: 21px;
    padding-bottom: 10px;
    font-weight: 700;
    padding-left: 5px;
    color: $vt-color-primary;
  }

  .layout-row {
    line-height: 30px;
  }
}

.fa-check.chosen-available-time:before,
.fa-check.chosen-available-time::before {
  color: $vt-color-primary;
}

.next-section-button {
  a.md-button.md-fab {
    background-color: $vt-color-primary;
  }

  a.md-button.md-fab:hover {
    background-color: $vt-color-primary-hue-1;
  }
}

.unresolved-request {
  h1 {
    margin-right: 55px;
  }

  .user-info td {
    vertical-align: top;
    width: 200px;
    padding: 2px 2px 20px 55px;
  }

  .buttons {
    margin-top: 22px;
    margin-right: 34px;
  }
}

.mechanic-map {
  margin: 10px 0px;

  .map {
    width: 100%;
    height: 500px;
  }
}

@media (max-height: 500px) {
  .mechanic-map {
    .map {
      height: 400px;
    }
  }
}

.marker-info {
  .mechanic-map-info {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }

  .mechanic-map-level {
    font-size: 15px;
  }

  .mechanic-map-username {
    a {
      font-size: 16px;
      text-transform: capitalize;
      text-decoration: initial;
      font-weight: 500;
      color: #333;
    }

    a:hover {
      color: #777;
      font-weight: 500;
    }
  }
}

md-input-container label:not(.md-container-ignore).md-required:after {
  content: '';
}

.vt-form {
  h1 {
    text-align: center;
    color: white;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-bottom: 20px;
    padding-top: 20px;
  }

  .vt-form__action-buttons {
    padding: 15px 5px;
  }
}

.form-center {
  padding-left: 15px;
  padding-right: 15px;

  @media (max-width: $mobile-width) {
    padding-left: 0;
    padding-right: 0;
  }

  .vt-form {
    text-align: left;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: $mobile-width) {
      border-radius: 0;
    }
  }
}

.form-center-no-padding {
  padding-left: 0;
  padding-right: 0;

  .vt-form {
    text-align: left;
    margin-left: auto;
    margin-right: auto;
  }
}

md-input-container:not(.md-input-has-value) md-select[required]:not(.md-no-asterisk) .md-select-value span:first-child:after,
md-input-container:not(.md-input-has-value) md-select.ng-required:not(.md-no-asterisk) .md-select-value span:first-child:after {
  content: '';
}

md-select-menu.md-default-theme md-content md-option, md-select-menu md-content md-option {
  color: $input-default-color;
}

md-select-menu.md-default-theme md-content, md-select-menu md-content {
  background: transparent;
}

.margin-0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.m-0 {
  margin: 0 !important;
}

.tool-container {
  padding-left: 0px;
  padding-right: 0px;
}

.no-overflow {
  overflow: hidden;
}

md-tabs-canvas.md-paginated {
  margin: 0px 40px;
}

md-tabs-wrapper md-prev-button, md-tabs-wrapper md-next-button {
  top: 0px;
  transform: translateY(0px);
}

.dashboard-mechanics {
  max-width: $container-width-1200;
  padding: 8px;

  .vt-form.dashboard__mechanic__header {
    margin: 0px;
    width: 100%;
    max-width: 1200px;
  }
}

.pagination-buttons {
  overflow: hidden;

  @include floatClearBefore();
  @include floatClearAfter();

  .pagination__button {
    width: 50%;

    .md-button {
      border-radius: 18px;
      color: $vt-color-primary;
    }

    .md-button:focus,
    .md-button:hover,
    .md-button:active {
      border-radius: 18px;
      color: $input-active-color;
    }
  }

  .pagination__button_left {
    float: left;
    text-align: left;
  }

  .pagination__button_right {
    text-align: right;
    width: 100%;
  }
}

.requests-dashboard {
  background-color: $form-background;
  overflow: hidden;

  .request {
    display: block;
    background-color: $color-primary-l2;
  }

  .request__info {
    position: relative;
  }

  .request__desc {
    padding-right: 4.3em;
    color: #fff;

    .request_label {
      color: $vt-color-primary;
      text-transform: none;
    }

    .request_deadline_label {
      text-transform: none;
    }

    div {
      text-align: left;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      line-height: 2em;
    }
  }

  .request_cost-color {
    color: $vt-color-primary;
  }

  .request_empty-list {
    background: rgba(0, 0, 0, 0);
    min-height: 200px !important;

    h2 {
      text-align: center;
      padding-top: 70px;
      padding-bottom: 70px;
    }
  }

  .request_empty-list:hover {
    background: rgba(0, 0, 0, 0) !important;
  }

  .request__status {
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -1em;
    padding: 0 5px;
    line-height: 2;
    color: $vt-color-primary;

    .group_request {
      .request_cost-color {
        line-height: 11px;
      }

      .request_price_per_bike__label {
        line-height: 20px;
        font-size: 10px;
        color: white;
      }
    }

    .open {
      background-color: #667b9c;
      color: black;
      padding: 2px 10px;
      border-radius: 10px
    }

    .done,
    .resolved,
    .refunded {
      background-color: #2c9a57;
      color: black;
      padding: 2px 10px;
      border-radius: 8px;
    }

    .accepted {
      background-color: #d1a713;
      color: black;
      padding: 2px 10px;
      border-radius: 8px;
    }

    .expired {
      background-color: #3a3a3a;
      color: white;
      padding: 2px 10px;
      border-radius: 8px;
    }

    .cancelled,
    .could-not-be-fixed,
    .cancelled-with-charges {
      background: rgba(181, 57, 55, 0.87);
      color: white;
      padding: 2px 10px;
      border-radius: 8px;
    }

    .unsatisfied,
    .transfer-failed {
      background: rgba(154, 34, 32, 0.78);
      color: white;
      padding: 2px 10px;
      border-radius: 8px;
    }
  }

  .request.focus-marker,
  .request:active,
  .request:hover,
  .request:focus {
    background: $navbar-button-background-hover;
  }

  .loading-request {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    loading-indicator {
      md-progress-circular {
        margin-left: calc(50% - 50px);
        margin-top: 20%;
      }
    }
  }
}

.requests-search {
  overflow: hidden;

  .search-params {
    background-color: $form-background;
  }

  .md-button {
    color: $vt-color-primary;
  }
}

.fa-check.chosen-available-time:before,
.fa-check.chosen-available-time::before {
  color: $vt-color-primary;
}

.payment-form .back.md-button,
.back.md-button {
  margin-top: 20px;
}

.padding-top-35 {
  padding-top: 35px;
}

.padding-top-40 {
  padding-top: 40px;
}

.padding-top-15 {
  padding-top: 15px;
}

.no-horizontal-padding {
  padding-left: 0px;
  padding-right: 0px;
}

@media (min-width: 800px) {
  .dashboard-header .vt-form.dashboard .action-button-container {
    padding-top: 5px;
  }
}

.md-dialog-container {
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100% !important;
  top: 60px;
  position: fixed;
  padding-left: 5px;
  padding-right: 5px;
  z-index: 1500;
}

md-dialog {
  margin: 7% auto;
  position: relative;
  max-height: inherit;
  min-width: 300px;
  display: table;
}

md-dialog md-dialog-actions .md-button {
  margin-right: 7px;
}

.vt__header {
  background-color: $form-background;
  text-align: center;
  display: flex;
  align-items: center;
  color: $color-white;

  .vt__title {
    flex: 1 0 0%;
    min-width: 0;
  }

  div:first-child:nth-last-child(2) ~ div {
    margin-left: -51px;
  }

  .vt__left-button {
    text-align: left;
    flex: 0 0 0;
  }

  .vt__right-button {
    text-align: right;
    flex: 0 0 0;
  }

  @media (min-width: 420px) {
    .vt__left-button {
      padding-left: 10px;
    }

    .vt__right-button {
      padding-right: 10px;
    }
  }
}

.dashboard {
  max-width: 1200px;
  margin: 0 auto;
  overflow: hidden;
  padding-top: 2em;
  padding-bottom: 2em;

  .dashboard__header {
    min-height: 70px;
    margin-bottom: 1em;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: $form-background;
    padding-left: 20px;
    display: flex;
    align-items: center;
    color: $color-white;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

    .row,
    .col-xs-3,
    .col-xs-6 {
      height: 100%;
    }

    h1 {
      font-size: 1.5em;
      margin: 0;
    }

    .md-button {
      margin: 0;
      padding: 7px;
      border-radius: 50%;
      background-color: transparent;
      box-shadow: 0 0 0 0 !important;

      img {
        display: block;
        margin: 0 auto;
      }
    }

    .md-button:focus,
    .md-button:hover,
    .md-button:active {
      color: $input-active-color;
      background-color: rgba(200, 200, 200, 0.12) !important;
    }

    .md-button[disabled] span {
      color: $input-disabled-color;
    }

    .dashboard__right-square-button {
      border-radius: 0%;
      margin-right: 10px;
    }
  }

  .dashboard__search-form {
    background-color: $form-background;

    .horizontal-margin {
      margin-right: -5px;
      margin-left: -5px;
    }

    .dashboard__search-btn {
      margin: 0px 8px 8px 8px;
    }
  }

  .dashboard__search-without-error {
    .horizontal-margin.row {
      margin-right: 5px;
      margin-left: 5px;
    }

    .row {
      margin-left: -5px;
      margin-right: -5px;

      .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
        padding-left: 5px;
        padding-right: 5px;
      }
    }

    md-input-container {
      margin-bottom: 10px;

      .md-errors-spacer {
        min-height: 0px;
        min-width: 0px;
      }
    }
  }

  .dashboard_remove-top-margin {
    margin-top: -1em;
  }

  .dashboard__title {
    flex: 15 0 0;
    min-width: 0;
  }

  .dashboard_vertical-align {
    height: 100%;
    text-align: center;
    vertical-align: middle;
  }

  .dashboard_no-left-padding {
    padding-left: 0;
  }

  .dashboard_no-right-padding {
    padding-right: 0;
  }

  .dashboard__left-button {
    text-align: left;
    flex: 1 0 0;
  }

  .dashboard__right-button {
    text-align: right;
    flex: 0 0 0;
  }

  @media (min-width: 420px) {
    .dashboard__left-button {
      padding-left: 10px;
    }

    .dashboard__right-button {
      padding-right: 10px;
    }
  }
}

.dashboard-content {
  max-width: 1200px;
  margin: 0 auto;
  overflow: hidden;

  .show-bike-button {
    padding: 0px;
    margin: 0px;
    position: absolute;
    background-color: rgba(50, 50, 50, 0) !important;
    border-radius: 0px !important;
  }

  .show-bike-button:active,
  .show-bike-button:hover,
  .show-bike-button:focus {
    background-color: rgba(50, 50, 50, 0.4) !important;
  }
}

.search-button-img {
  width: 44px !important;
}

.dashboard-request-status {
  margin-top: -40px;

  span {
    color: $vt-color-primary;
  }
}

@media screen and (max-width: 599px) {
  .dashboard-request-status {
    margin-top: 0px;
  }
}

img-crop {
  min-width: 250px;
  min-height: 250px;
  width: 100%;
  height: 100%;
}

.profile, .main-desc-form {
  .review-row {
    padding-top: 10px;
  }
}

.review-row__vt_chip {
  margin: 2px;
  font-size: 15px;
  color: wheat;
}

.input-container_paddings {
  padding-left: 5px;
  padding-right: 5px;
}

.public-about-user-form {
  background-color: rgba(0, 0, 0, .9);
  color: rgb(245, 245, 245);
}

.row {
  .col-lt-3 .col-lt-4, .col-lt-6 {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 500px) {
  .row {
    .col-lt-3 {
      width: 25%;
      float: left;
    }

    .col-lt-4 {
      width: 33.33333%;
      float: left;
    }

    .col-lt-offset-4 {
      margin-left: 33.33333%;
    }

    .col-lt-6 {
      width: 50%;
      float: left;
    }

    .condition-box {
      margin-top: 1px;
    }
  }
}

@media (max-width: 499px) {
  .row {
    .col-lt-3, .col-lt-4, .col-lt-6 {
      padding-bottom: 15px;
      width: 100%;
    }

    .condition-box {
      margin-top: -1.5em;
    }
  }
}

.request-details {
  max-width: 650px;
  width: 100%;

  .request-detail__info {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.no-overflow-x {
  overflow-x: hidden;
}

md-dialog md-dialog-content h1,
md-dialog md-dialog-content:not([layout=row]) > *:first-child:not(.md-subheader) {
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

md-input-container .md-input,
.card-content,
.tab-container {
  -ms-flex-preferred-size: 100%;
}

md-select {
  height: 30px;
}

.md-select {
  -ms-flex-preferred-size: 100%;
  width: 100%;
  -ms-flex-direction: column;
  display: block;
}

.md-select-value, .md-select-value.md-select-placeholder {
  -ms-flex-preferred-size: 100%;
  -ms-flex-direction: column;
  display: block;

  span {
    float: left;
  }

  span.md-select-icon {
    position: absolute;
    right: 0;
    color: $vt-color-primary;
  }
}

.md-select-menu-container {
  display: block;
  z-index: 1501;
}

md-option {
  display: block;
  padding-top: 15px;
}

.images-content:after {
  content: "";
  display: table;
  clear: both;
}

@media (min-width: 768px) {
  .xs-show {
    display: none;
  }
}

.xs-left {
  text-align: left;
}

@media (max-width: 767px) {
  .xs-left {
    text-align: center;
  }

  .lg-show {
    display: none;
  }

  .pagination {
    padding-top: 10px;
  }
}

@media (min-width: 500px) {
  .lt-show {
    display: none;
  }

  .sm-show {
    display: inline-block;
  }
}

@media (max-width: 499px) {
  .lt-show {
    display: inline-block;
  }
  .sm-show {
    display: none;
  }

  .pagination .prev a, .pagination .next a {
    min-width: 30px;
    font-weight: bold;
  }
}

.padding-top-5 {
  padding-top: 5px;
}

.padding-right-5 {
  padding-right: 5px !important;
}

.padding-top-30 {
  padding-top: 30px !important;
}

.show-chosen-color-bike, .show-chosen-color {
  .width-100 {
    width: 100%;
  }

  .width-50 {
    width: 50%;
  }

  .width-33 {
    width: 33.3333%;
  }
}

.break-word {
  word-wrap: break-word;
}

#tooltip {
  z-index: 9999;
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  background-color: #333;
  color: white;
  font-size: 14px;
  text-align: justify;
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  width: 300px;
}

.tooltip_visible {
  display: block !important;
}

.image-view {
  position: relative;
  margin-left: 5px;
  margin-right: 5px;

  .loading-gif {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .image__prev,
  .image__next {
    position: absolute;
    top: 0;
    width: 20%;
    height: calc(100% - 3px);
    outline: none;
    border: none;
    opacity: 0;
    cursor: pointer;

    &:hover {
      background-color: rgba(0, 0, 0, 0.4);
      opacity: 1;
    }
  }

  .image__prev {
    left: 0;
    background: url("/img/gallery-arrow-left.png") no-repeat center center;
  }

  .image__next {
    right: 0;
    background: url("/img/gallery-arrow-right.png") no-repeat center center;
  }
}

@media (max-width: 420px) {
  .md-select-menu-container {
    left: 15px !important;
    right: 15px;
  }
}

.circle {
  border-radius: 50%;
  width: 200px;
  height: 200px;
}

.safari-height-vh {
  height: 1vh !important;
}

.safari-min-height-vh {
  min-height: 1vh !important;
}

.vt-padding {
  padding: 8px 8px 20px;
}

.vt-form.verification-form.add-insurance {
  max-width: 800px;

  md-tabs-wrapper {
    display: none;
  }

  .desc {
    text-align: justify;
  }

  .vertical-margin-30 {
    margin-top: 30px;
  }

  .choose-policy-card {
    border: 2px solid $input-default-color;
    border-radius: 5px;
    padding: 15px;
    -moz-padding-start: 16px;
    -moz-padding-end: 16px;
    margin-top: 30px;

    .md-button {
      color: #555;
      background: $vt-color-primary;
      width: 100%;
      margin: 15px 0px 0px;
    }

    .md-button:hover {
      color: #666;
      background: $vt-color-primary-hue-1;
    }
  }

  .green {
    color: #8BC34A;
  }

  .yellow {
    color: $vt-color-primary;
  }

  .policy-desc, .validate-policy-desc {
    font-size: 1.3em;
    margin: 0 10px;
  }

  .link {
    text-decoration: initial;
  }

  .insurance-document {
    text-align: center;
    margin-top: 20px !important;
  }

  .purchase-policy, .upload-policy-notes {
    margin: 3em 4em;
    text-align: justify;
    border-left: 2px solid $vt-color-primary;
    padding-left: 2em;
  }

  @media (max-width: 500px) {
    .purchase-policy, .upload-policy-notes {
      margin: 3em 1em;
    }
  }

  .validate-policy-desc {
    margin-top: 5em;
    margin-bottom: 5em;
  }

  .upload-desc {
    margin: 0 10px;
  }

  .amount {
    margin-top: 80px;
  }

  .terms {
    width: auto;
    text-align: left;
    margin-top: 70px;
    margin-bottom: 10px;
  }

  .upload-desc.insurance-smth {
    margin: 10px 10px 40px 50px;
  }
}

.insurance-status {
  .expired {
    color: #EF9A9A;
  }

  .active {
    color: #66BB6A;
  }

  .pending {
    color: #FFE082;
  }

  .in-future {
    color: #E0E0E0;
  }
}

.date-label {
  margin-left: 10px;
  color: $input-default-color;
}

.update-insurance-field {
  padding-left: 10px;
  padding-right: 10px;
}

.about-gallery-message {
  background-color: #222;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 4px;
}

md-datepicker.disable-date-input {
  .md-datepicker-input {
    display: none;
  }
}

.deadline {
  padding-bottom: 20px;
}

.time-picker-container {
  .disable-input-container {
    margin-right: -80px !important;
    padding-right: 0;

    .md-datepicker-button {
      .md-datepicker-calendar-icon {
        line-height: 26px;
      }
    }

    .md-datepicker-input-container {
      width: 45px !important;

      .md-datepicker-input {
        display: none;
      }
    }
  }

  .date-picket-label {
    font-size: 0.9em;
    margin-top: 20px;
    top: 1px;
    position: relative;
    vertical-align: middle;
  }
}

.md-datepicker-input-mask {
  display: none;
}

.rzslider[disabled] .rz-pointer, .rzslider[disabled] .rz-pointer:after {
  background-color: $footer-color-link-hover;
}

.img-tmb.img-tmb-pdf {
  max-width: 250px;
}

.mechanic-agreement-label {
  color: $vt-color-primary;
  text-align: justify;
  padding: 30px 15px 0px 15px;
}

.italic {
  font-style: italic;
}

.mechanic-agreement-desc-label {
  color: $input-default-color;
  text-align: justify;
  padding: 30px 15px 0px 15px;
}

.mechanic-agreement-body {
  background: #FFFFFF;
  display: inline-block;
  width: 100%;
  height: 400px;
  overflow-y: auto;
}

.bootstrap-side-paddings {
  @include bootstrap-container-paddings();
}

.paging-info {
  padding-top: 6px;
}

.paging-size-input-container {
  margin: 0px;
  width: 65px;
}

.custom-filter-select-container {
  margin: 6px 0px;
}

.phone-link {
  color: $vt-color-primary;
  text-decoration: initial;
}

.phone-link:hover {
  color: $vt-color-primary-hue-1;
}

.add-buttons-fab.serial-number-button.zip-info {
  .md-fab {
    margin: 0px;
    margin-top: 20px;
  }
}

md-input-container.disabled-email {
  .md-input[disabled] {
    color: $label-color;
  }
}

.date-of-birth-label {
  color: $label-color;
  font-size: 14px;
  padding-left: 2px;
}

md-input-container.md-input-invalid [ng-message] {
  color: $input-error-color;
  font-size: 16px;
}

md-input-container:not(.md-input-invalid) [ng-message] {
  display: none;
}

.forgot-password {
  margin: 9px 0px;
  font-size: 15px;
}

#freshwidget-frame {
  max-width: 100%;
}

.fd-btn-left a.freshwidget-theme {
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, .1), inset 0 -1px 0 rgba(0, 0, 0, .08), inset 0 -2px 0 rgba(0, 0, 0, 0.9), inset 0 9px 29px rgba(0, 0, 0, .1) !important;
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, .1), inset 0 -1px 0 rgba(0, 0, 0, .08), inset 0 -2px 0 rgba(0, 0, 0, 0.9), inset 0 9px 29px rgba(0, 0, 0, .1) !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, .1), inset 0 -1px 0 rgba(0, 0, 0, .08), inset 0 -2px 0 rgba(0, 0, 0, 0.9), inset 0 9px 29px rgba(0, 0, 0, .1) !important;
}

.sso {
  .sso-grid {
    text-align: center;
    max-width: 150px;
    margin: 0 auto;

    .md-button {
      float: left;
      cursor: pointer;
      background-color: transparent;
      transition: all 0.1s cubic-bezier(0.55, 0, 0.55, 0.2);

      i {
        color: white;
      }
    }

    .facebook {
      background-color: #3b5998;

      &:hover {
        background-color: #4b69a8;
      }
    }

    .twitter {
      background-color: #1da1f2;

      &:hover {
        background-color: #2db1ff;
      }
    }

    .linkedin {
      background-color: #0077b5;

      &:hover {
        background-color: #1087c5;
      }
    }

    .googleplus {
      background-color: #dd4b39;

      &:hover {
        background-color: #ed5b49;
      }
    }

    i {
      font-size: 24px;
      display: block;
      color: #7F7F7F;
      border: none !important;
      outline: none !important;
    }

    @include floatClearAfter();

    @media (min-width: 400px) {
      max-width: 300px;
    }
  }

  .bottom-underlined {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    overflow: hidden;
    font-size: .9em;
    color: #7F7F7F;

    &:before,
    &:after {
      content: '';
      display: inline-block;
      vertical-align: middle;
      box-sizing: border-box;
      width: 100%;
      height: 1px;
      background: #7F7F7F;
    }

    &:before {
      margin-left: -100%;
      margin-right: 20px;
    }

    &:after {
      margin-right: -100%;
      margin-left: 20px;
    }
  }

}

.margin-top-10 {
  margin-top: 10px;
}

.login-form {
  h1 {
    font-size: 1.5em;
    color: white;
  }

  @media (min-width: 768px) {
    max-width: 580px;
    padding-left: 90px;
    padding-right: 90px;
  }

}

.single-signon {

  text-align: center;
  color: white !important;
  background-color: $form-background;
  max-width: 700px;
  margin: 0 auto;
  padding-bottom: 1em;
  margin-bottom: 30px;

  h2 {
    font-size: 2em;
    margin: 0;
    padding: 1.5em 0;
  }

  .md-button.registration-link {
    width: 250px;
    font-size: .8em;
    border: 2px solid $color-main;
    background-color: $color-main;
    color: black;
    text-transform: uppercase;
    font-weight: 600;
    transition: box-shadow 0.9s cubic-bezier(0.25, 0.8, 0.25, 1), background-color 0.9s cubic-bezier(0.25, 0.8, 0.25, 1);
  }

  a.registration-link.md-button:hover {
    background-color: rgba(255, 235, 59, 0.7);
  }

  .sso-mechanic-icon,
  .sso-user-icon {
    margin: 0 auto;
    width: 80px;
    height: 80px;
    background: no-repeat center center;
    background-size: contain;
  }

  .sso-mechanic-icon {
    background-image: url("/img/ic_mech.svg");
  }

  .sso-user-icon {
    background-image: url("/img/ic_user.svg");
  }

  .sso-description {
    max-width: 350px;
    margin: 0 auto;
    text-align: center;
    padding: 20px 0;
  }

  .col-sm-6 {
    margin-top: 40px
  }
}

.chart-container {
  background: rgba(100, 100, 100, 0.1);
  margin-top: 10px;
}

.general-statistic {
  padding-bottom: 10px;
}

.vt-form.general-statistic {
  max-width: 1000px;
}

.input-indent-for-icon {
  padding-right: 30px !important;
}

.toggle-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px;
  box-sizing: border-box;
  outline: 0;
  color: white;
  cursor: pointer;
}

.youAre-select {
  margin-bottom: 20px;

  .md-select-value {
    padding-right: 18px;

    span:first-child {
      width: 100%;
    }
  }
}

.white-logo.nav-bar-height {
  display: none;
}

.show-request {
  padding-left: 20px;
  padding-right: 20px;

  .hgroup-sides-margin {
    margin-left: -20px;
    margin-right: -20px;
  }

  @media(max-width: 470px) {
    margin: 0;
  }
}

body .smartbanner {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 9999;
}

@media (min-width: 800px) {
  .smartbanner-show {
    margin-top: 0 !important;
  }
  .smartbanner {
    margin-top: 0;
  }

  html[data-smartbanner-original-margin-top] {
    margin-top: 0 !important;
  }

  body .smartbanner {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: auto;
    z-index: 999;
  }
}

.sending-messages-row {
  line-height: 2.4;

  .md-gap {
    border-top-color: $vt-color-primary !important;
    border-bottom-color: $vt-color-primary !important;
  }

  .md-left {
    .md-half-circle {
      border-top-color: $vt-color-primary !important;
      border-left-color: $vt-color-primary !important;
    }
  }

  .md-right {
    .md-half-circle {
      border-top-color: $vt-color-primary !important;
      border-right-color: $vt-color-primary !important;
    }
  }
}

.clearfloat {
  display: none;
  clear: both;
}

.video-dialog {
  width: 100%;
  max-width: 840px;

  .modal-video-frame {
    height: 0;
    position: relative;
    padding-bottom: 56.25%;

    iframe {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      max-width: none;
    }
  }
}

.disabled-email {
  .md-button {
    display: none;
    font-size: .7em !important;
    min-height: 30px;
  }

  button.done, button.done span {
    color: $done-button-text-color !important;
  }

  button:not([disabled]):hover {
    background-color: rgba(68, 68, 68, 0.2);
  }

  button.warning, button.warning span {
    color: $warning-button-text-color !important;
  }

  i.toggle-button.done {
    color: $done-button-text-color !important;
  }

  i.toggle-button.warning {
    color: $warning-button-text-color !important;
  }

  @media (min-width: 480px) {
    .input-indent-for-icon {
      padding-right: 90px !important;
    }
    .md-button {
      display: block;
    }
    i.toggle-button {
      display: none;
    }
  }
}

.show-message-dialog-content {
  color: $input-default-color;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-14 {
  font-size: 14px;
}

.i-have-question {
  margin: 40px 0px;
  text-align: center;
}

form .vt-button.md-button.remove-button {
  color: $input-remove-color;
}

form .vt-button.md-button.remove-button:hover {
  color: $input-active-remove-color;
}

md-dialog-content {
  .some-text {
    color: $input-default-color;
  }
}

.bike-components {
  md-input-container .md-input[disabled] {
    color: $label-color;
  }
}

md-backdrop.md-opaque {
  opacity: 0.9;
}

.custom-style {
  background-color: #222;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 20px;
}

.img-upload-indicator {
  color: wheat;
  padding-right: 20px;

  span {
    font-size: 0.8em;
  }
}

.mechanics-google-map {
  width: 100%;
  height: calc(100vh - 64px);
  position: relative;
}

.map-buttons {
  position: absolute;

  .md-primary.md-button.back-button {
    background: $form-background;
    z-index: 20;
    margin: 8px 8px;
    color: white !important;
  }

  .md-primary.md-button.back-button.active {
    background: $header-background;
  }
}

md-radio-button .md-on {
  background-color: $vt-color-primary;
}

md-radio-button.md-checked .md-off {
  border-color: $vt-color-primary;
}

.clearfloat {
  display: none;
  clear: both;
}

.video-dialog {
  width: 100%;
  max-width: 840px;

  .modal-video-frame {
    height: 0;
    position: relative;
    padding-bottom: 56.25%;

    iframe {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      max-width: none;
    }
  }
}

md-tabs md-ink-bar {
  background: $color-main;
  color: $color-main;
}

.vt-form.add-organisation,
.vt-form.edit-organisation,
.vt-form.add-station,
.vt-form.edit-station {
  width: 100%;
  padding-bottom: 15px;

  .avatar {
    padding-top: 10px;

    .current {
      max-width: 100%;
      max-height: 420px;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.39);
    }
  }

  .center {
    text-align: center;
  }
}

@media (max-width: 767px) {
  .mobile-center {
    text-align: center;
  }
}

.vt-form.add-organisation, .vt-form.add-station {
  max-width: 600px;
}

.vt-form.edit-organisation, .vt-form.edit-station {
  max-width: $container-width-1200;
}

.no-avatar {
  max-width: 300px;
  width: 100%;
}

.importing-file-name {
  color: $input-default-color;
}

.vt-action-button {
  padding: 6px 17px;
  min-height: auto;
  line-height: normal;
  border: 1px solid #444;
  color: #999;
  border-radius: 6px;
  font-size: 0.8em;
  overflow: visible;
  cursor: pointer;
  margin: 3px;

  &:disabled {
    border: 1px solid #333;
    cursor: default;
    background: none;

    span {
      color: #333;
    }

    &:hover {
      background: none;
    }
  }

  &.yellow-button:not(:disabled) {
    background: none;
    color: #333;
    border: none;

    &:hover {
      background: none;
    }
  }

  &.green-button:not(disabled) {
    background-color: #4caa77;
    color: black;
    border: none;

    &:hover {
      background-color: #6cca97;
    }
  }

  span {
    vertical-align: baseline !important;
  }
}

.not-happy {
  margin-bottom: 1em;

  a {
    font-size: 0.7em;
    text-decoration: none;
    color: $vt-color-primary;
  }

  a:hover {
    color: $vt-color-primary-hue-1;
  }
}

md-toolbar {
  .inline-block {
    display: inline-block;

    &.nav-button {
      padding-top: 12px !important;
    }

    .request-button-container {
      padding-right: 45px;
      margin-right: 20px;
      border-right: 1px solid #303030;
    }
  }
}

md-tabs-canvas {
  border-bottom: 1px solid $color-primary-l3;
}

.not-specified {
  color: #777;
  font-size: 1em;
  font-weight: normal;
}

.confirmation-link {
  font-size: 0.9em;
  color: $input-default-color;
}

mdp-calendar {
  button.md-button.md-raised {
    border: 0;
    padding: 0;
    margin: 0;
  }
}

.ng-hide-add, .ng-hide-remove {
  display: block !important;
}

.show-components {
  padding: 0;
  transition: .4s linear all;
  max-height: 100%;
  overflow: hidden;
  border-top: 1px solid #333;
  margin-top: 10px;
}

.show-components.ng-hide {
  max-height: 0;
}

button.md-button.md-raised.show-bikes-button {
  margin-left: auto;
  margin-right: auto;
}

.main-desc-form {
  .md-button.cancel-request-btn {
    color: black;
    background-color: $vt-color-primary;
  }

  .md-button.cancel-request-btn:hover {
    background-color: $vt-color-primary-hue-1 !important;
  }
}

.autocomplete__item-text {
  vertical-align: top;
}

.additional-components-form {
  textarea:disabled {
    color: white;
  }
}

.certificate-notes {
  textarea:disabled + .md-errors-spacer {
    display: none;
  }

  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  border: 1px solid #292929;
  padding: 10px;
}

md-autocomplete[md-floating-label] md-input-container {
  padding-bottom: 0px;
  margin-bottom: 18px;
}

md-progress-linear.md-default-theme .md-bar, md-progress-linear .md-bar {
  background-color: rgb(16, 108, 200) !important;
}

md-progress-linear.md-default-theme .md-container, md-progress-linear .md-container {
  background-color: rgb(170, 209, 249) !important;
}

.md-virtual-repeat-container.md-autocomplete-suggestions-container {
  background-color: rgba(0, 0, 0, 0);

  ul.md-autocomplete-suggestions {
    li {
      background-color: #333;

      md-autocomplete-parent-scope {
        span {
          color: $input-default-color;

          span.highlight {
            color: $input-active-color;
          }
        }
      }
    }

    li.selected,
    li:hover,
    li:active,
    li:focus {
      background-color: rgba(30, 30, 0, 1);
    }
  }
}

.md-select-menu-container {
  position: fixed;
}

.total-statistics {

  .total-statistics__row {
    margin: 5px;
  }

  .total-statistics__item {
    padding: 0;
  }

  .total-statistics__clock {
    text-align: center;
    color: #999;
    padding: 10px 10px 0 0;
  }
}

.margin-right15 {
  margin-right: 15px;
}

.margin-left15 {
  margin-left: 15px;
}

.padding0 {
  padding: 0 !important;
}

.padding-bottom17 {
  padding-bottom: 17px;
}

.padding-bottom18 {
  padding-bottom: 18px;
}

.padding-left0 {
  padding-left: 0 !important;
}

.padding-right0 {
  padding-right: 0 !important;;
}

.padding-right20 {
  padding-right: 20px;
}

.padding-left20 {
  padding-left: 20px;
}

.padding-left15 {
  padding-left: 15px;
}

.padding-bottom15 {
  padding-bottom: 15px;
}

.color-main {
  color: $color-main;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.vert-middle {
  vertical-align: middle;
}

.md-virtual-repeat-container.md-autocomplete-suggestions-container {
  z-index: 1501;
}

.padding-5 {
  padding: 5px !important;
}

.padding-4 {
  padding: 4px;
}

.no-copy {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.display_inherit {
  display: inherit !important;
}

.user-connection-indicator {
  position: absolute;
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background-color: #07ff07;
  top: 69px;
  left: 39px;
  border: 3px solid #191919;
}

input[type="number"] {
  -moz-appearance: textfield !important;
}

.position_relative {
  position: relative;
}

.no-scroll {
  overflow-y: hidden !important;
}

::-webkit-input-placeholder {
  color: #666 !important;
}

::-moz-placeholder { /* Firefox 19+ */
  color: #666 !important;
}

:-ms-input-placeholder { /* IE 10+ */
  color: #666 !important;
}

:-moz-placeholder { /* Firefox 18- */
  color: #666 !important;
}

.margin-bottom-35 {
  margin-bottom: 35px;
}

md-menu-item > a.md-button {
  padding: 10px 16px !important;
}

.reset-password__label {
  font-size: 14px;
  line-height: 15px;
  color: white;
  margin-right: 5px;
  background-color: rgba(50, 50, 50, 0.5);
  padding: 15px;
  border-radius: 4px;
}

.padding-right50 {
  padding-right: 50px !important;
}

.padding-right60 {
  padding-right: 60px !important;
}

.disabled {
  pointer-events: none;
}

.color_green {
  color: $done-button-text-color !important;
}

.remove-task {
  .remove-button {
    margin-top: 20px;
    margin-bottom: 0;
  }
}

.additional-description__color-label {
  text-align: center;
  font-size: 19px;
  padding-top: 20px;
}

.md-dialog-container {
  padding: 0;
}

.md-open-menu-container {
  z-index: 1501;
}

.margin-auto {
  margin: auto !important;
}

.tooltip_visible {
  white-space: pre-line;
}

.grecaptcha-badge {
  display: none !important;
}

.position-absolute {
  position: absolute;
}
