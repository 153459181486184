.phone-number {
  padding: 2px !important;
  width: 74% !important;
  margin-left: 0 !important;
}

.phone-country {
  width: 25% !important;
  float: left;
  margin-right: 1% !important;
  padding-left: 2px !important;
  padding-right: 2px !important;
}

.iti-flag {
  margin: 2px;
  width: 16px;
  height: 11px;
  background: url(/img/flags.png);
}

.iti-flag.AU {
  background-position: -192px 0;
}

.iti-flag.US {
  background-position: -112px -143px
}

.iti-flag.CA {
  background-position: -16px -22px;
}

.iti-flag.UK {
  background-position: -80px -44px;
}

.iti-flag.DE {
  background-position: -32px -33px;
}

.iti-flag.FR {
  background-position: -48px -44px;
}

.iti-flag.CH {
  background-position: -80px -22px;
}

.iti-flag.IT {
  background-position: -32px -66px;
}

.iti-flag.ES {
  background-position: -192px -33px;
}

.iti-flag.IE {
  background-position: -176px -55px;
}

.iti-flag.BE {
  background-position: -32px -11px;
}

.iti-flag.NL {
  background-position: -128px -99px;
}

.iti-flag.DK {
  background-position: -64px -33px;
}
