.landing-page-grid-two {

  .landing-page-grid-two__col {
    width: 48%;
    max-width: 530px;

    @media (max-width: 991px) {
      width: 100%;
      max-width: 490px;
      margin-left: auto;
      margin-right: auto;
    }
  }

}
