@import "../../../velotooler-app.scss";

.vui-image-slider {

  position: relative;

  md-virtual-repeat-container {
    height: 120px;
    margin: 10px 0;
    width: 100%;

    .md-virtual-repeat-scroller {
      &::-webkit-scrollbar {
        display: none;
      }
      background: none;
    }
  }

  .md-orient-horizontal .md-virtual-repeat-scroller {
    overflow-x: hidden !important;
  }

  .vui-image-slider-btn {
    margin: 0 !important;
    min-width: 0 !important;
    height: 120px !important;
    width: 36px;
    border-radius: 0;
    position: absolute;
    top: 0;
    background-color: $color-primary-l3;
    z-index: 1;

    &:hover {
      background-color: $color-primary-l4;
    }

    &:disabled {
      background-color: #1f1f1f;
    }

    i {
      font-size: 20px;
      color: white;
    }
  }

  .vui-image-slider-btn_left {
    left: 0;
  }

  .vui-image-slider-btn_right {
    right: 0;
  }

  .vui-image-slider__photos-container {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    margin: 0 4px;
  }

  .vui-image-slider__photos-container-item {
    font-size: 20px;
    width: 120px;
    height: 120px;
    text-align: center;
    cursor: pointer;
  }
}