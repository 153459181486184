i.smile {
  float: left;
  margin: 6px;
}

.smile {
  width: 16px;
  height: 16px;
  margin: 2px;
  background: url("/img/chat/smiles.png") no-repeat;
}

img.smile {
  margin: 0 1px -2px 1px;
}

.smile:hover {
  opacity: 0.8;
  cursor: pointer;
}

.smile-23F0 {
  background-position: -0px -0px;
}

.smile-23F3 {
  background-position: -16px -0px;
}

.smile-2600 {
  background-position: -32px -0px;
}

.smile-2601 {
  background-position: -48px -0px;
}

.smile-260E {
  background-position: -64px -0px;
}

.smile-2615 {
  background-position: -80px -0px;
}

.smile-261D {
  background-position: -96px -0px;
}

.smile-263A {
  background-position: -112px -0px;
}

.smile-267B {
  background-position: -128px -0px;
}

.smile-26A0 {
  background-position: -144px -0px;
}

.smile-26A1 {
  background-position: -160px -0px;
}

.smile-26BD {
  background-position: -176px -0px;
}

.smile-26BE {
  background-position: -192px -0px;
}

.smile-26C4 {
  background-position: -208px -0px;
}

.smile-26C5 {
  background-position: -224px -0px;
}

.smile-26D4 {
  background-position: -240px -0px;
}

.smile-26EA {
  background-position: -256px -0px;
}

.smile-26F3 {
  background-position: -272px -0px;
}

.smile-26F5 {
  background-position: -288px -0px;
}

.smile-26FD {
  background-position: -304px -0px;
}

.smile-2702 {
  background-position: -320px -0px;
}

.smile-2708 {
  background-position: -336px -0px;
}

.smile-2709 {
  background-position: -352px -0px;
}

.smile-270A {
  background-position: -368px -0px;
}

.smile-270B {
  background-position: -0px -16px;
}

.smile-270C {
  background-position: -16px -16px;
}

.smile-270F {
  background-position: -32px -16px;
}

.smile-2712 {
  background-position: -48px -16px;
}

.smile-2728 {
  background-position: -64px -16px;
}

.smile-2744 {
  background-position: -80px -16px;
}

.smile-2764 {
  background-position: -96px -16px;
}

.smile-D83CDC04 {
  background-position: -112px -16px;
}

.smile-D83CDCCF {
  background-position: -128px -16px;
}

.smile-D83CDD98 {
  background-position: -144px -16px;
}

.smile-D83CDF02 {
  background-position: -160px -16px;
}

.smile-D83CDF0D {
  background-position: -176px -16px;
}

.smile-D83CDF1B {
  background-position: -192px -16px;
}

.smile-D83CDF1D {
  background-position: -208px -16px;
}

.smile-D83CDF1E {
  background-position: -224px -16px;
}

.smile-D83CDF1F {
  background-position: -240px -16px;
}

.smile-D83CDF30 {
  background-position: -256px -16px;
}

.smile-D83CDF31 {
  background-position: -272px -16px;
}

.smile-D83CDF32 {
  background-position: -288px -16px;
}

.smile-D83CDF33 {
  background-position: -304px -16px;
}

.smile-D83CDF34 {
  background-position: -320px -16px;
}

.smile-D83CDF35 {
  background-position: -336px -16px;
}

.smile-D83CDF37 {
  background-position: -352px -16px;
}

.smile-D83CDF38 {
  background-position: -368px -16px;
}

.smile-D83CDF39 {
  background-position: -0px -32px;
}

.smile-D83CDF3A {
  background-position: -16px -32px;
}

.smile-D83CDF3B {
  background-position: -32px -32px;
}

.smile-D83CDF3C {
  background-position: -48px -32px;
}

.smile-D83CDF3D {
  background-position: -64px -32px;
}

.smile-D83CDF3E {
  background-position: -80px -32px;
}

.smile-D83CDF3F {
  background-position: -96px -32px;
}

.smile-D83CDF40 {
  background-position: -112px -32px;
}

.smile-D83CDF41 {
  background-position: -128px -32px;
}

.smile-D83CDF42 {
  background-position: -144px -32px;
}

.smile-D83CDF43 {
  background-position: -160px -32px;
}

.smile-D83CDF44 {
  background-position: -176px -32px;
}

.smile-D83CDF45 {
  background-position: -192px -32px;
}

.smile-D83CDF46 {
  background-position: -208px -32px;
}

.smile-D83CDF47 {
  background-position: -224px -32px;
}

.smile-D83CDF48 {
  background-position: -240px -32px;
}

.smile-D83CDF49 {
  background-position: -256px -32px;
}

.smile-D83CDF4A {
  background-position: -272px -32px;
}

.smile-D83CDF4B {
  background-position: -288px -32px;
}

.smile-D83CDF4C {
  background-position: -304px -32px;
}

.smile-D83CDF4D {
  background-position: -320px -32px;
}

.smile-D83CDF4E {
  background-position: -336px -32px;
}

.smile-D83CDF4F {
  background-position: -352px -32px;
}

.smile-D83CDF50 {
  background-position: -368px -32px;
}

.smile-D83CDF51 {
  background-position: -0px -48px;
}

.smile-D83CDF52 {
  background-position: -16px -48px;
}

.smile-D83CDF53 {
  background-position: -32px -48px;
}

.smile-D83CDF54 {
  background-position: -48px -48px;
}

.smile-D83CDF55 {
  background-position: -64px -48px;
}

.smile-D83CDF56 {
  background-position: -80px -48px;
}

.smile-D83CDF57 {
  background-position: -96px -48px;
}

.smile-D83CDF5A {
  background-position: -112px -48px;
}

.smile-D83CDF5B {
  background-position: -128px -48px;
}

.smile-D83CDF5C {
  background-position: -144px -48px;
}

.smile-D83CDF5D {
  background-position: -160px -48px;
}

.smile-D83CDF5E {
  background-position: -176px -48px;
}

.smile-D83CDF5F {
  background-position: -192px -48px;
}

.smile-D83CDF60 {
  background-position: -208px -48px;
}

.smile-D83CDF61 {
  background-position: -224px -48px;
}

.smile-D83CDF62 {
  background-position: -240px -48px;
}

.smile-D83CDF63 {
  background-position: -256px -48px;
}

.smile-D83CDF64 {
  background-position: -272px -48px;
}

.smile-D83CDF65 {
  background-position: -288px -48px;
}

.smile-D83CDF66 {
  background-position: -304px -48px;
}

.smile-D83CDF67 {
  background-position: -320px -48px;
}

.smile-D83CDF68 {
  background-position: -336px -48px;
}

.smile-D83CDF69 {
  background-position: -352px -48px;
}

.smile-D83CDF6A {
  background-position: -368px -48px;
}

.smile-D83CDF6B {
  background-position: -0px -64px;
}

.smile-D83CDF6C {
  background-position: -16px -64px;
}

.smile-D83CDF6D {
  background-position: -32px -64px;
}

.smile-D83CDF6E {
  background-position: -48px -64px;
}

.smile-D83CDF6F {
  background-position: -64px -64px;
}

.smile-D83CDF70 {
  background-position: -80px -64px;
}

.smile-D83CDF71 {
  background-position: -96px -64px;
}

.smile-D83CDF72 {
  background-position: -112px -64px;
}

.smile-D83CDF73 {
  background-position: -128px -64px;
}

.smile-D83CDF74 {
  background-position: -144px -64px;
}

.smile-D83CDF75 {
  background-position: -160px -64px;
}

.smile-D83CDF76 {
  background-position: -176px -64px;
}

.smile-D83CDF77 {
  background-position: -192px -64px;
}

.smile-D83CDF78 {
  background-position: -208px -64px;
}

.smile-D83CDF79 {
  background-position: -224px -64px;
}

.smile-D83CDF7A {
  background-position: -240px -64px;
}

.smile-D83CDF7B {
  background-position: -256px -64px;
}

.smile-D83CDF7C {
  background-position: -272px -64px;
}

.smile-D83CDF80 {
  background-position: -288px -64px;
}

.smile-D83CDF81 {
  background-position: -304px -64px;
}

.smile-D83CDF82 {
  background-position: -320px -64px;
}

.smile-D83CDF83 {
  background-position: -336px -64px;
}

.smile-D83CDF84 {
  background-position: -352px -64px;
}

.smile-D83CDF85 {
  background-position: -368px -64px;
}

.smile-D83CDF88 {
  background-position: -0px -80px;
}

.smile-D83CDF89 {
  background-position: -16px -80px;
}

.smile-D83CDF8A {
  background-position: -32px -80px;
}

.smile-D83CDF8B {
  background-position: -48px -80px;
}

.smile-D83CDF8C {
  background-position: -64px -80px;
}

.smile-D83CDF8D {
  background-position: -80px -80px;
}

.smile-D83CDF8E {
  background-position: -96px -80px;
}

.smile-D83CDF8F {
  background-position: -112px -80px;
}

.smile-D83CDF90 {
  background-position: -128px -80px;
}

.smile-D83CDF92 {
  background-position: -144px -80px;
}

.smile-D83CDF93 {
  background-position: -160px -80px;
}

.smile-D83CDFA3 {
  background-position: -176px -80px;
}

.smile-D83CDFA4 {
  background-position: -192px -80px;
}

.smile-D83CDFA7 {
  background-position: -208px -80px;
}

.smile-D83CDFA8 {
  background-position: -224px -80px;
}

.smile-D83CDFA9 {
  background-position: -240px -80px;
}

.smile-D83CDFAA {
  background-position: -256px -80px;
}

.smile-D83CDFAB {
  background-position: -272px -80px;
}

.smile-D83CDFAC {
  background-position: -288px -80px;
}

.smile-D83CDFAD {
  background-position: -304px -80px;
}

.smile-D83CDFAF {
  background-position: -320px -80px;
}

.smile-D83CDFB0 {
  background-position: -336px -80px;
}

.smile-D83CDFB1 {
  background-position: -352px -80px;
}

.smile-D83CDFB2 {
  background-position: -368px -80px;
}

.smile-D83CDFB3 {
  background-position: -0px -96px;
}

.smile-D83CDFB4 {
  background-position: -16px -96px;
}

.smile-D83CDFB7 {
  background-position: -32px -96px;
}

.smile-D83CDFB8 {
  background-position: -48px -96px;
}

.smile-D83CDFB9 {
  background-position: -64px -96px;
}

.smile-D83CDFBA {
  background-position: -80px -96px;
}

.smile-D83CDFBB {
  background-position: -96px -96px;
}

.smile-D83CDFBD {
  background-position: -112px -96px;
}

.smile-D83CDFBE {
  background-position: -128px -96px;
}

.smile-D83CDFBF {
  background-position: -144px -96px;
}

.smile-D83CDFC0 {
  background-position: -160px -96px;
}

.smile-D83CDFC1 {
  background-position: -176px -96px;
}

.smile-D83CDFC2 {
  background-position: -192px -96px;
}

.smile-D83CDFC3 {
  background-position: -208px -96px;
}

.smile-D83CDFC4 {
  background-position: -224px -96px;
}

.smile-D83CDFC6 {
  background-position: -240px -96px;
}

.smile-D83CDFC7 {
  background-position: -256px -96px;
}

.smile-D83CDFC8 {
  background-position: -272px -96px;
}

.smile-D83CDFC9 {
  background-position: -288px -96px;
}

.smile-D83CDFCA {
  background-position: -304px -96px;
}

.smile-D83CDFE6 {
  background-position: -320px -96px;
}

.smile-D83DDC00 {
  background-position: -336px -96px;
}

.smile-D83DDC01 {
  background-position: -352px -96px;
}

.smile-D83DDC02 {
  background-position: -368px -96px;
}

.smile-D83DDC03 {
  background-position: -0px -112px;
}

.smile-D83DDC04 {
  background-position: -16px -112px;
}

.smile-D83DDC05 {
  background-position: -32px -112px;
}

.smile-D83DDC06 {
  background-position: -48px -112px;
}

.smile-D83DDC07 {
  background-position: -64px -112px;
}

.smile-D83DDC08 {
  background-position: -80px -112px;
}

.smile-D83DDC09 {
  background-position: -96px -112px;
}

.smile-D83DDC0A {
  background-position: -112px -112px;
}

.smile-D83DDC0B {
  background-position: -128px -112px;
}

.smile-D83DDC0C {
  background-position: -144px -112px;
}

.smile-D83DDC0D {
  background-position: -160px -112px;
}

.smile-D83DDC0E {
  background-position: -176px -112px;
}

.smile-D83DDC0F {
  background-position: -192px -112px;
}

.smile-D83DDC10 {
  background-position: -208px -112px;
}

.smile-D83DDC11 {
  background-position: -224px -112px;
}

.smile-D83DDC12 {
  background-position: -240px -112px;
}

.smile-D83DDC13 {
  background-position: -256px -112px;
}

.smile-D83DDC18 {
  background-position: -272px -112px;
}

.smile-D83DDC1C {
  background-position: -288px -112px;
}

.smile-D83DDC28 {
  background-position: -304px -112px;
}

.smile-D83DDC2B {
  background-position: -320px -112px;
}

.smile-D83DDC2E {
  background-position: -336px -112px;
}

.smile-D83DDC31 {
  background-position: -352px -112px;
}

.smile-D83DDC36 {
  background-position: -368px -112px;
}

.smile-D83DDC37 {
  background-position: -0px -128px;
}

.smile-D83DDC3B {
  background-position: -16px -128px;
}

.smile-D83DDC3C {
  background-position: -32px -128px;
}

.smile-D83DDC42 {
  background-position: -48px -128px;
}

.smile-D83DDC43 {
  background-position: -64px -128px;
}

.smile-D83DDC46 {
  background-position: -80px -128px;
}

.smile-D83DDC47 {
  background-position: -96px -128px;
}

.smile-D83DDC48 {
  background-position: -112px -128px;
}

.smile-D83DDC4A {
  background-position: -128px -128px;
}

.smile-D83DDC4C {
  background-position: -144px -128px;
}

.smile-D83DDC4D {
  background-position: -160px -128px;
}

.smile-D83DDC4E {
  background-position: -176px -128px;
}

.smile-D83DDC4F {
  background-position: -192px -128px;
}

.smile-D83DDC79 {
  background-position: -208px -128px;
}

.smile-D83DDC7A {
  background-position: -224px -128px;
}

.smile-D83DDC7D {
  background-position: -240px -128px;
}

.smile-D83DDC7F {
  background-position: -256px -128px;
}

.smile-D83DDC8B {
  background-position: -272px -128px;
}

.smile-D83DDC94 {
  background-position: -288px -128px;
}

.smile-D83DDCA6 {
  background-position: -304px -128px;
}

.smile-D83DDCA9 {
  background-position: -320px -128px;
}

.smile-D83DDCAA {
  background-position: -336px -128px;
}

.smile-D83DDCAD {
  background-position: -352px -128px;
}

.smile-D83DDD1E {
  background-position: -368px -128px;
}

.smile-D83DDE00 {
  background-position: -0px -144px;
}

.smile-D83DDE01 {
  background-position: -16px -144px;
}

.smile-D83DDE02 {
  background-position: -32px -144px;
}

.smile-D83DDE03 {
  background-position: -48px -144px;
}

.smile-D83DDE04 {
  background-position: -64px -144px;
}

.smile-D83DDE05 {
  background-position: -80px -144px;
}

.smile-D83DDE06 {
  background-position: -96px -144px;
}

.smile-D83DDE07 {
  background-position: -112px -144px;
}

.smile-D83DDE08 {
  background-position: -128px -144px;
}

.smile-D83DDE09 {
  background-position: -144px -144px;
}

.smile-D83DDE0A {
  background-position: -160px -144px;
}

.smile-D83DDE0B {
  background-position: -176px -144px;
}

.smile-D83DDE0C {
  background-position: -192px -144px;
}

.smile-D83DDE0D {
  background-position: -208px -144px;
}

.smile-D83DDE0E {
  background-position: -224px -144px;
}

.smile-D83DDE0F {
  background-position: -240px -144px;
}

.smile-D83DDE10 {
  background-position: -256px -144px;
}

.smile-D83DDE11 {
  background-position: -272px -144px;
}

.smile-D83DDE12 {
  background-position: -288px -144px;
}

.smile-D83DDE13 {
  background-position: -304px -144px;
}

.smile-D83DDE14 {
  background-position: -320px -144px;
}

.smile-D83DDE15 {
  background-position: -336px -144px;
}

.smile-D83DDE16 {
  background-position: -352px -144px;
}

.smile-D83DDE18 {
  background-position: -368px -144px;
}

.smile-D83DDE19 {
  background-position: -0px -160px;
}

.smile-D83DDE1A {
  background-position: -16px -160px;
}

.smile-D83DDE1B {
  background-position: -32px -160px;
}

.smile-D83DDE1C {
  background-position: -48px -160px;
}

.smile-D83DDE1D {
  background-position: -64px -160px;
}

.smile-D83DDE1E {
  background-position: -80px -160px;
}

.smile-D83DDE1F {
  background-position: -96px -160px;
}

.smile-D83DDE20 {
  background-position: -112px -160px;
}

.smile-D83DDE21 {
  background-position: -128px -160px;
}

.smile-D83DDE22 {
  background-position: -144px -160px;
}

.smile-D83DDE23 {
  background-position: -160px -160px;
}

.smile-D83DDE24 {
  background-position: -176px -160px;
}

.smile-D83DDE25 {
  background-position: -192px -160px;
}

.smile-D83DDE26 {
  background-position: -208px -160px;
}

.smile-D83DDE27 {
  background-position: -224px -160px;
}

.smile-D83DDE28 {
  background-position: -240px -160px;
}

.smile-D83DDE29 {
  background-position: -256px -160px;
}

.smile-D83DDE2A {
  background-position: -272px -160px;
}

.smile-D83DDE2B {
  background-position: -288px -160px;
}

.smile-D83DDE2C {
  background-position: -304px -160px;
}

.smile-D83DDE2D {
  background-position: -320px -160px;
}

.smile-D83DDE2E {
  background-position: -336px -160px;
}

.smile-D83DDE2F {
  background-position: -352px -160px;
}

.smile-D83DDE30 {
  background-position: -368px -160px;
}

.smile-D83DDE31 {
  background-position: -0px -176px;
}

.smile-D83DDE32 {
  background-position: -16px -176px;
}

.smile-D83DDE33 {
  background-position: -32px -176px;
}

.smile-D83DDE34 {
  background-position: -48px -176px;
}

.smile-D83DDE35 {
  background-position: -64px -176px;
}

.smile-D83DDE36 {
  background-position: -80px -176px;
}

.smile-D83DDE37 {
  background-position: -96px -176px;
}

.smile-D83DDE38 {
  background-position: -112px -176px;
}

.smile-D83DDE39 {
  background-position: -128px -176px;
}

.smile-D83DDE3A {
  background-position: -144px -176px;
}

.smile-D83DDE3B {
  background-position: -160px -176px;
}

.smile-D83DDE3C {
  background-position: -176px -176px;
}

.smile-D83DDE3D {
  background-position: -192px -176px;
}

.smile-D83DDE3E {
  background-position: -208px -176px;
}

.smile-D83DDE3F {
  background-position: -224px -176px;
}

.smile-D83DDE40 {
  background-position: -240px -176px;
}

.smile-D83DDE4F {
  background-position: -256px -176px;
}