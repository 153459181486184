@import "../../../sass/variables";

.request-rate-dialog {

  .rating-label {
    line-height: 20px;
    vertical-align: middle;
  }

  .angular-input-stars,
  .request-rate-dialog__item-fab{
    vertical-align: middle;
  }

  @media (max-width: 470px) {
    margin: 0;
    width: 100%;
    min-width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .request-rate-dialog__header {
    @media (max-width: 470px) {
      display: none;
    }
  }

  .request-rate-dialog__mobile-header {
    @media (max-width: 470px) {
      height: 50px;
      line-height: 30px;
    }

    @media (min-width: 471px) {
      display: none;
    }
  }

  .request-rate-dialog__body {
    @media (max-width: 470px) {
      position: fixed;
      -webkit-overflow-scrolling: touch;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 4px;
      }
    }
  }

  .request-rate-dialog__footer {
    @media (max-width: 470px) {
      display: none;
    }
  }

  .request-rate-dialog__mobile-footer {
    @media (max-width: 470px) {
      position: fixed;
      -webkit-overflow-scrolling: touch;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 0;
    }

    @media (min-width: 471px) {
      display: none;
    }

    &.request-rate-dialog__user-rate {
      @media (max-width: 470px) {
        background: $vt-color-primary;
        display: block;
        height: 50px;
      }
    }
  }

  .request-rate-dialog__user-btn {
    color: #222 !important;
    width: 100%;
    margin: 0;
    height: 100%;
  }

  .request-rate-dialog__mobile-footer-menu {
    position: fixed;
    -webkit-overflow-scrolling: touch;
    width: 16%;
    border-top: 1px solid #585858;
  }

  .request-rate-dialog__mobile-footer-menu-open-btn {
    text-align: center;
    width: 100%;
    margin: 0;
    height: 100%;
    min-height: 100%;
    padding: 13px 0;
  }

  .request-rate-dialog__mobile-footer-btn {
    margin: 0 !important;
    border-radius: 0;
    height: 50px;
    position: relative;
    width: 84%;
    left: 16%;
    font-size: 13px;
    color: #4a4a4a;
  }

  @media (min-width: 600px) {
    min-width: 465px;
  }

  .angular-input-stars > li .active {
    color: $vt-color-primary;
  }

  .request-rate-dialog__item {
    padding: 0 20px;

    @media (min-width: 600px) {
      line-height: 51px;
      height: 55px;
    }

    @media (max-width: 599px) {
      line-height: 35px;
    }

  }

  .request-rate-dialog__item-fab {
    float: right;
    line-height: 47px;
  }

  .request-rate-dialog__item-fab__icon {
    line-height: 29px;
    height: 26px;
    width: 26px;
    font-size: 18px;
    border-radius: 21px;
    color: #bbb;
    cursor: pointer;
    background-color: rgba(70, 70, 70, 0.5);
    &:before {
      padding-left: 7px;
    }
  }
}

.request-rate-dialog__mobile-footer-menu-content {
  overflow-y: hidden !important;
  position: fixed;
  -webkit-overflow-scrolling: touch;
  left: 0 !important;
  width: 100%;
  height: 25%;
  bottom: 0;
  max-height: max-content;
  background: #222;

  md-menu-item {
    min-height: 60px;
    height: 60px;
    margin: 0 5px;
  }
}

.request-rate-dialog__mobile-footer-menu-btn {
  font-weight: 400;
  background-color: rgb(78, 78, 78) !important;
  height: 48px;
  max-height: 48px;
  color: white !important;
  span {
    color: white !important;
  }
}
