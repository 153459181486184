@import "../../../../velotooler-app";

.landing-page-steps-item {
  width: 200px;

  .landing-page-steps-item__icon {
    width: 4.5rem;
    height: 4.5rem;
    background: $vui-color-primary;
    border-radius: 2.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .landing-page-steps-item__desc {
    margin-top: 2rem;
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: 600;
  }
}
