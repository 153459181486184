@import "../../../velotooler-app";

.vui-pagination {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .vui-page-item {
    background: $color-white;
    font-size: 0.75rem;
    line-height: 0.75rem;
    padding: 0.5rem 0.875rem;
    font-weight: 500;
    height: 2rem;
    min-width: 2.25rem;
    display: flex;
    align-items: center;
    color: $vui-input-label-color;
    cursor: pointer;
    border: 1px solid $vui-main-menu-color;
    -webkit-transition: none !important;
    transition: none !important;

    &.active,
    &:hover,
    &:active {
      background: #FFFCB3;
    }

    &.active {
      cursor: default;
    }

    &.disabled {
      color: $vui-label-color;
      cursor: default;

      path {
        stroke: $vui-label-color;
      }
    }
  }

  .vui-page-item__prev {
    border-radius: 0.25rem 0 0 0.25rem;
    flex-direction: row;
    column-gap: 0.25rem;
  }

  .vui-page-item__next {
    border-radius: 0 0.25rem 0.25rem 0;
    flex-direction: row;
    column-gap: 0.25rem;
  }
}

@media only screen and (max-width: 767px) {
  .vui-pagination {
    .vui-pagination__label {
      display: none;
    }
  }
}