.profile-address-list {
  .profile-address-list__button {
    display: inline-block;
    vertical-align: -8px;
    text-align: center;
    text-transform: none;
    font-weight: 400;
    color: #999;
    margin: 0;
    padding-top: 9px;
  }

  .profile-address-list__actions {
    padding-top: 5px;
  }

  .profile-address-list__favorite-section {
    margin-top: 20px;
  }

  .profile-address-list__favorite-section-items {
    padding: 15px 0;
  }

  .profile-address-list__other-section {
    margin-top: 20px;
  }

  .profile-address-list__other-section-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .profile-address__section-title {
    color: #999;
  }
}