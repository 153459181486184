.vt-dropdown {

  line-height: 41px;
  max-width: 50px;

  .vt-dropdown-actions {
    border-radius: 2px;
    a:focus {
      background-color: rgba(107, 107, 107, 0.2) !important;
    }
  }

  .vt-dropdown-actions:after, .vt-dropdown-actions:before {
    bottom: 100%;
    left: 98%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  .vt-dropdown-actions:after {
    border-bottom-color: #222;
    border-width: 13px;
    margin-left: -28px;
  }

  .vt-dropdown-actions:before {
    border-bottom-color: #222;
    border-width: 13px;
    margin-left: -28px;
  }
}

.vt-dropdown-actions__img {
  width: 1.5rem !important;
  vertical-align: middle !important;
  margin: 5px 5px 8px 5px !important;
}