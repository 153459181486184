@import "../../sass/variables";

.ui-open-submenu {
  transform: scaleX(1);
  -webkit-transform: scaleX(1);
  transition: transform 150ms linear 150ms;
  -webkit-transition: transform 150ms linear 150ms;
}

.ui-submenu.ui-submenu-active {
  z-index: 75;
}

.ui-submenu {
  background-color: $color-primary-l2;

  .ui-submenu-element-container.active-element {
    background-color: $color-primary-l3;
  }

  .ui-submenu-element-container:not(:last-child) {
    border-bottom: solid 1px $color-primary-l3;
  }
}

@media (min-width: 471px) {
  .ui-submenu {
    width: 380px;
  }
}

@media (max-width: 470px) {
  .ui-submenu.ui-submenu-active {
    z-index: 80;
  }
}

.vt-submenu__header {
  display: flex;
  justify-content: space-between;
  background-color: $color-primary-l2;
  color: white;
  padding: 25px;
  font-weight: bold;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.vt-submenu {
  background-color: #303030;
}

.vt-ui-icon-close {
  background-image: url(/img/menu/close-icon.png);
}
