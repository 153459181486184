@import "../../../../velotooler-app";

.mechanic-card {

  .mechanic-card__category_individual {
    color: $color-green;
  }

  .mechanic-card__category_mobile {
    color: $color-red;
  }

  .mechanic-card__category_shop {
    color: $color-blue;
  }

}