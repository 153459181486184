@import "../variables";

a.md-button, button.md-button, a.md-button.md-default-theme, button.md-button.md-default-theme, md-dialog .md-button {
  min-width: 88px;
  border-radius: 0.125rem;
  font-size: 13px;
  text-decoration: none;
  border: none;
  outline: none;
  line-height: 25px;
  min-height: 25px;
  text-transform: uppercase;
  color: white;
  vertical-align: middle;

  &.md-button_no-transform {
    text-transform: none;
  }

  &.md-border-radius_none {
    border-radius: 0;
  }

  &.md-border_top {
    border-top: 1px solid #555;
  }

  &.right {
    text-align: center;
  }

  &.md-inline {
    margin: 0;
    min-height: inherit;
  }

  &.md-big {
    line-height: 35px;
    min-height: 35px;
  }

  &.md-margin-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  &.md-static-height {
    max-height: 27px;
  }

  &.md-primary {
    color: $vt-color-primary;
  }

  &.md-remove {
    background-color: #d53e4a;
  }

  &.md-neutral {
    color: white;
  }

  &.md-fixed-position {
    position: fixed !important;
  }

  &.md-raised {
    text-transform: none;
    padding: 0 15px 0 15px;
    margin: 6px 8px 6px 8px;
    border: 1px solid #555;
    color: $vt-color-primary;
    background-color: transparent;

    span {
      padding: 0 7px;
    }

    &.md-button-uppercase {
      text-transform: uppercase;
    }

    &.md-primary {
      color: #333;
      border: none;
      background-color: $vt-color-primary;

      &:not([disabled]):hover, &:not([disabled]):focus, &:not([disabled]).md-focused {
        background-color: $vt-color-primary-hue-1;
        color: #333333 !important;
      }
    }

    &.md-remove {
      color: white;
      background-color: #d53e4a;
      border: none;

      &:not([disabled]):hover, &:not([disabled]):focus, &:not([disabled]).md-focused {
        background-color: #f55e6a;
      }
    }

    &.md-success {
      color: white;
      background-color: rgba(81, 197, 126, .5);
      border: none;

      &:not([disabled]):hover, &:not([disabled]):focus, &:not([disabled]).md-focused {
        background-color: rgba(81, 197, 126, .8);
      }
    }

    &.md-create {
      color: white;
      background-color: rgba(88, 88, 88, 0.5);
      border: none;

      &:not([disabled]):hover, &:not([disabled]):focus, &:not([disabled]).md-focused {
        background-color: rgba(78, 78, 78, 0.8);
      }
    }

    &.md-neutral {
      color: white;
      background-color: rgba(158, 158, 158, 0.2);
      border: none;
      box-shadow: none;

      &:not([disabled]):hover, &:not([disabled]):focus, &:not([disabled]).md-focused {
        background-color: rgba(158, 158, 158, 0.4);
      }
    }

    &:not([disabled]):hover, &:not([disabled]):focus, &:not([disabled]).md-focused {
      background-color: #333;
    }

    &[disabled] {
      color: rgba(150, 150, 150, 0.36) !important;
      background-color: rgba(20, 20, 20, 0.36) !important;
      border: none;
    }
  }

  &[disabled] {
    color: rgba(150, 150, 150, 0.36) !important;
    border: none;
  }
}
