@import "../../../../sass/variables";

$color-components-label: #2c9a57;

.request-info-tab {

  .request-info-tab__info-message {
    margin: auto;
    display: flex;
    padding: 8px 7px 8px 41px;
    color: $vt-color-primary;
  }

  .request-info-tab__request-time {
    margin: 0 17px;
  }

  @media (min-width: 341px) and (max-width: 470px) {
    font-size: 15px;
  }

  @media (max-width: 340px) {
    .request-info-tab__request-time-value {
      font-size: 13px;
    }

    .request-info__approval-time-actions-decline, .request-info__approval-time-actions-offer {
      font-size: 12px;
    }
  }

  .request-info-tab__label {
    color: #888888;
    @media (max-width: 768px) {
      padding-bottom: 15px;
    }
  }

  .request-info-tab__underlying-text {
    font-size: 12px;
    font-weight: normal;
    color: $color-green;
  }

  .request-info-tab__label_padding-bottom0 {
    padding-bottom: 0;
  }

  .customer-receipt {
    float: right;
    margin-right: 27px !important;
    color: white;

    .receipt-icon {
      width: 23px;
      padding: 4px 0;
      vertical-align: middle;
    }
  }

  .customer-receipt:not([disabled]):hover {
    background-color: rgb(34, 34, 34);
  }

  .component-label {
    color: #777;
    font-size: 14px;
  }

  .request-info {

    .request-info__approval-time {

    }

    .request-info__approval-time-text {
      padding: 17px;
      color: #ffffff;
      font-weight: 500;
      line-height: 21px;
    }

    .request-info__approval-time_yellow {
      color: #FFEC92;
    }

    .request-info__approval-time-actions-agree {
      @media (max-width: 470px) {
        width: 100%;
        margin: 7px 0;
        border-radius: 0;
        line-height: 35px;
        min-height: 35px;
        z-index: 5;
      }
    }

    .request-info__approval-time-actions-offer, .request-info__approval-time-actions-decline {
      @media (max-width: 470px) {
        width: 100%;
        margin: 7px 0;
        border-radius: 0;
        z-index: 5;
      }
    }

    .request-info__approval-time {
      background-color: rgba(50, 50, 50, 0.5);
      padding: 20px;

      @media (min-width: 471px) {
        padding-bottom: 0;
      }
    }

    .request-info__approval-time-actions {
      text-align: right;
      border-top: 1px solid #444;
    }

    .request-info__user-notes {
      margin: 0;
    }

    .show-more-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: -5px;

      .show-bikes-button {
        padding: 0 8px;
        margin-left: 2px !important;
        margin-right: 2px !important;
      }
    }

    .rg__bike-count {
      font-size: 14px;
    }

    .bike-list-label {
      line-height: 41px;
    }

    .request-bike-status {
      line-height: 2.5;
      font-size: 11px;
      padding: 1px 5px;
      border-radius: 3px;
      margin-left: 30px;

      i {
        margin-right: 5px;
      }

      i.fa-check-circle {
        margin-right: 9px;
      }
    }

    .request-bike-status.fixed {
      color: black;
      background-color: #51c57e;
    }

    .request-bike-status.open {
      color: white;
      background-color: #134f5c;
    }

    md-input-container.search-bike-by-sn-container {
      .md-errors-spacer {
        min-height: 0;
        min-width: 0;
      }
    }

    .rg__bike-mechanic {
      font-size: 14px;
      padding: 0 0 0 35px;
    }

    .rg__bike-mechanic-label {
      color: #777;
    }

    .rg__bike-mechanic-name {
      font-weight: 500;
      color: #ffec92;
    }

    .rg__chat {
      display: inline-block;
      border: 1px solid #333;
      border-radius: 5px;
      margin-left: 5px;
      padding: 5px;
      color: #aaa;
    }

    .rg__chat-icon {
      color: #ffec92;
    }

    .request-bike-list {
      .rg__bike {
        background-color: rgba(70, 70, 70, 0.3);
        margin: 3px 0 0;
        padding: 5px 15px 5px 15px;
        position: relative;
      }

      .rg__group-request-button-container {
        display: inline-block;
      }

      .rg__bike-action-buttons {
        padding: 0;
      }

      .rg__bike-mechanic-avatar {
        width: 64px;
        height: 64px;
        border-radius: 50%;
        padding: 16px;
      }

      .show-bike-link-container {
        padding-left: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .show-bike-link {
          font-size: 0.9em;
          line-height: 2.5;
        }
      }

      .vt-link {
        color: white;
        font-size: 14px;
        line-height: 35px;
      }

      .bike-components-link {
        padding-left: 0;
        padding-right: 5px;
        text-align: left;
      }

      .bike-status {
        padding-right: 0;
        .fix-btn {
          min-height: 15px;
          line-height: 19px;
        }
        .fix-btn[disabled] {
          border: none;
        }
      }
    }

    @media (max-width: 470px) {

      .bike-status {
        .fix-btn {
          min-width: 85px;
        }
      }

      .request-bike-list {
        .vt-link {
          font-size: 12px;
        }

        .request-bike-status {
          font-size: 12px;
        }
      }

      .filter-placeholder {
        font-size: 14px;
      }

      .review-row {
        font-size: 14px;
        word-wrap: break-word;
      }

    }

    @media (max-width: 880px) {
      .show-more-btn {
        .show-bikes-button {
          font-size: 11px;
        }
      }
    }
  }

  .additional-services {
    .additional-services__label {
      @media (max-width: 768px) {
        text-align: center;
        padding-bottom: 15px;
      }
    }

    .additional-services__item {

      .additional-services__item__icon {
        line-height: 33px;
      }

      .additional-services__item__label {
        border: 1px solid #222;
        padding: 6px;
        border-radius: 8px;
        margin-bottom: 6px;
      }
    }
  }

  .request-info-tab__request-time-label {
    color: #aaa;
    padding-bottom: 5px;
  }

  .request-info-tab__request-time-value {
    font-weight: bold;
    @media (min-width: 341px) and (max-width: $mobile-width) {
      font-size: 14px;
    }
  }
}

.rg__user-avatar {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  vertical-align: middle;
  margin-right: 5px;
}

.rg__user-avatar-container {
  cursor: pointer;
  display: block;
}

.rg__user-avatar-group {
  display: inline-block;
}

.rg__user-phone {
  margin-left: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 30px;
}

.rg__mechanic-phone {
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 30px;
}

.rg__components-dropdown--opened {
  .rg__dropdown-button--arrow-down {
    span {
      transform: rotate(135deg);
    }
  }
}

.rg__dropdown-button--arrow-down {
  padding-right: 15px;
  cursor: pointer;
  span {
    display: inline-block;
    transform: rotate(-45deg);
    transition: transform .2s ease-out;
  }
}
