@import "../../../../velotooler-app";

.landing-page-steps {
  display: flex;

  hr {
    color: $vui-color-primary;
    background-color: $vui-color-primary;
  }
}

@media only screen and (min-width: 768px) {
  .landing-page-steps {
    flex-direction: initial;

    hr {
      height: 2px;
      margin-top: 35px;
      width: 100%;
      margin-right: 44px;
      margin-left: -94px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .landing-page-steps {
    flex-direction: column;
  }
}
