.custom-service-category-view {
  .custom-service-category-view__name {

  }

  .custom-service-category-view__description {
    margin-top: 20px;
  }

  .custom-service-category-view__name-error {
    margin-top: -32px;
    margin-left: 2px;
  }
}