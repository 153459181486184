@import "../../../velotooler-app";

.vui-input-container {
  .vui-input-group {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
  }
}

.vui-input-container.vui-input-container__input-group-left,
.vui-input-container.vui-input-container__input-group-right {
  &.vui-form-control-lg {
    .vui-input-group {
      @include size-rect(70px);
    }
  }

  .vui-input-group {
    @include size-rect(40px);
    margin-left: 0.5rem;
  }
}

.vui-input-container.vui-input-container__input-group-left {
  &.vui-form-control-lg {
    .vui-input {
      padding-left: 70px;
    }
  }

  .vui-input {
    padding-left: 48px;
  }
}

.vui-input-container.vui-input-container__input-group-right {
  &.vui-form-control-lg {
    .vui-input {
      padding-right: 70px;
    }
  }

  .vui-input {
    padding-right: 48px;
  }

  .vui-input-group {
    right: 0
  }
}

@media only screen and (max-width: 767px) {
  .vui-form-control-lg.vui-input-container.vui-input-container__input-group-left,
  .vui-form-control-lg.vui-input-container.vui-input-container__input-group-right {
    .vui-input-group {
      @include size-rect(42px);
    }
  }

  .vui-form-control-lg.vui-input-container.vui-input-container__input-group-left {
    .vui-input {
      padding-left: 48px;
    }
  }

  .vui-form-control-lg.vui-input-container.vui-input-container__input-group-right {
    .vui-input {
      padding-right: 48px;
    }
  }
}
