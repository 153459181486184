.mechanic-work-area-item {
  margin: 0 !important;
  color: white;

  &.mechanic-work-area-item_highlight {
    cursor: pointer;
    &:hover {
      background-color: #222;
    }
  }

  .mechanic-work-area-item__info {
    padding: 15px 0 15px 15px;
  }

  .mechanic-work-area-item_inactive {
    color: #555;

    .mechanic-work-area-item__radius {
      color: #555;
    }
  }

  .mechanic-work-area-item__radius {
    color: #888;
  }

  .mechanic-work-area-item__separator {
    margin-bottom: 20px;
  }

  .mechanic-work-area-item__controls {
    padding: 15px;
  }

  .mechanic-work-area-item__activity-switch {
    line-height: 24px;
    height: 26px;
    margin: 0 20px 0 0;
    font-weight: bold;
  }

  .mechanic-work-area-item__activity-switch-text_inactive {
    color: #888;

  }

  .mechanic-work-area-item__remove-btn {
    margin: 0 !important;
  }

}