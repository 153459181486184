.landing-page-info-section {
  .landing-page-info-section-title {
    font-size: 2.6rem;
    line-height: 3rem;
    text-align: center;
  }
}

.is-content-center {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

@media screen and (min-width: 901px) {
  .is-header-right {
    width: 40%;
    margin-left: auto;
  }

  .is-header-left {
    width: 40%;
    margin-right: auto;
  }
}

@media screen and (max-width: 900px) {
  .is-header-right,
  .is-header-left {
    width: 100%;
    text-align: center;
  }
}

.is-header-center {
  text-align: center;
}
