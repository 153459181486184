.vt-icon {

  width: 16px;
  height: 16px;
  display: inline-block;

  &.vt-icon__l {
    width: 27px;
    height: 27px;
  }

  &.vt-icon__s {
    width: 8px;
    height: 8px;
  }

  &.vt-icon__m {
    width: 20px;
    height: 20px;
  }

  &.vt-icon__cancel {
    background: url("/img/icons/cancel.png") no-repeat center;
    background-size: cover;
  }
}