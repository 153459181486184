@import "../../../velotooler-app";

.vui-stepper {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;

  .vui-stepper__steps {
    vui-stepper-steps {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .vui-stepper-item {
        width: 100px;

        vui-step-label {
          text-wrap: nowrap;
        }
      }

      vui-stepper-item:first-child {
        .vui-stepper-item {
          align-items: start;
        }
      }

      vui-stepper-item:last-child {
        .vui-stepper-item {
          align-items: end;
        }
      }
    }
  }

  .vui-stepper__spaces {
    display: flex;
    flex-direction: row;
    gap: 6rem;
    padding: 0 8rem;
    margin-top: -56px;

    hr {
      height: 1px;
      background: $vui-label-color;
      width: calc(100% - 2rem);
    }

    hr:first-child {
      margin-left: -4rem;
      width: calc(100% + 4rem);
    }

    hr:last-child {
      margin-right: -4rem;
      width: calc(100% + 4rem);
    }
  }

  .vui-stepper__content {
    display: block;
    margin: 0;
    overflow: hidden;
    position: relative;

    vui-stepper-contents {
      min-height: 0;
      position: relative;
      top: auto;
      left: auto;
      right: auto;
      bottom: auto;
      overflow: visible;
      display: flex;
      @include transition-short(left);
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .vui-stepper {
    .vui-stepper__spaces {
      gap: 0;
      padding: 0;
      margin-top: -56px;

      hr {
        height: 1px;
        background: $vui-label-color;
        width: calc(100% / 3 - 5rem);
      }

      hr:first-child {
        width: calc(100% / 3 - 3rem);
        margin-left: 2.5rem;
      }

      hr:last-child {
        width: calc(100% / 3 - 3rem);
        margin-right: 2.5rem;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .vui-stepper {
    .vui-stepper__steps {
      vui-stepper-steps {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .vui-stepper-item {
          max-width: 84px;
          width: 64px;

          vui-step-label {
            text-wrap: balance;
            max-width: 84px;
            height: 28px;
            display: block;
          }
        }

        vui-stepper-item:first-child {
          .vui-stepper-item {
            align-items: start;
          }
        }

        vui-stepper-item:last-child {
          .vui-stepper-item {
            align-items: end;
          }
        }
      }
    }

    .vui-stepper__spaces {
      gap: 0;
      padding: 0;
      margin-top: -48px;

      hr {
        height: 1px;
        background: $vui-label-color;
        width: calc(100% / 3 - 3.5rem);
      }

      hr:first-child {
        width: calc(100% / 3 - 2.25rem);
        margin-left: 1.75rem;
      }

      hr:last-child {
        width: calc(100% / 3 - 2.25rem);
        margin-right: 1.75rem;
      }
    }
  }
}