@import "../../../velotooler-app";

.vui-select__dropdown {
  position: absolute;
  display: none;
  background: $color-white;
  border-radius: 4px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
  margin-top: 20px;
  margin-bottom: 20px;
  max-height: calc(70px * 5);
  overflow-y: auto;
  z-index: 9999;
  scrollbar-color: rgba(255, 255, 255, 0.8) rgba(0, 0, 0, 0) !important;
  scrollbar-width: thin !important;
}

.vui-select__dropdown::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
  border-radius: 3px;
  background-color: transparent;
}

.vui-select__dropdown::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

.vui-select__dropdown::-webkit-scrollbar-thumb {
  border-radius: 3px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .2);
  background-color: rgba(0, 0, 0, .2);
}
