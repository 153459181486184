@import "../../../velotooler-app.scss";

.vui-settings-section {

  padding-top: 15px;
  padding-bottom: 15px;
  color: white;

  .vui-settings-section__left-group {
    padding: 15px;
  }

  .vui-settings-section__title {
    font-size: 1.1rem;
    font-weight: bold;
  }

  .vui-settings-section__description {
    padding-top: 10px;
    font-size: 1rem;
    color: #aaa;
  }
}

.vui-settings-section-separator {
  margin: 10px 0;
  border-bottom: 1px solid #333;

  @media (max-width: $mobile-width) {
    display: none;
  }
}