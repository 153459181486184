.profile-address {
  .profile-address__selected {
    line-height: 36px;
  }

  .profile-address__more {
    padding-left: 5px;
    color: #777;
  }

  .profile-address__manage-btn {
    margin: 0 0 0 5px !important;
  }
}