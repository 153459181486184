@import "../../../velotooler-app";

@mixin coloredInput($backgroundColor, $borderColor, $placeholderColor, $activeColor) {
  border: 1px solid $borderColor;
  background: $backgroundColor;

  &:active,
  &:hover,
  &:focus {
    border: 1px solid $activeColor;
  }

  &::placeholder {
    color: $placeholderColor !important;
    opacity: 1;
    font-weight: 600;
  }
}

.vui-input-container {
  width: 100%;
  display: inline-block;
  position: relative;
  margin-top: 1rem;
  margin-bottom: 1rem;
  transition: all 250ms ease-in-out;

  &.vui-form-control-lg {
    margin-top: 1rem;
    margin-bottom: 1rem;

    .vui-input, .vui-input-container__autocomplete input {
      font-size: 1rem;
      line-height: 1.375rem;
      padding: 1.375rem;
    }

    input.vui-input, .vui-input-container__autocomplete input {
      height: 4.375rem;
    }

    textarea.vui-input {
      min-height: 4.375rem;
    }

    label {
      font-size: 0.875rem;
      line-height: 0.875rem;
    }
  }

  .vui-input, .vui-input-container__autocomplete input {
    width: 100%;
    font-size: 0.875rem;
    font-weight: 600;
    padding: 0.75rem 1.25rem;
    border-radius: 0.25rem;
    height: 2.5rem;

    @include coloredInput($color-white, $vui-text-placeholder-color, #b3b3b3, $vui-color-primary--hover);
  }

  label {
    opacity: 0;
    color: $vui-input-label-color;
    position: absolute;
    top: 1rem;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 0.75rem;
    transition: all 250ms ease-in-out;
  }

  .vui-input::placeholder, .vui-input-container__autocomplete input::placeholder {
    font-weight: 600;
  }

  .vui-input:disabled, .vui-input-container__autocomplete input:disabled {
    @include coloredInput(#f3f3f3, #f3f3f3, #b3b3b3, #f3f3f3);
  }

  &.vui-form-control-grey {
    .vui-input, .vui-input-container__autocomplete input {
      @include coloredInput($vui-dark-input-color, $vui-dark-input-color, #b3b3b3, $vui-dark-input-color);
    }

    .vui-input:disabled, .vui-input-container__autocomplete input:disabled {
      @include coloredInput(#eeeeee, #eeeeee, #b3b3b3, #eeeeee);
    }
  }

  /*&.vui-input-container--focused {
    label {
      opacity: 1;
      top: 1px;
    }
  }

  &.vui-form-control-lg.vui-input-container--focused {
    label {
      top: 4px;
    }
  }*/

  & {
    label {
      opacity: 1;
      top: -16px;
    }
  }

  &.vui-form-control-lg {
    label {
      top: -20px;
    }

    .error {
      span {
        font-size: 0.875rem;
        line-height: 1rem;
      }
    }
  }

  .vui-input-group {
    g > * {
      stroke: $vui-input-placeholder-color;
    }
  }

  .error {
    span {
      color: $vui-input-error-color;
      font-size: 0.75rem;
      line-height: 1rem;
      font-weight: 600;
      text-wrap: nowrap;
    }
  }
}

.vui-form.ng-invalid {
  .vui-input-container {
    .vui-input.ng-invalid.ng-dirty, .vui-input-container__autocomplete input.ng-invalid.ng-dirty {
      border: 1px solid $vui-input-error-color;
      color: $vui-input-error-color;
    }

    .ng-invalid.ng-dirty + vui-input-group .vui-input-group {
      g > * {
        stroke: $vui-input-error-color;
      }
    }
  }
}

.vui-form.ng-invalid.ng-submitted {
  .vui-input-container {
    .vui-input.ng-invalid, .vui-input-container__autocomplete input.ng-invalid.ng-dirty {
      border: 1px solid $vui-input-error-color;
      color: $vui-input-error-color;
    }

    .ng-invalid + vui-input-group .vui-input-group {
      g > * {
        stroke: $vui-input-error-color;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .vui-input-container {
    &.vui-form-control-lg {
      .vui-input, .vui-input-container__autocomplete input {
        font-size: 0.875rem;
        line-height: 0.875rem;
        padding: 0.75rem 1.25rem;
        height: 40px;
      }

      input.vui-input, .vui-input-container__autocomplete input {
        height: 2.5rem;
      }

      textarea.vui-input {
        height: 2.625rem;
      }

      label {
        font-size: 0.75rem;
        line-height: 0.75rem;
        top: -16px;
      }
    }
  }
}
