.mechanic-work-area-list {

  max-width: 1100px;

  .mechanic-work-area-list__body {
    display: flex;
  }

  .mechanic-work-area-list__areas-section {
    flex-basis: 60%;

    @media (max-width: 1100px) {
      flex-basis: 100%;
    }
  }

  .mechanic-work-area-list__info-section {
    flex-basis: 40%;
    padding: 15px;
    margin-right: 15px;
    text-align: justify;

    @media (max-width: 1100px) {
      display: none;
    }
  }

  .mechanic-work-area-list__page-header-info {
    font-size: 13px;
    color: #aaa;
  }

}