@import "../../../../velotooler-app";

.vui-main-menu-organisations-item {
  .vui-popover__dropdown {
    padding: 0;
  }

  .vui-navigations-organisations {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 1rem;
    height: 2.25rem;
    @include transition-short(color);

    .vui-navigations-organisations__avatar {
      width: 2.25rem;
      height: 2.25rem;
      border-radius: 1.125rem;
      @include transition-short(opacity);
    }

    .vui-navigations-organisations__name {
      width: 120px;
      overflow: hidden;
      text-overflow: ellipsis;
      text-wrap: nowrap;
      font-size: 0.75rem;
      font-weight: 600;
    }

    .vui-navigations-organisations__toggler {
      path {
        @include transition-short(stroke);
      }
    }

    &:hover {
      color: $vui-dark-text-color--hover;

      .vui-navigations-organisations__avatar {
        opacity: 0.8;
      }

      .vui-navigations-organisations__toggler {
        path {
          stroke: $vui-dark-text-color--hover;
        }
      }
    }
  }

  .vui-organisations-menu {
    width: 210px;

    .vui-organisations-menu__list {
      display: flex;
      flex-direction: column;

      .list-item {
        width: 100%;
        display: flex;
        flex-direction: row;
        column-gap: 0.25rem;
        padding: 0.5rem 1rem;

        &.list-item__all-organisations {
          padding-left: 2.25rem;
        }

        .list-item__image {
          width: 1rem;
          height: 1rem;
          border-radius: 0.5rem;
          @include transition-short(opacity);
        }

        .list-item__name {
          font-size: 0.875rem;
          color: $vui-dark-text-color;
          overflow: hidden;
          text-overflow: ellipsis;
          text-wrap: nowrap;
          @include transition-short(color);
        }

        &:hover {
          .list-item__image {
            opacity: 0.8;
          }

          .list-item__name {
            color: $vui-dark-text-color--hover;
          }
        }
      }
    }

    .vui-organisations-menu__delimiter {
      width: 100%;
      height: 1px;
      color: $vui-main-menu-color;
      margin: 0;
    }

    .vui-organisations-menu__profile {
      display: block;
      font-size: 0.875rem;
      line-height: 17px;
      text-align: center;
      padding: 1rem;
      color: $vui-dark-text-color;
      @include transition-short(color);

      &:hover {
        color: $vui-dark-text-color--hover;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .vui-main-menu-organisations-item {
    .vui-navigations-organisations {
      column-gap: 0;

      .vui-navigations-organisations__name {
        display: none;
      }

      .vui-navigations-organisations__toggler {
        display: none;
      }
    }

    .vui-popover__dropdown {
      right: 1rem;
    }
  }
}

@media only screen and (max-width: 767px) {
  .vui-main-menu-organisations-item {
    .vui-navigations-organisations {
      column-gap: 0;

      .vui-navigations-organisations__name {
        display: none;
      }

      .vui-navigations-organisations__toggler {
        display: none;
      }
    }

    .vui-popover__dropdown {
      right: 1rem;
    }
  }
}
