.custom-service-category-item {
  line-height: 28px;
  color: white;
  display: flex;

  .custom-service-category-item__name {
    flex: 1 0 0;
  }

  .custom-service-category-item__actions {
    display: flex;
  }

  .custom-service-category-item__remove-btn {
    display: flex;
    border-radius: 50%;
    min-width: 22px;
    min-height: 22px;
    margin: auto;
    img {
      display: flex;
      margin: auto;
      width: 12px;
    }

    &:hover {
      background-color: transparent;
      cursor: pointer;
    }
  }

  .custom-service-category-item__underlying-text {
    color: #aaa;
    font-size: 12px;
    font-weight: normal;

    &.custom-service-category-item__underlying-text_custom {
      color: $color-green;
    }
  }
}