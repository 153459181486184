.view-uncoated-area {

  padding: 30px 20px;
  margin: 0 15px !important;
  max-width: none !important;
  border-radius: 0 !important;
  background: rgba(26, 26, 26, 0.9);

  .view-uncoated-area__label {
    color: white;
    margin-bottom: 4px;
  }

  .view-uncoated-area__section {
    padding-bottom: 35px;
    margin: 8px;
  }

  .view-uncoated-area__email {
    flex-basis: 75% !important;
  }
}