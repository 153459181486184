.vui-confirmation-details-message-model {
  .vui-confirmation-details-message-model__body {
    max-width: 440px;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;

    .vui-confirmation-details-message-model__update-info {
      font-weight: 600;
    }
  }

  .vui-confirmation-details-message-model__actions {
    column-gap: 1.5rem;
  }
}