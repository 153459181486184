@import "../../../velotooler-app";

.vui-calendar {
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  border: 1px solid $vui-color-primary;
  width: 226px;
  background: $color-white;

  .vui-calendar__month-selector {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    border-radius: 0.25rem;
    align-items: center;
    align-self: center;

    .month-selector__label {
      font-size: 0.625rem;
      font-weight: 600;
      text-transform: uppercase;
    }

    .month-selector__action {
      padding: 0.5rem 1rem;
      cursor: pointer;

      path {
        stroke: $color-dark;
      }

      &.disabled {
        cursor: default;
      }

      &:hover,
      &.disabled {
        background: $vui-dark-input-color;

        path {
          stroke: $vui-label-color;
        }
      }
    }
  }

  .vui-calendar__body {
    font-size: 0.75rem;

    .calendar__week {
      display: flex;
      flex-direction: row;

      .calendar__day-of-week {
        flex: 1 0 2rem;
        width: 2rem;
        background: $color-white;
        font-weight: 600;
        text-align: center;
        text-transform: uppercase;
        line-height: 1.5rem;
      }
    }

    .calendar__days {
      display: flex;
      flex-flow: row wrap;

      .calendar__day {
        flex: 1 0 2rem;
        width: 2rem;
        max-width: 2rem;
        aspect-ratio: 1 / 1;
        background: $color-white;
        border-top: 1px solid $vui-dark-input-color;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        &.calendar__day--borderred {
          border-right: 1px solid $vui-dark-input-color;
        }

        &.selected:not(.disabled),
        &:hover:not(.disabled) {
          .day__label {
            span {
              background: $vui-color-primary;
            }
          }
        }

        &.disabled {
          background: $vui-content-background;
          cursor: default;

          .day__label {
            span {
              color: $vui-label-color;
              background: $vui-content-background;
            }
          }
        }

        .day__label {
          span {
            width: 1.5rem;
            height: 1.5rem;
            line-height: 25px;
            text-align: center;
            margin-left: auto;
            display: block;
            background: $color-white;
            border-radius: 0.75rem;
          }
        }
      }
    }
  }
}
