@import "vt-form-header",
"vt-form-body",
"vt-form-footer";

.vtform {
  @media (min-width: $not-mobile-width) {
    color: #F5F5F5;
    background-color: #232323;
    border-radius: 2px;
    max-width: 600px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    padding: 15px 25px;
  }

}