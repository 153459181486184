@import "../../../sass/variables";

.confirm-appointment-date-dialog {
  @media (max-width: 470px) {
    margin: 0;
    width: 100%;
    min-width: 100%;
    height: 100%;
  }

  .confirm-appointment-date-dialog__header {
    @media (max-width: 470px) {
      display: none;
    }
  }

  .confirm-appointment-date-dialog__header-mobile {
    @media (max-width: 470px) {
      height: 50px;
      line-height: 30px;
      padding: 10px 13px;
    }

    @media (min-width: 471px) {
      display: none;
    }
  }

  .confirm-appointment-date-dialog__header-mobile-close-btn {
    position: fixed;
    -webkit-overflow-scrolling: touch;
    right: 16px;
    top: 16px;
  }

  .confirm-appointment-date-dialog__body {
    @media (max-width: 470px) {
      position: fixed;
      -webkit-overflow-scrolling: touch;
    }
  }

  .confirm-appointment-date-dialog__footer {
    @media (max-width: 470px) {
      display: none;
    }
  }

  .confirm-appointment-date-dialog__mobile-footer {
    @media (max-width: 470px) {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 0;
    }

    @media (min-width: 471px) {
      display: none;
    }
  }

  .confirm-appointment-date-dialog__mobile-footer-menu {
    position: fixed;
    -webkit-overflow-scrolling: touch;
    width: 16%;
    border-top: 1px solid #585858;
  }

  .confirm-appointment-date-dialog__mobile-footer-menu-open-btn {
    text-align: center;
    width: 100%;
    margin: 0;
    height: 100%;
    min-height: 100%;
    padding: 13px 0;
  }

  .confirm-appointment-date-dialog__mobile-footer-btn {
    margin: 0 !important;
    border-radius: 0;
    height: 50px;
    position: relative;
    width: 84%;
    left: 16%;
  }

  .confirm-appointment-date-dialog__body-user-avatar {
    flex: 4 0 0;
    img {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }

    @media (max-width: 470px) {

    }

    @media (min-width: 600px) and (max-width: 650px) {
      flex: 3 0 0;
    }
  }

  .confirm-appointment-date-dialog__body-user, .confirm-appointment-date-dialog__body-description-bike {
    display: flex;
    @media (max-width: 470px) {

    }
  }

  .confirm-appointment-date-dialog__body-description-bike {
    padding: 10px;
    align-items: center;
  }

  .confirm-appointment-date-dialog__body-description-bikes {
    border-top: 1px solid #373737;
    padding: 2px;
  }

  .confirm-appointment-date-dialog__body-description-bike-img {
    img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
  }

  .confirm-appointment-date-dialog__body-description-bike-name {
    margin: 8px;
  }

  .confirm-appointment-date-dialog__body-user-fullname {
    flex: 1 0 0;
    font-weight: 500;
    color: #eaeaea;
    @media (max-width: 470px) {

    }
  }

  .confirm-appointment-date-dialog__body-mechanic-category {
    flex: 2 0 0;
    margin-top: 4px;
    @media (min-width: 361px) and (max-width: 470px) {

    }

    @media (max-width: 360px) {
      line-height: 20px;
    }
  }

  .confirm-appointment-date-dialog__body-user-info {
    display: flex;
    flex-direction: column;
    flex: 9 0 0;
    padding: 6px 0;
    @media (min-width: 361px) and (max-width: 470px) {

    }

    @media (max-width: 360px) {
      line-height: 25px;
    }
  }

  .confirm-appointment-date-dialog__body-mechanic-category_individual {
    color: $color-green;
  }

  .confirm-appointment-date-dialog__body-mechanic-category_mobile {
    color: #EB5757;
  }

  .confirm-appointment-date-dialog__body-mechanic-category_shop {
    color: #43A6F9;
  }

  .confirm-appointment-date-dialog__body-mechanic-category-icon {
    height: 12px;
  }

  .confirm-appointment-date-dialog__body-mechanic-category-description {
    font-weight: normal;
    font-size: 13px;
  }

  .confirm-appointment-date-dialog__body-description {
    padding: 5px 0;
    line-height: 21px;
  }

  .confirm-appointment-date-dialog__body-description-service {
    color: #ffec92;
    padding-bottom: 20px;
    font-weight: bold;
  }

  .confirm-appointment-date-dialog__body-description-time {
    font-weight: bold;
    color: $vt-color-primary;
  }

  .confirm-appointment-date-dialog__body-user-view-btn {
    color: #aaa;
    vertical-align: middle;
    font-size: 12px;
    @media (max-width: 470px) {

    }
  }

  .confirm-appointment-date-dialog__body-user-view-btn-icon {
    width: 13px;
  }

  .confirm-appointment-date-dialog__body-user-view-container {
    font-weight: bold;
    margin-top: 8px;
    text-align: left;
    padding: 0;
    color: white;
    flex: 19 0 0;
    @media (min-width: 361px) and (max-width: 650px) {
      flex: 5 0 0;
    }

    @media (max-width: 360px) {
      margin-top: 0;
    }

    @media (min-width: 600px) and (max-width: 650px) {
      flex: 10 0 0;
    }
  }
}

.confirm-appointment-date-dialog__mobile-footer-menu-content {
  overflow-y: hidden !important;
  position: fixed;
  -webkit-overflow-scrolling: touch;
  left: 0 !important;
  width: 100%;
  height: 25%;
  bottom: 0;
  max-height: max-content;
  background: #222;

  md-menu-item {
    min-height: 60px;
    height: 60px;
    margin: 0 5px;
  }
}

.confirm-appointment-date-dialog__mobile-footer-menu-btn {
  font-weight: 400;
  background-color: rgb(78, 78, 78) !important;
  height: 48px;
  max-height: 48px;
  color: white !important;
  span {
    color: white !important;
  }
}

