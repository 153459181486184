@import "../../../velotooler-app.scss";

.vui-page {
  padding-top: 2rem;
  padding-right: 3.2rem;
  padding-left: 3.2rem;
  margin-right: auto;
  margin-left: auto;
  max-width: 1200px;
  color: white;

  .vui-page__footer {
    margin-bottom: 70px;
  }

  .vui-page__sticky-title {
    max-height: 0;
    background-color: $color-primary-l3;
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    overflow: hidden;
    border: none;
    padding-top: 0;
    padding-bottom: 0;
    box-shadow: none;
    transition: max-height .3s;
  }

  .vui-page__sticky-title_active {
    z-index: 29 !important;
    transition: max-height .7s;
    max-height: 130px;
    padding: 5px 20px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }

  @media (max-width: $mobile-width) {
    padding-right: 0;
    padding-left: 0;
  }

  h2 {
    font-size: 1.5rem;
    font-weight: 200;
    margin: 0;
  }

  .vui-page__title_flex {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    align-items: center;
  }

  .vui-page__title {
    padding: 1rem 1.25rem;
    border-bottom: 1px solid #333;
    margin-bottom: 1rem;
  }

  &.vui-page_large {
    max-width: 1500px;
  }

  &.vui-page_bg {
    .vui-page__title {
      padding: .5rem 1.25rem;
      min-height: 70px;
      border-bottom: none;
      background-color: #232323;
      display: flex;
      align-items: center;
      color: #fff;
      box-shadow: 0 3px 6px rgba(0, 0, 0, .16), 0 3px 6px rgba(0, 0, 0, .23);
    }
  }
}
