.landing-page-main-actions {
  .landing-page-main-actions__button.md-button {
    min-height: 50px;
    min-width: 180px;
    font-size: 16px;
    line-height: 50px;

    &.md-button.md-raised:not([disabled]):focus {
      background-color: transparent;
    }

    &.landing-page-main-actions__secondary-btn {

      color: black;
      border: none;

      &:hover, &:focus {
        background-color: #eee !important;
      }

    }
  }

}
