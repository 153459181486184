@import "../../../velotooler-app";

.vui-side-menu {
  top: 0;
  left: 0;
  height: calc(100vh - #{$vui-header-height});
  background: $input-default-color;
  z-index: 20;
  @include transition-short(width);
  transition: width 250ms ease-in-out;

  .vui-side-menu__toggler-content {
    .vui-side-menu__toggler {
      width: 3.25rem;
      height: 2.25rem;
      padding: 0.5rem 0;
      border-radius: 0.25rem;
      @include transition-all-short();

      svg {
        @include transition-short(transform);
      }

      &.active,
      &:active,
      &:hover {
        background: $vui-color-primary;
      }
    }
  }
}

.vui-side-menu--expanded {
  .vui-side-menu {
    width: 250px;
  }

  .vui-side-menu__toggler {
    svg {
      transform: rotateY(0);
    }
  }
}

.vui-side-menu--collapsed {
  .vui-side-menu {
    width: 88px;
  }

  .vui-side-menu__toggler {
    svg {
      transform: rotateY(180deg);
    }
  }
}

@media only screen and (max-width: 991px) {
  .vui-side-menu--expanded .vui-side-menu {
    width: 100%;
  }

  .vui-side-menu {
    @include transition-all-short();
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    z-index: 100;
    background: $color-white;
    padding-left: 4px;
    overflow-y: auto;

    .vui-side-menu__menu-items {
      padding-top: 74px;
    }

    .vui-side-menu__toggler-content {
      display: none;
    }

    .vui-side-menu__menu-toggler {
      width: $vui-header-height;
      height: $vui-portal-header-height-sm;
      display: block;
      position: relative;
      top: 0;
      bottom: 0;
      outline: none;
    }
  }

  .vui-side-menu--close {
    .vui-side-menu {
      left: -100%;
    }
  }

  .vui-side-menu--open {
    .vui-side-menu {
      left: 0;
    }
  }
}