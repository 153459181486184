.simplebar-scrollbar:before,
.simplebar-scrollbar.simplebar-visible:before {
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.8);
  background-color: rgba(255, 255, 255, 0.8);
  opacity: 1;
}

.simplebar-horizontal {
  .simplebar-scrollbar:before {
    height: 6px;
  }
}

.simplebar-vertical {
  .simplebar-scrollbar:before {
    width: 6px;
  }
}
