.error-dialog {

  @media (max-width: 470px) {
    margin: 0;
    width: 100%;
    min-width: 100%;
    height: 100%;
  }

  .error-dialog__image-icon {
    width: 140px;
  }

  .error-dialog__mobile-header {
    @media (max-width: 470px) {
      height: 50px;
      line-height: 30px;
    }

    @media (min-width: 471px) {
      display: none;
    }
  }

  .error-dialog__header {
    @media (max-width: 470px) {
      display: none;
    }
  }

  .error-dialog__footer {
    @media (max-width: 470px) {
      display: none;
    }
  }

  .error-dialog__body {
    @media (max-width: 470px) {
      width: 100%;
      word-wrap: break-word;
      position: fixed;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 4px;
      }
    }
  }

  .error-dialog__text {
    margin-top: 10px;
    font-size: 16px;
    color: #777;
  }

  .error-dialog__title {
    padding-top: 30px;
    font-size: 24px;
    color: #999;
  }

  .error-dialog__image {
    text-align: center;
  }

  li {
    padding: 3px 0;
  }

  @media (max-width: 599px) {

    .error-dialog__image-icon {
      width: 115px;
    }

    .error-dialog__title {
      font-size: 18px;
      text-align: center;
    }

    .error-dialog__text {
      font-size: 15px;
    }

    ul {
      padding: 5px 0 5px 23px;
    }
  }
}