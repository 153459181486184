@import "../../../velotooler-app";

.vui-form-images-list {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: stretch;

  &.vui-form-images-list--xl {
    .vui-form-images-list__photos {
      column-gap: 2rem;

      .photo-container {
        width: 180px;
        height: 200px;

        .photo-container__img {
          @include size-rect(180px);
        }

        .make-default {
          margin-top: 0.5rem;
        }
      }
    }

    .uploading,
    .error {
      @include size-rect(180px);
    }

    .uploading {
      .main-photo,
      .photo-container__img {
        width: 60px !important;
        height: 60px !important;
      }
    }

    .remove-image {
      margin: -192px -140px 0 0;
    }

    .vui-edit-btn {
      max-height: 180px;
    }
  }

  &.vui-form-images-list--s {
    .vui-form-images-list__photos {
      column-gap: 0.5rem;

      .photo-container {
        width: 50px;
        min-height: 50px;

        .photo-container__img {
          @include size-rect(50px);
        }
      }
    }

    .uploading,
    .error {
      @include size-rect(50px);
    }

    .uploading {
      .main-photo,
      .photo-container__img {
        width: 60px !important;
        height: 60px !important;
      }
    }

    .remove-image {
      margin: -52px -30px 0 0;
    }
  }

  .scrollable-list {
    width: 100%;
  }

  vui-edit-btn {
    z-index: 99;
    background: $color-white;
    display: flex;
    align-items: stretch;

    .vui-edit-btn {
      align-items: center;
    }
  }

  .vui-form-images-list__photos {
    display: flex;
    flex-direction: row;
    width: max-content;
    @include transition-short(transform);

    .photo-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .photo-container__img {
        border-radius: 0.25rem;
        cursor: default;

        &.photo-container__img--clickable {
          cursor: pointer;
          @include transition-short(filter);

          &:hover {
            filter: opacity(75%);
          }
        }
      }
    }
  }

  .repeat-upload {
    display: none;
    cursor: pointer;
  }

  .uploading,
  .error {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .error {
    .main-photo,
    .photo-container__img {
      display: none;
    }

    .repeat-upload {
      display: block;
    }
  }

  .remove-image {
    position: relative;
  }
}
