@import "../../../sass/variables";

.vt-sidenav {
  position: fixed;
  top: 64px;
  z-index: 74;
  max-width: 520px;
  background: #232323;
  overflow: hidden;
  height: 100%;
  width: 100%;

  @media (max-width: 343px) {
    top: 59px;
  }

  .vt-sidenav__body {
    background: #232323;
    box-shadow: none;
    overflow-y: auto;
    height: 100%;

    &::-webkit-scrollbar {
      width: 3px;
    }
  }

  .vt-sidenav__body-content {
    padding-bottom: 180px;
  }

  .vt-sidenav__opasity {
    opacity: 0.1;
    pointer-events: none;
    transition: opacity 0.3s ease-in;
  }

  .padding-bottom-140 {
    padding-bottom: 140px;
  }

  .vt-sidenav_readonly {
    pointer-events: none;
    input {
      color: #bfbfbf;
    }

    label {
      color: #999 !important;
    }
  }

  .vt-sidenav__header {
    flex-direction: row;
    background: #2D2D2D;
    color: #FFFFFF;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
  }

  .vt-sidenav__header-actions {
    display: flex;
    margin: auto;
  }

  .vt-sidenav__header-actions-cancel-btn {
    color: #E04141;
    font-size: 15px;
    &:hover {
      background-color: #353535;
    }
  }

  .vt-sidenav__header-title {
    flex: 10 0 0;
    display: flex;
    align-items: center;
    flex-direction: row;
    font-size: 18px;
  }

  .vt-sidenav__loading-indicator {
    position: absolute;
    top: 64px;
    left: 0;
    width: 100%;
    z-index: 10;
    opacity: 0;
    pointer-events: none;
    background-color: rgba(25, 25, 25, 0.9);
    transition: opacity 0.3s ease-in;
    height: 2px;
  }

  .vt-sidenav__loading-text {
    position: absolute;
    left: 50%;
    padding-top: 20px;
    transform: translateX(-50%);
    color: #999;
    text-align: center;
  }

  .vt-sidenav__loading-active {
    opacity: 1;
    pointer-events: auto;
  }

  md-progress-linear .md-container {
    height: 2px;
  }

  .vt-sidenav__footer {
    border: none;
    background-color: $color-primary-l3;
    width: 100%;
    position: fixed;
    left: 0;
    padding: 0;
    margin: 0;
    z-index: 100;
    height: 50px;
    bottom: 64px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);

    @media (max-width: 343px) {
      bottom: 59px;
    }
  }

  .vt-sidenav__footer-btn {
    width: 100%;
    margin: 0;
    height: 100%;
    border-radius: 0;
    margin: 0 !important;
  }
}

md-backdrop.md-opaque.ng-enter {
  opacity: 0.9 !important;
}