@import "../../../velotooler-app";

.vui-form-info {
  .vui-form {
    margin-left: 0;
    margin-top: 4rem;
    padding: 3rem;
    max-width: 854px;

    .vui-form-info__title {
      text-align: left;
      text-transform: capitalize;
      font-size: 1.375rem;
    }

    .vui-form-info__main-info {
      display: flex;
      flex-direction: row;
      border-radius: 1.25rem;
      background: $vui-color-primary;

      .main-info__images {
        display: flex;
        min-width: 290px;

        .main-info__general-image {
          @include size-rect(290px);
          border-radius: 1.25rem;
        }
      }

      .main-info__description {
        padding: 1rem;
        flex-grow: 1;
        max-width: calc(100% - 290px);
      }
    }

    .vui-form-info__field {
      display: flex;
      flex-direction: column;
      row-gap: 6px;
      padding-bottom: 1rem;

      .vui-form-info__label {
        font-size: 0.625rem;
        font-weight: 700;
        line-height: 0.75rem;
        color: $vui-label-color;
        text-transform: uppercase;
      }

      .vui-form-info__value {
        font-size: 0.875rem;
        font-weight: 600;
        line-height: 0.875rem;
        overflow: hidden;
        text-overflow: ellipsis;
        text-wrap: nowrap;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .vui-form-info {
    .vui-form {
      max-width: 100%;
    }
  }
}

@media only screen and (max-width: 767px) {
  .vui-form-info {
    .vui-form {
      margin-top: 2rem;
      padding: 1.25rem;

      .vui-form-info__title {
        font-size: 0.875rem;
      }

      .vui-form-info__main-info {
        flex-direction: column;

        .main-info__images {
          min-width: initial;

          .main-info__general-image {
            @include size-rect(100%);
          }
        }

        .main-info__description {
          max-width: 100%;
        }
      }
    }
  }
}