.not-covered-location {

  color: white;
  margin: auto;
  max-width: 1200px;
  text-align: center;

  .not-covered-location__icon {
    margin: 50px 0;
    width: 200px;
  }

  .not-covered-location__header {
    margin-top: 50px;
    font-size: 28px;
  }

  .not-covered-location__subtitle {
    margin-top: 30px;
    color: #ccc;
    font-size: 22px;
  }

  .not-covered-location__body {
    max-width: 600px;
    margin: 50px auto;
  }

  .not-covered-location__button {
    font-size: 15px !important;
  }

}