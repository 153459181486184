@import "../../../velotooler-app";

.vui-input-container {
  .vui-date-picker {
    .vui-date-picker__dropdown-icon {
      position: absolute;
      right: 0;
      top: 0;
      margin: 7px 0.75rem;
    }

    .vui-input {
      padding-right: 2.5rem;
      cursor: pointer;

      &::placeholder {
        color: $vui-dark-input-placeholder-color !important;
        opacity: 1;
      }
    }

    &.vui-input-container--opened {
      .vui-date-picker__dropdown--background,
      .vui-date-picker__dropdown {
        display: flex !important;
        flex-direction: column;

        .vui-date-picker__dropdown-container {
          display: flex;
        }

        .vui-date-picker__clear {
          display: flex;
          justify-content: end;

          .btn-sm {
            font-size: 0.75rem;;
            margin-top: 0.5rem;;
            min-width: 46px;
          }
        }
      }
    }
  }

  .vui-date-picker__dropdown--background {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    display: none;
  }

  &.vui-input-container__filter {
    margin: 0;

    .vui-input {
      font-size: 0.75rem;
      line-height: 0.75rem;
      padding: 0.5rem 1rem;
      height: 2rem;
      border: 1px solid $vui-main-menu-color;
      font-weight: 500;
    }

    .vui-date-picker__dropdown-icon {
      width: 0.625rem;
      height: 0.625rem;
      margin: 0.6875rem 1rem;
    }
  }
}

.vui-input-container.vui-form-control-lg {
  .vui-date-picker {
    .vui-date-picker__dropdown-icon {
      margin: 1.875rem 1rem;
    }

    .vui-input {
      padding-right: 3rem;
    }
  }
}

.vui-date-picker__dropdown {
  position: absolute;
  display: none;
  background: $color-white;
  min-width: 170px;
  border-radius: 0.25rem;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  max-height: calc(40px * 7);
  z-index: 9999;
  padding: 0.5rem;
  flex-direction: row;
  column-gap: 0.5rem;
  justify-content: space-around;
  max-width: 250px;

  .date-picker__days {
    min-width: 30px;
  }

  .date-picker__months {
    min-width: 56px;
  }

  .date-picker__years {
    min-width: 50px;
  }

  .date-picker-list {
    display: flex;
    flex-direction: column;
    row-gap: 0.25rem;
    width: 100%;

    .date-picker-list__title {
      font-size: 0.75rem;
      font-weight: 600;
      line-height: 0.875rem;
      text-align: center;
      text-transform: uppercase;
    }

    .date-picker-list__items {
      font-size: 0.875rem;
      line-height: 17px;
      text-align: center;
      display: flex;
      flex-direction: column;
      row-gap: 0.25rem;
      max-height: 120px;
      overflow-y: auto;

      .date-picker-list__item {
        cursor: pointer;
        transition: all 250ms ease-in-out;

        border-radius: 2px;

        &.active {
          font-weight: 600;
        }

        &:active,
        &:hover {
          background: rgba(0, 0, 0, 0.06);
          font-weight: 600;
        }
      }
    }
  }
}

.vui-date-picker__dropdown .date-picker-list__items::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
  border-radius: 3px;
  background-color: transparent;
}

.vui-date-picker__dropdown .date-picker-list__items::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

.vui-date-picker__dropdown .date-picker-list__items::-webkit-scrollbar-thumb {
  background: #E6E6E6;
}

@media only screen and (max-width: 767px) {
  .vui-input-container,
  .vui-input-container.vui-form-control-lg {
    .vui-date-picker {
      .vui-date-picker__dropdown-icon {
        margin: 0.5rem 0.75rem;
      }

      .vui-input {
        padding-right: 2rem;
      }
    }

    &.vui-input-container__filter {
      .vui-date-picker__dropdown-icon {
        width: 0.625rem;
        height: 0.625rem;
        margin: 0.6875rem 1rem;
      }
    }
  }
}
