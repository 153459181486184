@import "../../../velotooler-app";

.vui-form-image {
  aspect-ratio : 1 / 1;

  .vui-form-images__container {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    position: relative;
    width: 100%;
    aspect-ratio : 1 / 1;

    .vui-form-image__container {
      position: absolute;
      aspect-ratio : 1 / 1;
      @include transition-short(transform);
      cursor: default;
      width: 100%;

      &.inactive-left {
        transform: translateX(100%);
      }

      &.inactive-right {
        transform: translateX(-100%);
      }

      &.active {
        transform: translateX(0);
      }

      &.clickable {
        cursor: pointer;
      }

      .vui-form-image__item {
        width: 100%;
        border-radius: 1.25rem;

        &.no-clickable {
          cursor: default;
        }
      }
    }
  }

  .vui-form-images__pagination {
    position: relative;
    top: -34px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .vui-form-image {

  }
}

@media only screen and (max-width: 767px) {
  .vui-form-image {

  }
}