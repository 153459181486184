@import "../variables";

.vt-page {

  max-width: 1200px;
  margin: 0 auto;
  overflow: hidden;
  padding-top: 2em;
  padding-bottom: 2em;

  .vt-page__no-items {
    padding: 30px 20px;
    text-align: center;
    color: #999;
  }

  .vt-page__header {

    margin-left: 15px;
    margin-right: 15px;
    min-height: 70px;
    margin-bottom: 1em;
    background-color: $form-background;
    padding: 5px 20px;
    display: flex;
    align-items: center;
    color: $color-white;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

    &.vt-page__header_transparent {
      background-color: transparent;
      box-shadow: none;
      min-height: 0;
      margin-bottom: 0;
      padding: 0 0 5px 10px;
      margin-left: 0;
      margin-right: 0;
      border-bottom: 1px solid #333;
    }
  }

  .vt-page__body {
    margin-left: 15px;
    margin-right: 15px;
  }

  .vt-page_r-margin {
      margin-right: 15px;
  }

  .vt-page_l-margin {
    margin-left: 15px;
  }

  .vt-page_t-margin {
    margin-top: 15px;
  }

  .vt-page_b-margin {
    margin-bottom: 15px;
  }

  .vt-page__section_padding {
    padding: 15px;
  }

  .vt-page__section {
    background-color: $form-background;
    color: $color-white;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }

  .vt-page__body_padding {
    padding: 15px 0;
  }

  .vt-page__body_v-padding {
    padding: 15px 0;
  }

  .vt-page__body_vb-padding {
    padding-bottom: 15px;
  }

  .vt-page__body_background {
    background-color: $form-background;
    color: $color-white;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }

  .vt-page__title {
    flex: 1 0 0;
    min-width: 0;
    h1 {
      font-size: 1.5em;
      margin: 0;
    }
  }

}
