.vui-bicycle-public-link-model {
  .vui-bicycle-public-link-model__body {
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
    align-items: center;
    justify-content: center;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 17px;
  }

  .vui-bicycle-public-link-model__actions {
    column-gap: 1.5rem;
  }
}