.profile-rating {

  .profile-rating__review-item {
    margin-bottom: 20px;
  }

  .profile-rating__review-item-service {
    display: inline-block;
    color: white;
    font-weight: 500;
  }

  .profile-rating__review-item-date {
    display: block;
    font-size: 13px;
    color: #777;
  }

  .profile-rating__review-item-comment {
    margin-top: 5px;
    color: #ccc;

    &.profile-rating_color-dark-gray {
      color: #555;
    }
  }
}