@import "../../../velotooler-app";

.collapsible-section {
  display: flex;
  flex-direction: column;

  &.expand {
    .collapsible-section__title {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  .collapsible-section__title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    column-gap: 1rem;
    cursor: pointer;

    .section-title {
      font-size: 0.875rem;
      font-weight: 600;
    }

    svg {
      @include transition-short(transform);
    }
  }

  .collapsible-section__content {
    overflow: hidden;
    height: 0;
    @include transition-short(height);

    .section-content {
      padding-top: 0.5rem;
    }
  }
}
