.change-profile {
  .change-profile__button-container {

  }

  .change-profile__avatar {
    margin: 0 -23px;
  }

  .change-profile__public-profile-button {
    display: block;
    max-width: 200px;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .change-profile__share-buttons {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #232323;
    border-bottom-right-radius: 5px;
    padding: 5px 5px 5px 0;
  }
}