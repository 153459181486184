.add-bike {
  .add-bike__brand-autocomplete {
    padding-bottom: 23px;

    md-autocomplete {
      height: 26px;
    }
  }

  .add-bike__brand-autocomplete.md-input-has-placeholder label:not(.md-input-focused) {
    transform: translate3d(0, 28px, 0) scale(1) !important;
    transition: transform cubic-bezier(0.25, 0.8, 0.25, 1) 0.4s, width cubic-bezier(0.25, 0.8, 0.25, 1) 0.4s;
  }

  .add-bike__brand-autocomplete.md-input-focused label:not(.md-no-float),
  .add-bike__brand-autocomplete.md-input-has-value label:not(.md-no-float) {
    transform: translate3d(0, 3px, 0) scale(0.9) !important;
  }
}

