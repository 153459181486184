.vui-manage-service-model {
  .vui-modal {
    max-width: 440px;
    width: 100%;

    .vui-input-container {
      transition: none;
    }

    .vui-manage-service-model__description {
      textarea {
        height: 6.625rem;
      }
    }
  }

  .vui-manage-service-model__body {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;

    .vui-manage-service-model__info {
      font-size: 1.25rem;
      font-weight: 600;
    }

    .vui-manage-service-model__service-type {
      display: flex;
      flex-direction: column;
      row-gap: 0.5rem;
      
      .vui-radio-button {
        margin: 0;
      }
    }
  }

  .vui-manage-service-model__actions {
    column-gap: 1.5rem;
  }
}

@media only screen and (max-width: 991px) {
  .vui-manage-service-model {
    .vui-manage-service-model__body {
      .vui-input-container__serial-number {
        .vui-popover {
          .vui-popover__dropdown {
            width: 325px;
            padding-bottom: 2rem;

            .vui-popover__dropdown-container {
              display: block;
            }
          }
        }
      }
    }
  }
}