.profile-services {
  .profile-services__pricing-section-service {
    display: flex;
    background-color: rgba(60, 60, 60, .3);
    border-radius: 5px;
    padding: 10px;
    margin-top: 5px;
  }

  .profile-services__pricing-section-service-description {
    flex: 1 1 60%;
    color: #eee;
    line-height: 27px;
  }

  .profile-services__pricing-section-price {
    font-weight: 500;
    flex: 1 1 10%;
    margin-left: 10px;
    line-height: 27px;
  }

  .profile-services__pricing-section-button {
    margin: 0 0 0 10px !important;
    flex: 1 1 30%;
  }
}