.img-upload-dialog {
  @media (max-width: 470px) {
    margin: 0;
    width: 100%;
    min-width: 100%;
    height: 100%;
  }

  .img-upload-dialog__header {
    @media (max-width: 470px) {
      display: none;
    }
  }

  .img-upload-dialog__mobile-header {
    @media (min-width: 471px) {
      display: none;
    }

    display: block;
    padding: 15px 17px;
  }

  .img-upload-dialog__body {
    @media (max-width: 470px) {
      position: fixed;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      &::-webkit-scrollbar {
        width: 4px;
      }
    }
  }

  .img-upload-dialog__footer {
    @media (max-width: 470px) {
      display: none;
    }
  }

  .img-upload-dialog__mobile-footer {
    @media (max-width: 470px) {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 0;
      -webkit-overflow-scrolling: touch;
    }

    @media (min-width: 471px) {
      display: none;
    }
  }

  .img-upload-dialog__mobile-footer-btn {
    margin: 0 !important;
    border-radius: 0;
    height: 50px;
    position: relative;
    width: 100%;
    left: 0;
    font-size: 18px;
    font-weight: bold;
    color: #4a4a4a;
  }

  .img-upload-dialog__upload-indicator {
    @media (max-width: 470px) {
      text-align: center;
      color: #3e3e3e;
      font-weight: 500;
      font-size: 15px;
      line-height: 53px;
      background: $vt-color-primary;
    }
  }
}