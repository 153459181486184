.vui-add-payment-card-model {
  .vui-add-payment-card-model__body {
    max-width: 320px;
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;

    .vui-add-payment-card-model__x-gap {
      column-gap: 1rem;
    }
  }

  .vui-add-payment-card-model__actions {
    column-gap: 1.5rem;
  }
}