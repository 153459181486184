@import "../../variables";

.pageable-list {

  position: relative;
  color: white;

  .pageable-list_color-dark-gray {
    color: #555;
  }

  .pageable-list__button-container {
    clear: both;
    display: flex;
    justify-content: center;
    opacity: 1;
    margin-top: 15px;

    &.pageable-list__button-container_rendering {
      opacity: 0;
    }

  }

  .pageable-list__paging-button:disabled {
    color: #333 !important;
  }

  .pageable-list__page-prev {
    margin-right: 1px !important;
    border-top-left-radius: 4px !important;;
    border-bottom-left-radius: 4px !important;;
  }

  .pageable-list__page-next {
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;;
  }

  .pageable-list__paging-button {
    padding: 10px;
    color: white !important;
    background-color: rgba(50, 50, 50, 0.3) !important;
    margin: 0;
    border-radius: 0;
    line-height: 15px;
    height: 34px;

    &:hover:enabled {
      background-color: rgba(70, 70, 70, 0.3) !important;
      border-bottom: 1px solid $vt-color-primary;
    }
  }

  .pageable-list__page-index {
    color: white;
    cursor: pointer;
    font-weight: bold;
    padding: 10px 15px;
    height: 34px;
    max-width: 55px;
    background-color: rgba(50, 50, 50, 0.3);
    margin-right: 1px;

    &:hover:enabled {
      background-color: rgba(70, 70, 70, 0.3);
      border-bottom: 1px solid $vt-color-primary;
    }
  }

  .pageable-list__page-index_selected {
    color: $vt-color-primary;
    border-bottom: 1px solid $vt-color-primary;
    cursor: default;
    pointer-events: none;
  }

  .pageable-list__page-index-container {
    font-size: 14px;
    line-height: 14px;
  }

  .pageable-list__items {

  }

  .pageable-list__items-not-found-icon {
    width: 160px;
  }

  .pageable-list__items-not-found-title {
    padding-top: 30px;
    font-size: 24px;
    color: #999;
  }

  .pageable-list__items-not-found-text {
    font-size: 16px;
    color: #777;
    padding-top: 20px;
  }

  .pageable-list__paging-info {
    color: #777;
    font-size: 14px;
    margin: auto 0 auto auto;
  }

  // 3rd party
  md-progress-linear .md-container {
    height: 2px;
  }

  .pageable-list__loading-indicator {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
    opacity: 0;
    pointer-events: none;
    background-color: rgba(25, 25, 25, 0.9);
    transition: opacity 0.3s ease-in;
  }

  .pageable-list__loading-active {
    opacity: 1;
    pointer-events: auto;
  }

  .pageable-list__loading-text {
    position: absolute;
    left: 50%;
    padding-top: 20px;
    transform: translateX(-50%);
    color: #999;
    text-align: center;
  }

  .pageable-list__empty-data {
    text-align: center;
    width: 100%;
    margin: 30px 5px;
  }

  .pageable-list__empty-data-text {
    margin-top: 10px;
    color: #999;
  }

  .pageable-list__empty-data-add-new {
    margin-top: 30px !important;
  }

  .pageable-list__items-empty-data-title {
    padding-top: 30px;
    font-size: 24px;
    color: $vt-color-primary;
  }

  .pageable-list__items-error {
    text-align: center;
    width: 100%;
    margin: 30px 5px;
  }

  .pageable-list__items-error-icon {
    width: 140px;
  }

  .pageable-list__items-error-title {
    padding-top: 30px;
    font-size: 24px;
    color: #999;
  }

  .pageable-list__items-error-text {
    margin-top: 10px;
    font-size: 16px;
    color: #777;
  }

  .pageable-list__items-try-again-button {
    margin-top: 20px;
  }

  .pageable-list__items-try-again-button-icon {
    padding-left: 10px;
  }

  .pageable-list__item {

    &.pageable-list__item_rendering {
      display: none;
    }
  }

  .pageable-list__filter {
    padding: 10px;
    z-index: 74 !important;

    &.pageable-list__filter_hidden {
      display: none;
    }

  }

  .pageable-list__actions {
    padding: 10px;
    position: absolute;
    top: 0;
    right: 0;
  }

  .pageable-list__filter_mobile {
    &.pageable-list__filter_expanded {
      position: fixed;
      overflow-y: auto;
      overflow-x: hidden;
      z-index: 76 !important;
      margin-top: 0;
      top: 0 !important;
      left: 0 !important;
      background-color: #222;
      height: 100vh !important;
      width: 100% !important;
    }
  }

  .pageable-list__filter_sticky {
    background-color: #222;
  }

  .pageable-list__filter-button {
    margin: 0 !important;
  }

  .pageable-list__filter-icon {
    vertical-align: middle;
  }

  .pageable-list__filter-text {
    vertical-align: middle;
  }

  .pageable-list__filter-fields {
    margin-top: 10px;
  }

  .pageable-list__filter_collapsed {
    .pageable-list__filter-field {
      max-height: 0;
      min-height: 0;
      overflow: hidden;
    }
  }

  .pageable-list__filter-field {
    min-width: 100px;
    max-height: 450px;
    -webkit-transition: max-height .3s ease-in-out;
    transition: max-height .3s ease-in-out;

    md-autocomplete {
      min-width: 150px;
    }

    .rzslider {
      margin: 15px 0 15px 0;
    }
  }

  .pageable-list__filter-field-label {
    color: #888;
  }

  .pageable-list__range-filter-field-value {
    font-weight: bold;
  }

  .pageable-list__range-filter-field-label-container {
    text-align: center;
    margin-top: 12px;
  }

  .pageable-list__inline-filter-button {
    margin: 0 !important;
    padding-left: 0;
    padding-right: 0;
    position: absolute;
    right: 0;
    min-width: 50px;
  }

  .pageable-list__filter-count-selected {
    vertical-align: middle;
    width: 18px;
    height: 18px;
    display: inline-block;
    line-height: 18px;
    border-left: 1px solid #333;
    color: #ffec92;
    padding-left: 5px;
    margin-left: 5px;
  }

  .pageable-list__toolbar {
    position: absolute;
    right: 3px;
    top: 5px;
    z-index: 0;
  }

  .pageable-list__count-found {
    padding-left: 10px;
    font-size: 15px;
    color: #777;
    padding-bottom: 10px;
  }

}