@import "../variables";

.vt-ui-icon-bikes {
  background-image: url(/img/menu/bike.png);
}

.vt-ui-icon-stations {
  background-image: url(/img/menu/no-organization-avatar.png);
}

.vt-ui-icon-home {
  background-image: url(/img/menu/home.png);
}

.vt-ui-icon-mechanic-map {
  background-image: url(/img/menu/mechanic-location.png);
}

.vt-ui-icon-dealer {
  background-image: url(/img/menu/dealers.png);
}

.vt-ui-icon-setting {
  background-image: url(/img/menu/settings.png);
}

.ui-avatar {
  font-size: 36px;
  text-align: center;
}

.vt-ui-icon-notification {
  background-image: url(/img/menu/notification.png);
}

.vt-ui-icon-history {
  background-image: url(/img/menu/history.png);
}

.vt-ui-icon-list {
  background-image: url(/img/menu/list.png);
}

.vt-ui-icon-request-search {
  background-image: url(/img/menu/search.png);
}

.vt-ui-icon-down-arrow {
  background-image: url(/img/menu/down-arrow.png);
}

.ui-sidebar-element-container {
  overflow: hidden;

  .notification-budget.total-notifications {
    font-size: 10px;
    position: absolute;
    background: $input-remove-color;
    visibility: visible;
    display: block;
    margin-top: 34px;
    margin-left: 36px;
  }
}

@media (min-width: 671px) {
  i.ui-logo {
    background-image: url(/img/menu/logo.png);
  }
}

@media (max-width: 880px) {
  i.ui-logo {
    background-image: url(/img/menu/velotooler-logo.png) !important;
  }
}

a:hover .ui-sidebar-element-label, .ui-sidebar-element-container .ui-sidebar-element-label {
  color: $color-white;
}

.ui-submenu {
  .ui-submenu-element-label, .ui-submenu-element-label:hover {
    color: $color-white;
  }
}

.avatar-menu {
  margin: 0;
  padding: 0;
  border: 0 transparent;
  background-color: transparent;

  .ui-sidebar-element-label {
    text-align: left;
  }
}

.avatar-label:hover {
  background-color: #303030;
}

.avatar-label:active {
  background-color: transparent;
}

@media (min-width: 471px) {
  .ui-sidebar {
    .ui-sidebar-element-container:nth-last-child(3) {
      flex: 1 0 auto;
    }

    .ui-sidebar-element-container:nth-last-child(2) {
      flex: 0 0 auto;
    }

    .ui-sidebar-element-container:last-child {
      padding-bottom: 5px;
    }

    &.ui-sidebar-active {
      z-index: 75;
    }
  }
}

.ui-sidebar-element-container .ui-sidebar-element-label {
  text-align: left;
}

.ui-sidebar {

  -webkit-overflow-scrolling: touch;

  &.vt-sidebar_hidden {
    @media (max-width: 470px) {
      transform: translateY(200px);
      transition: all 0.7s ease-in-out;
    }
  }

  &.vt-sidebar_opened {
    @media (max-width: 470px) {
      transform: translateY(0);
      transition: all 0.7s ease-in-out;
    }
  }
}
