@import "../../../../sass/variables";

.bicycle-step {

  .bicycle-step__item-sign {
    width: 6px;
    height: 6px;
    border-radius: 5px;
    vertical-align: top;
  }

  .bicycle-step__item-new-sign {
    background-color: $color-green;
  }

  .bicycle-step__menu {
    display: inline-block;
  }

  .bicycle-step__menu-item:hover {
    background-color: #2d2d2d;
    box-shadow: 14px 4px 10px rgba(31, 31, 31, 0.5);
  }

  .bicycle-step__menu-item {
    display: inline-block;
    text-overflow: ellipsis;
    outline: none;
    white-space: nowrap;
    text-decoration: none;
    cursor: pointer;
    padding: 0 20px;
    border-bottom: 2px solid #666;
    font-size: 16px;
    color: #666;
    height: 45px;
    line-height: 45px;

    @media (max-width: 350px) {
      padding: 0 10px;
    }
  }

  .bicycle-step__menu-item_selected {
    background-color: #222;
    border-bottom: 2px solid white !important;
    font-weight: 500;
    span {
      color: white;
    }
  }

  .bicycle-step__list {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 345px;
    background-color: $color-primary-d1;

    &::-webkit-scrollbar {
      width: 5px;
      background-color: #666;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #444;
    }
  }

  .bicycle-step__item-container {
    padding-right: 1rem;
    padding-bottom: 0.5rem;
  }

  .bicycle-step__item-container_last {
    padding-bottom: 0;
  }

  .bicycles-step__select-icon {
    border: none;
    margin: auto;

    @media (max-width: $mobile-width) {
      width: 25px;
      height: 25px;
    }

    @media (min-width: $not-mobile-width) {
      width: 30px;
      height: 30px;
    }
  }

  .bicycle-step__item-sn-label {
    font-size: 14px;
  }

  .bicycle-step__item-description-label {
    color: #F5F5F5;
  }

  .bicycle-step__item-description-sn {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    @media (max-width: $mobile-width) {
      max-width: 150px;;
    }

    @media (min-width: $not-mobile-width) and (max-width: 650px) {
      max-width: 180px;
    }

    @media (min-width: 651px) {
      max-width: 200px;
    }
  }

  .bicycle-step__item-sn-name {
    background-color: $vt-color-primary;
    font-weight: 500;
    color: black !important;
    border-radius: 2px;
    padding: 1px 3px;
    margin-left: 5px;
    font-size: 13px;
  }

  .bicycle-step__item-icon {
    $icon-width: 52px;
    width: $icon-width;
    height: $icon-width;
    border-radius: 50%;
    margin: auto;
  }

  .bicycle-step__item-description {
    font-weight: normal;
    flex: 26 0 0;
    margin: auto auto auto 15px;
  }

  .bicycle-step__item {
    border-radius: 0.5rem;
    background-color: $color-primary;
    display: flex;
    width: 100%;
    box-sizing: border-box;
    align-self: stretch;
    margin: auto;
    flex: 1 0 0;
    cursor: pointer;

    @media (max-width: $mobile-width) {
      padding: 5px 10px;
    }
    @media (min-width: $not-mobile-width) {
      padding: 10px 20px;
    }

    &:hover {
      background-color: $color-primary-l2;
    }
  }

  .station-autocomplete {
    background-color: transparent;
    border-bottom: 1px solid #555555;

    md-autocomplete-wrap {
      box-shadow: none;
    }

    input {
      color: white;
      padding: 0 4px !important;

      &::-webkit-input-placeholder {
        color: #ababaa !important;
      }

      &::-moz-placeholder { /* Firefox 19+ */
        color: #ababaa !important;
      }
      &:-ms-input-placeholder { /* IE 10+ */
        color: #ababaa !important;
      }
      &:-moz-placeholder { /* Firefox 18- */
        color: #ababaa !important;
      }
    }

    button {
      path {
        fill: white !important;
      }
    }

    md-list-item img {
      border: 1px solid #333;
    }

    input[type=search] {
      font-weight: 400;
      font-size: 16px;
    }
  }

  .bicycle-step__empty-bike-list {
    text-align: center;
    padding: 60px 0;
  }

  .bicycle-step__items-not-found-icon {
    width: 160px;
  }

  .bicycle-step__items-not-found-title {
    padding-top: 30px;
    font-size: 24px;
    color: #999;
  }

  .bicycle-step__items-not-found-text {
    font-size: 16px;
    color: #777;
    padding-top: 20px;
  }

  .bicycle-step__close-icon {
    position: absolute;
    right: 13px;
    top: 9px;
    width: 13px;
    height: 13px;
  }
}

.md-autocomplete-suggestions li {
  border-bottom: 1px solid #222;
}

ul.md-autocomplete-suggestions li:hover {
  color: #777
}

.md-autocomplete-suggestions-container.md-default-theme li, .md-autocomplete-suggestions-container li {
  color: #777;
}

.autocomplete-item {
  $icon-width: 30px;
  height: 100%;
  position: relative;

  .bike-label {
    color: #cac5c5;
  }

  .item__icon {
    width: $icon-width !important;
    height: $icon-width !important;
    margin: 0 !important;
    display: block;
    border: 1px solid #333;
  }

  .icon--left {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }

  .item__text {
    line-height: 40px;
    color: #777;
    overflow: hidden;
    text-overflow: ellipsis;
    span {
      margin-right: 10px;
    }
  }

  .item__text--icon-left-margin {
    margin-left: $icon-width + 5px;
  }
}

.autocomplete-item {
  .organisation-type-label {
    padding-right: 10px;
  }

  .organisation-label {
    background-color: $color-green;
    border-radius: 3px;
    color: black;
    font-weight: 500;
    padding: 0 3px;
    text-decoration: none;
  }
}



