@import "../../../../velotooler-app";

.landing-page-desc-block {
  .landing-page-desc-block__title h2 {
    font-size: 2rem;
    line-height: 40px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 60px;
    margin-bottom: 0;
    letter-spacing: 0.5px;
    max-width: 500px;
  }

  .landing-page-desc-block__body {
    margin-top: 50px;

    p {
      font-size: 1.25rem;
      line-height: 2.5rem;
      margin-top: 1.25rem;
      max-width: 600px;
    }
  }

  .landing-page-desc-block__dot {
    top: -5px;
  }

  .landing-page-desc-block__dot-black {
    fill: $color-dark;
  }

  .landing-page-desc-block__dot-yellow {
    fill: $vui-color-primary;
  }

  &.landing-page-desc-block__theme-light {
    color: $vui-dark-text-color;
  }

  &.landing-page-desc-block__theme-dark {
    color: $color-white;

    .vui-href {
      color: $vui-color-primary;

      &:hover,
      &:focus,
      &:active {
        color: $vui-color-primary--hover;
      }
    }
  }
}

.landing-page-desc-block__action.vui-btn,
.landing-page-desc-block__action .vui-btn {
  max-width: 32rem;
  width: 100%;
  margin-top: 2rem;
}

@media only screen and (min-width: 992px) and (max-width: 1440px) {
  .landing-page-desc-block {
    .landing-page-desc-block__title h2 {
      margin-top: 40px;
      font-size: 22px;
      line-height: 28px;
    }

    .landing-page-desc-block__body {
      margin-top: 40px;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .landing-page-desc-block {
    .landing-page-desc-block__dot {
      margin-left: 0;
    }

    .landing-page-desc-block__title {
      margin: 70px 0
    }

    .landing-page-desc-block__body {
      p {
        font-size: 1rem;
        line-height: 1.25rem;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .landing-page-desc-block {
    .landing-page-desc-block__title h2 {
      margin: 60px 15px 40px 0;
      font-size: 22px;
      line-height: 32px;
    }

    .landing-page-desc-block__body {
      p {
        font-size: 1rem;
        line-height: 1.25rem;
      }
    }
  }
}
