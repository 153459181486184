@import
"vt-button",
"vt-link",
"vt-section",
"feedback-stars",
"gallery",
"grid",
"bike-status",
"bicycles-grid",
"organisations-grid",
"stations-grid",
"banner-wrapper.scss",
"vt-header",
"vt-sidebar",
"vt-submenu",
"share-buttons",
"price-view",
"vt-page",
"vt-fab",
"vt-modal",
"short-address-view",
"widget/vt-widget",
"widget/collapsible-list",
"widget/pageable-list",
"widget/table-list",
"widget/grid-list",
"widget/tabset",
"widget/vt-textarea",
"widget/vt-message-dialog",
"widget/total-count",
"vt-table",
"text-ellipsis",
"geo/vt-google-map",
"geo/location-autocomplete",
"geo/detailed-location-autocomplete",
"vt-icons",
"widget/vt-sidenav",
"vt-dropdown";

.md-open-menu-container {
  .md-button {
    text-transform: initial;
    font-size: 1em;
  }
}

md-dialog.vt-dialog {
  form {
    padding: 20px;
    color: white;
    margin-bottom: 20px;
    min-height: 50px;
  }
  md-dialog-actions {
    display: block;
  }
}

.vt-note {
  margin: 30px 25px;
  text-align: justify;
  border-left: 2px solid #ffec92;
  padding-left: 20px;
  color: white;
}

.vt-chip {
  background-color: #292929;
  border-radius: 11px;
  display: inline-block;
  padding: 3px 8px;

  &.vt-chip_sm {
    font-size: 13px;
  }
}

.vt-input-hint {
  font-size: 12px;
  line-height: 14px;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
  color: #999;
}

.vt-shadow {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.vt-radius {
  border-radius: 5px;
}

.vt-separator {
  margin: 10px 0;
  border-bottom: 1px solid #333;
}

.vt-rating {
  background: #EF5350;
  display: inline-block;
  position: relative;
  font-size: 12px;
  text-align: center;
  line-height: 18px;
  padding: 0 3px;
  min-width: 6px;
  height: 18px;
  font-weight: 400;
  white-space: nowrap;

  i {
    font-size: 8px;
    line-height: 9px;
    vertical-align: middle;
  }

  &:after {
    border-left: 5px solid #EF5350;
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
    content: "";
    height: 0;
    top: 0;
    position: absolute;
    right: -5px;
    width: 0;
  }

}


.vt-download-app-button {
  display: block;
  min-width: 200px;
  max-width: 200px;
  background-color: black;
  margin: auto;
  border-radius: 7px;
  cursor: pointer;
  border: 1px solid #000;
  color: white;
  padding: 5px 0;

  &:hover {
    background-color: #090909;
    border: 1px solid #222;
  }

  .vt-download-app-button__int {
    max-width: 170px;
    margin: auto;
  }

  .vt-download-app-button__caption {
    font-size: 10px;
    line-height: 10px;
    padding-left: 3px;
  }

  .vt-download-app-button__main {
    font-size: 25px;
  }

  .vt-download-app-button__icon {
    min-width: 32px;
    max-width: 32px;
    min-height: 32px;
    max-height: 32px;
    vertical-align: middle;
    display: inline-block;
  }

  .vt-download-app-button__body {
    display: inline-block;
    vertical-align: middle;
  }
}

.vt-hover-shadow {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  transition: all 0.3s;

  &:hover {
    transition: all 0.3s;
    box-shadow: 0 10px 14px rgba(0, 0, 0, 0.25), 0 10px 14px rgba(0, 0, 0, 0.22);
  }
}

.collapsible-field {
  max-height: 0;
  overflow: hidden;
  -webkit-transition: all 250ms ease-in-out;
  -moz-transition: all 250ms ease-in-out;
  -o-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
}

.collapsible-field_active {
  max-height: 150px;
  overflow: hidden;
}
