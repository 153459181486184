@import "../variables";
@import "grid";
@import "bike-status";

.bikes-grid {
  @include gridContainer();

  .bike {
    @include gridElement(bike);

    .add_bike_icon {
      padding: 34px 0;
    }

    .bike__actions-tab {
      position: relative;

      @include gridElementActionsTab();

      .bike__action_button {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: 0;
      }

      .bike__action_left_button {
        margin-left: 8px;
        float: left;
      }

      .bike__action_right_button {
        margin-right: 8px;
        float: right;
      }
    }

    .bike__location {
      background-color: $form-background;
      padding: 0 15px;

      .bike__location-address {
        color: $color-white;
        font-size: 0.8em;
        padding-top: 8px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      i {
        color: #6F6F6F;
        font-size: 1.5em;
      }
    }

    .bike_serial {
      color: #6F6F6F;
      font-size: 0.8em;
    }

    .bike_status {
      width: 100%;

      @include getBikeStatuses(0.7em);
    }
  }

  .fa-background {
    color: $vt-color-primary;
  }

  @include gridElementMedia(bike);
}
