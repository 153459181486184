@import "../../../velotooler-app";

.vui-import-bicycles-model {
  .vui-import-bicycles-model__body {
    width: 400px;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    align-items: center;

    .body__message {
      font-size: 0.875rem;
      font-weight: 600;
    }

    .body__bicycle-list {
      display: flex;
      flex-direction: column;
      row-gap: 0.5rem;
      width: 100%;

      .body__bicycle-list-item {
        font-size: 0.875rem;
      }
    }
  }

  .vui-import-bicycles-model__actions {
    justify-content: end;
  }
}

@media only screen and (max-width: 767px) {
  .vui-import-bicycles-model {
    .vui-modal-container {
      .vui-modal {
        padding: 1rem;
        row-gap: 1rem;

        .vui-import-bicycles-model__body {
          row-gap: 1rem;
          width: 100%;
        }
      }
    }
  }
}