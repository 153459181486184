@import "../../../../velotooler-app";

.vui-organisation-owner-organisations-list {
  display: flex;
  flex-direction: column;
  border-radius: 1.25rem;
  max-width: 400px;
  width: 100%;
  margin: 4rem auto 2rem;

  .organisations__header {
    background: $vui-color-primary;
    padding: 3rem 1rem 1.5rem 1rem;
    display: flex;
    border-radius: 1.25rem 1.25rem 0 0;

    .header__content {
      display: flex;
      flex-direction: column;
      row-gap: 1.5rem;
      margin-left: auto;
      margin-right: auto;
      max-width: 184px;
      width: 100%;
    }

    .header__velotooler {
      display: flex;
      flex-direction: row;
      column-gap: 1rem;
      justify-content: start;
      align-items: center;

      .header__logo {
        width: 1.25rem;
        height: 1.25rem;
      }
    }

    .header__profile {
      display: flex;
      flex-direction: column;
      row-gap: 0.25rem;

      .profile__name {
        font-size: 0.875rem;
        font-weight: 600;
      }

      .profile__email {
        font-size: 0.625rem;
        font-weight: 600;
        color: $vui-label-color;
      }
    }
  }

  .organisations__body {
    background: $color-white;
    padding: 2rem 1rem 3rem 1rem;
    border-radius: 0 0 1.25rem 1.25rem;
    display: flex;

    .list__container {
      max-width: 184px;
      width: 100%;
      margin-right: auto;
      margin-left: auto;
      display: flex;
      flex-direction: column;
      row-gap: 1rem;

      .list__title {
        font-size: 0.875rem;
        font-weight: 600;
      }

      .list__content {
        display: flex;
        flex-direction: column;
        row-gap: 0.25rem;

        .vui-input-container {
          margin: 0;

          .vui-input-group {
            width: 1rem;
            height: 1rem;
            margin: .25rem 0;
          }

          .vui-input {
            border-radius: 0;
            border-top: 0;
            border-left: 0;
            border-right: 0;
            padding: 0.25rem 0.25rem 0.25rem 1.25rem;
            height: 1.5rem;
          }
        }
      }

      .list__load-more {
        display: flex;
        justify-content: center;

        .load-more__link {
          display: flex;
          flex-direction: row;
          column-gap: 0.25rem;
          justify-content: center;
          align-items: center;
          font-size: 0.625rem;
          font-weight: 600;
          cursor: pointer;

          span {
            @include transition-short(color);
          }

          &:hover span {
            color: $vui-label-color;
          }
        }

        .load-mor__loading img {
          height: 2.25rem ;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .vui-organisation-owner-organisations-list {
  }
}

@media only screen and (max-width: 767px) {
  .vui-organisation-owner-organisations-list {
  }
}
