@import "../../../velotooler-app";

.vui-input-stars {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;

  .vui-input-stars__star {
    padding: 0 0.5rem;

    &:hover,
    &:active,
    &.active {
      svg path {
        fill: $vui-color-primary;
      }
    }
  }
}
