@import "../../sass/variables";

$mobile-small-screen-width: 343px;
$header-width-small: 59px;
$header-height-small: 59px;

.mobile-menu-icon {
  text-align: center;
  display: inline-block;
}

.menu-avatar {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  padding: 16px;
}

@media (max-width: $mobile-small-screen-width) {
  .menu-avatar {
    width: $header-width-small;
    height: $header-height-small;
    padding: 13px;
  }
}

img.menu-avatar {
  display: block;
}

vt-main-menu {

  .vt-main-menu__menu-element_highlighted {
    background-color: #423c37;

    &:hover {
      background-color: #625c57;
    }
  }

  img.logo {
    width: inherit;
    float: left;
  }

  .dimmed {
    background-color: rgba(0, 0, 0, .8) !important;
  }

  .home-page-menu {
    position: fixed;
    z-index: 1000;
    width: 100%;
    top: 0;
    left: 0;
  }

  .ui-header {
    z-index: 76;
    background-color: $color-primary-l1;
  }

  .ui-sidebar {
    background-color: $color-primary-l1;

    @media (max-width: $mobile-width) {
      background-color: $color-primary-l3;
    }
  }

  .ui-panel {
    background-color: $color-primary-l1;
  }

  .ui-scroll::-webkit-scrollbar-track {
    background-color: $color-primary-l4;
    -webkit-box-shadow: none;
    border-radius: 0;
  }

  .ui-scroll::-webkit-scrollbar-thumb {
    background-color: $color-primary-d1;
    -webkit-box-shadow: none;
    border-radius: 0;
  }

  .float-left {
    float: left !important;
  }

  .active {
    border-bottom: 2px solid #fff2b7 !important;
  }

  a:hover .ui-sidebar-element-label, .ui-panel-element-container:hover, .ui-submenu-element-container:hover, .ui-header-element-container:hover {
    background-color: $color-primary-l3;
  }
}

.ui-header .ui-logo {
  width: 110px;
  height: 100%;
}

.ui-header-btn.ui-header-logo {
  height: 64px;
}

.ui-header-btn {
  color: #ffec92;
}

.ui-panel .ui-panel-element-container.ui-panel-btn-active:after {
  visibility: hidden;
}

.ui-panel-element-container.bicycle-service-btn {
  background-color: transparent;
}

@media (min-width: 881px) {
  .ui-header-btn.ui-header-logo {
    padding: 0 20px !important;
  }

  .ui-header .ui-logo {
    background: url(/img/logo.png) center center no-repeat;
    background-size: contain;
  }
}

@media (max-width: 880px) {
  .ui-header .ui-logo {
    background: url(/img/velotooler-logo.png?v=1.1) center center no-repeat;
    background-size: contain;
    width: 40px;
    height: 64px;
    margin-left: 12px;
  }
}

@media (max-width: $mobile-small-screen-width) {
  .ui-header .ui-logo {
    width: 37px;
    height: 58px;
    margin-left: 11px;
  }
}

@media (max-width: 880px) {
  .ui-panel {
    position: fixed;
    width: 230px;
  }
}

@media (max-width: 1350px) and (min-width: 881px) {
  .ui-panel {
    display: block;
    width: 695px;
    right: 0;
  }
}

@media (min-width: 471px) {
  .ui-header-logo-conteiner {
    width: auto;
  }
}

.ui-panel {

  @media (min-width: 881px) {
    .ui-panel-fieldset.ui-panel-active-scale {
      transform: scaleX(1);
      -webkit-transform: scaleX(1);
    }
  }

  .ui-panel-element-container {
    a, a:hover {
      span {
        color: $color-white;
      }
    }

    .help {
      padding: 0 20px;
    }

    .inline-block .request-button-container {
      padding-right: 45px;
      margin-right: 20px;
    }

    @media (min-width: 881px) {
      &.request-element-container {
        display: block;
        padding-top: 12px !important;

        &:hover {
          background: transparent;
        }

        &:hover span, span {
          color: #333;
          font-size: 13px;
        }
      }
    }
    @media (max-width: 880px) {
      &.request-element-container {
        display: none;
      }
    }
  }
}

@media (max-width: 470px) {
  .ui-header .ui-settings-mobile {
    right: 64px;
  }

  .ui-avatar {
    font-size: 0 !important;
  }

  .ui-header .ui-avatar-mobile {
    vertical-align: middle;
    margin-top: 0;
  }
}
