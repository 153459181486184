@import "../../../velotooler-app";

.vui-stepper-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1.5rem;
  cursor: pointer;

  .vui-stepper-item__label {
    font-size: 0.75rem;
    font-weight: 600;
    line-height: 0.875rem;
    color: $vui-label-color;
    @include transition-short(color);
  }

  .vui-stepper-item__icon {
    width: 2rem;
    height: 2rem;
    border-radius: 1rem;
    border: 1px solid $vui-label-color;
    color: $vui-label-color;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 0.875rem;
    @include transition-all-short();

    .icon__active-icon {
      display: block;
      max-height: 0.75rem;
    }

    .icon__completed-icon {
      display: none;
    }
  }

  &.active,
  &:hover {
    .vui-stepper-item__label {
      color: $vui-input-label-color;
    }

    .vui-stepper-item__icon {
      color: $vui-input-label-color;
      border: 1px solid $vui-input-label-color;
    }
  }

  &.completed {
    .vui-stepper-item__icon {
      background: $vui-color-primary;
      border: 1px solid $vui-color-primary;
    }

    .icon__active-icon {
      display: none;
    }

    .icon__completed-icon {
      display: block;
      height: 13px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .vui-stepper-item {
    row-gap: 0.5rem;

    .vui-stepper-item__label {
      line-height: 0.75rem;
    }

    .vui-stepper-item__icon {
      width: 1rem;
      height: 1rem;
      border-radius: 0.5rem;
      font-size: 0.75rem;
      line-height: 0.75rem;

      .icon__completed-icon {
        svg {
          width: 9px;
          height: 9px;
        }
      }
    }

    &.completed {
      .icon__completed-icon {
        height: 9px;
      }
    }
  }
}