@import "../../../velotooler-app";

.vui-set-mechanic-rating-model {
  .vui-modal {
    max-width: 440px;
    width: 100%;
  }

  .vui-set-mechanic-rating-model__body {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;

    .vui-set-mechanic-rating-model__message {
      display: flex;
      flex-direction: row;
      font-size: 0.875rem;
      font-weight: 600;
      justify-content: center;
      column-gap: 0.5rem;
      align-items: center;
      text-transform: none;

      img {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 1.25rem;
      }
    }

    .vui-label {
      font-size: 0.75rem;
      font-weight: 400;
      text-transform: none;
    }

    .vui-input {
      height: 150px;
    }
  }

  .vui-set-mechanic-rating-model__actions {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;

    .vui-set-mechanic-rating-mode__dont-like {
      font-size: 0.875rem;
      font-weight: 700;
      text-align: center;
      cursor: pointer;
      text-decoration: underline;
      @include transition-short(color);

      &:hover {
        color: $vui-label-color;
      }
    }
  }
}
