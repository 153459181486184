.vui-contact-us-model {
  .vui-modal {
    max-width: 440px;
    width: 100%;
  }

  .vui-contact-us-model__body {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;

    .vui-contact-us-model__subtitle {
      font-size: 0.875rem;
      font-weight: 600;
      text-align: center;
    }

    .vui-input-container {
      margin-bottom: 0;

      .vui-input {
        height: 7rem;
      }
    }
  }

  .vui-contact-us-model__actions {
    column-gap: 1.5rem;
  }
}
