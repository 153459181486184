@import "../../../../velotooler-app";

.vui-organisation-owner-organisations-list-item {
  display: flex;
  flex-direction: row;
  column-gap: 0.5rem;
  align-items: center;

  .organisation-list-item__image {
    width: 2.25rem;
    height: 2.25rem;
    border-radius: 1.125rem;
    @include transition-short(opacity);
  }

  .organisation-list-item__info {
    display: flex;
    flex-direction: column;
    row-gap: 2px;
    justify-content: center;

    .organisation-list-item__name {
      font-size: 0.875rem;
      color: $vui-dark-text-color;
      overflow: hidden;
      text-overflow: ellipsis;
      text-wrap: nowrap;
      max-width: 140px;
      @include transition-short(color);
    }

    .organisation-list-item__type {
      font-size: 0.625rem;
      font-weight: 600;
      text-transform: uppercase;
      color: $vui-label-color;
      @include transition-short(color);
    }
  }

  &:hover {
    .organisation-list-item__image {
      opacity: 0.8;
    }

    .organisation-list-item__info {
      .organisation-list-item__name {
        color: $vui-dark-text-color--hover;
      }

      .organisation-list-item__type {
        color: $vui-light-text-color--hover;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .vui-organisation-owner-organisations-list {
  }
}

@media only screen and (max-width: 767px) {
  .vui-organisation-owner-organisations-list {
  }
}
