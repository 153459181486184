@import "../../../velotooler-app";

.vui-navigations-profile {
  height: 2.25rem;

  .vui-avatar {
    width: 2.25rem;
    height: 2.25rem;
    border-radius: 1.125rem;
  }
}