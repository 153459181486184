@import "../../../../velotooler-app";

@mixin itemColor($number, $color) {
  &:nth-of-type(#{$number}) {
    .landing-page-accordion-item .landing-page-accordion-item__title {
      background: $color;
    }
  }
}

.landing-page-accordion {
  landing-page-accordion-item {
    @include itemColor(1, #FBFBFB);
    @include itemColor(2, #FAFAFA);
    @include itemColor(3, #F8F8F8);
    @include itemColor(4, #F6F6F6);
    @include itemColor(5, #F4F4F4);
    @include itemColor(6, #F2F2F2);
    @include itemColor(7, #F1F1F1);
    @include itemColor(8, #EFEFEF);
    @include itemColor(9, #EDEDED);
    @include itemColor(10, #EDEDED);
  }
}
