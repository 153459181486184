@import "../../../sass/variables";

.notification-list {
  overflow: hidden;
  height: 100%;
  padding-top: 64px;
  position: relative;

  .notification-list__container-last-item {
    padding-bottom: 6px;
  }

  .inet-connection-status {
    color: #999;
    font-size: 14px;
    text-align: center;
    font-weight: 400;
  }

  .inet-status-connection-circular {
    display: inline-block;
    vertical-align: middle;
  }

  .notification-list__content {
    overflow-y: auto;
    overflow-x: hidden;
    height: 95%;
    background-color: $color-primary-d2;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      border-color: #333;
      background-color: #333;
    }
  }

  .notification-list__container {
    background-color: #333;
    margin: 8px;
    border-radius: 4px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }

  .notification-list__item {
    color: #e6e6e6;
    font-weight: normal;
  }

  .notification-list__item_read {
    color: #858585;
    font-weight: normal;
    md-icon {
      color: #858585;
    }
  }

  .notification-list__scroll-to-top {
    position: absolute;
    bottom: 30px;
    right: 30px;
    cursor: pointer;
    transform: translateY(0);
    transition: all 0.7s ease-in-out;
    z-index: 1000;

    img {
      width: 45px;
      height: 45px;
    }
  }

  .notification-list__scroll-to-top_hidden {
    transform: translateY(200px);
    transition: all 0.7s ease-in-out;
    display: none;
  }

  .notification-list__close-icon {
    color: #999;
    font-size: 25px;
  }

  .notification-list__empty {
    color: #777;
    text-align: center;
    margin-top: 50px;
  }

  @media (max-width: 470px) {
    top: 0;
    border-radius: 0;
    max-height: 100%;
    text-align: left;
    left: 0;
    right: 0;
    padding-top: 0;
  }

  @media (min-width: 471px) {
    width: 380px;
  }
}