@import "../../../velotooler-app";

.vui-checkbox {
  display: flex;
  flex-direction: initial;
  align-items: center;

  .vui-checkbox__label {
    cursor: pointer;
    display: inline-block;
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 400;
    color: $vui-dark-text-color;
    margin-left: .75rem;
  }

  .vui-checkbox__input {
    display: none;
  }

  .vui-checkbox__icon {
    cursor: pointer;
    height: 20px;
    width: 20px;
    display: block;

    &::after {
      content: url("data:image/svg+xml,%3Csvg%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M15.8333%202.5H4.16667C3.24619%202.5%202.5%203.24619%202.5%204.16667V15.8333C2.5%2016.7538%203.24619%2017.5%204.16667%2017.5H15.8333C16.7538%2017.5%2017.5%2016.7538%2017.5%2015.8333V4.16667C17.5%203.24619%2016.7538%202.5%2015.8333%202.5Z%22%20stroke%3D%22black%22%20stroke-width%3D%221.66667%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%3C%2Fsvg%3E");
      opacity: 1;
      margin-left: -20px;
      transition: opacity 0.3s ease-in-out;
    }

    &::before {
      transition: opacity 0.3s ease-in-out;
      opacity: 0;
      content: url("data:image/svg+xml,%3Csvg%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M7.5%209.16667L10%2011.6667L18.3333%203.33333M17.5%2010V15.8333C17.5%2016.2754%2017.3244%2016.6993%2017.0118%2017.0118C16.6993%2017.3244%2016.2754%2017.5%2015.8333%2017.5H4.16667C3.72464%2017.5%203.30072%2017.3244%202.98816%2017.0118C2.67559%2016.6993%202.5%2016.2754%202.5%2015.8333V4.16667C2.5%203.72464%202.67559%203.30072%202.98816%202.98816C3.30072%202.67559%203.72464%202.5%204.16667%202.5H13.3333%22%20stroke%3D%22black%22%20stroke-width%3D%221.66667%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%3C%2Fsvg%3E%0A");
    }
  }

  &.vui-checkbox--checked, &:checked {
    .vui-checkbox__icon {
      &::after {
        opacity: 0;
      }

      &::before {
        opacity: 1;
      }
    }
  }

  &.vui-checkbox--disabled {
    .vui-checkbox__icon {
      cursor: default;

      &::after {
        opacity: 1 !important;
        content: url("data:image/svg+xml,%3Csvg%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M15.8333%202.5H4.16667C3.24619%202.5%202.5%203.24619%202.5%204.16667V15.8333C2.5%2016.7538%203.24619%2017.5%204.16667%2017.5H15.8333C16.7538%2017.5%2017.5%2016.7538%2017.5%2015.8333V4.16667C17.5%203.24619%2016.7538%202.5%2015.8333%202.5Z%22%20stroke%3D%22%23808080%22%20stroke-width%3D%221.66667%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%3C%2Fsvg%3E");
      }

      &::before {
        opacity: 0 !important;
      }
    }
  }
}
