@import "../variables";
@import "grid";

.organisations-grid {
  .organisation__details {
    .organisation_type {
      color: #6F6F6F;
      font-size: 0.8em;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .organisation_title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
