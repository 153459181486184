@import "../../../../sass/variables";

.choose-mechanic-step {

  .choose-mechanic-step__item-container {
    padding: 5px;
  }

  .choose-mechanic-step__item {
    position: relative;
    background-color: $color-primary;
    min-height: 240px;
    cursor: pointer;

    &:hover {
      background-color: $color-primary-l2;
    }
  }

  .choose-mechanic-step__img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }

  .choose-mechanic-step__item-text {
    color: white;
  }

  .choose-mechanic-step__type {
    margin-top: 8px;
  }

  .choose-mechanic-step__type_individual {
    color: $color-green;
  }

  .choose-mechanic-step__type_mobile {
    color: #EB5757;
  }

  .choose-mechanic-step__type_shop {
    color: #43A6F9;
  }

  .choose-mechanic-step__item-reviews {
    font-size: 12px;
    color: #aaa;
  }

  .choose-mechanic-step__type-icon {
    height: 12px;
  }

  .choose-mechanic-step__type-description {
    font-weight: normal;
    font-size: 13px;
  }

  .choose-mechanic-step__action-button-container {
    text-align: center;
    margin-top: 22px;
  }

  .choose-mechanic-step__action-button {
    border-top: 1px solid $color-primary-l3;
    border-radius: 0;
  }

  .choose-mechanic-step__item-stars {
    margin-top: 8px;
  }

  .choose-mechanic-step__price {
    position: absolute;
    right: 15px;
    top: 15px;
    font-weight: bold;
    font-size: 18px;
  }

  .choose-mechanic-step__price--height {
    color: #EB5757;
  }

  .choose-mechanic-step__select-icon {
    position: absolute;
    top: 15px;
    left: 15px;
    width: 30px;
    height: 30px;
  }

  .choose-mechanic-step__item-avatar {
    padding-top: 50px;
    text-align: center;
  }

  .choose-mechanic-step__item-body {
    display: block;
    margin-top: 16px;
    text-align: center;
  }

}
