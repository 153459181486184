@import "../../../sass/variables";

.grid-list {

  background-color: transparent;
  margin: auto;

  .grid-list__pl-loading-indicator {
    background-color: rgba(25, 25, 25, 0.9);
  }

  .grid-list__items {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    align-content: flex-start;
  }

  .grid-list__body {
    max-width: 1200px;
    margin: auto;
  }

  .grid-list__item {
    position: relative;
    overflow: hidden;
    min-width: 190px;
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
    padding: 0 8px 16px;
  }

  .grid-list__item-body {
    background-color: #292929;
  }

  .grid-list__item-container {
    display: block;
    border-radius: 4px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    transition: all 0.3s;
    overflow: hidden;

    &:hover {
      transition: all 0.3s;
      box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    }
  }

  @media (min-width: 540px) {
    .grid-list__item {
      max-width: 50%;
      flex-basis: 50%;
    }
  }

  @media (min-width: 900px) {
    .grid-list__item {
      max-width: 33.333333%;
      flex-basis: 33.333333%;
    }

  }

  @media (min-width: 1200px) {
    .grid-list__item {
      max-width: 20%;
      flex-basis: 20%;
    }
  }

  /*.grid-list__hover-view {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    transform: translateX(-100%);
    transition: all 0.4s ease-in-out;
  }*/

  .grid-list__item-footer {
    padding: 10px 0;
    margin: 0;
    background-color: $color-primary-l3;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .grid-list__menu-button {
    padding: 0;
    height: inherit;
  }

  .grid-list__menu {
    padding: 0;
  }

  .grid-list__menu-icon {
    color: white;
  }

  .grid-list__item-image-container {
    display: block;
    cursor: pointer;
    padding-bottom: 100%;
    border: none;
    height: 0;
    position: relative;

    /*&:hover {
      .grid-list__hover-view {
        transform: translateX(0);
      }

      .grid-list__item-image {
        transform: translateX(100%);
        transition-delay: 0.1s;
      }
    }*/

    &.grid-list__item-image-container_inactive {
      pointer-events: none;
      cursor: default;
    }
  }

  .grid-list__item-image {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    transition: all 0.3s ease-in-out;
  }

  .grid-list__action-bar {
    min-height: 50px;
  }

  /* .grid-list__hover-view-button-container {
     margin: 0 auto;
     width: 70%;
   }

   .grid-list__hover-view-button {
     width: 100%;
     margin: 10px 0 !important;
   }*/
}