@import "../../../velotooler-app";

vui-stepper-content {
  min-width: 100%;
  width: 100%;
}

.vui-stepper-content {
  max-height: 0;
  opacity: 0;
  @include transition-all-short();

  &.active {
    max-height: initial;
    opacity: 1;
  }
}
