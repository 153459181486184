@import "../../../../velotooler-app";

.vui-landing-header {
  .vui-header-item__sign-up-nbda {
    display: none;
  }
}

@media only screen and (max-width: 991px) {
  header.container {
    width: 100%;
  }

  .vui-landing-header {
    padding-right: 64px;
    padding-left: 64px;

    .vui-landing-header__menu-toggler {
      @include size-rect(84px);
      padding: 20px;
      display: block;
      position: relative;
      top: 0;
      bottom: 0;
    }

    .vui-landing-header__menu-toggler.vui-landing-header__menu-toggler--open {
      position: absolute;
      margin-left: 5px;
      top: 0;
      left: 0;
    }

    .vui-landing-header__menu-toggler.vui-landing-header__menu-toggler--close {
      line-height: 50px;
      margin-left: -14px;
    }

    .vui-landing-header__menu-container {
      @include transition-all-short();
      position: fixed;
      height: 100vh;
      top: 0;
      z-index: 100;
      background: $color-white;
      padding-left: 18px;
      overflow-y: auto;
    }

    .vui-landing-header__logo {
      @include margin-x(auto);
      z-index: 120;
    }

    .vui-landing-header__main-menu {
      padding-top: 74px;
      margin-left: 4px;
    }

    .vui-landing-header__sign-in {
      margin-left: 4px;
      margin-top: 48px;
    }
  }

  .vui-landing-header__side-menu--open {
    padding-right: 64px;
    padding-left: 64px;

    .vui-landing-header__menu-container {
      left: 0;
    }
  }

  .vui-landing-header__side-menu--closed {
    .vui-landing-header__menu-container {
      left: -100%;
    }
  }
}

@media only screen and (max-width: 767px) {
  .vui-landing-header {
    padding-right: 34px;

    .vui-landing-header__menu-toggler {
      @include size-rect(54px);
      padding: 5px;
    }

    .vui-landing-header__logo {
      svg {
        @include size-rect(50px);
      }
    }
  }
}
