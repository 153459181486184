@import "../../../sass/variables";

.lp-mechanics-by-city {

  color: white;

  .lp-mechanics-by-city__header-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .lp-mechanics-by-city__header-logo-container {
    margin: 20px 0 0 20px;
    display: block;
  }

  .lp-mechanics-by-city__header-logo {
    width: 110px;
  }

  .lp-mechanics-by-city__title-container {
    max-width: 900px;
    margin: 30px auto;
    padding: 0 20px 20px 20px;

    @media (max-width: 574px) {
      margin: 10px auto;
    }
  }

  .lp-mechanics-by-city__title {
    font-size: 27px;
    font-weight: normal;
    margin: 0;

    @media (max-width: 574px) {
      font-size: 20px;
    }
  }

  .lp-mechanics-by-city__subtitle {
    font-size: 17px;
    font-weight: normal;
    margin: 0;
    color: #aaaaaa;

    @media (max-width: 574px) {
      font-size: 12px;
    }
  }

  .lp-mechanics-by-city__header {
    height: 500px;
    position: relative;
  }

  .lp-mechanics-by-city__header-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .lp-mechanics-by-city__header-background:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #09100e;
    opacity: .8;
  }

  .lp-mechanics-by-city__list {
    max-width: 900px;
    margin: 30px auto;
  }

  .lp-mechanics-by-city__list-title {
    color: white;
    text-align: center;
    font-size: 18px;
  }

  .lp-mechanics-by-city__process {
    margin: auto;
    padding: 0 10px;
    max-width: 975px;

    @media (max-width: 984px) {
      max-width: 575px;
    }
  }

  .lp-mechanics-by-city__process-step-text {
    font-size: 15px;
    text-align: center;
    margin-top: 10px;

    @media (max-width: 984px) {
      font-size: 12px;
    }

    @media (max-width: 574px) {
      display: inline-block;
      vertical-align: middle;
      width: 75%;
      text-align: left;
      margin-left: 10px;
      margin-top: 0;
    }
  }

  .lp-mechanics-by-city__process-step-container {
    width: 160px;
    height: 80px;
    display: inline-block;
    vertical-align: middle;

    @media (max-width: 984px) {
      width: 80px;
      height: 50px;
    }

    @media (max-width: 574px) {
      width: 100%;
    }
  }

  .lp-mechanics-by-city__process-step-icon-circle {
    width: 80px;
    height: 80px;
    border-radius: 80px;
    background-color: rgba(119, 119, 119, .3);
    position: relative;
    margin: auto;

    @media (max-width: 984px) {
      width: 50px;
      height: 50px;
    }

    @media (max-width: 574px) {
      display: inline-block;
      vertical-align: middle;
      width: 40px;
      height: 40px;
    }
  }

  .lp-mechanics-by-city__process-step-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    height: 50%;
  }

  .lp-mechanics-by-city__process-arrow {
    vertical-align: middle;
    font-size: 30px;
    color: #777777;

    @media (max-width: 574px) {
      display: none;
    }
  }

  .lp-mechanics-by-city__location-input {
    max-width: 900px;
    margin: 120px auto 0 auto;

    input {
      border-bottom-color: $vt-color-primary !important;
    }

    @media (max-width: 574px) {
      margin-top: 10px;
    }
  }

  .lp-mechanics-by-city__request-service-btn {
    position: relative;
    bottom: 3px;
    margin: 0 !important;

    @media (max-width: 574px) {
      width: 100%;
    }
  }

  .lp-mechanics-by-city__map {

    padding: 5px;

    .google-map {
      margin: 0;
      height: 600px;
    }
  }

  .lp-mechanics-by-city__section-title {
    font-weight: bold;
    font-size: 17px;
    text-align: center;
    margin: 0 0 10px 0;
  }
}