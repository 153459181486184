@import "../../../velotooler-app";

.vui-back-btn {
  path {
    fill: $vui-dark-text-color;
    @include transition(200ms, ease-in-out);
  }
}

.vui-back-btn:active,
.vui-back-btn:focus,
.vui-back-btn:hover {
  path {
    fill: $vui-dark-text-color--hover;
  }
}
