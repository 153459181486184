@import "../../../sass/variables";

.create-general-service {
  max-width: 800px !important;
  $remove-tool-btn-color: rgb(255, 236, 146);
  $task-switch-thumb-background-color: #51C57E;
  $task-switch-bar-background-color: rgba(81, 197, 126, 0.5);
  $task-switch-bar-disabled-background-color: #777777;
  $task-switch-icon-color: #00aae5;

  .vt-textarea {
    margin-top: 0;
  }

  .add-tool__btn {
    padding-top: 20px;
  }

  .create-general-service-form__switch {

    margin: 20px 0;
    color: white;
    font-weight: 500;

    .md-checked .md-thumb {
      background-color: $task-switch-thumb-background-color;
    }

    .md-checked .md-bar {
      background-color: $task-switch-bar-background-color;
    }

    md-switch[disabled] .md-bar {
      background-color: $task-switch-bar-disabled-background-color;
    }

    md-switch[disabled] .md-thumb {
      background-color: $input-disabled-color;
    }

    md-switch .md-thumb {
      background-color: $input-disabled-color;
    }

    .create-general-service-form__switch__label {
      vertical-align: middle;
      display: inline-block;
      margin-right: 10px;
      padding-left: 3px;
    }

    .create-general-service-form__switch__body {
      display: inline-block;
      margin: 0;
      vertical-align: middle;
      height: 25px;
    }

    .create-general-service-form__switch__icon {
      color: $task-switch-icon-color;
      height: 15px;
      width: 15px;
      font-size: small !important;
      margin-top: -8px;
      cursor: pointer;
    }
  }

  .md-button.md-fab.md-mini {
    line-height: 25px;
    width: 25px;
    height: 25px;
  }

  .md-button.md-fab {
    background-color: transparent;
    color: $remove-tool-btn-color;
    box-shadow: none;
  }

  .md-button.md-fab:not([disabled]):hover {
    background-color: #222;
  }

  .create-general-service__details {
    .width-100, md-input-container {
      height: 60px !important;
    }
  }

  .flow-container {
    width: 35%;
  }

  .part-container {
    width: 31%;
  }

  .price-container {
    width: 33%;
  }

  .time-to-complete-container {
    width: 32%;
  }

  .service-bucket-container {
    width: 31%;
    padding: 2px 2px 8px 2px;
    margin: 18px 0;
  }

  .task-checkbox-button {
    padding-left: 18px;
  }

  .task-checkbox-button ul li {
    display: inline-block;
    list-style-type: none;
    width: 32%;
    padding: 10px;
  }

  @media (max-width: 768px) {
    .flow-container, .part-container, .price-container, .time-to-complete-container, .service-bucket-container {
      width: 100%;
    }

    .task-checkbox-button ul li {
      display: list-item !important;
      width: 100%;
      padding-bottom: 10px;
    }

    button.md-button {
      min-width: 82px;
    }
  }
}

.create-general-service-form__autocomplete {
  color: $done-button-text-color !important;
}