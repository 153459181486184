@import "variables";

footer {
  padding: 4rem 1rem 1.5rem 1rem;
  overflow: hidden;
  background: $vui-color-primary;
  color: $vui-dark-text-color;

  hr {
    height: 0.5px;
    color: $vui-dark-text-color;
    background-color: $vui-dark-text-color;
    margin-bottom: 3.25rem;
  }

  a:hover {
    text-decoration: underline;
    transition: color 250ms;
  }

  a {
    color: $vui-footer-color-link;
    display: block;
    cursor: pointer;
    font-weight: 300;
    line-height: 1.5rem;

    &:active,
    &:hover,
    &:focus {
      color: $footer-color-link-hover;
    }
  }

  ul {
    padding: 0;
    margin: 0;

    li {
      list-style-type: none;
      font-size: 1rem;
      line-height: 1rem;
      margin: 1rem 0;
    }
  }

  .footer-contact {
    margin-top: 1rem;

    .footer-contact__context-us {
      text-align: center;

      .vui-btn {
        max-width: 260px;
        width: 100%;
      }
    }

    .footer-contact__social {
      text-align: start;
      display: flex;
      margin-left: 0.25rem;
      margin-bottom: 2rem;
      margin-top: 2.5rem;

      ul {
        display: flex;
        margin-left: auto;
        margin-right: auto;

        li {
          margin: 0 0.625rem;
        }

        a svg {
          fill: $vui-footer-color-link;
          text-decoration: none;
          transition: fill 250ms;

          & svg:active,
          & svg:hover,
          & svg:focus {
            fill: $footer-color-link-hover;
          }
        }
      }
    }
  }

  .copyright-container {
    padding-top: 6.25rem;
    text-align: center;
    font-size: .625rem;
    font-weight: 600;
    letter-spacing: 0.4rem;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  footer {
    .copyright-container {
      padding-top: 4.25rem;
    }
  }
}

@media only screen and (max-width: 767px) {
  footer {
    hr {
      margin-bottom: 2.5rem;
    }

    .footer-container {
      ul {
        li {
          margin: 0.625rem 0;
          text-align: center;
        }
      }
    }

    .copyright-container {
      padding-top: 4.25rem;
    }
  }
}
