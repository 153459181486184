.notification-toast {

  z-index: 5000;
  background: #565656 !important;

  .notification-toast__btn-close {
    md-icon {
      color: white;
    }
  }

  .notification-toast__item {
    color: #e6e6e6;
    font-weight: normal;
  }
}