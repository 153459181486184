@import "../../../velotooler-app";

$mobile-list-view-width: 750px;

.mechanic-list {

  margin: 0;
  display: flex;

  .mechanic-list__location-filter {
    margin: 0 8px;
  }

  .mechanic-list__list {
    padding-top: 5px;
    flex-basis: 650px;
    max-width: 650px;

    @media (min-width: 1400px) {
      flex-basis: 750px;
      max-width: 750px;
    }

    @media (min-width: 1600px) {
      flex-basis: 850px;
      max-width: 850px;
    }
  }

  .mechanic-list__list-body {
    padding-top: 0;
  }

  .mechanic-list__list_mobile {
    position: absolute;
    max-width: none;
    width: 100%;
    top: 64px;
    left: 0;


    &.mechanic-list__list_mobile-logged-in {
      left: 64px;
      width: calc(100% - 64px);
    }

    @media (max-width: $mobile-width) {
      width: 100% !important;
      left: 0 !important;
    }
  }

  .mechanic-list__list_mobile-inactive {
    .pageable-list__body {
      opacity: 0 !important;
      visibility: hidden !important;
      overflow-y: hidden !important;
    }
  }

  .mechanic-list__map {
    flex-grow: 100;

    padding: 5px 5px 5px 0;

    .google-map {
      margin: 0;
      height: calc(100vh);
    }
  }

  .mechanic-list__change-view-btn {
    position: absolute;
    right: 25px;
    top: 5px;
    text-transform: none;
  }

  .mechanic-list__change-view-btn-icon {
    vertical-align: middle;
    width: 16px;
  }

  .mechanic-list__change-view-btn-label {
    vertical-align: middle;
  }

  .mechanic-list__map_mobile {
    position: absolute;
    left: 0;
    top: 128px;
    width: 100%;
    padding: 0;
    z-index: 70;

    &.mechanic-list__map_mobile-logged-in {
      left: 64px;
      width: calc(100% - 64px);
    }

    @media (max-width: $mobile-width) {
      width: 100% !important;
      left: 0 !important;
    }

    .google-map {
      height: calc(100vh - 195px);
    }
  }

  .mechanic-list__map_mobile-inactive {
    z-index: -10;
    opacity: 0 !important;
    visibility: hidden !important;
    overflow-y: hidden !important;
  }

  .mechanic-list__info-window {
    min-height: 60px;
    margin-left: 20px;
  }

  .mechanic-list__info-window-avatar {
    width: 50px;
    height: 50px;
    border-radius: 50px;
  }

  .mechanic-list__info-window-name {
    font-size: 16px;
    color: black;
    font-weight: bold;
    padding-left: 10px;
  }

  .mechanic-list__info-window-schedule-btn {
    color: #0e90d2 !important;
  }

}