@import "../../../velotooler-app";

.vui-form-image-pagination {
  display: flex;
  flex-direction: row;
  column-gap: 6px;
  justify-content: center;

  &.vui-form-image-pagination--animation {
    @include transition-short(transform);
  }

  .vui-form-image-pagination__container {
    cursor: pointer;

    circle {
      @include transition-short(fill);
    }

    &.active {
      cursor: default;
    }

    &.active,
    &:hover {
      circle {
        fill: $vui-label-color;
      }
    }

    &.hide {
      cursor: default;
      transform: scale(0);
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .vui-form-image-pagination {

  }
}

@media only screen and (max-width: 767px) {
  .vui-form-image-pagination {

  }
}