@import "../../../sass/variables";
@import "../mechanic-list";

.mechanic-list-item {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  background-color: $color-primary-l2;
  border-radius: 3px;
  margin: 10px 10px;
  display: flex;
  color: white;

  .mechanic-list-item__image-container {
    max-height: 150px;
    min-height: 150px;
    min-width: 150px;
    max-width: 150px;
    margin: 10px 10px 10px 20px;
    display: block;
    color: white;

    @media (max-width: $mobile-list-view-width) {
      max-height: 110px;
      min-height: 110px;
      min-width: 110px;
      max-width: 110px;
      margin: 10px;
      position: absolute;
    }
  }

  .mechanic-list-item__image {
    max-height: 150px;
    border-radius: 150px;

    @media (max-width: $mobile-list-view-width) {
      max-height: 110px;
      border-radius: 4px;
    }
  }

  .mechanic-list-item__main {
    padding: 5px 10px 10px 10px;
    width: 100%;
    overflow: hidden;
  }

  .mechanic-list-item__main-header {
    @media (max-width: $mobile-list-view-width) {
      min-height: 110px;
      margin-left: 120px;
    }
  }

  .mechanic-list-item__desc-container {
    @media (max-width: $mobile-list-view-width) {
      position: absolute;
      left: 0;
      top: 110px;
      margin: 10px;
    }
  }

  .mechanic-list-item__location {
    padding-left: 10px;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 10px;
  }

  .mechanic-list-item__details {
    margin-top: 5px;
  }

  .mechanic-list-item__details-val {
    font-weight: bold;
    color: white;
    text-transform: lowercase;
  }

  .mechanic-list-item__details-label {
    color: #aaa;
  }

  .mechanic-list-item__type {
    font-weight: bold;
    font-size: 12px;
    color: #999999;
    text-transform: lowercase;
    display: inline-block;
    margin-top: 5px;
    white-space: nowrap;
    padding: 0;
    line-height: 21px;
  }

  .mechanic-list-item__desc {
    margin-top: 10px;
  }

  .mechanic-list-item__services {
    margin-top: 25px;
  }

  .mechanic-list-item__services-item {
    color: #aaa;
    font-size: 13px;
    font-weight: normal;
    line-height: 15px;
  }

  .mechanic-list-item__schedule-btn {
    margin: 25px 0 0 0 !important;

    @media (max-width: $mobile-list-view-width) {
      width: 100%;
    }
  }

  .mechanic-list-item__icons {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 15px;
  }

  .mechanic-list-item__icon {
    display: inline-block;
    padding: 5px;
    border-radius: 30px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;

    &:hover {
      background-color: #444;
    }
  }

  .mechanic-list-item__header {
    font-size: 16px;
    text-align: left;
    margin: 0;
  }

  .mechanic-list-item__name-container {
    font-weight: bold;
    margin-top: 5px;
    text-align: left;
    padding: 0;
    color: white;
    display: block;
  }

  .mechanic-list-item__name {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 300px;
    display: inline-block;
    vertical-align: middle;
    font-size: 16px;
    margin-right: 10px;
  }

  .mechanic-list__view-item-btn {
    display: inline-block;
    color: #aaa;
    vertical-align: middle;
    font-size: 12px;
  }

  .mechanic-list__view-item-btn-icon {
    width: 13px;
  }

  .mechanic-list-item__rating-container {
    font-size: 16px;
    margin-top: 5px;
  }

  .mechanic-list-item__rating {
    display: inline-block;
  }

  .mechanic-list-item__reviews {
    display: inline-block;
    margin-left: 10px;
  }

  .mechanic-list-item__level {
    font-size: 13px;
    height: 26px;
    line-height: 26px;
    background-color: #6BE897;
    color: #333;
    position: absolute;
    top: -5px;
    right: -5px;
    width: 26px;
    border-radius: 26px;
    text-align: center;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }

  .mechanic-list-item__footer {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    height: 30px;
    margin: 0;
    display: flex;
    justify-content: space-between;
  }

  .mechanic-list-item__actions-container {
    padding-right: 0;
    flex-basis: 30px;
  }

  .mechanic-list-item__actions {
    padding: 0;
  }

  .mechanic-list-item__action-button {
    margin: 0;
    padding: 0;
    min-width: 30px;
    line-height: 30px;
    transform: rotate(90deg);
  }

  .mechanic-list-item__menu-icon {
    color: white;
  }
}

.mechanic-list-item-pricing {

  @media (min-width: 800px) {
    min-width: 700px;
    max-width: 700px;
  }
}