@mixin getBikeStatuses($fz) {
  .transfer-to-email {
    font-size: 0.8em;
    padding-left: 5px;
    white-space: nowrap;
  }

  .color_white {
    color: white;
  }

  .broken {
    background-color: #ffd351;
    color: #594300;
    font-weight: bold;
    padding: 2px 4px;
    font-size: #{$fz};
    border-radius: 3px;
    vertical-align: middle;
  }

  .used {
    background-color: #4caa77;
    color: black;
    font-weight: bold;
    padding: 2px 4px;
    font-size: #{$fz};
    border-radius: 3px;
    white-space: nowrap;
    vertical-align: middle;
  }
  .in_transfer {
    background-color: #5E412F;
    color: #FCEBB6;
    font-weight: bold;
    padding: 2px 4px;
    font-size: #{$fz};
    border-radius: 3px;
    white-space: nowrap;
    vertical-align: middle;
  }
}