@import "../../../../velotooler-app";

.vui-organisation-info {
  .vui-organisation-info__title {
    .vui-organisation-info__title-container {
      column-gap: 0.5rem;

      .title__type {
        font-size: 0.875rem;
        font-weight: 600;
        color: $vui-label-color;
      }
    }
  }

  .vui-organisation-info__addresses {
    border-top: 1px solid $vui-dark-text-color;

    .vui-form-info__field {
      padding-top: 1rem;
      padding-bottom: 0;
    }
  }

  .vui-form-info__value {
    &.vui-organisation-info__phone-number {
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 0.5rem;

      .iti__flag {
        height: 20px;
        width: 27px;
      }
    }
  }

  .vui-form-info__link {
    color: $vui-dark-text-color;
    text-decoration: underline;
    @include transition-short(color);

    &:hover {
      color: $vui-dark-text-color--hover;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .vui-organisation-info {
    .vui-form-info {
      padding: 0 1.5rem;

      .vui-form {
        .vui-form-info__field {
          padding-bottom: 0.5rem;
        }

        .vui-organisation-info__addresses {
          .vui-form-info__field {
            padding-top: 0.5rem;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .vui-organisation-info {
    .vui-organisation-info__title {
      .vui-organisation-info__title-container {
        .title__type {
          font-size: 0.625rem;
        }
      }
    }

    .vui-form-info .vui-form {
      .vui-form-info__field {
        padding-bottom: 0.5rem;
      }

      .vui-organisation-info__addresses {
        .vui-form-info__field {
          padding-top: 0.5rem;
        }
      }
    }
  }
}
