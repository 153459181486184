@import "../../../../velotooler-app";

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .landing-page-steps {
    @include margin-x(70px);
  }
}

@media only screen and (max-width: 767px) {
  .landing-page-steps {
    @include margin-x(25px);
  }
}
