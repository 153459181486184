.vt-fab {
  border: none !important;
  max-width: 56px;
  max-height: 56px;
  line-height: 56px !important;
  position: fixed;
  right: 5px;
  bottom: 5px;
  z-index: 10;
  border-radius: 50px !important;
  display: block;
  margin: 0;
  padding: 0;
  font-size: 24px !important;
  min-width: 56px !important;
  min-height: 56px !important;
  transform: translateY(0);
  transition: all 0.7s ease-in-out;
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);

  @media (max-width: 470px) {
    bottom: 69px;
  }
}

.vt-fab_hidden {
  transform: translateY(200px);
  transition: all 0.7s ease-in-out;
}