@import "../../../../velotooler-app";

.landing-page-service-list {
  background-color: $color-white;
  border-radius: 0.25rem;
  padding: 3rem;

  .service-item {
    @include transition-all-middle();
    font-style: normal;
    font-size: 22px;
    line-height: 70px;
    cursor: pointer;
    display: flex;
    margin-top: 8px;
    margin-bottom: 8px;

    .service-item__container {
      @include transition-all-middle();
      padding-left: 20px;
      padding-right: 0;
      border: 5px solid $color-white;
      border-radius: 0;
      color: $vui-dark-text-color;
    }

    .service-item__vertical-delimiter {
      @include transition-all-middle();
      height: 80px;
      width: 0;
      background-color: $vui-color-primary;
    }
  }

  .service-item.active {
    .service-item__container {
      border: 5px solid $vui-color-primary;
    }

    .service-item__vertical-delimiter {
      height: 80px;
      width: 7px;
      margin-left: 10px;
    }
  }

  .simplebar-scrollbar:before,
  .simplebar-scrollbar.simplebar-visible:before {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, .2);
    background-color: rgba(0, 0, 0, .2);
  }
}

@media only screen and (min-width: 992px) {
  .landing-page-service-list {
    .service-item {
      margin-right: 40px;

      .service-item__container {
        margin-right: 40px;
      }

      &.active {
        .service-item__container {
          margin-right: 0;
        }

        .service-item__vertical-delimiter {
          margin-right: 8px;
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .landing-page-service-list {
    .service-item {
      margin-left: 0;

      .service-item__container {
        margin-right: 0;
      }

      .service-item__vertical-delimiter {
        margin-left: 0;
        margin-right: 8px;
      }

      .service-item__dropdown-icon {
        @include transition-all-short();
        right: 0;
        top: 0;
        margin: 30px 1.5rem;
      }

      &.active {
        .service-item__dropdown-icon {
          transform: rotate(180deg);
          transition-delay: 600ms;
        }
      }
    }

    .service-item + .service-item__description {
      transition: max-height 600ms linear 600ms,
      margin 600ms ease-in-out 600ms,
      opacity 400ms ease-in-out 1100ms;
      margin: 0;
      max-height: 0;
      opacity: 0;
      overflow: hidden;

      .service-item__more {
        margin-top: 2rem;
        position: relative;
        color: $vui-dark-text-color;
      }
    }

    .service-item.active + .service-item__description {
      opacity: 1;
      display: block;
    }

    .service-item.closing + .service-item__description {
      transition: opacity 600ms ease-in-out,
      margin 400ms ease-in-out 650ms,
      max-height 600ms linear 650ms;
      max-height: 0;
      opacity: 0;
      display: block;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .landing-page-service-list {
    max-height: 1200px;
    min-height: calc(100vh - 338px);
    border: 1px solid $vui-dark-text-color;
    padding: 60px 25px 24px 20px;

    .landing-page-service-list__container {
      padding-right: 50px;
    }

    .service-item {
      .service-item__container {
        color: $vui-dark-text-color;
      }

      p, .service-item__more {
        font-size: 20px;
        line-height: 24px;
      }

      &.active {
        .service-item__vertical-delimiter {
          margin-left: 16px;
        }
      }

      &.active + .service-item__description {
        margin: 2rem;
        max-height: 600px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .landing-page-service-list {
    max-height: 100%;
    min-height: 300px;
    border: 1px solid $vui-dark-text-color;
    padding: 20px;

    .landing-page-service-list__container {
      padding-right: 20px;
    }

    .service-item {
      font-size: 16px;
      line-height: 40px;

      .service-item__description {
        .service-item__more, p {
          font-size: 16px;
          line-height: 20px;
        }
      }

      .service-item__container {
        color: $vui-dark-text-color;
        padding-left: 14px;
        padding-right: 48px;
      }

      .service-item__vertical-delimiter {
        height: 50px;
      }

      .service-item__dropdown-icon {
        margin: 15px;
      }

      &.active {
        .service-item__vertical-delimiter {
          margin-left: 12px;
          height: 50px;
        }

        & + .service-item__description {
          margin: 8px 16px 16px 26px;
          max-height: 800px;
        }
      }
    }
  }
}
