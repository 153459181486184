@import "widget/profile-about.scss",
"widget/profile-services.scss",
"widget/profile-verifications.scss",
"widget/profile-work-area.scss",
"widget/profile-education.scss",
"widget/profile-achievements.scss",
"widget/profile-photos.scss",
"widget/profile-rating.scss";

.profile-view {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.profile-view_align-center {
  margin-left: auto;
  margin-right: auto;
}

.profile-view__header {
  background-color: #191919;
  color: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.profile-body__row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.profile-body__column-left {
  flex: 1 1 540px;
  display: flex;
  flex-direction: column;
  align-content: space-around;
  margin-right: 20px;
  max-width: 540px;

  @media (max-width: 1100px) {
    margin-right: 0;
    flex-basis: 720px;
    max-width: 100%;
  }
}

.profile-body__column-right {
  flex: 1 1 440px;
  display: flex;
  flex-direction: column;
  align-content: space-around;
}

.profile-body__widget {
  margin-top: 20px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.titled-block {
  background-color: #191919;
  padding: 15px;
}

.titled-block__header {
  color: #999999;
  border-bottom: 1px solid #222;
  text-align: left;
  margin-bottom: 5px;
  font-weight: 500;
}

.titled-block__body {
  color: white;
  font-size: 15px;
}

.titled-block__footer {
  margin-top: 20px;
  border-top: 1px solid #222;
  text-align: center;
}

.profile-view__summary {
  display: flex;
  position: relative;
  padding: 20px 20px 5px 20px;
  flex-wrap: wrap;
  background-color: $color-primary;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.profile-view__sticky-summary {
  max-height: 0;
  background-color: $color-primary-l3;
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  overflow: hidden;
  border: none;
  padding-top: 0;
  padding-bottom: 0;
  box-shadow: none;
  transition: max-height .3s;
}

.profile-view__sticky-summary_active {
  z-index: 29 !important;
  padding-top: 5px;
  padding-bottom: 5px;
  transition: max-height .7s;
  max-height: 130px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.profile-view__sticky-item {
  display: block;
  margin-bottom: 0;
}

.profile-view__summary-item {
  flex-basis: 115px;
  margin-bottom: 15px;
  margin-right: 15px;

  @media (max-width: 360px) {
    flex-basis: 90px;
    margin-right: 3px;
  }
}

.profile-view__summary-button {
  flex-basis: 100px;
  margin-left: auto;
  margin-bottom: 15px;
}

.profile-view__summary-item-title {
  text-transform: uppercase;
  font-size: 13px;
  color: #999;
  margin-bottom: 5px;
}

.profile-view__summary-item-body {
  font-size: 15px;
  color: #eeeeee;
  font-weight: 500;

  @media (max-width: 360px) {
    font-size: 13px;
  }
}

.profile-view__cover {
  height: 300px;
  position: relative;
}

.profile-view__background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.profile-view__background:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #39403e;
  opacity: .6;
}

.profile-view__avatar-container {
  position: absolute;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  top: 140px;
}

.profile-view__avatar {
  position: relative;
  width: 150px;
  min-height: 150px;
  min-width: 150px;
}

.profile-view__verified-icon {
  position: absolute;
  bottom: 0;
  right: 15px;
  font-size: 2em;
  color: #51c57e;
}

.profile-view__verified-icon_small {
  font-size: 1em;
  right: 1px;
}

.profile-view__avatar_center-horizontally {
  margin: auto;
}

.profile-view__avatar_small {
  width: 50px;
  min-height: 50px;
  min-width: 50px;
}

.profile-view__avatar-image {
  border-radius: 50%;
  display: block;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
}

.profile-view__name {
  text-align: center;
  margin: 10px;
  color: white;
  font-size: 30px;
}

.profile-view__name_small {
  font-size: 15px;
}

.profile-view__label {
  font-size: 14px;
  line-height: 15px;
  color: white;
  text-align: center;
  margin-right: 5px;
  background-color: rgba(50, 50, 50, 0.5);
  padding: 15px;
  border-radius: 4px;
}

.profile-view__label-prefix {
  margin-right: 5px;
  font-weight: bold;
}

.profile-view__mechanic-city {
  font-size: 15px;
  color: white;
  font-weight: 500;
}

.profile-view__credo {
  text-align: center;
}

.titled-block__verification-item {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.titled-block__verification-item-key {
  flex: 1 1 70%;
  color: #eee;
}

.titled-block__verification-item-value {
  flex: 1 1 30%;
  color: #ffec92;
  font-size: 12px;
  text-transform: uppercase;
  &.done {
    color: #51c57e;
  }
}

.fa_status {
  width: 16px;
  height: 16px;
  background-color: #ffec92;
  border: solid 3px #3c3c3c;
  border-radius: 50%;

  &.done {
    background-color: #51c57e;
  }
}

.color-dark-gray {
  color: #555;
}

.color-gray {
  color: #aaa;
}

.profile-view_inline-item {
  display: inline-block;
}

.profile-view__sticky-summary {

  .profile-view__name {
    margin: 0;
    font-weight: bold;
  }

  .profile-view__summary-button {
    margin-bottom: 0;
  }
}

.profile-view__feedback-stars {
  vertical-align: text-top;
}

