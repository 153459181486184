.negotiation-card {

  margin-top: 15px;

  .negotiation-title {
    padding-top: 6px;
    font-weight: 500;
    font-size: 14px;
    line-height: 36px;
  }

  .negotiation-card__feedback-stars {
    vertical-align: middle;
    color: white;
  }

  .negotiation-card__label {
    color: #888;
    font-size: 15px;
  }

  .negotiation-card__show-chat-btn {
    float: none;
    clear: both;
    padding-top: 10px;
  }

  .negotiation-card__data {
    color: white;
    font-size: 15px;
    font-weight: bold;
  }

  .negotiation-card__status {
    &.request-status {
      font-size: 13px;
      padding: 1px 5px;
      margin-right: 10px;
      font-weight: bold;
    }
  }

  .negotiation-card__fixed-bikes-count {
    font-size: 14px;
    color: white;
    line-height: 30px;
  }

  .negotiation-card__connection-indicator {
    position: absolute;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background-color: #07ff07;
    top: 36px;
    left: 35px;
    border: 3px solid #191919;

    @media (max-width: 470px) {
      left: 27px;
    }
  }

  .negotiation-card-avatar {
    display: inherit;
  }

  .negotiation-card__history {
    @media (max-width: 470px) {
      position: initial !important;
    }
  }

  .negotiation-card__header {
    @media (max-width: 470px) {
      padding: 10px 0 !important;
    }
  }

  .negotiation-card__footer {
    position: inherit !important;
  }
}