@import "../../../sass/variables";

$verified: #2c9a57;
$not-verified: #d63333;
$profile-titile-color: #909090;
$review-no-comments: #7e7e7e;
$two-column-padding: 0 20px 0 20px;

.vt-public-profile {

  .vt-full-name {
    font-size: 30px;
  }

  .vt-stars {
    position: relative;
    top: -12px;
    .angular-input-stars > li {
      padding: 0;
    }
  }

  .vt-since {
    font-size: 20px;
  }

  .vt-main-label {
    color: $color-main;
    font-size: 18px;
  }

  .vt-main-text {
    font-size: 18px;
    font-weight: bold;
  }

  .main-desc {
    & > div {
      padding: 5px 0 5px 0;
    }

    .verified {
      color: $verified;
    }

    .not-verified {
      color: $not-verified;
    }

    .chad-container a {
      width: 200px;
      margin: 0;
    }
  }

  .profile-scroll-minibar {
    height: 64px;
    width: 100%;
    position: fixed;
    z-index: 99;
    top: -64px;
    background-color: $form-background;
    transition-property: top;
    transition-duration: .25s;

    &.active {
      top: 0;
    }

    .img-tmb {
      max-width: 64px;
    }

    .request-chad {
      position: absolute;
      top: 18px;
      right: 15px;
      margin: 0;
    }

    .profile-short-description {
      display: inline-block;
      margin: 0;
      padding: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: calc(100% - 209px);

      & > * {
        margin: 0;
        padding: 0;
      }

      .vt-stars {
        visibility: hidden;
      }
    }
  }

  hr {
    background-color: #4a4949;
  }

  .vt-profile-container {
    .vt-profile-title {
      color: $profile-titile-color;
      line-height: 35px;
    }

    .vt-profile-credo {
      display: flex;
      justify-content: center;

      i {
        color: #686868;
        font-size: 1.5em;
      }

      em {
        padding: $two-column-padding;
        max-width: 500px;
      }
    }

    .vt-profile-area-marker {
      color: #545454
    }

    .google-map {
      height: 500px;
      overflow: hidden;
    }

    .vt-profile-servicelist {
      text-transform: uppercase;

      & > div {
        padding: $two-column-padding;
      }

      .vt-profile-list-container {
        display: flex;
        flex-direction: column;
        font-size: .9em;
        color: #adacac;

        & > div {
          display: flex;
          padding: 5px 0 5px 0;

          & div {
            flex: 1 0 0%;
          }

          & div:nth-child(2) {
            flex: 1 0 50%;
          }
        }
      }
    }

    .vt-profile-pricing {
      text-align: center;
      font-size: 1.5em;
      padding-top: 20px;

      a {
        text-decoration: none;
        color: $color-main;
      }
    }
  }

  .no-comments {
    color: #928f8f;
  }

  .vt-profile-verification {
    font-size: 1.1em;
    color: $not-verified;

    .verification-container {
      display: flex;
      justify-content: center;

      .verification-icon {
        width: 16px;
        height: 16px;
        background-color: $not-verified;
        border: solid 3px #3c3c3c;
        border-radius: 50%;

        &.verified {
          background-color: $verified;
        }
      }

      span {
        color: #c6c6c6;
      }

      & > div {
        width: 270px;
        padding: 15px 10px 0 10px;
      }

      & > div:last-of-type {
        max-width: 135px;
      }

    }

    .verified {
      color: $verified;
    }
  }

  .public-profile-photo {
    .profile-photo-container {
      box-sizing: border-box;
      position: relative;
      width: 100%;

      .profile-photo-img {
        width: 100%;
      }

      .profile-img-background {
        position: absolute;
        display: flex;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        cursor: pointer;

        i {
          display: none;
        }

        &:hover {
          color: $color-main;
          background-color: rgba(250,250,250,0.12);

          i {
            margin: auto;
            font-size: xx-large;
            display: inline-block;
          }
        }
      }
    }
  }
}

/* For 3 columns and mobile view*/
@media (max-width: 627px) {
  .vt-public-profile {
    .row .col-xs-4:last-of-type {
      width: 100%;
    }

    .row .col-xs-4 {
      width: 50%;
    }

    .row .col-sm-6 {
      width: 100%;
    }
  }
}

@media (min-width: 768px) {
  .vt-public-profile {
    .max-width500 {
      max-width: 500px;
    }

    .profile-scroll-minibar {
      .profile-short-description {

        .vt-full-name {
          max-width: calc(100% - 108px);
          float: left;
          overflow: inherit;
          text-overflow: ellipsis;
        }

        .vt-stars {
          visibility: visible;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .vt-public-profile {
    .padding-side35 {
      padding: 0 35px 0 35px;
    }

    .chad-container {
      text-align: center;
    }
  }
}

@media (min-width: 960px) {
  .profile.vt-public-profile {
    padding-left: 0;
    padding-right: 0;
  }
}

/* Galery */

@media (min-width: 508px) {
  .vt-public-profile {
    .public-profile-photo {
      .profile-photo-container {
        width: calc(50% - 20px);
        float: left;
        margin: 10px;
      }
    }
  }
}

@media (min-width: 787px) {
  .vt-public-profile {
    .public-profile-photo {
      .profile-photo-container {
        width: calc(33.3% - 20px);
      }
    }
  }
}

@media (min-width: 1067px) {
  .vt-public-profile {
    .public-profile-photo {
      .profile-photo-container {
        width: calc(25% - 20px);
      }
    }
  }
}
