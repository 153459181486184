@import "../../../velotooler-app";

.vui-input-container {
  .vui-input-filter {
    .vui-input::placeholder {
      color: $vui-dark-input-placeholder-color !important;
      opacity: 1;
    }

    .vui-input-filter__dropdown-icon {
      position: absolute;
      right: 0;
      top: 0;
      margin: 17px 0.75rem;
      transition: all 250ms ease-in-out;
    }

    .vui-input {
      padding-right: 2.5rem;
      cursor: pointer;
    }

    .vui-select__dropdown {
      padding: 0.5rem;

      .vui-input-filter {
        padding: .5rem 1rem 0.5rem 2rem;
      }

      svg {
        position: absolute;
        left: 1rem;
        top: 1rem;
      }
    }

    &.vui-input-container--opened {
      .vui-input-filter__dropdown-icon {
        transform: rotate(180deg);
      }

      .vui-input-filter__dropdown--background,
      .vui-select__dropdown {
        display: block !important;
      }
    }
  }

  .vui-input-filter__dropdown--background {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    display: none;
  }

  &.vui-input-container__filter {
    margin: 0;

    .vui-input {
      font-size: 0.75rem;
      line-height: 0.75rem;
      padding: 0.5rem 1rem;
      height: 2rem;
      border: 1px solid $vui-main-menu-color;
      font-weight: 500;
    }

    .vui-input-filter__dropdown-icon {
      width: 0.625rem;
      height: 0.625rem;
      margin: 0.6875rem 1rem;
    }

    .vui-input-filter__option {
      font-size: .75rem;
      line-height: 2.25rem;
    }
  }
}

.vui-input-container.vui-form-control-lg {
  .vui-input-filter {
    .vui-input-filter__dropdown-icon {
      margin: 1.875rem 1rem;
    }

    .vui-input {
      padding-right: 3rem;
    }
  }
}

@media only screen and (max-width: 767px) {
  .vui-input-container,
  .vui-input-container.vui-form-control-lg {
    .vui-input-filter {
      .vui-input-filter__dropdown-icon {
        margin: 16px 12px;
      }

      .vui-input {
        padding-right: 2rem;
      }
    }

    &.vui-input-container__filter {
      .vui-input-filter__dropdown-icon {
        width: 0.625rem;
        height: 0.625rem;
        margin: 0.6875rem 1rem;
      }
    }
  }
}
