@import "../../../../velotooler-app";

.vui-update-organisation-info {
  .update-organisation-info__content {
    .update-organisation-info__avatar {
      margin-bottom: 2.5rem;

      .update-avatar {
        @include size-rect(180px);
        border-radius: 0.25rem;
        cursor: pointer;
        border: 1px solid transparent;
        @include transition-short(border-color);

        &:hover {
          border: 1px solid $vui-color-primary--hover;
        }
      }

      .clear-avatar {
        left: -30px;
        top: -156px;
        position: relative;
        cursor: pointer;

        path {
          @include transition-short(stroke);
        }

        &:hover,
        &:focus {
          path {
            stroke: $vui-color-primary--hover;
          }
        }
      }
    }

    .update-organisation-info__data {
      display: flex;
      flex-direction: column;
    }

    .update-organisation-info__dob {
      max-width: 172px;
    }

    .update-organisation-info__address {
      .address__title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }

      .address__list {
        display: flex;
        flex-direction: column;
        row-gap: 2rem;
        margin-top: 1.5rem;

        .address-item {
          opacity: 1;
          display: flex;
          flex-direction: column;

          &.ng-enter,
          &.ng-leave {
            -webkit-transition: 0.25s linear all;
            transition: 0.25s linear all;
          }

          &.ng-enter,
          &.ng-leave.ng-leave-active {
            opacity: 0;
            height: 0;
          }

          &.ng-leave,
          &.ng-enter.ng-enter-active {
            opacity: 1;
            height: 236px;
          }

          .address-item__type-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;

            .vui-edit-btn {
              font-size: 0.75rem;
              font-weight: 500;
              line-height: 0.75rem;
              column-gap: 0.5rem;
            }
          }

          .address-item__apartment {
            opacity: 1;
            @include transition-all-short();

            &.ng-hide {
              opacity: 0;
            }
          }
        }

        .address-item__type {
          max-width: 172px;
        }
      }
    }
  }

  .vui-side-form__actions {
    column-gap: 1.5rem;
  }
}
