@import "../../../sass/variables";

.appointment-date-dialog {
  max-width: 822px;
  width: inherit;

  .appointment-date-dialog__info {
    background-color: rgba(220, 91, 91, 0.65);
    padding: 20px;
    margin: 0 0 20px 0;
    @media (max-width: 470px) {
      font-size: 14px;
    }

    @media (min-width: 471px) {
      font-size: 15px;
    }
  }
}