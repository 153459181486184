@import "../../../velotooler-app";

.vui-popover-container {
  .vui-popover {
    .vui-popover__input {
      display: flex;
      flex-direction: row;
      column-gap: 1rem;
    }

    &.vui-popover-container--opened {
      .vui-popover__dropdown--background {
        display: flex !important;
      }

      .vui-popover__dropdown {
        display: flex !important;
        flex-direction: column;
        opacity: 1;
      }
    }

    &.vui-popover-container--opened.vui-popover-container--opening {
      .vui-popover__dropdown {
        opacity: 0;
      }
    }
  }

  .vui-popover__dropdown--background {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    display: none;
  }
}

.vui-popover-container.disabled {
  .vui-popover {
    .vui-popover__input {
      .color {
        cursor: default;
      }
    }
  }
}

.vui-popover__dropdown {
  position: absolute;
  display: none;
  background: $color-white;
  padding: 1.25rem;
  border-radius: 0.25rem;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
  z-index: 9999;
  flex-direction: row;
  column-gap: 0.5rem;
  justify-content: space-around;
  opacity: 0;
  @include transition-short(opacity);

  .vui-popover__dropdown-container {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    align-items: center;

    .vui-popover__dropdown-title {
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 17px;
      text-align: center;
      max-width: 210px;
    }
  }
}
