@import "../../../velotooler-app";

.vui-images-gallery {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(179, 179, 179, 0.8);
  z-index: 9999;

  &.ng-enter,
  &.ng-leave {
    @include transition-short(opacity);
  }

  &.ng-enter,
  &.ng-leave.ng-leave-active {
    opacity: 0;
  }

  &.ng-leave,
  &.ng-enter.ng-enter-active {
    opacity: 1;
  }

  .vui-images-gallery__container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;

    .vui-images-gallery__header {
      display: flex;
      flex-direction: row;
      justify-content: end;
      padding-right: 1.25rem;
      padding-left: 1.25rem;
    }

    .vui-images-gallery__body {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .navigation-btn {
        padding-right: 1.25rem;
        padding-left: 1.25rem;
        height: 100%;

        .vui-edit-btn {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .vui-images-gallery__images-list {
        display: flex;
        flex-direction: row;
        overflow: hidden;
        position: relative;
        width: 100%;
        height: calc(100vh - 8rem);

        .vui-gallery-image__container {
          position: absolute;
          width: 100%;
          height: calc(100vh - 8rem);
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: stretch;
          @include transition-short(transform);

          &.inactive-left {
            transform: translateX(100%);
          }

          &.inactive-right {
            transform: translateX(-100%);
          }

          &.active {
            transform: translateX(0);
          }

          .vui-gallery-image__item {
            object-fit: contain;
          }
        }
      }
    }

    .vui-images-gallery__pagination {
      position: absolute;
      width: 100%;
      bottom: 64px;
    }
  }

  .vui-edit-btn:not([disabled]) {
    path {
      stroke: $color-white;
    }

    &:hover {
      path {
        stroke: $vui-label-color;
      }
    }
  }
}
