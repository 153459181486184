.booking-pricing-details {

  position: relative;

  .booking-pricing-details__footer {
    background: #2F2F2F;
    padding: 6px 20px;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    font-weight: bold;
  }
}