.price-view {
  display: flex;
  justify-content: center;

  .price-view__parts {
    padding-left: 40px;
  }

  .price-view__label {
    color: #999;
    font-size: 15px;
    line-height: 25px;
  }

  .price-view__value {
    color: white;
    font-size: 15px;
    line-height: 25px;
  }

  .price-view__label_total {
    font-size: 16px;
    line-height: normal;
  }

  .price-view__value_total {
    font-size: 45px;
    line-height: normal;
  }

}