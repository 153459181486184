@import "../../../velotooler-app";

.vui-after-create-request-modal {
  .vui-after-create-request-modal__body {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
    max-width: 360px;

    .body__message {
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 17px;
    }

    ul {
      padding-left: 1.25rem;
      display: flex;
      flex-direction: column;
      row-gap: 0.5rem;

      li {
        font-size: 0.875rem;
      }
    }

    .contact-us-link {
      text-decoration: underline;
      @include transition-short(color);

      &:hover {
        color: $vui-label-color;
      }
    }
  }

  .vui-after-create-request-modal__actions {
    column-gap: 1.5rem;
  }
}