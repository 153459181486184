@import "../../../velotooler-app";

.vui-input-container {
  .vui-select {
    .vui-select__dropdown-icon {
      position: absolute;
      right: 0;
      top: 0;
      margin: 17px 0.75rem;
      transition: all 250ms ease-in-out;
    }

    .vui-input {
      padding-right: 2.5rem;
      cursor: pointer;

      &::placeholder {
        color: $vui-dark-input-placeholder-color !important;
        opacity: 1;
      }
    }

    &.vui-input-container--opened {
      .vui-select__dropdown-icon {
        transform: rotate(180deg);
      }

      .vui-select__dropdown--background,
      .vui-select__dropdown {
        display: block !important;
      }
    }

    &.vui-select--disabled {
      .vui-input {
        cursor: default;
      }

      .vui-select__dropdown-icon {
        path {
          stroke: $vui-input-placeholder-color;
        }
      }
    }
  }

  .vui-select__dropdown--background {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    display: none;
  }

  &.vui-input-container__filter {
    margin: 0;

    .vui-input {
      font-size: 0.75rem;
      line-height: 0.75rem;
      padding: 0.5rem 1rem;
      height: 2rem;
      border: 1px solid $vui-main-menu-color;
      font-weight: 500;
    }

    .vui-select__dropdown-icon {
      width: 0.625rem;
      height: 0.625rem;
      margin: 0.6875rem 1rem;
    }

    .vui-select__option {
      font-size: .75rem;
      line-height: 2.25rem;
    }
  }
}

.vui-input-container.vui-form-control-lg {
  .vui-select {
    .vui-select__dropdown-icon {
      margin: 1.875rem 1rem;
    }

    .vui-input {
      padding-right: 3rem;
    }
  }
}

@media only screen and (max-width: 767px) {
  .vui-input-container,
  .vui-input-container.vui-form-control-lg {
    .vui-select {
      .vui-select__dropdown-icon {
        margin: 16px 12px;
      }

      .vui-input {
        padding-right: 2rem;
      }
    }

    &.vui-input-container__filter {
      .vui-select__dropdown-icon {
        width: 0.625rem;
        height: 0.625rem;
        margin: 0.6875rem 1rem;
      }
    }
  }
}
