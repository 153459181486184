.vt-textarea {
  margin-top: 50px;
  margin-bottom: -28px;

  .vt-textarea__input, .vt-textarea__input:focus, .vt-textarea__input.ng-invalid.ng-dirty {
    min-height: 90px;
    height: auto;
    border: 1px solid #333;
    margin: 10px 0;
    padding: 8px;
  }

  .vt-textarea__input.ng-invalid.ng-dirty {
    border-color: #ef5350;
  }

  .vt-textarea__input:focus {
    border-color: #444 !important;
  }

  .vt-textarea__label:not(.md-no-float):not(.md-container-ignore) {
    transform: translate3d(0, 10px, 0) scale(1);
  }

  &.md-input-focused .vt-textarea__label:not(.md-no-float) {
    transform: translate3d(0, 3px, 0) scale(0.9)
  }
}