@import "../../../sass/variables";

.profile-address-card {
  background-color: rgba(68, 68, 68, 0.2);
  border-radius: 5px;
  padding: 10px;
  margin: 5px 0;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;

  &:hover {
    background-color: rgba(68, 68, 68, 0.4);
  }

  .profile-address-card__main {
    flex: 6 0 0;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-self: center;
    align-items: center;
    margin: auto;
    padding: 0 10px;
    flex-wrap: nowrap;

    @media (max-width: 600px) {
      flex: 0 74 300px;
    }
  }

  .profile-address-card__actions {
    flex: 1 0 0;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-self: center;
    align-items: center;
    margin: auto;
    padding: 5px 10px;
    justify-content: center;

    .md-button {
      margin: 0;
    }

    @media (max-width: 600px) {
      flex: 0 74 100px;
    }

    @media (min-width: $md-width) {
      line-height: normal;
    }
  }

  .profile-address-card__icon-container {
    flex-basis: 20px;
    margin-right: 10px;
  }

  .profile-address-card__icon {
    font-size: 18px;
    color: #999;

    &.fa-home {
      font-size: 23px;
    }
  }

  .profile-address-card__desc {
    color: $vt-color-primary;
    font-weight: bold;
    font-size: 15px;
  }

  .profile-address-card__address {
    font-size: 15px;
  }

  .profile-address-card__apt {
    font-size: 15px;
  }

  .profile-address-card__apt-label {
    color: #999999;
  }
}

