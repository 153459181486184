@import "../../../velotooler-app";

.vui-tabs {
  background: $input-default-color;
  overflow-y: hidden;
  overflow-x: auto;

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
}
