@import "../../../velotooler-app";

.vui-select__option {
  font-size: 0.875rem;
  line-height: 2.5rem;
  padding: 0 0.5rem;
  font-weight: 400;
  cursor: pointer;
  color: $vui-dark-input-placeholder-color;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  transition: all 200ms ease-in-out;
}

.vui-form-control-lg {
  .vui-select__option {
    font-size: 1rem;
    line-height: 4.375rem;
  }
}

.vui-select__option:hover,
.vui-select__option:focus,
.vui-select__option.active,
.active .vui-select__option {
  color: $vui-dark-text-color;
  background: #F6F6F6;
}

@media only screen and (max-width: 767px) {
  .vui-form-control-lg {
    .vui-select__option {
      font-size: 0.875rem;
      line-height: 2.5rem;
    }
  }
}
