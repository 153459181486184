.text-ellipsis {

  .text-ellipsis__show-more-btn {
    margin: 5px 0 0 0 !important;
  }

  .text-ellipsis__text {
    text-align: justify;
    font-size: 14px;
  }

}