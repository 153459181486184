@import "../../variables";

.location-autocomplete {

  #location-autocomplete-id {
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    padding-right: 30px;
  }

  .location-autocomplete__label {
    margin-right: 36px;
  }

  &.location-autocomplete_no-padding {
    md-input-container {
      padding-bottom: 0;
    }
  }

  &.location-autocomplete_with-padding {
    md-input-container {
      padding-bottom: 20px;
    }
  }

  &.location-autocomplete_with-max-padding {
    md-input-container {
      padding-bottom: 30px;
    }
  }

  .fa {
    font-size: larger;
    &.fa-star {
      color: $vt-color-primary;
    }
  }

  md-autocomplete-wrap {
    height: initial;
    button {
      display: none;
      width: 0 !important;
      margin: 0 !important;
      right: 12px;

      &:after {
        display: none;
      }
    }
  }

  .location-autocomplete__button {
    display: inline-block;
    vertical-align: -8px;
    text-align: center;
  }

  .location-autocomplete__input {

  }

  .location-autocomplete__favorite__icon {
    margin-right: 30px;
    position: absolute;
    top: 0;
    right: 0;
    font-size: larger;
    padding: 7px;
    cursor: pointer;
  }

  .md-input-has-value, .md-input-focused {
    label {
      transform: translate3d(0, 6px, 0) scale(0.9) !important;
      transition: transform cubic-bezier(0.25, 0.8, 0.25, 1) 0.5s !important;
    }
  }

  .md-input-has-placeholder {
    label:not(.md-input-has-value) {
      transform: translate3d(0, 28px, 0) scale(1);
      transition: transform cubic-bezier(0.25, 0.8, 0.25, 1) 0.25s;
      transform-origin: left top;
    }
  }

}

.location-autocomplete__dropdown {

  .current-location-marker {
    background: url(/img/icons/cl-marker.png) 100% 100% no-repeat;
    background-size: cover;
    position: absolute;
    top: 1px;
    right: 10px;
    padding: 12px;
    margin-top: 6px;
    margin-right: 6px;
    width: 20px;
    height: 20px;
    box-sizing: border-box;
    outline: 0;
    cursor: pointer;
    border-right: none !important;
  }

  li {
    color: $vt-color-primary !important;

    &:hover {
      color: $vt-color-primary !important;
    }
  }

  .md-autocomplete-suggestions {
    padding: 0 10px;
  }

  .location-autocomplete__dropdown-icon {
    padding: 0;
    max-width: 35px;

    i {
      border-right: 1px solid;
      margin-right: 6px;
      font-size: initial;
      color: #999999;
      &.fa-star {
        color: $vt-color-primary;
      }
    }
  }

  .location-autocomplete__dropdown-description {
    line-height: 20px;
    font-size: 13px;
    color: #999999;
  }

  .location-autocomplete__dropdown_line-height13 {
    line-height: 13px;
  }

  .location-autocomplete__dropdown-highlight-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 500;

    .highlight {
      color: #ffec92 !important;
      font-weight: 500;
    }
  }

  .location-autocomplete__dropdown-current-location {
    color: #d0d0d0;
    font-size: 17px;
    font-weight: 400
  }
}

.md-virtual-repeat-scroller {
  background-color: #333;
}
