@import "../../../velotooler-app";

.main-menu-global-search-item {
  &.vui-input-container {
    width: 275px;
    display: inline-block;
    position: relative;
    height: 2.25rem;
    @include margin-y(0);

    .vui-input {
      max-width: 209px;
      background: $color-white;
      border: 1px solid #B3B3B3;
      height: 19px;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }

    .vui-input-group {
      height: 2.25rem;
    }
  }
}