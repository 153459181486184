.vt-prefixed-input {
  padding: 2px;
  margin-bottom: 18px;

  .vt-prefixed-input__prefix {
    @media (max-width: 590px) {
      display: none;
    }

    border-right: 0;
    color: #a5a5a5;
    text-align: center;
    border-bottom: 1px solid $vt-color-primary;
    white-space: nowrap;
    vertical-align: middle;
    padding: 4px 1px;
  }

  .vt-prefixed-input__body {
    padding-bottom: 7px !important;
    padding-top: 3px !important;
  }

  .vt-prefixed-input__label {
    transform: translate3d(0, 3px, 0) scale(.9) !important;
  }
}
