@import "../../../sass/variables";

.request-availability {

  md-virtual-repeat-container {
    height: 155px;
    width: 100%;

    .md-virtual-repeat-scroller {
      &::-webkit-scrollbar {
        display: none;
      }
      background: none;
    }
  }

  .md-orient-horizontal .md-virtual-repeat-scroller {
    overflow-x: hidden !important;
  }

  .request-availability__dates {
    position: relative;
  }

  .request-availability__dates-btn {
    margin: 0 !important;
    min-width: 0 !important;
    height: 120px !important;
    width: 36px;
    border-radius: 0;
    position: absolute;
    top: 3px;
    background-color: $color-primary-l3;
    z-index: 1;

    &:hover {
      background-color: $color-primary-l4 !important;
    }

    i {
      font-size: 20px;
      color: white;
    }
  }

  .request-availability__dates-btn_left {
    left: 0;
  }

  .request-availability__dates-btn_right {
    right: 0;
  }

  .request-availability__date-container {
    padding-bottom: 35px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    margin: 4px;

    &:hover .request-availability__date-remove {
      display: block;
    }

    &:hover .request-availability__date:after {
      width: 100%;
      background: $vt-color-primary;
    }
  }

  .request-availability__date {
    background-color: rgba(70, 70, 70, .3);
    font-size: 20px;
    width: 90px;
    text-align: center;
    cursor: pointer;

    &:after {
      content: '';
      display: block;
      margin: auto;
      height: 3px;
      width: 0;
      background: transparent;
      transition: width .2s ease, background-color .2s ease;
    }
  }

  .request-availability__date-remove {
    position: absolute;
    color: white;
    bottom: 0;
    left: 0;
    font-size: 15px;
    width: 100%;
    padding: 4px;
    display: none;
    cursor: pointer;
    text-align: center;
  }

  .request-availability__date-remove-icon {
    background-color: rgba(239, 83, 80, 0.7);;
    border-radius: 50%;
    font-size: 11px;
    padding: 3px 4px;
    color: #191919;
  }

  .request-availability__date_selected {
    background-color: rgba(81, 197, 126, .3);

    .request-availability__day-of-week-label {
      background-color: rgb(81, 197, 126);
      color: white;
    }
  }

  .request-availability__date_focused {
    &:after {
      width: 100%;
      background: $vt-color-primary;
    }
  }

  .request-availability__day-of-week-label {
    font-size: 13px;
    color: #ccc;
    text-align: center;
    padding: 7px 0;
    background-color: #444;
  }

  .request-availability__day-body {
    padding: 9px;
  }

  .request-availability__day {
    display: inline-block;
    font-size: 20px;
  }

  .request-availability__year {
    font-size: 13px;
    padding-top: 3px;
  }

  .request-availability__month {
    font-size: 11px;
    display: inline-block;
    text-transform: uppercase;
  }

  .request-availability__date-availability {
    width: 4px;
    height: 4px;
    border-radius: 100%;
    display: inline-block;
  }

  .request-availability__date-availability_morning {
    background-color: #32B67A;
  }

  .request-availability__date-availability_afternoon {
    background-color: #F8892C;
  }

  .request-availability__date-availability_evening {
    background-color: #4285F6;
  }

  .request-availability__day-periods {
    margin-top: 10px;
  }

  .request-availability__time-slots {

    &.ng-hide {
      opacity: 0;
    }

    &.ng-hide-add {
    }

    &.ng-hide-remove {
      transition: all linear 0.5s;
    }
  }

  .request-availability__time-slot-item {
    display: inline-block;

    .md-button:hover {
      color: #333 !important;
    }
  }

  .request-availability__time-slot-item_selected {
    .md-button {
      background-color: #51c57e !important;
    }
  }

  .request-availability__time-slot-item_non-selected {
    .md-button {
      background-color: #999 !important;
    }
  }

  .request-availability__day-period {
    padding: 20px 0 20px 10px;
    border-bottom: 1px solid $color-primary;
    cursor: pointer;

    &:hover {
      background-color: $color-primary-l1;
    }

  }

  .request-availability__day-period_selected {
    background-color: #222;
    border-left: 1px solid #51c57e;
  }

  .request-availability__day-period-color {
    display: inline-block;
    width: 10px;
    height: 10px;
    vertical-align: top;
    margin-top: 5px;
    margin-right: 10px;
    border-radius: 100%;
  }

  .request-availability__day-period-color_morning {
    border: 2px solid #32B67A;
  }

  .request-availability__day-period-color_afternoon {
    border: 2px solid #F8892C;
  }

  .request-availability__day-period-color_evening {
    border: 2px solid #4285F6;
  }

  .request-availability__day-period-info {
    display: inline-block;
  }

  .request-availability__day-period_name {
    font-weight: bold;
    font-size: 18px
  }

  .request-availability__day-period_hours {
    color: #999;
    font-size: 13px
  }
}