.vui-clipboard {
  vertical-align: middle;
  font-size: 0.85rem;
  color: white;

  .vui-clipboard__img {
    width: 20px;
  }

  .vui-clipboard__tooltip {
    font-weight: 500;
    position: relative;
    display: inline-block;
  }

  .vui-clipboard__tooltip .vui-clipboard__tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #a4a4a47d;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 130%;
  }

  .vui-clipboard__tooltip .vui-clipboard__tooltiptext::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #a4a4a47d transparent transparent;
  }

  .vui-clipboard__tooltip:hover .vui-clipboard__tooltiptext {
    visibility: visible;
  }
}