@import "../../../velotooler-app";

.vui-modal-container {
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100% !important;
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1500;
  background-color: rgb(40, 40, 40, .8);
  opacity: 1;
  @include transition-all-short();

  .vui-modal {
    max-width: 800px;
    margin: 7% auto;
    position: relative;
    max-height: inherit;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    width: fit-content;
    background: white;
    padding: 4rem;
    border-radius: 1.875rem;
    row-gap: 2rem;
    transform: translateY(0);

    @include transition-all-short();

    .vui-modal__title {
      font-size: 1.375rem;
      font-weight: 700;
      line-height: 27px;
      text-align: center;
      text-transform: capitalize;
    }

    .vui-modal__body {

    }

    .vui-modal__actions {

    }
  }
}

.vui-modal--hide {
  opacity: 0;

  .vui-modal {
    transform: translateY(-32px);
  }
}
