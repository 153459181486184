md-switch {
  &.md-switch_green-theme {
    &.md-checked {
      .md-bar {
        background-color: rgba(81, 197, 126, 0.5);
      }
      .md-thumb {
        background-color: rgb(81, 197, 126);
      }
      &.md-focused {
        .md-thumb:before {
          background-color: rgba(81, 197, 126, 0.26);
        }
      }
    }
  }
}

md-toast {
  &.md-center {
    left: 50%;
    transform: translateX(-50%) translate3d(0, 0, 0);
    -webkit-font-smoothing: antialiased;
    -webkit-transform: translateX(-50%) translate3d(0, 0, 0);
  }
}
