.create-request-warning {
  .create-request-warning__title {
    font-size: 24px;
    color: #999;
    padding: 4px 0;

    @media (max-width: 470px) {
      font-size: 16px;
      color: #c3c3c3;
      padding: 4px 0;
    }
  }
}