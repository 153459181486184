@import "../variables";

.vt-section {
  h2 {
    font-size: 0.9em;
    text-transform: uppercase;
    font-weight: normal;
  }

  .vt-section__header {
    font-size: 14px;
    text-transform: uppercase;
  }

  &.vt-section_last {
    border-bottom: none;
  }

  &.vt-section_first {
    border-top: 1px solid $color-primary-l3;
  }

  border-bottom: 1px solid $color-primary-l3;
  padding-bottom: 20px;
}