.vt-modal-menu {

  display: flex;
  height: 100%;

  .vt-modal-menu__btn {
    background-color: #373737;
    padding: 12px;
    border-radius: 0;
    flex: 1;
    height: 100%;
  }

  .vt-modal-menu__main-btn {
    flex: 8;
    border-radius: 0;
    height: 100%;
  }

  .vt-modal-menu__content {
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    width: 100%;
    bottom: 0;
    z-index: 100;

    .md-button {
      color: white;
      padding: 13px 16px;
      text-decoration: none;
      display: block;
      border-radius: 0;
      background: #3f3f3f;
    }

    .md-button:hover {
      background-color: #464646;
    }
  }
}